<template>
    <div>
        <v-dialog v-model="visible" max-width="600">
            <v-card>
                <v-card-title> Compare {{ groupLabel }} Photos </v-card-title>

                <v-card-text>
                    <div>
                        <div class="d-flex justify-space-between">
                            <span> Progress: {{ resolvedGroupCount }}/{{ initialGroupCount }} resolved </span>
                            <span>{{ progressResolved }}%</span>
                        </div>
                        <v-progress-linear rounded v-model="progressResolved"></v-progress-linear>

                        <div class="d-flex justify-end my-6">
                            <v-btn @click="initSmartResolve" dark depressed color="#651FFF" class="no-text-transform">
                                <font-awesome-icon
                                    class="mr-2"
                                    icon="fa-solid fa-wand-magic-sparkles"
                                ></font-awesome-icon>
                                <span> Smart Resolve </span>
                            </v-btn>
                        </div>
                    </div>

                    <div class="p-3 groups-list-wrapper">
                        <div class="d-flex justify-space-between black--text">
                            <h5 class="font-weight-bold">{{ groupLabel }} Photos</h5>
                            <v-chip label color="#FEF3C7" text-color="#92400E">
                                {{ groups.length }} set{{ groups.length === 1 ? '' : 's' }} found</v-chip
                            >
                        </div>
                        <v-divider></v-divider>

                        <p v-if="!groups.length" class="text-center">No groups found</p>
                        <v-list three-line>
                            <template v-for="(group, index) in groups">
                                <!-- <v-subheader v-if="item.header" :key="index" v-text="item.header"></v-subheader> -->

                                <v-list-item>
                                    <v-list-item-icon>
                                        <div class="group-resolve-list">
                                            <template v-for="(slide, subIndex) in group.photos.slice(0, 2)">
                                                <!-- If it's the last visible image and there are more -->
                                                <span
                                                    style="position: relative"
                                                    v-if="subIndex === 1 && group.photos.length > 2"
                                                >
                                                    <ImageThumbnail
                                                        smart-crop
                                                        :src="slide.url"
                                                        :w="400"
                                                        :h="400"
                                                        :smartCrop="slide.type !== 1"
                                                        :type="slide.mediaType"
                                                        :alt="'Slide'"
                                                        class="slide-image"
                                                        :style="{
                                                            right: `${subIndex * 20}px`,
                                                            zIndex: 10 + subIndex,
                                                        }"
                                                    />

                                                    <div class="group-count-badge">
                                                        <span> +{{ group.photos.length - 2 }} </span>
                                                    </div>
                                                </span>

                                                <!-- Default image (no badge) -->
                                                <span v-else>
                                                    <ImageThumbnail
                                                        smart-crop
                                                        :key="slide.url"
                                                        :src="slide.url"
                                                        :w="400"
                                                        :h="400"
                                                        :smartCrop="slide.type !== 1"
                                                        :type="slide.mediaType"
                                                        :alt="'Slide'"
                                                        class="slide-image"
                                                        :style="{
                                                            right: `${subIndex * 20}px`,
                                                            zIndex: 10 + subIndex,
                                                        }"
                                                    />
                                                </span>
                                            </template>
                                        </div>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold"
                                            >Duplicate Set #{{ index + 1 }}</v-list-item-title
                                        >
                                        <v-list-item-subtitle>
                                            <span
                                                v-for="(name, idx) in getUniqueUserNameList(group.photos).slice(0, 2)"
                                            >
                                                <span v-if="idx == 1">and</span>

                                                <span class="font-weight-bold">
                                                    {{ name }}
                                                </span>
                                            </span>
                                            <span v-if="getUniqueUserNameList(group.photos).length > 2">...</span>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle></v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-btn
                                            @click="initSlideCompareModal(group.photos)"
                                            color="primary"
                                            depressed
                                            class="no-text-transform"
                                        >
                                            <font-awesome-icon
                                                class="mr-2"
                                                icon="fa-solid fa-magnifying-glass"
                                            ></font-awesome-icon>
                                            Compare</v-btn
                                        >
                                    </v-list-item-action>
                                </v-list-item>

                                <v-divider v-if="index != groups.length - 1"></v-divider>
                            </template>
                        </v-list>
                    </div>
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn outlined @click="visible = false" depressed class="no-text-transform">Close</v-btn>
                    <!-- <v-btn depressed class="no-text-transform">Next</v-btn> -->
                </v-card-actions>
            </v-card>
        </v-dialog>

        <SlideCompareModal
            @stage-delete="slide => initSingleSlideResolve(slide)"
            v-model="showSlideCompareModal"
            :slides="slidesToCompare"
        ></SlideCompareModal>
    </div>
</template>
<script>
import ImageThumbnail from '../../Media/ImageThumbnail.vue';
import SlideCompareModal from './SlideCompareModal.vue';
import { slideGroupTypes } from '../../../constants';

export default {
    data() {
        return {
            visible: this.value,
            showSlideCompareModal: false,
            slidesToCompare: [],
            groupTypes: slideGroupTypes,
            initialGroupCount: 0,
        };
    },
    components: { ImageThumbnail, SlideCompareModal },
    props: {
        groups: {
            type: Array,
            default: () => {},
        },
        groupType: {
            type: Number,
            required: true,
        },
        value: {
            type: Boolean,
        },
    },
    computed: {
        groupLabel() {
            if (this.groupType === this.groupTypes.DUPLICATE_PHASH) {
                return 'Duplicate';
            }

            if (this.groupType === this.groupTypes.SIMILAR_LSH_ANY) {
                return 'Similar';
            }
        },
        resolvedGroupCount() {
            const gc = this.initialGroupCount - this.groups.length;
            if (gc < 0) {
                return 0;
            }

            return gc;
        },
        progressResolved() {
            return Math.round((this.resolvedGroupCount / this.initialGroupCount) * 100) || 0;
        },
    },
    watch: {
        value(val) {
            this.visible = val;
        },
        visible(val) {
            this.$emit('input', val);

            if (val) {
                this.initialGroupCount = this.groups.length;
            }
        },
        groups(val) {
            if (val && val.length > this.initialGroupCount) {
                this.initialGroupCount = val.length;
            }
        },
    },
    methods: {
        initSmartResolve() {
            let slidesToDelete = [];
            this.groups.forEach(group => {
                const nonOriginals = group?.photos.slice(1);
                slidesToDelete = slidesToDelete.concat(nonOriginals);
            });

            this.$emit('stage-delete', slidesToDelete);
        },
        initSingleSlideResolve(slide) {
            this.$emit('stage-delete', [slide]);
        },
        initSlideCompareModal(slides) {
            this.slidesToCompare = slides;
            this.showSlideCompareModal = true;
        },
        getUniqueUserNameList(slides) {
            return [...new Set(slides.map(p => p.uploadUserName))];
        },
    },
    mounted() {
        if (this.groups && this.groups.length > this.initialGroupCount) {
            this.initialGroupCount = this.groups.length;
        }
    },
};
</script>
<style lang="scss" scoped>
.group-resolve-list {
    // border: 2px solid red;
    // position: relative;

    .slide-image {
        height: 60px;
        width: 60px;
        border-radius: 8px;
        position: relative;
        border: 2px solid white;
    }

    .group-count-badge {
        background-color: #1976d2;
        position: absolute;
        top: -25px;
        right: 10px;
        z-index: 15;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: white;
        border: 2px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.groups-list-wrapper {
    border: 1px solid black;
    border-radius: 8px;
}
</style>
