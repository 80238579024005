<template>
    <div class="container">
        <div class="card tribute-preview-card">
            <div class="card-header">
                <div class="header-title">
                    <font-awesome-icon icon="fa-regular fa-photo-video" class="fa-lg mr-2 header-icon" />
                    Preview
                </div>
                <!-- <div class="header-controls">
                    <v-btn @click="previewExpanded = !previewExpanded" plain icon>
                        <font-awesome-icon
                            icon="fa-regular fa-chevron-up"
                            class="fa-lg"
                            :class="{ 'fa-rotate-180': !previewExpanded }"
                        />
                    </v-btn>
                </div> -->
            </div>
            <div class="card-body" v-if="previewExpanded">
                <slot name="previewPlayer"></slot>
            </div>
            <div class="card-footer">
                <div>
                    <font-awesome-icon icon="fa-solid fa-clock" class="mr-2 preview-icon" />
                    Length: {{ formattedTributeDuration }}
                </div>
            </div>
        </div>

        <div :key="processingStatus" class="card summary-card">
            <h1 class="title">{{ summaryTitle }}</h1>

            <div v-if="processingStatus === processingStatusEnum.INITIAL" class="content-section">
                <div v-if="autoRenderActive" class="scheduled-render-section">
                    <div class="info-icon">
                        <font-awesome-icon icon="fa-solid fa-clock"></font-awesome-icon>
                    </div>
                    <div class="info-text">
                        <h3>Automatic Creation Scheduled</h3>
                        <p v-if="countdownExpired">
                            Contribution deadline has been reached. Your tribute video will be automatically created
                            soon if you don't create it manually.
                        </p>
                        <p v-else>
                            Your tribute video will be automatically created on
                            <span class="deadline">{{ tributeVideo.deadline | localTime }}</span> if you don't create it
                            manually.
                        </p>

                        <div class="countdown-wrapper">
                            <CountdownCards
                                :deadline="tributeVideo.deadline"
                                @countdown-expired="handleCountdownExpired"
                            />
                        </div>

                        <div class="actions">
                            <v-btn @click="disableAutoRenderConfirmModal = true" outlined color="#3b82f6">
                                <font-awesome-icon icon="fa-solid fa-circle-xmark" class="mr-2" />Disable auto-creation
                            </v-btn>
                            <span v-if="!countdownExpired" class="scheduled-time">{{
                                tributeVideo.deadline | localTime
                            }}</span>
                        </div>
                    </div>
                </div>

                <div class="details-section">
                    <h3>Ready When You Are</h3>
                    <p>Your tribute video is ready to be created. Click the button below to start processing.</p>

                    <div class="stats">
                        <div v-for="stat in stats">
                            <font-awesome-icon
                                :icon="getIcon(stat.status)"
                                class="mr-2"
                                :style="{ color: getIconColor(stat.status) }"
                            />

                            <span v-if="stat.title">
                                {{ stat.title }}
                            </span>
                            <span>
                                {{ stat.details }}
                            </span>
                        </div>

                        <span v-if="tributeBrandingSettingsLink" class="text-decoration-underline">
                            <a :href="tributeBrandingSettingsLink">Customize ending slide</a>
                        </span>
                    </div>
                </div>

                <div>
                    <v-btn
                        :disabled="disableSubmitRender"
                        @click="confirmRenderIfNeeded"
                        block
                        depressed
                        color="#ff6600"
                        :dark="!disableSubmitRender"
                    >
                        <font-awesome-icon class="mr-2" icon="fa-solid fa-wand-magic-sparkles"></font-awesome-icon>
                        Create Tribute Video Now</v-btn
                    >
                </div>
            </div>

            <div v-if="processingStatus === processingStatusEnum.COMPLETED" class="content-section">
                <div class="details-section">
                    <h3>Summary</h3>
                    <p>Your tribute video is complete.</p>

                    <div class="stats">
                        <div v-for="stat in stats">
                            <font-awesome-icon
                                :icon="getIcon(stat.status)"
                                class="mr-2"
                                :style="{ color: getIconColor(stat.status) }"
                            />

                            <span v-if="stat.title">
                                {{ stat.title }}
                            </span>
                            <span>
                                {{ stat.details }}
                            </span>
                        </div>
                        <span v-if="tributeBrandingSettingsLink" class="text-decoration-underline">
                            <a :href="tributeBrandingSettingsLink">Customize ending slide</a>
                        </span>
                    </div>
                </div>
                <div>
                    <v-btn
                        :disabled="disableSubmitRender"
                        @click="confirmRenderIfNeeded"
                        block
                        depressed
                        color="#ff6600"
                        :dark="!disableSubmitRender"
                    >
                        <font-awesome-icon class="mr-2" icon="fa-solid fa-wand-magic-sparkles"></font-awesome-icon>
                        Regenerate Tribute Video</v-btn
                    >
                </div>
            </div>

            <div v-if="processingStatus === processingStatusEnum.ERROR" class="content-section">
                <div class="details-section">
                    <h3>Summary</h3>
                    <p>An error occured when creating your tribute video.</p>

                    <div v-if="renderErrorMessage" class="error-message">
                        <p>{{ renderErrorMessage }}</p>
                    </div>

                    <div class="stats">
                        <div v-for="stat in stats">
                            <font-awesome-icon
                                :icon="getIcon(stat.status)"
                                class="mr-2"
                                :style="{ color: getIconColor(stat.status) }"
                            />

                            <span v-if="stat.title">
                                {{ stat.title }}
                            </span>
                            <span>
                                {{ stat.details }}
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <v-btn
                        :disabled="disableSubmitRender"
                        @click="confirmRenderIfNeeded"
                        block
                        depressed
                        color="#ff6600"
                        :dark="!disableSubmitRender"
                    >
                        <font-awesome-icon class="mr-2" icon="fa-solid fa-wand-magic-sparkles"></font-awesome-icon>
                        Regenerate Tribute Video</v-btn
                    >
                </div>
            </div>

            <div v-if="processingStatus === processingStatusEnum.PROCESSING" class="content-section">
                <div class="render-in-progress-container">
                    <img
                        src="https://memoryshareprod.blob.core.windows.net/front-end-assets/logo-animated.gif"
                        class="ms-loading-logo"
                    />

                    <p class="text-center">{{ currentRenderMessage }}</p>

                    <v-progress-linear
                        color="#ff6600"
                        v-model="renderProgress"
                        :indeterminate="indeterminate"
                        dark
                        :height="indeterminate ? 5 : 24"
                        rounded
                    >
                        <span v-if="!indeterminate"> {{ renderProgress }}% </span>
                    </v-progress-linear>
                </div>
                <div class="details-section">
                    <h3>Summary</h3>
                    <p>Your tribute video is currently processing.</p>

                    <div class="stats">
                        <div v-for="stat in stats">
                            <font-awesome-icon
                                :icon="getIcon(stat.status)"
                                class="mr-2"
                                :style="{ color: getIconColor(stat.status) }"
                            />

                            <span v-if="stat.title">
                                {{ stat.title }}
                            </span>
                            <span>
                                {{ stat.details }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog v-model="showConfirmRenderModal" :overlay-opacity="0.85" max-width="500">
            <template v-slot:default="{ isActive }">
                <v-card>
                    <v-card-text class="p-4">
                        <h4>Regenerate Tribute Video</h4>
                        <p>
                            You've made changes to your tribute video. To reflect these changes, the video needs to be
                            regenerated. A small fee will be charged. This will take a few minutes to complete.
                        </p>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="cancel" @click="() => (showConfirmRenderModal = false)">Cancel</v-btn>
                        <v-btn
                            :disabled="disableSubmitRender"
                            color="primary"
                            @click="
                                () => {
                                    submitTributeRender();
                                    showConfirmRenderModal = false;
                                }
                            "
                            >Proceed to Regenerate</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <v-dialog v-model="disableAutoRenderConfirmModal" max-width="400">
            <v-card>
                <v-card-title> Diasable Automatic Video Creation? </v-card-title>

                <v-card-text>
                    This tribute video is scheduled to automatically render on
                    <span class="text-decoration-underline font-weight-bold">{{
                        tributeVideo.deadline | localTime
                    }}</span
                    >. Disabling the automatic schedule render will remove the contribution deadline. Please confirm to
                    continue.
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn :disabled="loading" @click="disableAutoRenderConfirmModal = false" depressed>Cancel</v-btn>
                    <v-btn :disabled="loading" :loading="loading" @click="disableAutoRender" depressed color="error"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="mobile-spacer"></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CountdownCards from '@/components/ui/CountdownCards.vue';
import moment from 'moment';
import { creatomateRenderStatusEnum } from '@/constants';
import TributeVideoService from '@/services/tributeVideo.service';

export default {
    components: { CountdownCards },
    data() {
        return {
            creatomateRenderStatusEnum,
            currentTime: '00:00',
            progressPercentage: 0,
            // processingStatus: 'initial', // 'initial', 'processing', 'completed', 'error'
            processingStatusEnum: {
                INITIAL: 0,
                PROCESSING: 1,
                COMPLETED: 2,
                ERROR: 3,
            },
            processingSteps: [
                { id: 1, name: 'Preparing assets', completed: false, current: false },
                { id: 2, name: 'Applying theme', completed: false, current: false },
                { id: 3, name: 'Adding music', completed: false, current: false },
                { id: 4, name: 'Rendering video', completed: false, current: false },
                { id: 5, name: 'Finalizing', completed: false, current: false },
            ],
            error: null,
            renderRequirementStatuses: {
                REQUIREMENT_MET: 0,
                REQUIREMENT_NOT_MET: 1,
                OUT_OF_SYNC: 2,
                INFO: 3,
            },
            showConfirmRenderModal: false,
            tributeApi: null,
            disableAutoRenderConfirmModal: false,
            loading: false,
            renderInProg: false,
            previewExpanded: true,
            countdownExpired: false,
        };
    },
    props: {
        tributeVideo: {
            type: Object,
            default: () => {},
        },
        slideChanges: {
            type: Array,
            default: () => [],
        },
        mainPhotoChanges: {
            type: Array,
            default: () => [],
        },
        themeChanges: {
            type: Array,
            default: () => [],
        },
        musicChanges: {
            type: Array,
            default: () => [],
        },
    },
    inject: ['tributeRender', 'state'],
    filters: {
        localTime(val) {
            if (!val) return '';

            return moment.utc(val).local().format('MMM Do [at] h:mm A');
        },
    },
    watch: {
        renderComplete(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.renderInProg = false;
            }
        },
    },
    computed: {
        ...mapGetters('tributeVideo', [
            'selectedTheme',
            'selectedSongCount',
            'tributeIsRendering',
            'minimumCreateRequirementsMet',
            'currentRenderMessage',
            'renderStateText',
            'formattedTributeDuration',
            'estimatedRenderDuration',
        ]),
        renderErrorMessage() {
            if (this.tributeRender.standardRender.creatomateErrorMessage) {
                return this.tributeRender.standardRender.creatomateErrorMessage;
            }

            return null;
        },
        renderProgress() {
            return this.$store.state.tributeVideo.renderProgress;
        },
        autoRenderActive() {
            if (this.tributeRender?.standardRender?.url) return false;
            if (this.tributeVideo?.deadline) return true;

            return false;
        },
        disableSubmitRender() {
            if (this.tributeIsRendering) return true;

            if (!this.minimumCreateRequirementsMet) return true;

            return false;
        },
        renderStarted() {
            return !!this.$store.state.tributeVideo.renderStarted;
        },
        indeterminate() {
            return !this.renderStarted || this.renderProgress >= 100;
        },
        renderComplete() {
            return !!this.$store.state.tributeVideo.renderCompleted;
        },
        totalSlides() {
            return this.$store.state.tributeVideo.totalSlidesCount || 0;
        },
        estimatedProcessTime() {
            return Math.ceil(this.$store.state.tributeVideo.totalDuration * 2);
        },
        firstSelectedSong() {
            if (this.selectedSongCount > 0) {
                return this.$store.state.tributeVideo.selectedSongs[0];
            }

            return null;
        },
        processingStatus() {
            const status = this.tributeRender?.standardRender?.status;
            const url = this.tributeRender?.standardRender?.url;

            if (
                this.renderInProg ||
                status === creatomateRenderStatusEnum.RENDERING ||
                status === creatomateRenderStatusEnum.PLANNED
            ) {
                return this.processingStatusEnum.PROCESSING;
            }

            if (status === creatomateRenderStatusEnum.FAILED) {
                return this.processingStatusEnum.ERROR;
            }

            if (status === creatomateRenderStatusEnum.SUCCEEDED && url) {
                return this.processingStatusEnum.COMPLETED;
            }

            return this.processingStatusEnum.INITIAL;
        },
        summaryTitle() {
            if (this.processingStatus === this.processingStatusEnum.COMPLETED) {
                return 'Tribute Video Summary';
            }

            if (this.processingStatus === this.processingStatusEnum.PROCESSING) {
                return this.renderStateText;
            }

            if (this.processingStatus === this.processingStatusEnum.INITIAL) {
                return 'Complete Your Tribute Video';
            }

            return 'Complete Your Tribute Video';
        },
        stats() {
            let songDetails;
            if (this.selectedSongCount > 0) {
                if (this.firstSelectedSong.title) {
                    songDetails = `"${this.firstSelectedSong.title}" by ${
                        this.firstSelectedSong.artist ? this.firstSelectedSong.artist : 'Unknown'
                    }`;
                }

                if (this.selectedSongCount > 1) {
                    songDetails += ` and  ${this.selectedSongCount - 1} more`;
                }
            }
            const hasMainPhoto =
                this.$store.state.tributeVideo.mainPhotoId > 0
                    ? this.renderRequirementStatuses.REQUIREMENT_MET
                    : this.renderRequirementStatuses.REQUIREMENT_NOT_MET;
            const slideStatus = this.getStatus(this.slideChanges, this.totalSlides);
            const songStatus = this.getStatus(this.musicChanges, this.selectedSongCount, true);

            const themeCount = this.selectedTheme?.id ? 1 : 0;
            const themeStatus = this.getStatus(this.themeChanges, themeCount);

            return [
                {
                    id: 0,
                    title: 'Profile photo ',
                    details: hasMainPhoto === 0 ? 'set' : 'not set',
                    status: hasMainPhoto,
                },
                { id: 1, title: null, details: `${this.totalSlides} slides prepared`, status: slideStatus },
                {
                    id: 2,
                    title: 'Theme:',
                    details: this.selectedTheme ? this.selectedTheme.title : '',
                    status: themeStatus,
                },
                { id: 3, title: 'Music:', details: songDetails, status: songStatus },
                {
                    id: 4,
                    title: 'Estimated processing time:',
                    details: this.estimatedRenderDuration,
                    status: this.renderRequirementStatuses.INFO,
                },
            ];
        },
        tributeBrandingSettingsLink() {
            if (this.$auth.role.includes('SuperAdmin') && this.tributeVideo.funeralHomeId > 0) {
                return `${process.env.VUE_APP_BASE_URI}/homes/settings/${this.tributeVideo.funeralHomeId}?tab=branding&brandingTab=tribute`;
            }

            if (this.$auth.role.includes('FuneralHome') || this.$auth.role.includes('Owner')) {
                const fhId = this.$auth?.user['https://memoryshare.com/funeral_home_id'];

                if (fhId > 0) {
                    return `${process.env.VUE_APP_BASE_URI}/settings?tab=branding&brandingTab=tribute`;
                }
            }

            return null;
        },
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),

        handleCountdownExpired() {
            this.countdownExpired = true;
        },
        initRenderProgress() {
            const duration = this.$store.state.tributeVideo.totalDuration * 2;

            this.$store.dispatch('tributeVideo/updateRenderProgress', {
                duration: duration,
                started: moment().toISOString(),
            });

            this.$store.dispatch('tributeVideo/cycleRenderMessages');
        },
        formatTimeStamp(seconds) {
            const duration = moment.duration(seconds, 'seconds');
            const minutes = duration.minutes();
            const secs = duration.seconds();

            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(secs).padStart(2, '0');

            return `${formattedMinutes}:${formattedSeconds}`;
        },

        getStatus(changesArr, selectedArrCount = 0, isOptional = false) {
            const statusDict = this.renderRequirementStatuses;

            if (Array.isArray(changesArr) && changesArr.length > 0) {
                return statusDict.OUT_OF_SYNC;
            }

            if (selectedArrCount > 0) {
                return statusDict.REQUIREMENT_MET;
            }

            if (isOptional) {
                return statusDict.WARNING;
            }

            return statusDict.REQUIREMENT_NOT_MET;
        },
        getIcon(status) {
            const statusDict = this.renderRequirementStatuses;

            switch (status) {
                case statusDict.REQUIREMENT_MET:
                    return 'fa-solid fa-circle-check';
                case statusDict.REQUIREMENT_NOT_MET:
                    return 'fa-solid fa-circle-xmark';
                case statusDict.INFO:
                    return 'fa-solid fa-circle-info';
                case statusDict.OUT_OF_SYNC:
                    return 'fa-solid fa-circle-exclamation';
                case statusDict.WARNING:
                    return 'fa-solid fa-circle-exclamation';
                default:
                    console.log('Unhandled status');
            }
        },
        getIconColor(status) {
            const statusDict = this.renderRequirementStatuses;

            switch (status) {
                case statusDict.REQUIREMENT_MET:
                    return '#00c853';
                case statusDict.REQUIREMENT_NOT_MET:
                    return '#ff5353';
                case statusDict.INFO:
                    return '#3b82f6';
                case statusDict.OUT_OF_SYNC:
                    return '#fb8c00';
                case statusDict.WARNING:
                    return '#fb8c00';
                default:
                    console.log('Unhandled status');
            }
        },
        async disableAutoRender() {
            try {
                this.loading = true;
                await this.removeDeadline();
                this.disableAutoRenderConfirmModal = false;
            } catch (error) {
                console.log(error, 'error');
            } finally {
                this.loading = false;
            }
        },
        async removeDeadline() {
            if (!this.tributeVideo?.id) return;

            const deadlineData = { deadline: null };

            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                ...deadlineData,
            });

            try {
                await this.tributeApi.updateDeadline(this.tributeVideo.id, deadlineData);
                this.showSnackbar({ message: 'Deadline removed' });
            } catch (error) {
                console.log('error updating deadline', error);
                this.showSnackbar({ message: 'Error removing deadline', color: 'error' });
            }
        },
        confirmRenderIfNeeded() {
            // if there are no unsynced changes then there's no need to render again..
            // Let the user know

            if (this.tributeRender?.standardRender?.url) {
                this.showConfirmRenderModal = true;
            } else {
                this.submitTributeRender();
            }
        },

        initRenderState() {
            // Starts the render loading UI
            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                renderStarted: moment().toISOString(),
                // Reset any previous render stats/state
                renderCompleted: null,
                overlayDismissed: false,
            });
        },
        delayRenderCall() {
            return new Promise(resolve => {
                const renderDelay = this.$store.getters['tributeVideo/delayRenderCallByMilliseconds'];
                console.log('Delaying render by:', renderDelay);
                const renderTimeout = setTimeout(() => {
                    console.log('Render delay complete');
                    resolve();
                }, renderDelay);

                // Optional: store a reference if cancellation is needed
                this.cancelRenderDelay = () => {
                    clearTimeout(renderTimeout);
                    resolve();
                };
            });
        },
        async submitRenderApiCall(tributeId) {
            try {
                await this.tributeApi.submitRender(tributeId);
            } catch (err) {
                console.error('Render Error:', err);
                // Reset render state
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    renderStarted: null,
                    overlayDismissed: true,
                    renderCompleted: null,
                });
                this.renderInProg = false;
                this.showSnackbar({
                    color: 'error',
                    message: 'Rendering the video has failed. Please wait and try again.',
                });
                throw err; // rethrow to be handled by outer catch if needed
            }
        },
        handleRenderError(error) {
            const errorMessage = error.response?.data?.Message || '';
            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                renderStarted: null,
                overlayDismissed: true,
                renderCompleted: null,
            });
            this.renderInProg = false;
            const message =
                errorMessage === 'Multipart render error'
                    ? 'Multipart render error, please try another template.'
                    : 'Error creating tribute video';
            this.showSnackbar({ color: 'error', message });
        },
        async submitTributeRender() {
            try {
                if (!this.tributeVideo.id) {
                    this.showSnackbar({ message: 'Error submitting render', color: 'error' });
                    return;
                }

                this.initRenderState();
                this.loading = true;

                await this.delayRenderCall();

                await this.submitRenderApiCall(this.tributeVideo.id);

                this.showSnackbar({
                    message: 'Tribute Video Uploaded. Please allow time for processing',
                });
                // TODO: Fetch render status on page mount
                this.renderInProg = true;
                this.initRenderProgress();
                this.$emit('render-started');
            } catch (error) {
                this.handleRenderError(error);
            } finally {
                this.loading = false;
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async mounted() {
        if (this.state.token) {
            this.token = this.state.token;
        } else {
            await this.setAuthToken();
        }

        this.tributeApi = TributeVideoService(this.token);
    },
};
</script>
<style lang="scss" scoped>
.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}

.preview-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.card {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #fff;
    margin-bottom: 20px;
}

.title {
    // font-size: 24px;
    // font-weight: bold;
    // color: #333;
    margin-bottom: 20px;
}
.stats {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 24px 0;
}
.deadline {
    font-weight: bold;
    text-decoration: underline;
}
.scheduled-time {
    font-style: italic;
}
.content-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    justify-content: center;
}

.scheduled-render-section {
    background: #f0f8ff;
    border: 1px solid #b0c4de;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    color: #3b82f6;

    @include mobile() {
        flex-direction: column;
    }
}

.error-message {
    background: #ffefef;
    border: 1px solid rgba(255, 82, 84, 1);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    color: rgba(255, 82, 84, 1);
}

.details-section {
    border: 1px solid #e5e7eb;
    background-color: $light-gray;
    padding: 16px;
    border-radius: 8px;
}

.info-text {
    flex-grow: 1;
}

.info-icon {
    background: #e0ecff;
    padding: 10px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

h3 {
    font-size: 16px;
    font-weight: bold;
}

p {
    font-size: 14px;
}

.countdown-wrapper {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    gap: 24px;
    flex-wrap: wrap;
}

.link-button {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
}
.summary-card {
    flex-grow: 1;
    flex-basis: 50%;
    height: 100%;

    @include desktop() {
        margin: 0;
    }
}
.tribute-preview-card {
    // max-width: 500px;
    margin: auto;
    padding: 0;
    flex: 1;
    width: 100%;
    height: 100%;

    // to prevent header corners from protruding past the rounded corners
    overflow: hidden;

    @include desktop() {
        // max-width: 700px;
        flex-basis: 35%;
    }
    .card-header {
        justify-content: space-between;
        display: flex;
        background: white;
        align-items: center;
    }
    .header-title {
        font-weight: bold;
    }
    .header-icon {
        color: $primary-orange;
    }

    &::v-deep {
        .preview-player {
            min-height: auto;
        }
        .preview-player-inner {
            margin: 0;
        }
    }
    .preview-icon {
        color: $primary-orange;
    }
}
.ms-loading-logo {
    margin: auto;
    max-height: 100px;
    position: relative;
}
.render-in-progress-container {
    display: flex;
    flex-direction: column;
}

.mobile-spacer {
    height: 50px;
    display: none;
}

@include mobile() {
    .countdown {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .mobile-spacer {
        display: block;
    }
}

@media (max-width: 994px) {
    .container {
        grid-template-columns: 1fr;
    }

    .summary-card {
        order: 1;
    }

    .tribute-preview-card {
        order: 3;
    }

    .mobile-spacer {
        order: 3;
    }
}
</style>
