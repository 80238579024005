<template>
    <AppLayout :has-background="true" :contained="true">
        <div class="bg-white p-4 rounded elevation-6">
            <div class="header">
                <h4>Settings</h4>
            </div>
            <v-progress-circular v-if="!token" indeterminate color="primary"></v-progress-circular>
            <div v-else>
                <v-tabs show-arrows v-model="tab" color="#0d5259">
                    <v-tab href="#home">Home</v-tab>
                    <v-tab href="#branding">Branding</v-tab>
                    <!-- <v-tab href="#marketing">Marketing</v-tab> -->
                    <v-tab href="#general">General</v-tab>
                    <v-tab href="#music">Music License</v-tab>
                    <v-tab v-if="!isDevHome" href="#subscription">Subscription</v-tab>
                    <v-tab href="#integrations">Integrations</v-tab>
                </v-tabs>

                <v-tabs-items :value="tab">
                    <v-tab-item value="home">
                        <home-settings @keyword-report-link="link => handleReportLink(link)" :token="token" />
                    </v-tab-item>
                    <v-tab-item value="branding">
                        <branding :token="token" :id="this.$auth.funeralHomeId" :isActive="tab === 'branding'" />
                    </v-tab-item>
                    <v-tab-item value="marketing">
                        <marketing-panel :token="token" :id="this.$auth.funeralHomeId" />
                    </v-tab-item>
                    <v-tab-item value="general">
                        <general-settings modal="false" :token="token" ref="generalSettings" />
                    </v-tab-item>
                    <v-tab-item value="music">
                        <music-license :token="token" />
                    </v-tab-item>
                    <v-tab-item value="subscription">
                        <subscription-panel :token="token" :funeralHomeId="this.$auth.funeralHomeId" />
                    </v-tab-item>
                    <v-tab-item value="integrations">
                        <integration-settings :token="token" :funeralHomeId="this.$auth.funeralHomeId" />
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
    </AppLayout>
</template>

<script>
import WelcomeModal from '@/components/Modals/WelcomeModal';
import StripeCard from '@/components/ui/StripeCard';
import GeneralSettings from '@/components/Settings/GeneralSettings';
import IntegrationSettings from '@/components/Settings/IntegrationSettings';
import SubscriptionPanel from '@/components/Panels/SubscriptionPanel';
import HomeSettings from '@/components/Settings/HomeSettings.vue';
import musicLicense from '@/components/Misc/MusicLicense';
import Branding from '@/components/Settings/Branding.vue';
import MarketingPanel from '@/components/Panels/MarketingPanel.vue';
export default {
    metaInfo: {
        title: 'Settings',
    },
    data() {
        return {
            // tab: null,
            isDevHome: null,
            token: '',
            message: '',
            keywordReportLink: '',
        };
    },

    methods: {
        getToken() {
            return this.$auth.getIdTokenClaims().then(result => {
                this.token = result.__raw;
            });
        },
        setIsDevHome() {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/funeralhomes/${this.$auth.funeralHomeId}`)
                .then(response => {
                    const home = response.data.funeralHome;
                    this.isDevHome = home.devHome;
                });
        },
    },
    async created() {
        await this.getToken();
        this.setIsDevHome();
        //this.tab = this.$route.query.tab || 'home';
    },
    components: {
        WelcomeModal,
        StripeCard,
        GeneralSettings,
        IntegrationSettings,
        SubscriptionPanel,
        HomeSettings,
        musicLicense,
        Branding,
        MarketingPanel,
    },
    computed: {
        tab: {
            set(tab) {
                this.$router.replace({ query: { ...this.$route.query, tab } });
            },
            get() {
                return this.$route.query.tab;
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.dash-container {
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/f9ae4de7-3055-49e2-885e-92405c900525.svg')
        no-repeat 0 3rem;
}

.tile {
    width: 70%;
    margin: 4rem auto 10%;
    padding: 2rem inherit;
}

.v-tabs-items {
    padding: 3rem 2rem;
}

#uppy-select-files {
    h6 {
        display: inline-block;
    }
}

.callout-section {
    vertical-align: bottom;
}
</style>
