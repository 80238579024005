<template>
    <div class="billing-container">
        <div class="tab-padding" style="position: relative">
            <!-- <div class="d-flex" style="justify-content: space-between">
                <div class="text-center" style="display: flex; flex-direction: column; width: 100%">
                    <v-alert
                        @click="openBillingPortal()"
                        v-if="!billingForm.hasDefaultPaymentMethod && !loading"
                        border="top"
                        color="red lighten-2"
                        dark
                    >
                        No credit card on file. Please update to edit the billing information.
                    </v-alert>
                </div>
            </div> -->

            <div class="top-row">
                <div class="stripe">
                    <div
                        class="stripe-ids elevation-2"
                        v-if="$auth.role.includes('SuperAdmin') && billingForm.stripeCustomerId"
                    >
                        <div class="d-flex justify-center">
                            <font-awesome-icon icon="fa-brands fa-stripe" style="font-size: 2rem; padding-left: 5px" />
                        </div>
                        <div v-if="billingForm.stripeCustomerId" class="stripe-a-group">
                            <a
                                class="stripe-anchor"
                                :href="`${stripe_url}/customers/${billingForm.stripeCustomerId}`"
                                target="_blank"
                                >{{ billingForm.stripeCustomerId }}</a
                            >
                            <v-btn
                                @click="copyToClip(billingForm.stripeCustomerId)"
                                color="#625afa"
                                class="btn"
                                text
                                small
                            >
                                <font-awesome-icon icon="fa-regular fa-clone" />
                            </v-btn>
                        </div>
                        <div v-if="billingForm.subscriptionId" class="stripe-a-group">
                            <a
                                class="stripe-anchor"
                                :href="`${stripe_url}/subscriptions/${billingForm.subscriptionId}`"
                                target="_blank"
                                >{{ billingForm.subscriptionId }}</a
                            >
                            <v-btn
                                @click="copyToClip(billingForm.subscriptionId)"
                                color="#625afa"
                                class="btn"
                                text
                                small
                            >
                                <font-awesome-icon icon="fa-regular fa-clone" />
                            </v-btn>
                        </div>
                    </div>

                    <div v-else>
                        <v-skeleton-loader type="list-item, list-item,list-item"></v-skeleton-loader>
                    </div>
                </div>

                <div v-if="billingForm.subscriptionStatus != null" class="btn-group">
                    <div class="inner">
                        <v-btn color="#0d3d60" dark small @click="openBillingPortal()"
                            >Update Payment and Billing Address</v-btn
                        >
                        <v-chip
                            :color="subscriptionStatuses[billingForm.subscriptionStatus].color"
                            dark
                            label
                            style="width: 100%; display: flex; justify-content: center"
                            @click="subscriptionStatusDialog = true"
                            v-if="$auth.role.includes('SuperAdmin')"
                        >
                            <span>
                                Subscription Status |
                                <strong>{{ subscriptionStatuses[billingForm.subscriptionStatus].label }}</strong>
                            </span>
                        </v-chip>

                        <v-chip
                            style="width: 100%; display: flex; justify-content: center"
                            v-else
                            :color="subscriptionStatuses[billingForm.subscriptionStatus].color"
                            dark
                        >
                            <span>{{ subscriptionStatuses[billingForm.subscriptionStatus].label }}</span>
                        </v-chip>

                        <v-btn
                            small
                            :dark="
                                !(!billingForm.hasDefaultPaymentMethod && FuneralHomeBillCollectionOptions.value === 0)
                            "
                            :disabled="
                                !billingForm.hasDefaultPaymentMethod && FuneralHomeBillCollectionOptions.value === 0
                            "
                            @click="togglePromo"
                            :color="promoOptions.promo && currentSubscriptionStatus != 10 ? '#FFC400' : ''"
                            ><span v-if="currentSubscriptionStatus == 10"
                                >{{ promoOptions.promo ? 'Disable' : 'Set' }} Promo
                            </span>
                            <span v-if="currentSubscriptionStatus != 10"
                                >{{ promoOptions.promo ? 'Cancel' : 'Set' }} Promo
                            </span>
                        </v-btn>
                    </div>
                </div>
                <div class="mobile-hidden" v-else style="width: 50%">
                    <v-skeleton-loader type="list-item, list-item,list-item"></v-skeleton-loader>
                </div>
            </div>

            <div
                style="border: 2px solid #ff530d; border-radius: 10px"
                class="mt-5 p-2 elevation-2"
                v-if="promoOptions.promo"
            >
                <div>
                    <h5 style="color: #ff530d">Promotional Price</h5>
                </div>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            attach=""
                            item-text="label"
                            item-value="value"
                            label="Live Support"
                            :items="boolOptions"
                            v-model="promoOptions.liveSupport"
                        ></v-select>
                        <div style="display: flex">
                            <v-text-field
                                v-if="promoOptions.streamsPerMonth != 199"
                                v-model="promoOptions.streamsPerMonth"
                                type="number"
                                label="Streams Per Month"
                            >
                            </v-text-field>
                            <v-text-field
                                v-if="promoOptions.streamsPerMonth == 199"
                                disabled
                                type="number"
                                label="Unlimited Streams Per Month"
                            >
                                <!-- <template v-slot:prepend-inner>
                                    <div>
                                        <font-awesome-icon icon="fa-solid fa-infinity"></font-awesome-icon>
                                    </div>
                                </template> -->
                            </v-text-field>

                            <v-btn
                                :color="promoOptions.streamsPerMonth == 199 ? 'error' : ''"
                                class="ml-5 mt-5"
                                small
                                @click="toggleUnlimitedPromoStreams"
                                ><font-awesome-icon icon="fa-solid fa-infinity"></font-awesome-icon>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <vuetify-money
                            v-model="promoOptions.price"
                            :disabled="
                                (!billingForm.hasDefaultPaymentMethod &&
                                    FuneralHomeBillCollectionOptions.value === 0) ||
                                currentSubscriptionStatus === 9
                            "
                            label="Promo Price"
                            :options="currencyOptions"
                        ></vuetify-money>
                        <calendar v-model="promoOptions.endDate" :output-local="true" label="Promo End Date"></calendar>
                        <!-- <v-text-field label="Promo Length" type="number" min="1"></v-text-field> -->
                    </v-col>
                </v-row>
            </div>

            <div style="position: relative">
                <div class="billing-overlay" v-if="notSafeToEdit">
                    <!-- <p>Stripe is currently finalizing the previous billing cycle. This usually takes 2-3 hours.</p> -->

                    <p>
                        Stripe is currently finalizing the previous billing cycle. This usually takes 1-2 hours. If this
                        message is persistent for longer please check invoice
                        <a
                            id="invoice-link"
                            v-if="notSafeInvoice"
                            :href="getStripeUrl(notSafeInvoice.stripeId)"
                            target="_blank"
                            >{{ notSafeInvoice.stripeId }}</a
                        >. To get rid of this message either pay, or void this invoice.
                    </p>

                    <v-btn
                        v-if="$auth.role.includes('SuperAdmin') && pastDueInvoices.length > 0"
                        :loading="loading"
                        :disabled="loading"
                        :dark="!loading"
                        color="red"
                        @click="initCancelPastDueSubModal"
                    >
                        <font-awesome-icon icon="fa-regular fa-triangle-exclamation" class="mr-2" />
                        <span>Cancel Subscription</span>
                    </v-btn>

                    <div
                        style="border: 2px solid red"
                        v-if="$auth.role.includes('SuperAdmin')"
                        class="loom-embed elevation-4 p-2"
                    >
                        <h6>Questions? 👇</h6>
                        <a href="https://www.loom.com/share/f18cd58f823f43b08cdc6ecf6ddff812" target="_blank">
                            <!-- <p>How to Fix a Stuck Invoice in Stripe 🤔 - Watch Video</p> -->
                            <img
                                style="max-width: 100%; border-radius: 10px"
                                src="https://cdn.loom.com/sessions/thumbnails/f18cd58f823f43b08cdc6ecf6ddff812-with-play.gif"
                            />
                        </a>
                    </div>
                </div>

                <div class="mt-5 mb-3 p-2" :class="promoOptions.promo ? 'promoIsActive' : ''">
                    <div v-if="promoOptions.promo">
                        <h5>Standard Pricing Starting on {{ promoOptions.endDate | nextDay() }}</h5>
                    </div>
                    <div>
                        <v-row>
                            <v-col cols="12" md="6">
                                <p>Billing Presets</p>
                                <div v-if="$auth.role.includes('SuperAdmin')" class="d-flex">
                                    <v-btn
                                        :disabled="
                                            (billingForm.hasDefaultPaymentMethod === false &&
                                                FuneralHomeBillCollectionOptions.value === 0) ||
                                            currentSubscriptionStatus === 9
                                        "
                                        @click="billingPreset(1)"
                                        class="me-2"
                                        >$249</v-btn
                                    >
                                    <v-btn
                                        :disabled="
                                            (billingForm.hasDefaultPaymentMethod === false &&
                                                FuneralHomeBillCollectionOptions.value === 0) ||
                                            currentSubscriptionStatus === 9
                                        "
                                        @click="billingPreset(2)"
                                        class="me-2"
                                        >$349</v-btn
                                    >
                                    <v-btn
                                        :disabled="
                                            (billingForm.hasDefaultPaymentMethod === false &&
                                                FuneralHomeBillCollectionOptions.value === 0) ||
                                            currentSubscriptionStatus === 9
                                        "
                                        @click="billingPreset(3)"
                                        class="me-2"
                                        >$449</v-btn
                                    >
                                    <v-btn
                                        :disabled="
                                            (billingForm.hasDefaultPaymentMethod === false &&
                                                FuneralHomeBillCollectionOptions.value === 0) ||
                                            currentSubscriptionStatus === 9
                                        "
                                        @click="billingPreset(4)"
                                        class="me-2"
                                        >$549</v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <v-form ref="billingForm" class="billing-form">
                        <div class="mt-10">
                            <h5>General</h5>
                            <!-- Subscription Cost, Billed Annually, Currency, Subscription Start Date, Payment Collection -->
                            <v-row>
                                <v-col cols="12" md="6">
                                    <vuetify-money
                                        v-model="billingForm.subscriptionCost"
                                        :disabled="
                                            (!billingForm.hasDefaultPaymentMethod &&
                                                FuneralHomeBillCollectionOptions.value === 0) ||
                                            currentSubscriptionStatus === 9
                                        "
                                        label="Subscription Cost"
                                        :options="currencyOptions"
                                        :rules="[v => !!v || 'Required']"
                                        class="money-input"
                                        :background-color="promoOptions.promo ? '#f1f4f7' : ''"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-select
                                        return-object
                                        v-model="FuneralHomeBillCollectionOptions"
                                        :items="paymentCollection"
                                        item-text="title"
                                        item-value="value"
                                        label="Payment Collection"
                                        :disabled="
                                            currentSubscriptionStatus === 9 ||
                                            billingForm.hasDefaultPaymentMethod === false
                                        "
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-select
                                        v-model="billingForm.currency"
                                        :items="currencies"
                                        item-text="currency"
                                        item-value="code"
                                        label="Currency"
                                        return-object
                                        :disabled="
                                            (billingForm.hasDefaultPaymentMethod === false &&
                                                FuneralHomeBillCollectionOptions.value === 0) ||
                                            billingForm.subscriptionId != null ||
                                            currentSubscriptionStatus === 9
                                        "
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-select
                                        v-model="billingForm.billedAnnually"
                                        :items="billedAnnuallyOptions"
                                        label="Billed Annually"
                                        return-object
                                        item-text="text"
                                        item-value="value"
                                        :disabled="true"
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <calendar
                                        :output-local="true"
                                        :disabled="
                                            (billingForm.hasDefaultPaymentMethod === false &&
                                                FuneralHomeBillCollectionOptions.value === 0) ||
                                            billingForm.subscriptionId != null ||
                                            currentSubscriptionStatus === 9
                                        "
                                        readonly
                                        v-model="billingForm.subscriptionStartDate"
                                        label="Subscription Start Date"
                                        required
                                        :rules="[v => !!v || 'Required']"
                                    />
                                    <small
                                        style="position: absolute; margin-top: -1.1rem; opacity: 0.5"
                                        v-if="billingForm.subscriptionEndDate"
                                        >Subscription End Date: {{ billingForm.subscriptionEndDate | moment('l') }}
                                    </small>
                                </v-col>
                            </v-row>

                            <div class="mt-10 d-flex justify-content-between align-items-center">
                                <h5>
                                    Live Streaming{{
                                        billingForm.tributeVideosEnabled && billingForm.isTributeVideosBundled
                                            ? ' & Tribute Videos'
                                            : ''
                                    }}
                                </h5>
                                <v-switch
                                    v-model="billingForm.liveStreamEnabled"
                                    :label="billingForm.liveStreamEnabled ? 'Enabled' : 'Disabled'"
                                ></v-switch>
                            </div>

                            <!-- Streams Per Month, Max Viewers, Max Stream Length,  Cost Per Additional Stream, Cost Per 500 Viewers, Cost Per 15 minutes -->
                            <v-row v-if="billingForm.liveStreamEnabled">
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="billingForm.streamsPerMonth"
                                        :disabled="isStreamingFieldsDisabled"
                                        type="number"
                                        :label="
                                            billingForm.tributeVideosEnabled && billingForm.isTributeVideosBundled
                                                ? 'Services Per Month'
                                                : 'Streams Per Month'
                                        "
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="billingForm.maxViewers"
                                        :disabled="isStreamingFieldsDisabled"
                                        type="number"
                                        autocomplete="false"
                                        label="Max Viewers"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="billingForm.maxStreamLength"
                                        :disabled="isStreamingFieldsDisabled"
                                        autocomplete="false"
                                        hint="in minutes"
                                        :label="
                                            billingForm.tributeVideosEnabled && billingForm.isTributeVideosBundled
                                                ? 'Max Service Length'
                                                : 'Max Stream Length'
                                        "
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <vuetify-money
                                        v-model="billingForm.costPerAdditionalStream"
                                        :disabled="isStreamingFieldsDisabled"
                                        label="Cost Per Additional Stream"
                                        :options="currencyOptions"
                                        :rules="[v => !!v || 'Required']"
                                        :background-color="promoOptions.promo ? '#f1f4f7' : ''"
                                    ></vuetify-money>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <!-- cost per 100 has changed to cost per 500 / backend varaibles have not been updated -->
                                    <vuetify-money
                                        v-model="billingForm.costPer100Viewers"
                                        :disabled="isStreamingFieldsDisabled"
                                        label="Cost Per 500 Viewers"
                                        :options="currencyOptions"
                                        :background-color="promoOptions.promo ? '#f1f4f7' : ''"
                                    ></vuetify-money>
                                    <!-- <v-text-field v-model="billingForm.costPer100Viewers" label="Cost Per 100 Viewers" :rules="[v => !!v || 'Required']"></v-text-field> -->
                                </v-col>

                                <v-col cols="12" md="6">
                                    <vuetify-money
                                        v-model="billingForm.costPerFifteenMinute"
                                        :disabled="isStreamingFieldsDisabled"
                                        label="Cost Per Fifteen Minute"
                                        :options="currencyOptions"
                                        :rules="[v => !!v || 'Required']"
                                        :background-color="promoOptions.promo ? '#f1f4f7' : ''"
                                    ></vuetify-money>
                                </v-col>
                            </v-row>

                            <div class="mt-10 d-flex justify-content-between align-items-center">
                                <h5>Tribute Videos</h5>

                                <v-switch
                                    v-model="billingForm.tributeVideosEnabled"
                                    :label="billingForm.tributeVideosEnabled ? 'Enabled' : 'Disabled'"
                                >
                                </v-switch>
                            </div>

                            <v-row v-if="billingForm.tributeVideosEnabled" class="mb-3">
                                <v-col cols="12">
                                    <v-switch
                                        v-model="billingForm.isTributeVideosBundled"
                                        label="Bundled with Live Streaming"
                                        :disabled="isTributeFieldsDisabled"
                                    ></v-switch>

                                    <v-alert v-if="billingForm.isTributeVideosBundled" text outlined type="info">
                                        <strong>Bundle Enabled</strong>: Each service can have both live streams and
                                        tribute videos. One Person = One Service. Overages still apply, we add total
                                        usage of live stream/recording and tribute video.
                                    </v-alert>
                                    <!-- <v-checkbox
                                        v-model="billingForm.isTributeVideosBundled"
                                        label="Bundled with Live Streaming"
                                        :disabled="isTributeFieldsDisabled"
                                    /> -->
                                </v-col>

                                <v-col v-if="billingForm.isTributeVideosBundled == false" cols="12" md="6">
                                    <v-text-field
                                        v-model="billingForm.tributeVideosPerMonth"
                                        :disabled="isTributeFieldsDisabled"
                                        type="number"
                                        label="Tribute Videos Per Month"
                                    ></v-text-field>
                                </v-col>

                                <v-col v-if="billingForm.isTributeVideosBundled == false" cols="12" md="6">
                                    <vuetify-money
                                        v-model="billingForm.costPerTributeVideo"
                                        :disabled="isTributeFieldsDisabled"
                                        label="Cost Per Additional Video"
                                        :options="currencyOptions"
                                        :background-color="promoOptions.promo ? '#f1f4f7' : ''"
                                    ></vuetify-money>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </div>

                <v-row no-gutters>
                    <v-col cols="12" md="5">
                        <div class="l-btn-group">
                            <v-btn @click="openBillingDocs">
                                <div style="display: flex; align-items: center">
                                    <div class="img-container mr-2">
                                        <img :src="require('@/assets/images/notion_logo.png')" alt="" />
                                    </div>
                                    <span> Documentation </span>
                                </div>
                            </v-btn>
                        </div>
                    </v-col>

                    <v-col cols="12" md="7">
                        <div class="r-btn-group">
                            <v-btn
                                v-if="currentSubscriptionStatus === 0 || currentSubscriptionStatus === 10"
                                :loading="loading"
                                :disabled="loading"
                                :dark="!loading"
                                @click="changeModal(1, false)"
                                color="red"
                            >
                                <font-awesome-icon icon="fa-regular fa-triangle-exclamation" class="mr-2" />
                                <span>Cancel</span>
                            </v-btn>

                            <v-btn
                                v-if="
                                    $auth.role.includes('SuperAdmin') &&
                                    currentSubscriptionStatus === 5 &&
                                    pastDueInvoices.length > 0
                                "
                                :loading="loading"
                                :disabled="loading"
                                :dark="!loading"
                                color="red"
                                @click="initCancelPastDueSubModal"
                            >
                                <font-awesome-icon icon="fa-regular fa-triangle-exclamation" class="mr-2" />
                                <span>Cancel</span>
                            </v-btn>

                            <v-btn
                                v-if="currentSubscriptionStatus === 9"
                                :loading="loading"
                                :disabled="loading"
                                @click="reactivateSub($route.params.id)"
                                color="success"
                            >
                                Reactivate Subscription
                            </v-btn>
                            <v-btn
                                v-else-if="currentSubscriptionStatus >= 1 && currentSubscriptionStatus <= 3"
                                :loading="loading"
                                :disabled="loading"
                                color="success"
                                @click="createSubscription"
                                >{{
                                    currentSubscriptionStatus == 1 ? 'Create New Subscription' : 'Create Subscription'
                                }}</v-btn
                            >
                            <v-btn
                                v-else
                                :loading="loading"
                                :disabled="loading"
                                color="primary"
                                @click="updateSubscription"
                                >Update Subscription</v-btn
                            >
                            <!-- :dark="currentSubscriptionStatus >= 1 && currentSubscriptionStatus <= 3" -->

                            <!-- <v-btn
                                v-else
                                @click="saveBilling"
                                :color="
                                    currentSubscriptionStatus >= 1 && currentSubscriptionStatus <= 3
                                        ? 'green'
                                        : 'primary'
                                "
                                :loading="loading"
                                :disabled="subscriptionUpdateDisabled"
                                :dark="!subscriptionUpdateDisabled"
                            >
                                <span v-if="currentSubscriptionStatus === 1">Create New Subscription</span>
                                <span v-else-if="currentSubscriptionStatus === 2 || currentSubscriptionStatus === 3"
                                    >Create Subscription</span
                                >
                                <span v-else>Update Subscription</span>
                            </v-btn> -->
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>

        <v-dialog v-model="cancelDialog1" max-width="500px">
            <v-card>
                <div class="pl-3 pr-3 decorated-title ms-orange">
                    <v-card-title style="display: flex; justify-content: space-between">
                        <div class="text-container">Cancel Subscription</div>
                        <div class="img-container">
                            <img :src="require('@/assets/images/SW125_MemoryShare_Final-15.png')" alt="" />
                        </div>
                    </v-card-title>
                </div>

                <v-card-text class="p-3">
                    <div>
                        <strong>Cancel At Billing Period End</strong> (Preferred)

                        <div>
                            This will cancel the subscription after the billing period ends. You can reactivate the
                            subscription anytime between now and
                            <strong v-if="billingForm.subscriptionEndDate">{{
                                billingForm.subscriptionEndDate | moment('l')
                            }}</strong
                            >. This is the prefered method, and will allow the funeral home to continue using the
                            service until their billing cycle is over.
                        </div>
                    </div>
                    <div class="mt-2">
                        <strong>Cancel Immediately</strong>
                        <div>
                            This will cancel the subscription immediately. Use this option if the customer complains and
                            MemoryShare issues a refund for the previous invoice. Using this option will block you from
                            reactivating the subscription. You will need to create a new subscription if the customer
                            would like to signup again.
                        </div>
                    </div>
                </v-card-text>

                <!-- <strong>When should this subscription be canceled?</strong> -->
                <div class="p-3" style="display: flex; flex-direction: column">
                    <v-btn dark color="#0d3d60" @click="changeModal(2, false)">Billing Period End</v-btn>
                    <v-btn class="mt-2" dark @click="changeModal(2, true)">Immediately</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="cancelDialog2" max-width="500px">
            <v-card>
                <div class="pl-3 pr-3 decorated-title ms-orange">
                    <v-card-title style="display: flex; justify-content: space-between">
                        <div class="text-container">Cancel Subscription</div>
                        <div class="img-container">
                            <img :src="require('@/assets/images/SW125_MemoryShare_Final-15.png')" alt="" />
                        </div>
                    </v-card-title>
                </div>

                <v-card-text class="p-3">
                    <v-checkbox
                        v-model="confirmCancel"
                        label="This will cancel the subscription in Stripe and in MemoryShare. After canceling the subscription please login to Stripe and verify the cancelation and final invoice"
                    />
                </v-card-text>

                <v-spacer></v-spacer>
                <div class="p-3" style="display: flex; flex-direction: column">
                    <v-btn
                        color="error"
                        :loading="loading"
                        :disabled="loading || !confirmCancel"
                        @click="cancelSubscription"
                        >Confirm</v-btn
                    >
                    <v-btn class="mt-2" @click="changeModal(1, false)">Back</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="subscriptionStatusDialog" max-width="450">
            <v-card>
                <!-- <v-card-title class="headline">Change Subscription Status</v-card-title> -->
                <div class="pl-3 pr-3 decorated-title ms-blue">
                    <v-card-title style="display: flex; justify-content: space-between">
                        <div class="text-container">Change Subscription Status</div>
                        <div class="img-container">
                            <img :src="require('@/assets/images/SW125_MemoryShare_Final-15.png')" alt="" />
                        </div>
                    </v-card-title>
                </div>
                <div class="p-3">
                    <v-card-text>
                        The subscription status is automatically calculated, and should be correct.
                    </v-card-text>
                    <strong class="p-3">Contact Shane or Kyle before changing this value.</strong>

                    <v-card-text>
                        <v-select
                            :items="subscriptionStatuses"
                            item-text="label"
                            item-value="value"
                            label="Subscription Status"
                            v-model="selectedSubscriptionStatus"
                        ></v-select>
                    </v-card-text>

                    <div style="display: flex; flex-direction: column" class="text-center">
                        <v-btn
                            color="red"
                            :dark="!loading"
                            :loading="loading"
                            :disabled="loading"
                            @click="requestConfirmStatusUpdate"
                            >Confirm</v-btn
                        >

                        <v-btn dark class="mt-2" @click="subscriptionStatusDialog = false">Back</v-btn>
                    </div>
                    <!-- </v-card-actions> -->
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmationModal" max-width="400px">
            <v-card class="p-3">
                <p class="p-3 pb-0">Please confirm the status to be applied.</p>
                <div class="p-3">
                    <v-chip
                        v-if="selectedSubscriptionStatus != null"
                        style="min-width: 100%; display: flex; justify-content: center"
                        dark
                        :color="subscriptionStatuses[selectedSubscriptionStatus].color"
                        large
                        label
                        outlined
                        >{{ subscriptionStatuses[selectedSubscriptionStatus].label }}</v-chip
                    >

                    <v-btn @click="updateSubscriptionStatus" color="error" class="mt-3" block>Confirm</v-btn>
                    <v-btn @click="confirmationModal = false" dark class="mt-3" block>Cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="disablePromoDialog" max-width="400px">
            <v-card class="p-3">
                <v-card-title>End promo now?</v-card-title>
                <v-card-text
                    >This will end any promotional pricing and resume standard pricing
                    <strong>immediately</strong>.</v-card-text
                >
                <div class="px-3 pb-3">
                    <v-btn class="mb-2" :disabled="loading" @click="disablePromoDialog = false" block>Cancel</v-btn>
                    <v-btn :loading="loading" :disabled="loading" @click="confirmEndPromo" block color="error"
                        >Confirm</v-btn
                    >
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-if="funeralHome" v-model="cancelPastDueSubModal" max-width="450px">
            <v-card>
                <v-card-title> Cancel Subscription </v-card-title>
                <v-card-text>
                    <div v-if="invoicesSuccessfullyVoided">
                        <p>
                            Succesfully voided {{ pastDueInvoices.length }} invoice{{
                                pastDueInvoices.length == 1 ? '' : 's'
                            }}. You can now refresh the page, and the subscription should be active, and be able to be
                            canceled.
                        </p>
                    </div>
                    <div v-else>
                        <p>Unable to cancel subscription with open invoices.</p>
                        <p>
                            There
                            {{
                                pastDueInvoices.length == 1
                                    ? 'is 1 open invoice'
                                    : `are ${pastDueInvoices.length} open invoices`
                            }}

                            associated with {{ funeralHome.name }}.
                        </p>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <div v-if="invoicesSuccessfullyVoided" style="width: 100%" class="d-flex justify-center">
                        <v-btn color="success" @click="$router.go()">Refresh Page</v-btn>
                    </div>
                    <div v-else style="width: 100%" class="d-flex justify-space-between">
                        <v-btn @click="cancelPastDueSubModal = false">Cancel</v-btn>
                        <v-btn
                            @click="voidAllOpenInvoices"
                            :loading="loading"
                            :disabled="loading"
                            :dark="!loading"
                            color="red"
                            >Void all open invoices</v-btn
                        >
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { subscriptionStatuses } from '@/constants.js';
import currencies from '@/currencies.js';
import { mapActions } from 'vuex';
import DatePicker from '@/components/ui/DatePicker.vue';
import Calendar from '@/components/ui/Calendar.vue';
import moment from 'moment';

export default {
    props: ['token'],
    components: {
        DatePicker,
        Calendar,
    },
    data() {
        return {
            promo: false,
            disablePromoDialog: false,
            notSafeToEdit: false,
            notSafeInvoice: null,
            tempPromoStreams: 0,
            currencies,
            subscriptionStatuses,
            paymentCollection: [
                { title: 'Automatic', value: 0 },
                { title: 'Invoice', value: 1 },
            ],
            val: 0,
            billedAnnuallyOptions: [
                {
                    text: 'Yes',
                    value: true,
                },
                {
                    text: 'No',
                    value: false,
                },
            ],
            subscriptionStatusDialog: false,
            selectedSubscriptionStatus: null,
            currentSubscriptionStatus: 0,
            loading: false,
            stripeId: '',
            isDevHome: false,
            billingForm: {
                streamsPerMonth: null,
                costPerAdditionalStream: null,
                maxStreamLength: null,
                costPerFifteenMinute: null,
                maxViewers: null,
                costPer100Viewers: null,
                billedAnnually: false,
                subscriptionCost: null,
                subscriptionStartDate: null,
                stripePaymentToken: null,
                subscriptionEndDate: null,
                subscriptionStatus: null,
                hasDefaultPaymentMethod: null,
                currency: null,
                costPerTributeVideo: null,
                liveStreamEnabled: false,
                tributeVideosEnabled: false,
                isTributeVideosBundled: false,
                tributeVideosPerMonth: null,
            },
            promoOptions: {
                promo: false,
                liveSupport: true,
                streamsPerMonth: 6,
                price: 200,
                endDate: new Date().toISOString(),
            },
            FuneralHomeBillCollectionOptions: {
                title: 'Automatic',
                value: 0,
            },
            currencyOptions: {
                locale: 'en-us',
                prefix: '$',
                suffix: '',
                length: 8,
                precision: 2,
            },
            hasSubStartDate: false,
            date: new Date(),
            menu: false,
            modal: false,
            menu2: false,
            cancelDialog1: false,
            cancelDialog2: false,
            cancelImmediate: false,
            confirmationModal: false,
            funeralHome: null,
            invoicesSuccessfullyVoided: false,
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            bandwidthData: {
                label: 'Bandwidth Data',
                borderColor: '#659fc3',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [32, 45, 12, 45, 65, 43, 55],
            },
            streamingExpensesData: {
                label: 'Streaming Expenses',
                borderColor: '#65c3b8',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [99, 120, 44, 54, 65, 23, 43],
            },
            totalExpenseseData: {
                label: 'Total Expenses',
                borderColor: '#c38965',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [23, 45, 32, 87, 67, 66, 56],
            },
            profitData: {
                label: 'Profit',
                borderColor: '#6570c3',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [76, 87, 67, 77, 88, 55, 66],
            },
            datacollection: null,
            chartReady: false,
            confirmCancel: false,
            OwnerIdList: [
                { text: 'Shane', val: 0 },
                { text: 'Vertin', val: 1 },
                { text: 'Dev Home', val: 2 },
                { text: 'Dev Owner', val: 3 },
            ],
            requiredRules: [v => !!v || 'This field is required'],
            subscriptionId: null,
            boolOptions: [
                { label: 'No', value: false },
                { label: 'Yes', value: true },
            ],
            promo: false,
            cancelPastDueSubModal: false,
            pastDueInvoices: [],
            billingPresets: {
                1: {
                    streamsPerMonth: 6,
                    maxViewers: 1000,
                    maxStreamLength: 120,
                    costPerAdditionalStream: 49,
                    costPerFifteenMinute: 10,
                    costPer100Viewers: 15,
                    costPerTributeVideo: 29,
                    subscriptionCost: 249,
                },
                2: {
                    streamsPerMonth: 9,
                    maxViewers: 1000,
                    maxStreamLength: 120,
                    costPerAdditionalStream: 49,
                    costPerFifteenMinute: 10,
                    costPer100Viewers: 15,
                    costPerTributeVideo: 29,
                    subscriptionCost: 349,
                },
                3: {
                    streamsPerMonth: 12,
                    maxViewers: 1000,
                    maxStreamLength: 120,
                    costPerAdditionalStream: 40,
                    costPerFifteenMinute: 10,
                    costPer100Viewers: 15,
                    costPerTributeVideo: 29,
                    subscriptionCost: 449,
                },
                4: {
                    streamsPerMonth: 15,
                    maxViewers: 1500,
                    maxStreamLength: 180,
                    costPerAdditionalStream: 40,
                    costPerFifteenMinute: 10,
                    costPer100Viewers: 10,
                    costPerTributeVideo: 29,
                    subscriptionCost: 549,
                },
            },
        };
    },
    created() {
        this.getBilling();
    },
    methods: {
        ...mapActions(['showSnackbar']),
        initCancelPastDueSubModal() {
            if (!this.$auth.role.includes('SuperAdmin')) {
                this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                return;
            }

            this.cancelPastDueSubModal = true;
        },
        getOpenInvoices() {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/billing/invoices/get-all-open/${this.$route.params.id}`)
                .then(response => {
                    this.pastDueInvoices = response.data;
                    console.log(this.pastDueInvoices, 'past due invoices');
                })
                .catch(error => {
                    console.log('error', error);
                    this.showSnackbar({ message: 'Error retreiving past due invoices' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        voidAllOpenInvoices() {
            if (this.pastDueInvoices.length < 1) {
                this.showSnackbar({ message: 'Past due invoices not found', color: 'error' });
                return;
            }

            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/billing/invoices/void-all-open/${this.$route.params.id}`)
                .then(response => {
                    this.invoicesSuccessfullyVoided = true;
                })
                .catch(error => {
                    console.log('error', error);
                    this.showSnackbar({ message: 'Error voiding past due invoices' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        checkFormValid() {
            if (!this.billingForm.subscriptionStartDate) {
                this.showSnackbar({ message: 'Start date cannot be empty', color: 'error' });
                return false;
            }

            let formValid = this.$refs.billingForm.validate();
            if (!formValid) {
                return false;
            }

            return true;
        },
        createSubscription() {
            if (!this.checkFormValid()) {
                return;
            }

            this.loading = true;
            const endpoint = this.promoOptions.promo ? 'create-subscription-promo' : 'create-subscription-standard';

            let data = this.createBillingDataObj();

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/billing/funeralhome/` + endpoint, data)
                .then(response => {
                    this.showSnackbar({ message: 'Billing Updated' });
                    this.getBilling();
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.Message,
                    });
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.loading = false;
                    this.disablePromoDialog = false;
                });
        },
        updateSubscription() {
            if (!this.checkFormValid()) {
                return;
            }

            this.loading = true;
            const endpoint = this.promoOptions.promo ? 'update-subscription-promo' : 'update-subscription-standard';

            let data = this.createBillingDataObj();
            console.log(data, 'data');

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/billing/funeralhome/` + endpoint, data)
                .then(response => {
                    this.showSnackbar({ message: 'Billing Updated' });
                    this.getBilling();
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.Message,
                    });
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.loading = false;
                    this.disablePromoDialog = false;
                });
        },
        createBillingDataObj() {
            let data = {
                ...this.billingForm,
                funeralHomeId: +this.$route.params.id,
                streamsPerMonth: +this.billingForm.streamsPerMonth,
                costPerAdditionalStream: parseInt(this.billingForm.costPerAdditionalStream * 100),
                maxStreamLength: +this.billingForm.maxStreamLength,
                costPerFifteenMinute: parseInt(this.billingForm.costPerFifteenMinute * 100),
                maxViewers: +this.billingForm.maxViewers,
                costPer100Viewers: parseInt(this.billingForm.costPer100Viewers * 100),
                costPerTributeVideo: parseInt(this.billingForm.costPerTributeVideo * 100), // TODO: Double check once the API is ready
                billedAnnually: this.billingForm.billedAnnually.value, // backend not set up to receive value from form yet
                currency: this.billingForm.currency.code,
                subscriptionCost: parseInt(this.billingForm.subscriptionCost * 100),
                subscriptionStartDate: this.billingForm.subscriptionStartDate,
                billCollectionOption: this.FuneralHomeBillCollectionOptions.value,
                subscriptionEndDate: '0001-01-01T00:00:00',
                billingModel: this.billingForm.isTributeVideosBundled ? 0 : 1,
                tributeVideosPerMonth: this.billingForm.isTributeVideosBundled
                    ? -1
                    : +this.billingForm.tributeVideosPerMonth,
            };

            if (this.promoOptions.promo) {
                data.promo = true;
                data.promoLiveSupport = this.promoOptions.liveSupport;
                data.promoStreamsPerMonth = parseInt(this.promoOptions.streamsPerMonth);
                data.promoPrice = parseInt(this.promoOptions.price * 100);
                data.promoEndDate = this.promoOptions.endDate;
            } else {
                data.promo = false;
                data.promoLiveSupport = null;
                data.promoStreamsPerMonth = null;
                data.promoPrice = null;
                data.promoEndDate = null;
            }
            return data;
        },
        getStripeUrl(stripeId) {
            return `${process.env.VUE_APP_STRIPE_URL}/invoices/${stripeId}`;
        },
        changeModal(i, bool) {
            this.cancelImmediate = false;
            this.cancelDialog1 = false;
            this.cancelDialog2 = false;
            if (i == 1) {
                this.cancelDialog1 = true;
            } else if (i == 2) {
                this.cancelDialog2 = true;
                this.cancelImmediate = bool;
            }
        },
        confirmEndPromo() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/billing/funeralhome/end-promo/${this.$route.params.id}`)
                .then(response => {
                    this.showSnackbar({ message: 'Billing Updated' });
                    this.getBilling();
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.Message,
                    });
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.loading = false;
                    this.disablePromoDialog = false;
                });
        },
        toggleUnlimitedPromoStreams() {
            if (this.promoOptions.streamsPerMonth == 199) {
                this.promoOptions.streamsPerMonth = this.tempPromoStreams;
            } else {
                this.tempPromoStreams = this.promoOptions.streamsPerMonth;
                this.promoOptions.streamsPerMonth = 199;
            }
        },
        togglePromo() {
            if (this.currentSubscriptionStatus == 10) {
                this.disablePromoDialog = true;
            } else {
                this.promoOptions.promo = !this.promoOptions.promo;
            }
        },
        copyToClip(value) {
            navigator.clipboard.writeText(value);
            this.showSnackbar({ message: 'Copied to clipboard!' });
        },
        checkForLocal: function (timeString) {
            if (timeString && timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        billingPreset(preset) {
            this.billingForm.streamsPerMonth = this.billingPresets[preset].streamsPerMonth;
            this.billingForm.maxViewers = this.billingPresets[preset].maxViewers;
            this.billingForm.maxStreamLength = this.billingPresets[preset].maxStreamLength;
            this.billingForm.costPerAdditionalStream = this.billingPresets[preset].costPerAdditionalStream;
            this.billingForm.costPerFifteenMinute = this.billingPresets[preset].costPerFifteenMinute;
            this.billingForm.costPer100Viewers = this.billingPresets[preset].costPer100Viewers;
            this.billingForm.costPerTributeVideo = this.billingPresets[preset].costPerTributeVideo;
            this.billingForm.subscriptionCost = this.billingPresets[preset].subscriptionCost;
            if (this.billingForm.subscriptionStartDate == null) {
                const today = new Date();
                this.billingForm.subscriptionStartDate = today.toISOString();
            }
        },

        openBillingDocs() {
            window.open(
                'https://www.notion.so/memoryshare/Admin-Billing-Bible-47da910684e0431f9e8800828667873a',
                '_blank',
            );
        },
        requestConfirmStatusUpdate() {
            this.confirmationModal = true;
        },
        updateSubscriptionStatus() {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(
                    process.env.VUE_APP_API +
                        `/billing/funeralhome/subscription-status/${this.$route.params.id}?status=${this.selectedSubscriptionStatus}`,
                )
                .then(() => {
                    this.getBilling();
                    this.loading = false;
                    this.subscriptionStatusDialog = false;
                })
                .catch(error => {
                    this.load = false;
                })
                .then(() => {
                    this.loading = false;
                    this.confirmationModal = false;
                    this.subscriptionStatusDialog = false;
                });
        },
        openBillingPortal() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/billing/funeralhome/portal/${this.$route.params.id}`)
                .then(response => {
                    window.open(response.data, '_blank');
                })
                .catch(error => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error Opening Stripe Portal',
                    });
                });
        },
        getBilling() {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/billing/funeralhome/${this.$route.params.id}`)
                .then(response => {
                    this.billingForm = {
                        ...response.data,
                        isTributeVideosBundled: response.data.billingModel == 0 ? true : false,
                    };

                    if (response.data.subscriptionStartDate === '0001-01-01T00:00:00') {
                        this.hasSubStartDate = false;
                        this.billingForm.subscriptionStartDate = null;
                    } else {
                        this.hasSubStartDate = true;
                        this.billingForm.subscriptionStartDate = this.checkForLocal(
                            response.data.subscriptionStartDate,
                        );
                    }

                    if (response.data.subscriptionEndDate === '0001-01-01T00:00:00') {
                        this.billingForm.subscriptionEndDate = null;
                    } else {
                        this.billingForm.subscriptionEndDate = this.checkForLocal(response.data.subscriptionEndDate);
                    }

                    this.billingForm.costPerAdditionalStream = response.data.costPerAdditionalStream / 100;
                    this.billingForm.costPer100Viewers = response.data.costPer100Viewers / 100;
                    this.billingForm.costPerFifteenMinute = response.data.costPerFifteenMinute / 100;
                    this.billingForm.subscriptionCost = response.data.subscriptionCost / 100;
                    this.billingForm.costPerTributeVideo = response.data.costPerTributeVideo / 100;
                    this.stripeId = response.data.stripeCustomerId;

                    // this.selectedSubscriptionStatus = this.subscriptionStatusEnums[response.data.subscriptionStatus];
                    this.selectedSubscriptionStatus = response.data.subscriptionStatus;

                    this.currentSubscriptionStatus = response.data.subscriptionStatus;
                    this.billingForm.billedAnnually = this.billedAnnuallyOptions.find(
                        o => o.value === response.data.billedAnnually,
                    );
                    this.billingForm.hasDefaultPaymentMethod = response.data.hasDefaultPaymentMethod;
                    const currentCurrency =
                        response.data.currency &&
                        currencies.find(c => c.code.toLowerCase() === response.data.currency.toLowerCase());

                    this.billingForm.currency = currentCurrency || this.currencies[0];
                    this.subscriptionId = response.data.subscriptionId;
                    this.FuneralHomeBillCollectionOptions = {
                        value: response.data.billCollectionOption,
                    };

                    // this.billingForm.isTributeVideosBundled = response.data.billingModel == 0 ? true : false;

                    // Promo Data
                    if (this.billingForm.promo) {
                        this.promoOptions.promo = response.data.promo;
                        this.promoOptions.liveSupport = response.data.promoLiveSupport;
                        this.promoOptions.streamsPerMonth = response.data.promoStreamsPerMonth;
                        this.promoOptions.endDate = this.checkForLocal(response.data.promoEndDate);
                        this.promoOptions.price = response.data.promoPrice / 100;
                    } else {
                        this.promoOptions.promo = false;
                        this.promoOptions.liveSupport = true;
                        this.promoOptions.streamsPerMonth = 6;
                        this.promoOptions.price = parseInt(response.data.subscriptionCost / 100);
                        // this.promoOptions.endDate = new Date().toISOString();
                        if (response.data.subscriptionStatus == 2) {
                            this.promoOptions.endDate = moment(new Date()).add(3, 'M').toISOString();
                        } else {
                            this.promoOptions.endDate = moment(this.billingForm.subscriptionEndDate)
                                .add(1, 'M')
                                .toISOString();
                        }
                    }

                    console.log(this.billingForm, 'billing form');
                })
                .catch(error => {
                    console.log(error, 'BILLING ERROR');
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error getting billing data',
                    });
                })
                .then(() => {
                    this.loading = false;
                });
        },
        reactivateSub(id) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/billing/funeralhome/reactivate?id=${id}`)
                .then(response => {
                    this.showSnackbar({ message: 'Subscription Reactivated' });
                })
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.getBilling();
                });
        },
        // saveBilling() {
        //     let formValid = this.$refs.billingForm.validate();
        //     if (!formValid) return;
        //     this.loading = true;

        //     let data = {
        //         ...this.billingForm,
        //         funeralHomeId: +this.$route.params.id,
        //         streamsPerMonth: +this.billingForm.streamsPerMonth,
        //         costPerAdditionalStream: parseInt(this.billingForm.costPerAdditionalStream * 100),
        //         maxStreamLength: +this.billingForm.maxStreamLength,
        //         costPerFifteenMinute: parseInt(this.billingForm.costPerFifteenMinute * 100),
        //         maxViewers: +this.billingForm.maxViewers,
        //         costPer100Viewers: parseInt(this.billingForm.costPer100Viewers * 100),
        //         costPerTributeVideo: parseInt(this.billingForm.costPerTributeVideo * 100), // TODO: Double check once the API is ready
        //         billedAnnually: this.billingForm.billedAnnually.value, // backend not set up to receive value from form yet
        //         currency: this.billingForm.currency.code,
        //         subscriptionCost: parseInt(this.billingForm.subscriptionCost * 100),
        //         subscriptionStartDate: this.billingForm.subscriptionStartDate,
        //         billCollectionOption: this.FuneralHomeBillCollectionOptions.value,
        //         subscriptionEndDate: '0001-01-01T00:00:00',
        //         billingModel: this.billingForm.isTributeVideosBundled && this.tributeVideosEnabled ? 0 : 1,
        //         tributeVideosPerMonth: this.billingForm.isTributeVideosBundled
        //             ? -1
        //             : +this.billingForm.tributeVideosPerMonth,
        //     };

        //     if (this.promoOptions.promo) {
        //         data.promo = true;
        //         data.promoLiveSupport = this.promoOptions.liveSupport;
        //         data.promoStreamsPerMonth = parseInt(this.promoOptions.streamsPerMonth);
        //         data.promoPrice = parseInt(this.promoOptions.price * 100);
        //         data.promoEndDate = this.promoOptions.endDate;
        //     } else {
        //         data.promo = false;
        //         data.promoLiveSupport = null;
        //         data.promoStreamsPerMonth = null;
        //         data.promoPrice = null;
        //         data.promoEndDate = null;
        //     }

        //     this.axios
        //         .create({ headers: { Authorization: `Bearer ${this.token}` } })
        //         .post(process.env.VUE_APP_API + '/billing/funeralhome', data)
        //         .then(response => {
        //             this.showSnackbar({ message: 'Billing Updated' });
        //             this.getBilling();
        //         })
        //         .catch(error => {
        //             this.$swal.fire({
        //                 icon: 'error',
        //                 title: 'Error',
        //                 text: error.response.data.Message,
        //             });
        //             console.log(error, 'error');
        //         })
        //         .finally(() => {
        //             this.loading = false;
        //             this.disablePromoDialog = false;
        //         });
        // },
        goToStripeDashboard() {
            window.open(`${process.env.VUE_APP_STRIPE_URL}/customers/${this.stripeId}`, '_blank');
        },
        openCancelSubscriptionModal() {
            this.confirmCancel = false;
            this.cancelDialog = true;
        },
        getFh() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/funeralhomes/${this.$route.params.id}`)
                .then(response => {
                    this.funeralHome = response.data.funeralHome;
                    console.log(this.funeralHome, 'fh');
                })
                .catch(err => {
                    console.log(err, 'error');
                    this.showSnackbar({ message: 'Error getting funeral home details', color: 'error' });
                });
        },
        cancelSubscription() {
            if (!this.confirmCancel) {
                this.showSnackbar({ message: 'You must confirm subscription is canceled in Stripe to proceed' });
            } else {
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(
                        process.env.VUE_APP_API +
                            `/billing/funeralhome/cancel/${this.$route.params.id}?cancelImmediate=${this.cancelImmediate}`,
                        {},
                    )
                    .then(response => {
                        this.showSnackbar({ message: 'Subscription canceled' });
                        this.getBilling();
                    })
                    .catch(error => {
                        this.showSnackbar({ message: 'Error ending subscription' });
                    })
                    .finally(() => {
                        this.changeModal();
                    });
            }
        },
    },
    filters: {
        nextDay(val) {
            let next = moment(val).add(1, 'd').format('l');
            return next;
        },
    },
    watch: {
        'billingForm.isTributeVideosBundled'(newVal) {
            if (!newVal && this.billingForm.tributeVideosPerMonth < 0) {
                //reset default if bundle disabled
                this.billingForm.tributeVideosPerMonth = 0;
                this.billingForm.costPerTributeVideo = 29;
            }
        },

        // 'billingForm.tributeVideosEnabled'(newVal) {
        //     if (newVal == false) {
        //         this.billingForm.isTributeVideosBundled = true;
        //     }
        // },
    },
    computed: {
        checkBillCollection: function () {
            if (this.FuneralHomeBillCollectionOptions.value === 1) {
                this.billCollectionOption = 1;
            }
            if ((this.billCollectionOption = 0)) {
                this.FuneralHomeBillCollectionOptions.value === 0;
            }
        },
        stripe_url() {
            return process.env.VUE_APP_STRIPE_URL;
        },
        subscriptionUpdateDisabled() {
            return !this.billingForm?.hasDefaultPaymentMethod && this.FuneralHomeBillCollectionOptions?.value === 0;
        },
        isStreamingFieldsDisabled() {
            return (
                (!this.billingForm?.hasDefaultPaymentMethod && this.FuneralHomeBillCollectionOptions?.value === 0) ||
                this.currentSubscriptionStatus === 9 ||
                !this.billingForm?.liveStreamEnabled
            );
        },
        isTributeFieldsDisabled() {
            return (
                (!this.billingForm?.hasDefaultPaymentMethod && this.FuneralHomeBillCollectionOptions?.value === 0) ||
                this.currentSubscriptionStatus === 9 ||
                !this.billingForm?.tributeVideosEnabled
            );
        },
    },
    mounted: function () {
        this.getFh();
        this.getOpenInvoices();

        this.axios
            .create({ headers: { Authorization: `Bearer ${this.token}` } })
            .get(process.env.VUE_APP_API + `/billing/funeralhome/is-edit-safe/${this.$route.params.id}`)
            .then(response => {
                const answer = response.data;

                if (answer != 'Yes') {
                    this.notSafeToEdit = true;
                    this.notSafeInvoice = answer;
                }
            });
    },
};
</script>

<style lang="scss">
.billing-container {
    container-type: inline-size;
    .top-row {
        display: flex;
        flex-direction: row-reverse;
        .stripe {
            width: 50%;
            margin-left: 30px;
        }
        .btn-group {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .inner {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                min-height: 110px;
            }
        }
    }
    .promoIsActive {
        // border: 2px solid #ccc;
        border-radius: 10px;
        background-color: #f1f4f7;
    }
}
@container (max-width: 900px) {
    .billing-container {
        .top-row {
            flex-direction: column-reverse;
            .stripe {
                width: 100%;
                margin-left: 0;
            }
            .btn-group {
                width: 100%;

                gap: 12px;
            }
        }
        .mobile-hidden {
            display: none;
        }
    }
}

@container (max-width: 475px) {
    .billing-container {
        .stripe-a-group {
            flex-direction: column;
            margin-top: 10px;
            .btn {
                border: 1px solid #3f51b5;
                width: 100%;
                margin-top: 5px;
            }
        }
    }
}
.billing-container {
    .status-wrap {
        display: flex;
        flex-wrap: wrap-reverse;
    }
    .stripe-a-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        word-wrap: break-word;
    }
    .stripe-anchor {
        padding: 0 5px;
        transition: 0.3s;
    }
    .stripe-anchor:hover {
        background-color: #625afa15;
        // color: #625afa !important;
        text-decoration: none;
        border-radius: 5px;
        transition: 0.3s;
    }
    .disabled {
        position: relative;
        color: rgb(49, 48, 48);
    }
    .disabled:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: ' ';
    }

    .tab-padding {
        padding: 3rem;
    }

    .v-messages__message {
        color: black !important;
    }

    .billing-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.75);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 100;
        text-align: center;

        .loom-embed {
            width: 100%;
            max-width: 250px;
            position: absolute;
            bottom: 0;
            right: 0;
            border-radius: 10px;
            background-color: white;
        }
    }

    .billing-overlay p {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .billing-overlay {
        #invoice-link {
            text-decoration: none;
        }
        #invoice-link:link {
            color: #3f51b5 !important;
        }
        #invoice-link:visited {
            color: #3f51b5 !important;
        }
        #invoice-link:hover {
            color: #2c3e50 !important;
        }
    }

    .img-container {
        width: 20px;
        max-width: 20px;
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .ms-orange {
        background-color: #ff530d !important;
    }
    .ms-blue {
        background-color: #0d3d60 !important;
    }
    .decorated-title {
        color: white !important;

        .text-container {
            width: 80%;
            word-break: break-word;
        }
        .img-container {
            width: 20%;
            max-width: 50px;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .r-btn-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
        gap: 15px;
    }
    .l-btn-group {
        // border: 2px solid orange;
        margin-bottom: 15px;
    }
    .status {
        text-align: right;
        // border: 2px solid green;
        padding-bottom: 12px;
        padding-left: 12px;
    }

    .l-col {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .top-row {
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }
    .stripe-ids {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 2px solid #625afa;
        background-color: white;
        border-radius: 10px;
        padding: 10px;
    }
}

@media only screen and (max-width: 830px) {
    .billing-container {
        .stripe-a-group {
            // flex-direction: column;
            word-wrap: break-word;
        }
        .r-btn-group {
            flex-direction: column;
        }
        .l-btn-group {
            display: flex;
            flex-direction: column;
        }
        .status {
            text-align: center;
        }
        .status-wrap {
            flex-direction: column-reverse;
        }
        .l-col {
            flex-direction: column;
        }
        .stripe-ids {
            text-align: center;
            max-width: 100%;
        }
    }
}
</style>
