<template>
    <div>
        <v-dialog
            :persistent="!confirmedClose"
            @click:outside="initCloseModal(false)"
            v-model="internalValue"
            max-width="550px"
        >
            <v-card>
                <v-card-title>Sync Slideshow and Music</v-card-title>

                <v-card-text>
                    <span>
                        Ensure your music matches or exceeds the video length. Excess audio will be trimmed at the end.
                    </span>

                    <v-divider></v-divider>

                    <div v-if="!showAutoDurationBtn" class="d-flex align-center">
                        <span class="mr-4 overline">Auto Sync</span>
                        <v-switch
                            :disabled="disableAutoDurationBtn"
                            color="#ff7837"
                            class="p-0 m-0"
                            inset
                            hide-details
                            @change="confirmedClose = false"
                            v-model="internalAutoSlideDuration"
                            >Auto Slide Duration</v-switch
                        >

                        <CustomTooltip :tooltipProps="{ top: true, maxWidth: '300px' }">
                            <template #activator>
                                <font-awesome-icon
                                    style="color: #1877f2"
                                    icon="fa-regular fa-info-circle"
                                ></font-awesome-icon>
                            </template>

                            <template #title> Auto Sync </template>

                            <template #content>
                                <span> Auto Sync is available when the slide duration would be between 5.5 - 7s </span>
                            </template>
                        </CustomTooltip>
                        <!-- <span>est auto duaration: {{ calculatedAutoSlideDuration }}</span> -->
                    </div>

                    <div class="d-flex justify-center flex-column flex-sm-row flex-wrap mt-5" style="gap: 24px">
                        <div :class="['main-stat', 'bordered', { disabled: internalAutoSlideDuration }]">
                            <div class="d-flex justify-space-between">
                                <h1 style="min-width: 120px" class="m-0 flex-grow-1">
                                    {{ internalSlideDuration.toFixed(2) }}
                                </h1>
                                <div class="d-flex flex-column">
                                    <div
                                        @mousedown="startUpdatingSlideDuration('up')"
                                        @mouseup="stopUpdatingSlideDuration()"
                                        @mouseleave="stopUpdatingSlideDuration()"
                                        :class="['pointer', 'duration-btn', { disabled: internalAutoSlideDuration }]"
                                    >
                                        <font-awesome-icon icon="fa-solid fa-caret-up"></font-awesome-icon>
                                    </div>
                                    <div
                                        @mousedown="startUpdatingSlideDuration('down')"
                                        @mouseup="stopUpdatingSlideDuration()"
                                        @mouseleave="stopUpdatingSlideDuration()"
                                        :class="['pointer', 'duration-btn', { disabled: internalAutoSlideDuration }]"
                                    >
                                        <font-awesome-icon icon="fa-solid fa-caret-down"></font-awesome-icon>
                                    </div>
                                </div>
                            </div>
                            <span>Seconds / Slide</span>
                        </div>

                        <div class="main-stat bordered">
                            <h1 class="m-0">{{ totalSlides }}</h1>
                            <span>Slides</span>
                        </div>

                        <div class="main-stat bordered">
                            <h1 class="m-0">
                                <v-progress-circular v-if="calculatingNewDuration" indeterminate></v-progress-circular>
                                <span v-else>
                                    {{ internalVideoDuration | formatTimeStamp }}
                                </span>
                            </h1>
                            <span> Video Length</span>
                        </div>
                    </div>

                    <div class="my-5">
                        <div class="d-flex justify-space-between">
                            <strong>Video</strong>
                            <span>{{ internalVideoDuration | formatTimeStamp }}</span>
                        </div>
                        <v-progress-linear
                            dark
                            rounded
                            height="25"
                            :value="playlistDurationPercent"
                            :color="playlistDurationPercent >= 100 ? '#10b981' : '#1877f2'"
                        >
                            <div>
                                <span
                                    >Music <strong>{{ playlistDuration | formatTimeStamp }}</strong></span
                                >
                            </div>
                        </v-progress-linear>
                    </div>

                    <v-alert v-if="showDurationWarning" text color="warning">
                        <template #prepend>
                            <font-awesome-icon class="mr-2" icon="fa-solid fa-circle-exclamation"></font-awesome-icon>
                        </template>
                        Video is longer than music length</v-alert
                    >

                    <v-alert v-if="!showDurationWarning" text color="#10b981">
                        <template #prepend>
                            <font-awesome-icon class="mr-2" icon="fa-solid fa-circle-check"></font-awesome-icon>
                        </template>
                        The music will fade at {{ internalVideoDuration | formatTimeStamp }}</v-alert
                    >
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn class="mr-2 no-text-transform" @click="initCloseModal(false)" depressed outlined
                        >Cancel</v-btn
                    >
                    <v-btn
                        class="no-text-transform"
                        :disabled="disableUpdateBtn"
                        :dark="!disableUpdateBtn"
                        :loading="loading"
                        @click="submitUpdateRenderSettings"
                        depressed
                        color="#ff7837"
                        >Apply</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="closeWithoutSavingModal" max-width="400">
            <v-card>
                <v-card-title> Close without saving? </v-card-title>
                <v-card-text> Click confirm to close without saving changes. </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn class="no-text-transform" outlined @click="closeWithoutSavingModal = false" depressed
                        >Cancel</v-btn
                    >
                    <v-btn class="no-text-transform" @click="confirmCloseWithoutSaving" depressed color="error"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import moment from 'moment';
import { playlistTypes } from '@/constants';
import TributeVideoService from '@/services/tributeVideo.service';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';
import CustomTooltip from '../../ui/CustomTooltip.vue';

export default {
    data() {
        return {
            playlistTypes,
            closeWithoutSavingModal: false,
            internalSlideDuration: 6,
            internalVideoDuration: 0,
            internalAutoSlideDuration: false,
            apiService: null,
            calculatingNewDuration: false,
            loading: true,
            formData: {
                autoSlideDuration: false,
                animation: 1,
                slideDuration: 6,
                transition: 0,
            },
            awaitingPreviewRefresh: false,
            updateInterval: null,
            updateDelayTimeout: null,
            maxSlideDuration: 12,
            minSlideDuration: 5,
            minAutoSlideDuration: 5.5,
            maxAutoSlideDuration: 7,
            calculatedAutoSlideDuration: 0,
            calculatedAutoSlideVideoDuration: 0,
            cachedManualSlideDuration: 0,
            cachedManualSlideVideoDuration: 0,
            confirmedClose: true,
        };
    },
    inject: ['state'],
    components: { CustomTooltip },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        playlistType: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        internalValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        disableUpdateBtn() {
            return this.calculatingNewDuration || this.loading;
        },
        totalSlides() {
            return this.$store.state.tributeVideo.totalSlidesCount;
        },
        totalTributeDuration() {
            return Math.ceil(this.$store.state.tributeVideo.totalDuration);
        },
        slideDuration() {
            return this.$store.state.tributeVideo.slideDuration;
        },
        autoSlideDuration() {
            return this.$store.state.tributeVideo.autoSlideDuration;
        },
        publicPlaylist: {
            get() {
                return this.$store.state.tributeVideo.publicPlaylist;
            },
        },
        originalPlaylist: {
            get() {
                return this.$store.state.tributeVideo.originalPlaylist;
            },
        },
        slides: {
            get() {
                return this.playlistType === this.playlistTypes.PUBLIC ? this.publicPlaylist : this.originalPlaylist;
            },
        },
        playlistDuration() {
            return this.slides.reduce((total, slide) => {
                if (slide.duration) {
                    return total + slide.duration;
                }
                return total;
            }, 0);
        },
        playlistDurationPercent() {
            if (this.internalVideoDuration <= 0) return 0;

            return Math.round((this.playlistDuration / this.internalVideoDuration) * 100);
        },
        videoDurationPercent() {
            return Math.round((this.internalVideoDuration / this.playlistDuration) * 100);
        },
        tributeId() {
            return this.$store.state.tributeVideo.id;
        },
        showDurationWarning() {
            // return Math.ceil(this.playlistDuration) < Math.ceil(this.internalVideoDuration);

            return this.playlistDurationPercent < 100;
        },
        autoDurationOutOfRange() {
            return !(
                this.calculatedAutoSlideDuration >= this.minSlideDuration &&
                this.calculatedAutoSlideDuration <= this.maxSlideDuration
            );
        },
        disableAutoDurationBtn() {
            return !(this.calculatedAutoSlideDuration >= 5.5 && this.calculatedAutoSlideDuration <= 7);
        },
        showAutoDurationBtn() {
            return this.autoDurationOutOfRange;
        },
    },
    filters: {
        formatTimeStamp(seconds) {
            const duration = moment.duration(seconds, 'seconds');
            const minutes = duration.minutes();
            const secs = duration.seconds();

            const formattedMinutes = String(minutes);
            const formattedSeconds = String(secs).padStart(2, '0');

            return `${formattedMinutes}:${formattedSeconds}`;
        },
    },
    watch: {
        internalValue(newVal) {
            this.refreshInternalValues();

            if (this.disableAutoDurationBtn) {
                this.internalAutoSlideDuration = false;
            }
        },
        internalSlideDuration(newVal) {
            if (newVal >= this.maxSlideDuration) {
                this.stopUpdatingSlideDuration();
                this.internalSlideDuration = this.maxSlideDuration;
                return;
            }
            if (newVal <= this.minSlideDuration) {
                this.stopUpdatingSlideDuration();
                this.internalSlideDuration = this.minSlideDuration;
                return;
            }
        },
        internalAutoSlideDuration(newVal) {
            if (newVal) {
                this.internalVideoDuration = Math.ceil(this.calculatedAutoSlideVideoDuration);
                this.internalSlideDuration = this.calculatedAutoSlideDuration;
            } else {
                this.internalVideoDuration = Math.ceil(this.cachedManualSlideVideoDuration);
                this.internalSlideDuration = this.cachedManualSlideDuration;
            }
        },
        autoDurationOutOfRange(newVal) {
            if (newVal) {
                this.internalAutoSlideDuration = false;
            }
        },
        disableAutoDurationBtn(newVal) {
            if (newVal) {
                this.internalAutoSlideDuration = false;
            }
        },
        calculatedAutoSlideDuration(newVal) {
            if (this.disableAutoDurationBtn) {
                this.internalAutoSlideDuration = false;
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        startUpdatingSlideDuration(direction) {
            if (this.internalAutoSlideDuration) return;
            this.stopUpdatingSlideDuration();
            this.confirmedClose = false;

            this.updateSlideDuration(direction);

            this.updateDelayTimeout = setTimeout(() => {
                this.updateInterval = setInterval(() => {
                    this.updateSlideDuration(direction);
                }, 150);
            }, 500);
        },
        stopUpdatingSlideDuration() {
            clearTimeout(this.updateDelayTimeout);
            clearInterval(this.updateInterval);
            this.updateInterval = null;
            this.updateUpdateDelayTimeout = null;
        },
        adjustToIncrement(num, dir, incr) {
            if (dir === 'up') {
                return Math.ceil(num / incr) * incr;
            } else {
                return Math.floor(num / incr) * incr;
            }
        },
        updateSlideDuration(direction) {
            const adjustmentAmount = 0.25;

            if (this.internalSlideDuration % adjustmentAmount !== 0) {
                this.internalSlideDuration = this.adjustToIncrement(
                    this.internalSlideDuration,
                    direction,
                    adjustmentAmount,
                );
            } else {
                switch (direction) {
                    case 'up':
                        this.internalSlideDuration += adjustmentAmount;
                        break;
                    case 'down':
                        this.internalSlideDuration -= adjustmentAmount;
                        break;
                    default:
                        console.log('Unhandled slide duration update');
                        break;
                }
            }

            this.internalAutoSlideDuration = false;
            this.cachedManualSlideDuration = this.internalSlideDuration;

            this.debouncedGetJsonWithSlideDuration();
        },
        initCloseModal(skipConfirm) {
            if (skipConfirm || this.confirmedClose) {
                this.confirmedClose = true;
                this.internalValue = false;
            } else {
                this.closeWithoutSavingModal = true;
            }
        },
        confirmCloseWithoutSaving() {
            this.closeWithoutSavingModal = false;
            this.internalValue = false;
        },
        refreshInternalValues() {
            this.internalSlideDuration = this.slideDuration;
            this.internalVideoDuration = this.totalTributeDuration;
            this.internalAutoSlideDuration = this.autoSlideDuration;

            // this.cachedManualSlideDuration = this.internalSlideDuration;
            this.cachedManualSlideVideoDuration = this.totalTributeDuration;

            this.confirmedClose = true;
            this.getRenderSettings();
            this.getJsonWithAutoSlideDuration();
            this.loading = false;

            this.awaitingPreviewRefresh = false;
        },
        async setAuthToken() {
            if (this.state.token) {
                this.token = this.state.token;
            } else {
                const response = await this.$auth.getIdTokenClaims();
                this.token = response.__raw;
            }
        },
        async getJsonWithSlideDuration() {
            try {
                if (!this.tributeId) {
                    throw new Error('Invalid tribute id');
                }

                this.calculatingNewDuration = true;

                const data = {
                    slideDuration: this.internalSlideDuration,
                    autoSlideDuration: false,
                };

                const resp = await this.apiService.getPreviewJsonWithRenderSettings(this.tributeId, data);

                if (resp.data.duration) {
                    this.internalVideoDuration = Math.ceil(resp.data.duration);
                    this.cachedManualSlideVideoDuration = this.internalVideoDuration;
                }
            } catch (error) {
                console.log(error, 'error fetching preview json w/ settings');
            } finally {
                this.calculatingNewDuration = false;
            }
        },
        async getJsonWithAutoSlideDuration() {
            try {
                if (!this.tributeId) {
                    throw new Error('Invalid tribute id');
                }

                this.calculatingNewDuration = true;

                const data = {
                    autoSlideDuration: true,
                };

                const resp = await this.apiService.getPreviewJsonWithRenderSettings(this.tributeId, data);
                if (resp.data.slideDuration) {
                    this.calculatedAutoSlideDuration = resp.data.slideDuration;
                }
                if (resp.data.duration) {
                    this.calculatedAutoSlideVideoDuration = resp.data.duration;
                }
            } catch (error) {
                console.log(error, 'error fetching preview json w/ settings');
            } finally {
                this.calculatingNewDuration = false;
            }
        },
        async getRenderSettings() {
            try {
                if (!this.tributeId) {
                    throw new Error('invalid tribute id');
                }

                const resp = await this.apiService.getRenderSettings(this.tributeId);

                if (resp.data.settings) {
                    this.formData = { ...resp.data.settings };

                    console.log(this.formData, 'form data mount');

                    this.cachedManualSlideDuration = this.formData.slideDuration;
                    this.internalSlideDuration = this.formData.slideDuration;

                    if (
                        this.internalSlideDuration < this.minSlideDuration &&
                        this.internalSlideDuration > this.maxSlideDuration
                    ) {
                        this.internalSlideDuration = 6;
                        this.debouncedGetJsonWithSlideDuration();
                    }
                }
            } catch (error) {
                console.log(error, 'error fetching render settings');
            }
        },
        async submitUpdateRenderSettings() {
            if (this.internalAutoSlideDuration) {
                await this.setAutoSlideDuration(this.internalAutoSlideDuration);
            } else {
                await this.submitManualSlideDuration();
            }

            this.initCloseModal(true);
        },
        async submitManualSlideDuration() {
            try {
                if (!this.tributeId) {
                    throw new Error('Invalid tribute id');
                }

                this.loading = true;
                const data = {
                    ...this.formData,
                    slideDuration: this.internalSlideDuration,
                    autoSlideDuration: false,
                };

                const resp = await this.apiService.updateRenderSettings(this.tributeId, data);

                if (resp?.data?.settings?.settings) {
                    this.formData = resp?.data?.settings?.settings;

                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        autoSlideDuration: resp.data.settings.settings.autoSlideDuration,
                        slideDuration: resp.data.settings.settings.slideDuration,
                        selectedAnimation: resp.data.settings.settings.animation,
                        selectedTransition: resp.data.settings.settings.transition,
                    });
                }

                if (resp?.data?.renderJson) {
                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        totalDuration: resp.data.renderJson.duration,
                    });
                }

                this.showSnackbar({ message: 'Slide duration settings updated' });
            } catch (error) {
                console.log(error, 'error updateing tribute render settings');
                this.showSnackbar({ message: 'Error saving slide duration settings', color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        async setAutoSlideDuration(bool) {
            try {
                if (!this.tributeId) {
                    throw new Error('Invalid tribute id');
                }

                this.loading = true;
                const data = {
                    ...this.formData,
                    autoSlideDuration: bool,
                };

                const resp = await this.apiService.updateRenderSettings(this.tributeId, data);

                if (resp?.data?.settings?.settings) {
                    this.formData = resp?.data?.settings?.settings;

                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        autoSlideDuration: resp.data.settings.settings.autoSlideDuration,
                        slideDuration: resp.data.settings.settings.slideDuration,
                        selectedAnimation: resp.data.settings.settings.animation,
                        selectedTransition: resp.data.settings.settings.transition,
                    });
                }

                if (resp?.data?.renderJson) {
                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        totalDuration: resp.data.renderJson.duration,
                    });
                }
            } catch (error) {
                console.log(error, 'error settings auto slide duration');

                this.showSnackbar({ message: 'Error setting slide auto duration', color: 'error' });
            } finally {
                this.loading = false;
            }
        },
    },
    created() {
        this.debouncedGetJsonWithSlideDuration = debounce(this.getJsonWithSlideDuration, 400);
        this.debouncedRefreshInternalValues = debounce(this.refreshInternalValues, 1000);
    },
    async mounted() {
        await this.setAuthToken();

        this.apiService = TributeVideoService(this.token);

        this.getRenderSettings();
        this.getJsonWithAutoSlideDuration();
        this.loading = false;
    },
    sockets: {
        NotifyTributeSongsUpdate(data) {
            if (this.tributeId && data.tributeId === this.tributeId) {
                this.awaitingPreviewRefresh = true;
            }
        },
        async NotifyTributeJsonRefreshed(data) {
            if (!this.awaitingPreviewRefresh) return;

            if (this.tributeId && data.id == this.tributeId) {
                this.debouncedRefreshInternalValues();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.main-stat {
    padding: 8px 24px;
    border-radius: 4px;
    color: #2c3e50;
    text-align: center;
    user-select: none;
    height: fit-content;
}

.disabled {
    color: $medium-gray !important;
    cursor: not-allowed !important;
}

.bordered {
    border: 1px solid $medium-gray;
}

.duration-btn {
    margin-left: 4px;
    padding: 0 8px;
    border-radius: 4px;
}

.duration-btn:hover {
    background-color: #f2f2f2;
}

.duration-btn.disabled {
    background-color: white;
}
</style>
