<template>
    <div>
        <v-dialog v-model="internalValue" max-width=" 500px">
            <v-card>
                <v-card-title> Share Links </v-card-title>
                <v-card-text>
                    <v-list class="sharing-menu">
                        <v-list-item v-for="item in shareLinks">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>

                            <v-list-item-subtitle>
                                <TextButtonInput v-model="item.value" :disabled="!item.value">
                                    <template #prepend-inner>
                                        <font-awesome-icon class="mt-1 mr-1" :icon="item.icon"></font-awesome-icon>
                                    </template>
                                    <template #action>
                                        <v-btn
                                            class="no-text-transform"
                                            :disabled="!item.value"
                                            @click="copyToClipboard(item.value)"
                                            depressed
                                        >
                                            <font-awesome-icon
                                                class="mr-2"
                                                icon="fa-regular fa-clone"
                                            ></font-awesome-icon>

                                            Copy</v-btn
                                        >
                                    </template>
                                </TextButtonInput>
                            </v-list-item-subtitle>
                        </v-list-item>
                    </v-list>

                    <v-btn
                        @click="refreshShareLinks"
                        :disabled="loading"
                        :loading="loading"
                        :dark="!loading"
                        class="no-text-transform"
                        depressed
                        >Refresh Share Links</v-btn
                    >
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn class="no-text-transform" depressed @click="internalValue = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import TextButtonInput from '../../ui/TextButtonInput.vue';
import TributeVideoService from '@/services/tributeVideo.service';

export default {
    data() {
        return {
            token: null,
            tributeApi: null,
            loading: false,
        };
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    inject: ['tributeRender', 'state'],
    components: { TextButtonInput },
    computed: {
        internalValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        familyShareLink() {
            return this.$store.state.tributeVideo.familyLink || null;
        },
        contributorLink() {
            return this.$store.state.tributeVideo.deeplink || null;
        },
        completedVideoLink() {
            // TODO: Figure out where the completed video link lives
            if (this.tributeRender?.standardRender?.url) {
                return `${process.env.VUE_APP_VIDEO}/video/${this.$route.params.slug}/tribute-video`;
            }

            return null;
        },
        tributeId() {
            return this.$store.state.tributeVideo.id;
        },
        shareLinks() {
            return [
                { title: 'Family', value: this.familyShareLink, icon: 'fa-regular fa-users' },
                { title: 'Contributor', value: this.contributorLink, icon: 'fa-regular fa-users' },
                { title: 'Tribute Video', value: this.completedVideoLink, icon: 'fa-regular fa-film' },
            ];
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        copyToClipboard(val) {
            navigator.clipboard.writeText(val);
            this.showSnackbar({ message: 'Copied to Clipboard' });
        },
        async refreshShareLinks() {
            if (!this.tributeId) {
                this.showSnackbar({ message: 'Invalid tribute id', color: 'error' });
                return;
            }

            try {
                this.loading = true;
                await this.tributeApi.refreshTokenAndAllLinks(this.tributeId);

                this.showSnackbar({ message: 'Share links updated' });
            } catch (error) {
                console.log(error, 'error');
                this.showSnackbar({ message: 'Error refreshing links', color: 'error' });
            } finally {
                this.loading = false;
            }
        },
        async setAuthToken() {
            if (this.state.token) {
                this.token = this.state.token;
            } else {
                const response = await this.$auth.getIdTokenClaims();
                this.token = response.__raw;
            }
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.tributeApi = TributeVideoService(this.token);
    },
};
</script>
<style lang="scss" scoped>
.sharing-menu {
    display: flex;
    flex-direction: column;

    .v-list-item {
        display: inline-block;
        padding: 0.5rem;
    }

    .v-list-item__title {
        font-weight: 500;
    }

    .v-list-item__title,
    .v-list-item__subtitle {
        margin: 4px 0;
        line-height: 1.3;
    }
}
</style>
