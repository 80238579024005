import ApiService from './api.service';

const GuestbookService = token => {
    const api = ApiService.createApiInstance(token);

    return {
        getResponsesByService(serviceId, params) {
            return api.get(`/guest-book/service/${serviceId}`, { params });
        },
        getResponsesByFH(params) {
            return api.get(`/guest-book/funeralHome`, params);
        },
        getResponse(responseId) {
            return api.get(`/guest-book/${responseId}`);
        },
        deleteResponse(responseId) {
            return api.delete(`/guest-book/${responseId}`);
        },
        getConfigPresets() {
            return api.get(`/guest-book/config-presets`);
        },
    };
};

export default GuestbookService;
