<template>
    <div :class="['manage-slides', { expanded: isExpanded }]" @wheel.passive="handleWheel">
        <div class="action-bar" v-if="!state.isMobile">
            <v-row>
                <v-col cols="12" sm="4">
                    <div class="left"></div>
                </v-col>

                <v-col cols="12" sm="4">
                    <div class="center-buttons">
                        <button class="icon-button rounded wide" @click="toggleHeight">
                            <font-awesome-icon
                                class="mr-2"
                                :style="{
                                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'width transform 0.5s',
                                }"
                                icon="fa-regular fa-chevron-up"
                            ></font-awesome-icon>
                            {{ !isExpanded ? 'Explore Themes' : 'Back to Preview' }}
                        </button>
                    </div>
                </v-col>
                <v-col cols="12" sm="4"></v-col>
            </v-row>
        </div>

        <div :class="['slides-container', { expanded: isExpanded }]" ref="templatesWrapper">
            <div v-if="!loading" :class="['selected-template-wrapper', { expanded: isExpanded }]">
                <transition name="fade" mode="out-in">
                    <ThemeCard
                        :key="selectedTemplate.id"
                        :template="selectedTemplate"
                        :isSelected="true"
                        @edit="openModal"
                    />
                </transition>
            </div>
            <div v-else class="templates-wrapper">
                <div class="loading-slide"></div>
            </div>

            <div :class="['divider-wrapper', { expanded: isExpanded }]">
                <span :class="['header-text', { expanded: isExpanded }]">Choose your favorite Theme</span>
                <div v-if="selectedTemplate" :class="['divider', { expanded: isExpanded }]"></div>
                <div class="ml-2" v-if="isExpanded">
                    <v-text-field
                            :loading="fetchingTemplates"
                            dense
                            outlined
                            v-model="themeSearch"
                            placeholder="Search Themes"
                            :debounce="250"
                            class="search-field"
                            clearable
                            @click:clear="() => {
                                themeSearch = null;
                                search();
                            }
                            "
                        >
                            <template v-slot:prepend-inner>
                                <font-awesome-icon icon="fa-regular fa-magnifying-glass"></font-awesome-icon>
                            </template>
                        </v-text-field>
                </div>
            </div>

            <transition-group
                v-if="!loading && orderedTemplates.length"
                name="slide-animation"
                tag="div"
                :class="['templates-wrapper', { expanded: isExpanded }]"
            >
                <div
                    v-for="(template, index) in orderedTemplates"
                    :key="template.id"
                    class="theme-card-wrapper"
                    ref="templateCards"
                >
                    <ThemeCard
                        :template="template"
                        :isSelected="false"
                        :loading="loading"
                        @select="quickSelectTemplate(tributeVideo.id, template.id)"
                        @preview="previewTemplate(template, index)"
                    />
                </div>
            </transition-group>
            <div v-else-if="loading" class="templates-wrapper">
                <div class="loading-slide" v-for="n in 10" :key="n"></div>
            </div>
            <div v-else class="no-results-container">
                <template v-if="themeSearch && themeSearch.length">
                    No themes matching "{{ themeSearch }}".
                </template>
                <template v-else>No themes to display.</template>
            </div>
        </div>

        <modify-theme-modal
            :isOpen="isModalOpen"
            :tributeId="tributeVideo.id"
            :previewSource="previewSource"
            :previewMods="previewMods"
            :currentFont="templateFont"
            :mainPhoto="mainPhotoSource"
            :currentModifications="modifications"
            @close="closeModal"
            @save="updateTheme"
        />

        <preview-theme-modal
            v-if="isPreviewModalOpen"
            :isOpen="isPreviewModalOpen"
            :tributeId="tributeVideo.id"
            :currentFont="templateFont"
            :currentModifications="modifications"
            :mainPhoto="mainPhotoSource"
            :templates="orderedTemplates"
            :selectedTemplateIndex="selectedTemplateIndex"
            @close="isPreviewModalOpen = false"
            @save="handlePreviewSave"
        />
    </div>
</template>

<script>
import TributeThemeService from '@/services/tributeTemplate.service';
import TributeVideoService from '@/services/tributeVideo.service';
import ThemeCard from './Themes/ThemeCard.vue';
import { mapActions } from 'vuex';
import ModifyThemeModal from './Themes/EditTheme.vue';
import PreviewThemeModal from './Themes/PreviewTheme.vue';
import { debounce } from 'lodash';
export default {
    name: 'ManageThemes',
    components: {
        ThemeCard,
        ModifyThemeModal,
        PreviewThemeModal,
    },
    inject: ['state'],
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        tributeVideo: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isExpanded: {
            get() {
                return this.$store.state.tributeEditor.expanded;
            },
        },
    },
    watch: {
        themeSearch() {
            this.pageNumber = 0;
            this.debouncedDataFetch();
        },
        filteredTemplates: {
            handler(newTemplates) {
                this.orderedTemplates = [...newTemplates];
                this.templateMap.clear();
                this.templateIndexMap.clear();

                newTemplates.forEach((template, index) => {
                    this.templateMap.set(template.id, template);
                    this.templateIndexMap.set(template.id, index);
                });
            },
            deep: true,
            immediate: true,
        },
        allTemplates: {
            handler(newTemplates) {
                this.filteredTemplates = newTemplates.filter(template => template.id !== this.selectedTemplate?.id);
            },
            deep: true,
            immediate: true,
        },
        selectedTemplate: {
            handler() {
                this.filteredTemplates = this.allTemplates.filter(
                    template => template.id !== this.selectedTemplate?.id,
                );
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeVideo', ['updateTributeVideo', 'updateTributeVideoSelectedTemplates']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),

        openModal() {
            if (this.isExpanded) this.toggleExpandedState();
            this.$emit('pause-preview');
            this.isModalOpen = true;
        },
        previewTemplate(template, index) {
            this.$emit('pause-preview');
            if (this.isExpanded) this.toggleExpandedState();

            this.selectedTemplateForPreview = this.templateMap.get(template.id);
            this.selectedTemplateIndex = this.templateIndexMap.get(template.id);
            this.isPreviewModalOpen = true;
        },

        closeModal() {
            this.isModalOpen = false;
        },
        handleWheel(event) {
            if (event.shiftKey) return;

            if (this.isModalOpen || this.isPreviewModalOpen) return;

            // How much a user needs to scroll before it triggers the expanding behavior
            const wheelBuffer = 80;
            // Access event.deltaY to get the scroll direction and amount
            const direction = event.deltaY > 0 ? 'down' : event.deltaY < 0 ? 'up' : '';
            const distance = Math.abs(event.deltaY);
            // console.log('Mousewheel:', { direction, distance, y: event.deltaY, event});
            // container that overflows the expanded content. Will only trigger a collapse when this is at the top

            const slideContainer = this.$refs.templatesWrapper;
            if (distance > wheelBuffer) {
                if (direction === 'down' && !this.isExpanded) {
                    this.toggleExpandedState();
                }

                if (direction === 'up' && this.isExpanded && slideContainer.scrollTop < 5) {
                    this.toggleExpandedState();
                }
            }
        },
        async updateTheme(data) {
            const { modifications, font } = data;

            this.closeModal();
            return await this.selectTemplateWithMods(
                this.tributeVideo.id,
                this.selectedTemplate.id,
                modifications,
                font,
            );
        },
        updateModificationIds(newModIds, currentModifications) {
            const result = [];

            currentModifications.forEach(mod => {
                const thisMod = newModIds.find(newMod => newMod.name === mod.name);

                if (thisMod) {
                    result.push({
                        ...mod,
                        key: thisMod.creatomateId,
                    });
                }
            });

            return result;
        },
        initializeModifications(templateElements, templateModifications) {
            this.modifications = templateElements
                .filter(
                    element =>
                        element.name &&
                        element.name !== 'MainPicture' &&
                        element.name !== 'dash' &&
                        element.name !== 'Quote' &&
                        element.name !== 'MainPictire',
                )
                .map(element => ({
                    key: element.creatomateId,
                    name: element.name,
                    value: templateModifications[element.creatomateId] || '',
                }));
        },
        formatModsForSaving(changedMods) {
            const mods = changedMods.map(obj => {
                const { name, ...rest } = obj;
                return rest;
            });
            return mods.reduce((acc, obj) => {
                const key = obj.key;
                const value = obj.value;
                acc[key] = value;
                return acc;
            }, {});
        },
        scrollToSelected() {
            this.$nextTick(() => {
                if (this.$refs.templatesWrapper) {
                    this.$refs.templatesWrapper.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    });
                }
            });
        },
        // Removes any previous chosen font - may need changing
        async quickSelectTemplate(tributeVideoId, templateId) {
            try {
                if (!tributeVideoId) throw new Error('Invalid tribute video id');

                if (!templateId) throw new Error('Invalid template id');

                if (this.isExpanded && !this.state.isMobile) this.toggleExpandedState();

                this.selectedTemplate = this.templateMap.get(templateId);

                this.scrollToSelected();

                const modsChanged = this.hasCustomModifications(this.defaultModifications, this.modifications);
                const selectedTemplateDetails = await this.api.getTemplate(templateId);
                const tempMods = this.updateModificationIds(
                    selectedTemplateDetails.data.dynamicElements,
                    this.modifications,
                );

                if (modsChanged) {
                    const mods = this.formatModsForSaving(tempMods);
                    const resp = await this.api.selectTemplate(tributeVideoId, templateId, mods);
                    if (resp.data) {
                        this.selectedTemplate = resp.data;
                        this.updateTributeVideoSelectedTemplates([resp.data]);
                        this.initializeModifications(
                            selectedTemplateDetails.data.dynamicElements,
                            this.selectedTemplate.modifications,
                        );
                    }
                } else {
                    const resp = await this.api.selectTemplate(tributeVideoId, templateId);
                    if (resp.data) {
                        this.selectedTemplate = resp.data;
                        this.updateTributeVideoSelectedTemplates([resp.data]);
                        this.modifications = tempMods;
                    }
                }
                await this.refreshSmallPreview(tributeVideoId);

                this.showSnackbar({ message: 'Tribute Theme Updated.' });
                this.$emit('refresh-preview', this.$props.tributeVideo.id);
            } catch (error) {
                this.showSnackbar({ message: 'Unable to save theme.' });
            } finally {
                this.loading = false;
            }
        },

        async handlePreviewSave(payload) {
            try {
                if (!payload) return;

                const { templateId, modifications, font } = payload;

                this.loading = true;
                if (this.isExpanded && !this.state.isMobile) this.toggleExpandedState();
                this.isPreviewModalOpen = false;

                this.selectedTemplate = this.templateMap.get(templateId);
                this.scrollToSelected();

                const { data: templateDetails } = await this.api.getTemplate(templateId);
                const updatedMods = this.formatModsForSaving(
                    this.updateModificationIds(templateDetails.dynamicElements, modifications),
                );

                const { data: templateResponse } = await this.api.selectTemplate(
                    this.tributeVideo.id,
                    templateId,
                    updatedMods,
                );

                if (templateResponse) {
                    this.selectedTemplate = templateResponse;
                    this.updateTributeVideoSelectedTemplates([templateResponse]);
                    this.initializeModifications(templateDetails.dynamicElements, templateResponse.modifications);
                }

                await this.refreshSmallPreview(this.tributeVideo.id);

                this.previewSource.fonts.push(font);
                this.previewSource.elements[0].elements.forEach(el => {
                    if (el.type === 'text') el.font_family = font.family;
                });

                const requestData = {
                    id: templateId,
                    customFont: font,
                    source: JSON.stringify(this.previewSource),
                    modifications: templateResponse.modifications,
                };

                const { data: fontResponse } = await this.api.selectTemplateWithFont(this.tributeVideo.id, requestData);
                this.templateFont = fontResponse.templates[0].customFont;

                await this.refreshSmallPreview(this.tributeVideo.id);

                this.showSnackbar({ message: 'Tribute Theme Updated.' });
                this.$emit('refresh-preview', this.tributeVideo.id);
            } catch (error) {
                this.showSnackbar({ message: 'Unable to save theme.' });
            } finally {
                this.loading = false;
            }
        },

        async selectTemplateWithMods(tributeVideoId, templateId, mods = null, font = null) {
            try {
                this.loading = true;
                let newTemplate;

                if (!tributeVideoId) throw new Error('Invalid tribute video id');

                if (!templateId) throw new Error('Invalid template id');

                if (this.isExpanded) this.toggleExpandedState();

                this.selectedTemplate = this.templateMap.get(templateId);

                this.scrollToSelected();

                const selectedTemplateDetails = await this.api.getTemplate(templateId);

                var resp = await this.api.selectTemplate(tributeVideoId, templateId, mods);
                if (resp.data) {
                    newTemplate = resp.data;
                    this.initializeModifications(
                        selectedTemplateDetails.data.dynamicElements,
                        newTemplate.modifications,
                    );
                }

                await this.refreshSmallPreview(tributeVideoId);

                if (font !== null) {
                    var updatedSource = this.previewSource;
                    updatedSource.fonts.push(font);

                    for (let i = 0; i < updatedSource.elements.length; i++) {
                        var el = updatedSource.elements[i];
                        el.font_family = font.family;
                    }

                    var data = {
                        id: newTemplate.id,
                        customFont: font,
                        source: JSON.stringify(updatedSource),
                        modifications: newTemplate.modifications,
                    };

                    var resp = await this.api.selectTemplateWithFont(tributeVideoId, data);
                    newTemplate = resp.data.templates[0];
                    this.templateFont = newTemplate.customFont;

                    this.previewSource = updatedSource;
                }

                this.selectedTemplate = newTemplate;

                this.updateTributeVideoSelectedTemplates([this.selectedTemplate]);

                this.showSnackbar({ message: 'Tribute Theme Updated.' });
                this.$emit('refresh-preview', this.tributeVideo.id);
                return;
            } catch (error) {
                this.showSnackbar({ message: 'An Error Occurred - Please Try again Later' });
            } finally {
                this.loading = false;
            }
        },
        async refreshSmallPreview(tributeId) {
            var resp = await this.videoApi.generatePreviewJson(tributeId);

            if (resp.data.creatomateJson) {
                const creatomateJson = JSON.parse(resp.data.creatomateJson);

                if (!creatomateJson.source) throw new Error('Error parsing preview source');

                this.previewSource = creatomateJson.source;

                if (creatomateJson.modifications) {
                    this.previewMods = creatomateJson.modifications;
                }
                this.templateFont = this.getCurrentFont();
            }
            return;
        },

        toggleHeight() {
            this.toggleExpandedState();
        },

        getCurrentFont() {
            try {
                if (this.selectedTemplate.customFont) {
                    return this.selectedTemplate.customFont;
                }

                const foundFont = this.previewSource.elements[0].elements.find(
                    el => el.type === 'text' && el.font_family !== '',
                );

                return foundFont
                    ? { family: foundFont.font_family, default: true }
                    : { family: 'default-font', default: true };
            } catch (error) {
                return { family: 'default-font', default: true };
            }
        },
        formatTemplate(rawTemplate) {
            const { id, cosmosId, duration, dynamicElements, staffFavorite, title, url } = rawTemplate;
            return { id, cosmosId, duration, dynamicElements, staffFavorite, title, url };
        },
        updateTemplateData(newTemplates = []) {
            newTemplates.forEach((template, index) => {
                this.templateMap.set(template.id, template);
                this.templateIndexMap.set(template.id, this.orderedTemplates.length + index);
            });

            this.orderedTemplates.splice(this.orderedTemplates.length, 0, ...newTemplates);
        },
        debouncedDataFetch: debounce(function () {
            this.search();
        }, 700),
        async search() {
            // reset pageNumber 
            this.pageNumber = 0;
            const templateResults = await this.fetchTemplates();

            if (templateResults.data.templates?.length) {
                const results = templateResults.data.templates.map(t => this.formatTemplate(t));
                this.allTemplates = results;
                this.availableTemplates = templateResults.data.total;
            } else {
                this.allTemplates = [];
                this.availableTemplates = 0;
            }
            this.fetchingTemplates = false;
        },
        async fetchTemplates() {
            if (this.fetchingTemplates) {
                return;
            }
            this.fetchingTemplates = true;

            const payload = {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
            };
            if (this.themeSearch?.length) {
                payload.search = this.themeSearch;
            }
            return this.api.getTemplates(payload);
        },
        async loadMoreTemplates() {
            if (this.allTemplates.length >= this.availableTemplates || this.loading || this.fetchingTemplates) return;

            try {
                this.pageNumber += 1;
                
                const moreTemplates = await this.fetchTemplates();
                if (moreTemplates?.data?.templates) {
                    const newTemplates = moreTemplates.data.templates.map(t => this.formatTemplate(t));
                    this.allTemplates = [...this.allTemplates, ...newTemplates];

                    this.updateTemplateData(newTemplates);

                    this.$nextTick(() => {
                        if (this.observer) {
                            this.observer.disconnect();
                            this.observeLastTemplate();
                        }
                    });
                }
            } catch (error) {
                this.showSnackbar({ message: 'Unable to load more theme templates' });
            } finally {
                this.fetchingTemplates = false;
            }
        },
        hasCustomModifications(obj, array) {
            for (let item of array) {
                const { name, value } = item;
                if (obj.hasOwnProperty(name) && obj[name] !== value) {
                    return true;
                }
            }
            return false;
        },
        setDefaultModifications(tributeVideo) {
            function formatDateToYear(dateString) {
                if (!dateString) return null;
                const date = new Date(dateString);
                const thisDate = date.getFullYear();
                return thisDate.toString();
            }

            const { birthDate, deathDate, firstName, lastName } = tributeVideo;

            if (tributeVideo.mainPhotoUrl !== null && tributeVideo.mainPhotoUrl !== '') {
                this.mainPhotoSource = tributeVideo.mainPhotoUrl;
            } else {
                this.mainPhotoSource = '';
            }

            this.defaultModifications = {
                FirstName: firstName,
                LastName: lastName,
                BirthDate: formatDateToYear(birthDate),
                DeathDate: formatDateToYear(deathDate),
            };
        },
        setupIntersectionObserver() {
            this.$nextTick(() => {
                if (!this.$refs.templatesWrapper || this.allTemplates.length >= this.availableTemplates) {
                    return;
                }

                this.observer = new IntersectionObserver(
                    entries => {
                        const lastEntry = entries[0];

                        // stops observing if all templates are loaded
                        if (this.allTemplates.length >= this.availableTemplates) {
                            this.observer.disconnect();
                            this.observer = null;
                            return;
                        }

                        if (lastEntry.isIntersecting) {
                            this.loadMoreTemplates();
                        }
                    },
                    { root: this.$refs.templatesWrapper, threshold: 0.5 },
                );

                this.observeLastTemplate();
            });
        },

        observeLastTemplate() {
            this.$nextTick(() => {
                const templateElements = this.$refs.templateCards;
                if (templateElements && templateElements.length > 0) {
                    const lastElement = templateElements[templateElements.length - 1];

                    if (this.observer) {
                        this.observer.disconnect(); // prevents re-observing old elements
                    }

                    if (this.allTemplates.length < this.availableTemplates) {
                        this.observer.observe(lastElement);
                    } else {
                        this.observer = null;
                    }
                }
            });
        },
    },
    async mounted() {
        try {
            if (this.state.token) {
                this.token = this.state.token;
            } else {
                const tokenClaims = await this.$auth.getIdTokenClaims();
                if (!tokenClaims?.__raw) {
                    this.showSnackbar({ message: 'Unable to retrieve token' });
                }

                this.token = tokenClaims.__raw;
            }

            this.api = TributeThemeService(this.token);
            this.videoApi = TributeVideoService(this.token);

            if (this.eventId && this.tributeVideo?.id) {
                const selectedResponse = await this.api.getSelected(this.tributeVideo.id);
                if (!selectedResponse?.data?.templates?.length) {
                    this.showSnackbar({ message: 'No selected templates returned by the server' });
                }
                this.selectedTemplate = selectedResponse.data.templates[0];

                const [selectedTemplateDetails, allResponse] = await Promise.all([
                    this.api.getTemplate(this.selectedTemplate.id),
                    // this.api.getTemplates({ pageNumber: this.pageNumber, pageSize: 24 }),
                    this.fetchTemplates()
                ]).finally(() => {
                    this.fetchingTemplates = false;
                });

                if (!allResponse?.data) {
                    this.showSnackbar({ message: 'No template data returned by the server' });
                }
                this.availableTemplates = allResponse.data.total;

                const mappedTemplates = allResponse.data.templates.map(template => this.formatTemplate(template));
                this.allTemplates = mappedTemplates;
                this.orderedTemplates = mappedTemplates;

                this.templateMap.clear();
                this.templateIndexMap.clear();

                this.orderedTemplates.forEach((template, index) => {
                    this.templateMap.set(template.id, template);
                    this.templateIndexMap.set(template.id, index);
                });

                await this.refreshSmallPreview(this.tributeVideo.id);

                if (!selectedTemplateDetails?.data?.dynamicElements) {
                    this.showSnackbar({ message: 'Error loading some template details' });
                }
                this.initializeModifications(
                    selectedTemplateDetails.data.dynamicElements,
                    this.selectedTemplate.modifications,
                );

                await this.$store.dispatch('tributeVideo/updateTributeVideoSelectedTemplates', [this.selectedTemplate]);

                this.setDefaultModifications(this.$props.tributeVideo);
            }
        } catch (error) {
            this.showSnackbar({
                message: 'An error occurred while loading data. Please try again later.',
            });
        } finally {
            this.loading = false;
            this.setupIntersectionObserver();
        }
    },
    beforeUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
    data() {
        return {
            api: null,
            videoApi: null,
            token: null,
            selectedTemplateIndex: 0,
            selectedTemplate: [],
            allTemplates: [],
            filteredTemplates: [],
            orderedTemplates: [],
            templateMap: new Map(),
            templateIndexMap: new Map(),
            previewSource: {},
            previewMods: {},
            templateFont: {},
            modifications: [],
            defaultModifications: [],
            loading: true,
            isModalOpen: false,
            isPreviewModalOpen: false,
            selectedTemplateForPreview: null,
            pageSize: 24,
            pageNumber: 0,
            availableTemplates: 0,
            mainPhotoSource: '',
            observer: null,
            fetchingTemplates: false,
            themeSearch: null,
        };
    },
};
</script>

<style lang="scss" scoped>
.manage-slides {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: height 0.8s;
    @include mobile() {
        flex-direction: column-reverse;
    }
}

.loading-slide {
    width: 360px;
    height: 100%;
    max-height: 225px;
    border-radius: 12px;
    position: relative;
    transition: transform 0.2s, border 0.2s;
    flex-shrink: 0;
    display: inline-block;
    background: white;
    overflow: hidden;
    border: 1px solid #ccc;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px, #ddd 400px);
    background-size: 200% 100%;
    background-repeat: repeat-x;
    animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
    0% {
        background-position: -32px;
    }

    40%,
    100% {
        background-position: 360px;
    }
}

.theme-placeholder {
    width: 360px;
    height: 220px;
    border-radius: 6px;
    position: relative;
    gap: 8px;
    padding: 16px;
    flex-shrink: 0;
    display: inline-block;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px, #ddd 400px);
    background-repeat: repeat-x;
    animation: shimmer 1s infinite linear;
}

.action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    width: 100%;
    max-width: 100%;

    @include mobile() {
        margin-top: 8px;
    }
}

.header-text.expanded {
    display: block;
    margin: 0;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.025em;
    text-align: center;
    text-decoration-skip-ink: none;
    color: rgba(107, 114, 128, 1);
    text-transform: uppercase;
    text-wrap-style: balance;
}

.header-text {
    display: none;
}

.icon-button {
    width: 38px;
    height: 38px;
    background: white;
    border: 1px solid #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    &.rounded {
        border-radius: 6px !important;
    }

    &.wide {
        width: initial;
        padding: 0 10px;
        font-weight: 500;
    }

    &:hover {
        background-color: $light-gray;
    }
}

.divider {
    display: block;
    width: 2px;
    height: 84px;
    margin: auto 0;
    border: 1px solid #d1d5db;
}

.divider-wrapper {
    display: block;
    align-items: center;
    width: 100%;
    padding-top: 80px;
}

.divider.expanded {
    display: none;
}

.divider-wrapper.expanded {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0;
    height: 20px;
    justify-content: space-between;
    @include mobile() {
        .header-text {
            text-align: start;
        }
    }
}

.slides-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 8px;
    height: 100%;
    max-width: 100%;
    width: 100%;
    max-height: 252px;
    transition: height 0.8s;
    scroll-snap-type: x mandatory;
}

.slides-container.expanded {
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 0;
    max-height: 100%;
    align-content: flex-start;
}

.slides-container.expanded .templates-wrapper .slide {
    width: 100%;
    height: auto;
    flex-shrink: 1;
    aspect-ratio: 8 / 5;
}

.slides-container.expanded .selected-template-wrapper .slide {
    width: 100%;
    height: auto;
    flex-shrink: 1;
    aspect-ratio: 8 / 5;
}

.slides-container {
    max-height: 100%;

    .templates-wrapper,
    .divider-wrapper,
    .selected-template-wrapper {
        max-height: 100%;
    }
}

.templates-wrapper,
.selected-template-wrapper {
    display: flex;
    gap: 16px;
    flex-wrap: nowrap;
    height: 250px;
    flex: 1 1 auto;
    overflow-x: visible;
    @include mobile() {
        .slide {
            width: min(73vw, 360px);
        }
    }
}

.slides-container:not(.expanded) {
    overflow-x: auto;
}

.templates-wrapper.expanded {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding-right: 5px;
    padding-bottom: 30px;
}

.center-buttons {
    display: flex;
    justify-content: center;
}

.selected-template-wrapper.expanded {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    max-height: 300px;
    width: 100%;
    box-sizing: border-box;
    padding-right: 5px;
}

.templates-wrapper,
.selected-template-wrapper {
    &.expanded {
        @include mobile() {
            grid-template-columns: 1fr;
        }
    }
}
.templates-wrapper {
    @include landscape() {
        grid-template-columns: 1fr 1fr;
    }
}
.no-results-container {
    background: darken($light-gray, 5%);
    padding: 1rem;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex: 1;
    text-align: center;
    font-weight: bold;
    width: 80%;
    justify-content: center;
}
</style>
