<template>
    <div>
        <RightPanelModal v-model="showPanel">
            <template #title>
                <div style="width: 100%" class="d-flex align-center justify-space-between">
                    <span class="pa-3 pb-0"> Tribute Super </span>
                    <v-btn @click="togglePanel" fab small depressed>
                        <font-awesome-icon icon="fa-regular fa-x"></font-awesome-icon>
                    </v-btn>
                </div>
            </template>

            <template #content>
                <v-card-text>
                    <v-row v-if="formData">
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.firstName)">
                            <v-text-field label="First Name" disabled v-model="formData.firstName"> </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.lastName)">
                            <v-text-field label="Last Name" disabled v-model="formData.lastName"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.id)">
                            <v-text-field label="Id" disabled v-model="formData.id"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.externalId)">
                            <v-text-field label="External Id" disabled v-model="formData.externalId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.eventId)">
                            <v-text-field label="Event Id" disabled v-model="formData.eventId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.serviceId)">
                            <v-text-field label="Service Id" disabled v-model="formData.serviceId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.funeralHomeId)">
                            <v-text-field
                                label="Funeral Home Id"
                                disabled
                                readonly
                                v-model="formData.funeralHomeId"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.ownderId)">
                            <v-text-field label="Owner Id" disabled v-model="formData.ownerId"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field label="Profile Photo Id" v-model="formData.mainPhotoId"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.mainPhotoUrl)">
                            <v-text-field
                                label="Profile Photo Url"
                                disabled
                                v-model="formData.mainPhotoUrl"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Contributor Link" v-model="formData.deeplink"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Family Link" v-model="formData.familyLink"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <calendar datetime v-model="formData.deadline" label="Deadline" />
                            <!-- <v-text-field label="Deadline" v-model="formData.deadline"></v-text-field> -->
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Max Items" v-model="formData.maxItems"></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" md="6">
                            <v-text-field label="Status" v-model="formData.status"></v-text-field>
                        </v-col> -->
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.audioRenderRequired)">
                            <v-text-field
                                label="Audio Render Required"
                                disabled
                                v-model="formData.audioRenderRequired"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.faceUuid)">
                            <v-text-field label="Face Uuid" disabled v-model="formData.faceUuid"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.birthDate)">
                            <!-- <v-text-field label="Birth Date" disabled v-model="formData.birthDate"></v-text-field> -->
                            <calendar datetime disabled v-model="formData.birthDate" label="Birth Date" />
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.deathDate)">
                            <!-- <v-text-field label="Death Date" disabled v-model="formData.deathDate"></v-text-field> -->
                            <calendar datetime disabled v-model="formData.deathDate" label="Death Date" />
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.endingDuration)">
                            <v-text-field
                                label="Ending Duration"
                                disabled
                                v-model="formData.endingDuration"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.endingType)">
                            <v-text-field label="Ending Type" disabled v-model="formData.endingType"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.videoSlidesCount)">
                            <v-text-field
                                label="Video Slides Count"
                                disabled
                                v-model="formData.videoSlidesCount"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.videoSlidesDuration)">
                            <v-text-field
                                label="Video Slides Duration"
                                v-model="formData.videoSlidesDuration"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pointer" @click="copyToClipboard(formData.separatePlayer)">
                            <v-text-field
                                label="Separate Player"
                                disabled
                                v-model="formData.separatePlayer"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <calendar
                                datetime
                                disabled
                                v-model="formData.previewJsonRefreshed"
                                label="Preview Json Refreshed"
                            />
                        </v-col>
                        <v-col v-if="formData.id" cols="12" md="6" class="d-flex align-center">
                            <v-btn
                                :disabled="loading"
                                :dark="!loading"
                                class="no-text-transform"
                                block
                                depressed
                                @click="forceRefreshTributeJson(formData.id)"
                                >Refresh Preview Json</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card-text>
            </template>

            <template #actions>
                <div class="p-3 pt-0 d-flex flex-wrap" style="width: 100%; gap: 12px">
                    <div class="flex-grow-1">
                        <v-btn class="no-text-transform" outlined depressed block @click="togglePanel"> Close</v-btn>
                    </div>
                    <div class="flex-grow-1">
                        <v-btn
                            @click="adminUpdateTribute"
                            :disabled="loading"
                            :loading="loading"
                            depressed
                            class="no-text-transform"
                            color="#ff530d"
                            block
                            :dark="!loading"
                            >Save</v-btn
                        >
                    </div>
                </div>
            </template>
        </RightPanelModal>

        <slot name="activator" :on-click="togglePanel"> </slot>
    </div>
</template>
<script>
import RightPanelModal from '@/components/Tribute/Layout/RightPanelModal.vue';
import TributeVideoService from '@/services/tributeVideo.service';
import { mapActions } from 'vuex';
import Calendar from '../../ui/Calendar.vue';

export default {
    data() {
        return {
            tributeApi: null,
            loading: false,
            formData: null,
            showPanel: false,
        };
    },
    components: { RightPanelModal, Calendar },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
    },
    watch: {
        showPanel(newVal) {
            if (newVal) {
                this.getTributeVideo(this.eventId);
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', ['updateTributeVideo']),
        async forceRefreshTributeJson(tributeId) {
            try {
                if (!tributeId) throw new Error('Invalid tribute id');
                this.loading = true;

                await this.tributeApi.forceRefreshTributeJson(tributeId);
                this.showSnackbar({ message: 'Tribute Json Refresh Success' });
            } catch (error) {
                console.log('error refreshing tribute json', error);
                this.showSnackbar({ message: error.message });
            } finally {
                this.loading = false;
            }
        },
        copyToClipboard(val) {
            navigator.clipboard.writeText(val);
            this.showSnackbar({ message: 'Copied to Clipboard' });
        },
        togglePanel() {
            this.showPanel = !this.showPanel;
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        async getTributeVideo(eventId) {
            try {
                this.loading = true;
                const resp = await this.tributeApi.getTributeVideoByEvent(eventId);
                if (resp.data) {
                    this.formData = { ...resp.data };
                    this.formData.deadline = this.parseDate(this.formData.deadline);
                    this.formData.birthDate = this.parseDate(this.formData.birthDate);
                    this.formData.deathDate = this.parseDate(this.formData.deathDate);
                    this.formData.previewJsonRefreshed = this.parseDate(this.formData.previewJsonRefreshed);
                }
            } catch (error) {
                console.log(error, 'error fetching tribute');
            } finally {
                this.loading = false;
            }
        },
        parseDate(date) {
            if (date) {
                const localDate = this.$moment.utc(date).local();
                return localDate.toISOString();
            }
        },
        async adminUpdateTribute() {
            this.loading = true;
            const tributeId = this.formData.id;
            try {
                if (tributeId <= 0) {
                    throw new Error('Invalid tribute id');
                }

                var resp = await this.tributeApi.adminUpdateTribute(tributeId, this.formData);
                this.showPanel = false;
                this.showSnackbar({ message: 'Updated tribute' });

                if (resp.data) {
                    this.updateTributeVideo({ ...resp.data });
                }
            } catch (error) {
                console.log(error, 'error');
                this.showSnackbar({ message: 'Error updating tribute', color: 'error' });
            } finally {
                this.loading = false;
            }
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.tributeApi = TributeVideoService(this.token);

        this.getTributeVideo(this.eventId);
    },
};
</script>
<style lang="scss" scoped></style>
