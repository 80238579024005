<template>
    <div>
        <RightPanelModal v-model="showPanel">
            <template #title>
                <div style="width: 100%" class="d-flex align-center justify-space-between">
                    <span class="pa-3 pb-0"> Processing Photos </span>
                    <v-btn @click="togglePanel" fab small depressed>
                        <font-awesome-icon icon="fa-regular fa-x"></font-awesome-icon>
                    </v-btn>
                </div>
            </template>

            <template #content>
                <div class="flex-grow-1 d-flex justify-center align-center" v-if="!processingItems.length">
                    <h5>No slides are currently proccessing</h5>
                </div>

                <v-list v-else class="px-3">
                    <template v-for="(item, index) in processingItems">
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="mb-2">
                                    <span>
                                        {{ item.name }}
                                    </span>
                                </v-list-item-title>

                                <v-list-item-subtitle class="text-caption">
                                    <font-awesome-icon icon="fa-regular fa-clock" class="mr-2"></font-awesome-icon>
                                    <span v-if="item.createDate">
                                        {{ $moment(item.createDate).format('l') }} |
                                        {{ $moment(item.createDate).format('LTS') }}
                                    </span>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-chip
                                    class="d-flex align-center"
                                    small
                                    :color="item.coreImageProcessingRunning ? '#fb8c00' : '#4caf50'"
                                    dark
                                    >Core Image Processing
                                    <v-progress-circular
                                        v-if="item.coreImageProcessingRunning"
                                        indeterminate
                                        size="12"
                                        width="2"
                                        class="ml-2"
                                    ></v-progress-circular>
                                    <font-awesome-icon
                                        class="ml-2"
                                        v-else
                                        icon="fa-regular fa-circle-check"
                                    ></font-awesome-icon>
                                </v-chip>

                                <v-chip
                                    class="d-flex align-center mt-2"
                                    small
                                    :color="item.photoTagRunning ? '#fb8c00' : '#4caf50'"
                                    dark
                                    >Tagging Photo
                                    <v-progress-circular
                                        v-if="item.photoTagRunning"
                                        indeterminate
                                        size="12"
                                        width="2"
                                        class="ml-2"
                                    ></v-progress-circular>
                                    <font-awesome-icon
                                        class="ml-2"
                                        v-else
                                        icon="fa-regular fa-circle-check"
                                    ></font-awesome-icon>
                                </v-chip>
                            </v-list-item-action>
                        </v-list-item>

                        <v-divider v-if="index != processingItems.length - 1" class="ma-0"></v-divider>
                    </template>
                </v-list>
            </template>

            <template #actions>
                <div class="p-3 pt-0 d-flex flex-wrap" style="width: 100%; gap: 12px">
                    <div class="flex-grow-1">
                        <v-btn
                            class="no-text-transform"
                            @click="togglePanel"
                            :disabled="loading"
                            style="border-color: #d1d5db"
                            outlined
                            depressed
                            block
                        >
                            Close</v-btn
                        >
                    </div>
                    <div class="flex-grow-1"></div>
                </div>
            </template>
        </RightPanelModal>
    </div>
</template>
<script>
import RightPanelModal from '../Layout/RightPanelModal.vue';
import TributePhotoService from '@/services/tributePhoto.service';
export default {
    data() {
        return {
            loading: false,
            showPanel: false,
            tributePhotoApi: null,
            processingItems: [],
        };
    },
    components: { RightPanelModal },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
    },
    watch: {
        showPanel(newVal) {
            if (newVal) {
                this.fetchProcessingImages(this.eventId);
            }
        },
    },
    methods: {
        async fetchProcessingImages(eventId) {
            const resp = await this.tributePhotoApi.ingestPipelineStatus(eventId);
            if (resp.data) {
                this.processingItems = resp.data;
            }
        },
        togglePanel() {
            this.showPanel = !this.showPanel;
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async mounted() {
        await this.setAuthToken();

        this.tributePhotoApi = TributePhotoService(this.token);
    },
    sockets: {
        NotifyIngestPipelineUpdate(data) {
            if (data.id === this.eventId) {
                this.fetchProcessingImages(this.eventId);
            }
        },
    },
};
</script>
<style lang=""></style>
