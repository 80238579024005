import { render, staticRenderFns } from "./EmailSmsLink.vue?vue&type=template&id=ac8f2e90&scoped=true&"
import script from "./EmailSmsLink.vue?vue&type=script&lang=js&"
export * from "./EmailSmsLink.vue?vue&type=script&lang=js&"
import style0 from "./EmailSmsLink.vue?vue&type=style&index=0&id=ac8f2e90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac8f2e90",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VChip,VCombobox,VListItem,VListItemContent})
