<template>
  <div v-if="isOpen" class="modal-container">
    <div class="modal-overlay" @click="closeModal"></div>
    <div class="modal-content">
      <div class="modal-banner">
        <div class="banner-text">{{ localTemplates[localSelectedIndex].title }}</div>
        <div class="close-button" @click="closeModal">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 15L15 5M5 5L15 15" stroke="#6B7280" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>

      <div class="modal-body">
        <div class="preview-container-wrapper">
          <div class="video-frame">
            <div class="live-preview-component-container">
              <div class="live-preview-area" :class="loading ? 'hidden-preview' : ''" ref="previewContainer"></div>
              <div class="preview-overlay" :class="loading ? '' : 'hidden-preview'">
                <div v-if="loadError" class="d-flex flex-column align-items-center">
                  <font-awesome-icon style="font-size: 2rem" icon="fa-regular fa-cloud-exclamation" />
                  <p class="mt-2">Error loading preview.</p>
                </div>
                <div v-else-if="!currentTemplate" class="d-flex flex-column align-items-center">
                  <font-awesome-icon style="font-size: 2rem" icon="fa-regular fa-cloud-exclamation" />
                  <p class="mt-2">Preview source not found.</p>
                </div>
                <div v-else class="d-flex flex-column align-items-center">
                  <v-progress-circular indeterminate />
                  <p class="mt-2">Loading preview...</p>
                </div>
              </div>
              <div v-if="!loading" class="preview-controls">
                <button v-if="!isPlaying" @click="playVideo">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.19019 5.87695V20.5734L20.7374 13.2252L9.19019 5.87695Z" fill="white" />
                  </svg>
                </button>
                <button v-else @click="pauseVideo">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="7" y="5" width="4" height="16" fill="white" />
                    <rect x="15" y="5" width="4" height="16" fill="white" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="mods-form">
          <label>Font</label>
          <google-fonts-drop-down v-show="isOpen" v-model="tempFont" class="font-drop-down"
            @font-changed="handleFontChange" ref="fontDropdown" />
          <div class="form-group-row">
            <div class="form-group">
              <label>First Name</label>
              <input :disabled="loading" v-model="mods['FirstName'].value" maxlength="100" type="text"
                class="text-input" />
            </div>
            <div class="form-group">
              <label>Last Name</label>
              <input :disabled="loading" v-model="mods['LastName'].value" maxlength="100" type="text"
                class="text-input" />
            </div>
          </div>
          <div class="form-group-row">
            <div class="form-group">
              <label>Birth Year</label>
              <input :disabled="loading" v-model="mods['BirthDate'].value" maxlength="4" type="text" class="text-input" />
            </div>
            <div class="form-group">
              <label>Death Year</label>
              <input :disabled="loading" v-model="mods['DeathDate'].value" maxlength="4" type="text" class="text-input" />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <v-btn :disabled="loading" class="save-button prev-button" :style="{ opacity: hasChanges ? 1 : 0.9 }"
          @click="prevTemplate">
          <v-icon left> mdi-chevron-left </v-icon>
          Prev
        </v-btn>
        <v-btn :disabled="loading" class="save-button use-template-button" :style="{ opacity: hasChanges ? 1 : 0.9 }"
          @click="saveTemplate">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0002 3.33398V16.6673M16.6668 10.0007L3.3335 10.0007" stroke="black" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          Use This Template
        </v-btn>
        <v-btn :disabled="loading" class="save-button next-button" :style="{ opacity: hasChanges ? 1 : 0.9 }"
          @click="nextTemplate">
          Next
          <v-icon right> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { Preview } from '@creatomate/preview';
import { mapActions } from 'vuex';
import GoogleFontsDropDown from '@/components/Tribute/Themes/GoogleFontsDropdown.vue';

export default {
  name: 'PreviewTheme',
  components: {
    GoogleFontsDropDown,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    tributeId: {
      type: Number,
      required: true
    },
    currentFont: {
      type: Object,
      required: true
    },
    currentModifications: {
      type: Array,
      required: true
    },
    mainPhoto: {
      type: String,
      required: false
    },
    templates: {
      type: Array,
      required: true
    },
    selectedTemplateIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      tempFont: this.currentFont,
      mods: null,
      hasChanges: false,
      api: null,
      localTemplates: this.templates.slice(),
      localSelectedIndex: this.selectedTemplateIndex,
      preview: null,
      loading: true,
      loadError: false,
      isPlaying: false,
      source: null,
      previewFont: this.currentFont,
      currentRequestId: 0,
      tempModifications: null,
    };
  },
  computed: {
    currentTemplate() {
      return this.localTemplates[this.localSelectedIndex] || {};
    },
    currentTemplateId() {
      return this.templates[this.selectedTemplateIndex].cosmosId || {};
    },
    FirstName() {
      return this.mods && this.mods.FirstName ? this.mods.FirstName.value : '';
    },
    LastName() {
      return this.mods && this.mods.LastName ? this.mods.LastName.value : '';
    },
    BirthDate() {
      return this.mods && this.mods.BirthDate ? this.mods.BirthDate.value : '';
    },
    DeathDate() {
      return this.mods && this.mods.DeathDate ? this.mods.DeathDate.value : '';
    },
    customFont() {
      return this.tempFont;
    }
  },
  watch: {
    currentModifications: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0) {
          this.initializeTempModifications();
        }
      }
    },
    isOpen(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.ensureFontsLoaded(() => {
            if (this.$refs.fontDropdown && this.$refs.fontDropdown.setFont) {
              this.$refs.fontDropdown.setFont(this.currentFont.family);
            }
          });
        });
      }
    },
    selectedTemplateIndex(newIndex) {
      this.localSelectedIndex = newIndex;
    },
    templates(newTemplates) {
      this.localTemplates = newTemplates.slice();
    },
    currentTemplateId(newId, oldId) {
      if (!newId || newId === oldId) return;
      this.updatePreviewTemplate();
    },
    FirstName(newValue) {
      if (this.loading) return;
      this.isPlaying = false;
      if (this.preview) {
        this.preview.applyModifications({ FirstName: newValue });
        this.ensureElementVisibility('DeathDate', 1.5);
      }
    },
    LastName(newValue) {
      if (this.loading) return;
      this.isPlaying = false;
      if (this.preview) {
        this.preview.applyModifications({ LastName: newValue });
        this.ensureElementVisibility('DeathDate', 1.5);
      }
    },
    BirthDate(newValue) {
      if (this.loading) return;
      this.isPlaying = false;
      if (this.preview) {
        this.preview.applyModifications({ BirthDate: newValue });
        this.ensureElementVisibility('DeathDate', 1.5);
      }
    },
    DeathDate(newValue) {
      if (this.loading) return;
      this.isPlaying = false;
      if (this.preview) {
        this.preview.applyModifications({ DeathDate: newValue });
        this.ensureElementVisibility('DeathDate', 1.5);
      }
    },
    customFont(newValue) {
      if (this.loading) return;
      this.loading = true;
      this.isPlaying = false;
      this.previewFont = newValue;
      let sourceCopy = JSON.parse(JSON.stringify(this.source));
      if (!sourceCopy || !sourceCopy.elements) return;
      for (const el of sourceCopy.elements) {
        if (el.type === 'text' && typeof el.name === 'string' && el.name.trim().toLowerCase() !== 'dash') {
          el.font_family = newValue.family;
        }
        if (el.name === 'FirstName') el.text = this.FirstName;
        if (el.name === 'LastName') el.text = this.LastName;
        if (el.name === 'FullName') el.text = `${this.FirstName} ${this.LastName}`;
        if (el.name === 'BirthDate') el.text = this.BirthDate;
        if (el.name === 'DeathDate') el.text = this.DeathDate;
      }

      this.source = sourceCopy;

      this.preview
        .setSource(sourceCopy)
        .then(() => this.preview.applyModifications({ MainPicture: this.mainPhoto, MainPictire: this.mainPhoto }))
        .then(() => this.ensureElementVisibility('DeathDate', 1.5))
        .then(() => this.preview.play())
        .finally(() => {
          this.loading = false;
          this.isPlaying = true;
        });
    }
  },
  async mounted() {
    if (this.isOpen) {
      this.$nextTick(() => {
        this.ensureFontsLoaded(() => {
          if (this.$refs.fontDropdown && this.$refs.fontDropdown.setFont) {
            this.$refs.fontDropdown.setFont(this.tempFont.family);
          }

          if (this.currentTemplate.cosmosId) {
            const el = this.$refs.previewContainer;

            if (el) {
              const preview = new Preview(el, 'interactive', process.env.VUE_APP_CREATOMATE_KEY);
              this.preview = preview;

              preview.onReady = async () => {
                try {

                  await this.preview.loadTemplate(this.currentTemplate.cosmosId);

                  this.source = await this.preview.getSource();

                  let sourceCopy = JSON.parse(JSON.stringify(this.source));

                  for (const el of sourceCopy.elements) {
                    if (el.type === 'text' && typeof el.name === 'string' && el.name.trim().toLowerCase() !== 'dash') {
                      el.font_family = this.tempFont.family;
                    }
                    if (el.name === 'FirstName') el.text = this.FirstName;
                    if (el.name === 'LastName') el.text = this.LastName;
                    if (el.name === 'FullName') el.text = `${this.FirstName} ${this.LastName}`;
                    if (el.name === 'BirthDate') el.text = this.BirthDate;
                    if (el.name === 'DeathDate') el.text = this.DeathDate;
                  }

                  await this.preview.setSource(sourceCopy);

                } catch (error) {
                  this.loadError = true;
                } finally {

                  const initialZoom = this.calculateZoom(el.clientWidth);

                  await Promise.all([
                    this.preview.setZoom('fixed', initialZoom),
                    this.preview.applyModifications({
                      MainPicture: this.$props.mainPhoto,
                      MainPictire: this.$props.mainPhoto, //Typo in some creatomate templates
                    }),
                  ]);

                  await this.preview.play();
                  this.isPlaying = true;
                  this.loading = false;
                }
              };

              this.resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                  if (!this.loading) {
                    const newZoom = this.calculateZoom(entry.contentRect.width);
                    this.preview.setZoom('fixed', newZoom);
                  }
                }
              });
              this.resizeObserver.observe(el);
            }
          }

          return;
        });
      });
    }

  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    ...mapActions(['showSnackbar']),
    ...mapActions('tributeVideo', ['updateTributeVideo', 'updateTributeVideoSelectedTemplates']),
    async updatePreviewTemplate() {
      try {
        if (this.loading) return;
        this.currentRequestId++;
        const requestId = this.currentRequestId;
        this.loading = true;
        this.isPlaying = false;
        try {
          await this.preview.loadTemplate(this.currentTemplate.cosmosId);
          if (this.currentRequestId !== requestId) return;
          this.source = await this.preview.getSource();
          if (this.currentRequestId !== requestId) return;
          if (this.source && this.source.elements) {
            for (const el of this.source.elements) {
              if (el.type === 'text' && typeof el.name === 'string' && el.name.trim().toLowerCase() !== 'dash') {
                el.font_family = this.previewFont.family;
              }
              if (el.name === 'FirstName') el.text = this.FirstName;
              if (el.name === 'LastName') el.text = this.LastName;
              if (el.name === 'FullName') el.text = `${this.FirstName} ${this.LastName}`;
              if (el.name === 'BirthDate') el.text = this.BirthDate;
              if (el.name === 'DeathDate') el.text = this.DeathDate;
            }
          }
          if (this.currentRequestId !== requestId) return;
          await this.preview.setSource(this.source);
          if (this.currentRequestId !== requestId) return;
          await this.preview.applyModifications({
            MainPicture: this.mainPhoto,
            MainPictire: this.mainPhoto
          });
          if (this.currentRequestId !== requestId) return;
          this.loading = false;
          this.isPlaying = true;
          this.playVideo();
        } catch (error) {
          console.error(error);
          this.loadError = true;
          this.loading = false;
        }
      } catch (error) {
        console.log(error)
      }
    },
    playVideo() {
      this.isPlaying = true;
      if (this.preview) {
        this.preview.play();
      }
    },
    pauseVideo() {
      this.isPlaying = false;
      if (this.preview) {
        this.preview.pause();
      }
    },
    findCurrentTemplate() {
      const template = this.localTemplates.find(t => t.id === this.currentTemplate.id);
      if (!template) {
        console.warn('Current template not found in localTemplates.');
      }
      return template;
    },

    saveTemplate() {
  try {
    if (!this.currentTemplate || !this.currentTemplate.id) {
      console.warn('No template selected to save.');
      return;
    }

    let newMods = [];

    if (this.mods && typeof this.mods === 'object') {
      for (const [name, mod] of Object.entries(this.mods)) {
        const original = this.currentModifications?.find(m => m.key === mod.key)?.value;
          newMods.push({
            name: name, 
            creatomateId: mod.key,
            value: mod.value, 
          });
      }
    }
    let customFont = null;
      if (
        this.tempFont &&
        (this.tempFont.family !== this.$props.currentFont?.family ||
          this.tempFont.style !== this.$props.currentFont?.style ||
          this.tempFont.weight !== this.$props.currentFont?.weight)
      ) {
        customFont = {
          family: this.tempFont.family,
          source: this.tempFont.files?.regular?.replace('http:', 'https:') || '',
          style: 'normal',
          weight: 700,
        };
      }

    this.$emit('save', { templateId: this.currentTemplate.id, modifications: newMods, font: customFont });
    this.loading = true;
  } catch (error) {
    console.error('Error in saveTemplate:', error);
  }
},

    async ensureElementVisibility(elementName, addTime) {
      const element = this.preview.getElements().find(el => el.source.name === elementName);
      if (element) {
        await this.preview.setTime(element.globalTime + addTime);
      }
    },
    calculateZoom(clientWidth) {
      if (clientWidth >= 670) {
        return 0.55 + ((clientWidth - 670) / (740 - 670)) * (0.6 - 0.55);
      } else if (clientWidth >= 510) {
        return 0.5 + ((clientWidth - 510) / (670 - 510)) * (0.55 - 0.5);
      } else if (clientWidth >= 460) {
        return 0.4 + ((clientWidth - 460) / (510 - 460)) * (0.5 - 0.4);
      } else if (clientWidth >= 410) {
        return 0.35 + ((clientWidth - 410) / (460 - 410)) * (0.4 - 0.35);
      } else if (clientWidth >= 340) {
        return 0.3 + ((clientWidth - 330) / (410 - 340)) * (0.35 - 0.3);
      } else {
        return 0.3;
      }
    },
    initializeTempModifications() {
      const expectedNames = ['FirstName', 'LastName', 'BirthDate', 'DeathDate'];
      this.mods = expectedNames.reduce((acc, name) => {
        const mod = this.currentModifications.find(m => m.name === name);
        acc[name] = mod ? { key: mod.key, value: mod.value } : { key: '', value: '' };
        return acc;
      }, {});

      const fullNameMod = this.currentModifications.find(m => m.name === 'FullName');
      if (fullNameMod && fullNameMod.value) {
        const parts = fullNameMod.value.split(' ');
        if (parts.length === 2) {
          this.mods['FirstName'] = { key: fullNameMod.key, value: parts[0] };
          this.mods['LastName'] = { key: fullNameMod.key, value: parts[1] };
          this.FirstName = parts[0];
          this.LastName = parts[1];
        } else {
          console.warn('FullName does not split into exactly two parts:', fullNameMod.value);
        }
      }
    },
    closeModal() {
      this.$emit('close');
    },
    handleFontChange(font) {
      this.tempFont = font;
    },
    ensureFontsLoaded(callback) {
      const dropdown = this.$refs.fontDropdown;
      if (dropdown && dropdown.fonts && dropdown.fonts.length > 0) {
        callback();
      } else {
        const interval = setInterval(() => {
          if (dropdown && dropdown.fonts && dropdown.fonts.length > 0) {
            clearInterval(interval);
            callback();
          }
        }, 50);
      }
    },
    nextTemplate() {
      if (this.localTemplates.length === 0) return;
      this.localSelectedIndex = (this.localSelectedIndex + 1) % this.localTemplates.length;
      this.updatePreviewTemplate();
    },
    prevTemplate() {
      if (this.localTemplates.length === 0) return;
      this.localSelectedIndex =
        (this.localSelectedIndex - 1 + this.localTemplates.length) % this.localTemplates.length;
      this.updatePreviewTemplate();
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  top: 90px;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  border: 1px solid #d1d5db;
  @include mobile() {
    top: 90px;
    bottom: 90px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

.modal-content {
  position: relative;
  width: auto;
  min-width: 50vw;
  max-width: 98vw;
  height: 99vh;
  background: white;
  z-index: 9999;
  @include mobile() {
    height: auto;
    flex-grow: 1;
    max-height: calc(100vh - 150px);
  }
}

.modal-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  max-height: 5vh;
  background: white;
  border-bottom: 1px solid #d1d5db;
  @include mobile() {
    max-height: 50px;
    max-height: min-content;
    padding: 8px;
  }
}

.banner-text {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #374151;
}

.close-button {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px #0000000d;
}

.modal-body {
  flex: 1;
  padding: 0 16px;
  background: #f9fafb;
  overflow-y: auto;
  @include mobile() {
    padding-bottom: 10px;
  }
}

.preview-container-wrapper {
  margin-top: -20px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-height: 60vh;

  @media screen and (max-width: 768px) {
    margin-top: 0;
    max-height: 70vh;
  }

  @media screen and (max-width: 480px) {
    padding: 5px;
    max-height: 80vh;
  }
}

.video-frame {
  max-width: 700px;
  width: 100%;
  aspect-ratio: 16 / 9;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 14.95px 18.69px -3.74px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    max-width: 90%;
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
}

.mods-form {
  margin-top: -20px;
  max-height: 40vh;

  @media screen and (max-width: 1200px) {
    margin-top: 0;
  }
  @include mobile() {
    max-height: initial;
  }
}

.form-group-row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.form-group {
  flex: 1;
}

.text-input {
  width: 100%;
  height: 38px;
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;

  @media screen and (max-width: 768px) {
    height: 48px;
  }

  @media screen and (max-width: 480px) {
    height: 58px;
  }
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8vh;
  background-color: #ffffff;
  padding: 0 10px;
  gap: 5px;
  width: 100%;

  @media screen and (max-width: 768px) {
    height: 12vh;
  }
  @include mobile () {
    flex-wrap: nowrap;
  }
}

.save-button {
  height: 38px;
  border-radius: 6px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex: 1;
  min-width: 0;
}

.prev-button,
.next-button {
  flex: 0 0 20%;
  background-color: #ffffff;
  color: black;
  border: 1px solid #d1d5db;
}

.use-template-button {
  flex: 0 0 50%;
  background-color: #ffffff;
  color: black;
  border: 1px solid #d1d5db;
}

.next-button {
  background-color: $primary-orange !important;
  color: white !important;
}

.save-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.live-preview-component-container {
  position: relative;
  aspect-ratio: 16 / 9;
  background-color: black;
}

.live-preview-area {
  aspect-ratio: 16 / 9;
  opacity: 1;
  transition: 0.5s;
}

.hidden-preview {
  opacity: 0;
  z-index: -2;
}

.preview-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  color: white;
}

.preview-controls {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  pointer-events: auto;
}

.preview-controls button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-controls svg {
  width: 28px;
  height: 28px;
  fill: white;
  pointer-events: auto;
}
</style>
