<template>
    <div>
        <v-card style="border: 2px solid cornflowerblue" class="mt-12 pb-3 pl-3 pr-3 m-3" elevation="2">
            <v-card
                v-if="this.account.owner"
                class="ml-5"
                style="max-width: 25%; margin-top: -30px; border: 2px solid cornflowerblue"
            >
                <v-card-title class="p-2 justify-center">{{ this.account.owner }}</v-card-title>
            </v-card>
            <v-row class="mt-3 text-center">
                <v-col>
                    <div :class="editManager ? 'pointer-hover' : 'background-hover'" @dblclick="editingManager">
                        <h3><u>Account Manager</u></h3>
                        <div style="display: flex; flex-direction: column; align-items: center" v-if="editManager">
                            <div style="max-width: 350px">
                                <v-select
                                    :items="admins"
                                    v-model="tempValueManager"
                                    item-text="description"
                                    item-value="id"
                                >
                                    <template v-slot:selection="{ item }">
                                        <span class="d-flex justify-center" style="width: 100%">
                                            {{ item.description }}
                                        </span>
                                    </template>
                                </v-select>
                            </div>

                            <v-btn @click="updateManager" color="primary">Update</v-btn>
                        </div>
                        <div v-else>
                            <h5 v-if="account.accountManager">{{ account.accountManager }}</h5>
                            <h5 v-else>N/A</h5>
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <div :class="editSalesRep ? 'pointer-hover' : 'background-hover'" @dblclick="editingRep">
                        <h3><u>Sales Rep.</u></h3>

                        <div style="display: flex; flex-direction: column; align-items: center" v-if="editSalesRep">
                            <div style="max-width: 350px">
                                <v-select
                                    :items="admins"
                                    v-model="tempValueRep"
                                    item-text="description"
                                    item-value="id"
                                >
                                    <template v-slot:selection="{ item }">
                                        <span class="d-flex justify-center" style="width: 100%">
                                            {{ item.description }}
                                        </span>
                                    </template>
                                </v-select>
                            </div>

                            <v-btn @click="updateRep" color="primary">Update</v-btn>
                        </div>

                        <div v-else>
                            <h5 v-if="account.salesRep">{{ account.salesRep }}</h5>
                            <h5 v-else>N/A</h5>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row class="text-center">
                <v-col>
                    <div :class="editCalls ? 'pointer-hover' : 'background-hover'" @dblclick="editingCalls()">
                        <h3><u>Calls Per Year</u></h3>

                        <div style="display: flex; flex-direction: column; align-items: center" v-if="editCalls">
                            <div style="width: 200px">
                                <v-text-field
                                    class="centered-input large-input"
                                    v-model="tempValueCalls"
                                ></v-text-field>
                            </div>

                            <v-btn @click="updateCalls" color="primary">Update</v-btn>
                        </div>
                        <div v-else>
                            <h5>{{ account.callsPerYear }}</h5>
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <h3><u>Subscribed On</u></h3>
                    <h5 v-if="account.subscribedOnDate">{{ account.subscribedOnDate | moment('l') }}</h5>
                </v-col>
            </v-row>
        </v-card>

        <div>
            <div v-if="chartMounted" class="text-right">
                <v-btn text @click="openGoalModal">Edit Goal</v-btn>
            </div>
            <div v-if="chartMounted">
                <line-chart ref="usageChart" :chart-data="datacollection" :options="options"></line-chart>
            </div>

            <div v-else>
                <v-progress-linear indeterminate></v-progress-linear>
                <p class="text-center">Loading Chart..</p>
                <line-chart :chart-data="emptyData" :options="secondaryOptions"></line-chart>
            </div>
        </div>

        <v-dialog v-model="goalModal" max-width="400px">
            <v-card class="p-3">
                <h4>Edit Goal</h4>
                <v-text-field @keydown.enter="updateGoal" v-model="tempGoal" type="number"></v-text-field>
                <v-btn color="primary" @click="updateGoal">Update</v-btn>
            </v-card>
        </v-dialog>

        <account-history-table :token="token" :funeralHomeId="funeralHomeId" />

        <div v-if="$auth.role.includes('SuperAdmin')" class="zone mb-3 pt-3 pl-3 pr-3">
            <div style="display: flex; justify-content: space-between; align-items: flex-start">
                <h6>TREASURED MEMORIES</h6>
            </div>
            <div style="display: flex; justify-content: space-between; align-items: flex-start">
                <v-container
                    style="display: flex; justify-content: space-between; align-items: center"
                    class="p-0"
                    fluid
                >
                    <v-checkbox :disabled="disableMem" v-model="memories">
                        <template v-slot:label>
                            <div>Connect to Treasured Memories</div>
                        </template>
                    </v-checkbox>
                    <img
                        src="https://treasuredmemories.us/wp-content/uploads/2020/07/TreasuredMemories_Logo.png"
                        alt="Treasured Memories Logo"
                        style="max-height: 60px"
                        class="pb-1"
                    />
                </v-container>
            </div>
        </div>

        <div class="mt-12">
            <v-form v-if="$auth.role.includes('SuperAdmin')" @submit.prevent="updateHubspot">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 585.44 164.79"
                    class="hubspot-logo"
                >
                    <defs>
                        <clipPath id="clip-path" transform="translate(0 -1.17)">
                            <rect class="cls-1" width="585.44" height="165.96" />
                        </clipPath>
                        <clipPath id="clip-path-3" transform="translate(0 -1.17)">
                            <rect class="cls-1" x="375.89" y="0.13" width="156.42" height="163.35" />
                        </clipPath>
                    </defs>
                    <title>Asset 3</title>
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Outlines_-_Full_Color" data-name="Outlines - Full Color">
                            <g class="cls-2">
                                <polygon
                                    class="cls-3"
                                    points="63.4 26.3 63.4 73.11 20.41 73.11 20.41 26.3 0 26.3 0 138.04 20.41 138.04 20.41 92.75 63.4 92.75 63.4 138.04 83.81 138.04 83.81 26.3 63.4 26.3"
                                />
                                <g class="cls-2">
                                    <path
                                        class="cls-3"
                                        d="M149.13,103.09a16.88,16.88,0,0,1-33.77,0V55.23H96v47.86a36.21,36.21,0,0,0,72.42,0V55.23H149.13Z"
                                        transform="translate(0 -1.17)"
                                    />
                                    <path
                                        class="cls-3"
                                        d="M292.57,60.16c0-9.81,6.49-12.92,13.6-12.92,5.73,0,13.3,4.36,18.25,9.65L337.1,41.95c-6.33-8.56-19.17-14.48-29.68-14.48-21,0-36.19,12.3-36.19,32.69,0,37.83,46.24,25.83,46.24,47,0,6.53-6.34,12.3-13.6,12.3-11.45,0-15.16-5.6-20.42-11.52l-14.07,14.63c9,11.05,20.1,16.66,33.4,16.66,19.95,0,36-12.45,36-31.91,0-42-46.24-28.95-46.24-47.16"
                                        transform="translate(0 -1.17)"
                                    />
                                    <path
                                        class="cls-3"
                                        d="M578.63,121.36c-11.44,0-14.69-4.95-14.69-12.53V75.28h17.78v-17H563.94V35.85l-19.63,8.81V113c0,17.48,12.06,26.29,28.6,26.29a39.62,39.62,0,0,0,7.74-.62l4.79-17.63c-2.16.15-4.64.3-6.81.3"
                                        transform="translate(0 -1.17)"
                                    />
                                    <path
                                        class="cls-3"
                                        d="M222.82,55.79c-9.59,0-16.28,2.78-22.75,9.13V28.14H180.68V96.31c0,25.52,18.45,43,39.18,43,23,0,43.23-17.79,43.23-41.75,0-23.66-18.62-41.76-40.27-41.76m-.12,64a22,22,0,1,1,22-22,22,22,0,0,1-22,22"
                                        transform="translate(0 -1.17)"
                                    />
                                    <path
                                        class="cls-3"
                                        d="M433.36,96.54c0-24-20.19-41.75-43.23-41.75-20.73,0-39.18,17.48-39.18,43V166h19.39V129.17c6.46,6.34,13.16,9.13,22.74,9.13,21.65,0,40.27-18.09,40.27-41.76M415,96.3a22,22,0,1,1-22-22,22,22,0,0,1,22,22"
                                        transform="translate(0 -1.17)"
                                    />
                                </g>
                                <g class="cls-4">
                                    <path
                                        class="cls-5"
                                        d="M495.81,54.48V35.2a14.84,14.84,0,0,0,8.56-13.38v-.45A14.87,14.87,0,0,0,489.54,6.54h-.45a14.87,14.87,0,0,0-14.83,14.83v.45a14.84,14.84,0,0,0,8.56,13.38V54.48a42.06,42.06,0,0,0-20,8.8L409.88,22.09a16.55,16.55,0,0,0,.6-4.17,16.73,16.73,0,1,0-16.75,16.71A16.55,16.55,0,0,0,402,32.38L454.05,72.9a42.18,42.18,0,0,0,.65,47.56L438.86,136.3a13.61,13.61,0,0,0-3.93-.64,13.75,13.75,0,1,0,13.75,13.75,13.53,13.53,0,0,0-.64-3.93l15.67-15.67a42.25,42.25,0,1,0,32.1-75.33m-6.49,63.42A21.68,21.68,0,1,1,511,96.22a21.68,21.68,0,0,1-21.68,21.68"
                                        transform="translate(0 -1.17)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <p class="text">Hubspot integration settings</p>
                <v-text-field v-model="hubspot.hubspotCompanyID" label="Hubspot Company Id"></v-text-field>
                <div class="mt-8 mb-1 text-right">
                    <v-btn type="submit">Update</v-btn>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import AccountHistoryTable from '@/components/Tables/Admin/AccountHistoryTable.vue';
import LineChart from '@/components/charts/LineChart';

var annotations = {
    drawTime: 'afterDatasetsDraw',
    events: ['click', 'mouseout', 'over', 'mouseenter'],
    annotations: [
        {
            id: '1',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'simple-y-axis',
            value: null,
            borderColor: '#61affe',
            borderWidth: 3,
            label: {
                enabled: true,
                position: 'right',
                content: 'Goal',
                fontSize: 10,
            },
        },
    ],
};

export default {
    data() {
        return {
            goalModal: false,
            tempGoal: null,
            account: {
                salesRep: '',
                salesRepId: '',
                salesRepEmail: '',
                accountManagerId: '',
                accountManagerEmail: '',
                createDate: '',
                goalStreams: '',
                callsPerYear: 0,
                owner: '',
            },
            tempValueCalls: '',
            tempValueManager: '',
            tempValueRep: '',
            editSalesRep: false,
            editManager: false,
            editCalls: false,
            admins: [],
            hubspot: {
                hubspotCompanyID: '',
            },
            datacollection: {
                labels: [],
                datasets: [
                    { label: 'Usage', data: [], backgroundColor: 'rgba(0,0,0,0)', borderColor: 'rgba(255,83,13,.7)' },
                ],
            },
            emptyData: {
                labels: [],
                datasets: [],
            },
            chartMounted: false,
            options: {
                scales: {
                    yAxes: [
                        {
                            id: 'simple-y-axis',
                            ticks: {
                                beginAtZero: true,
                                userCallback: function (label, index, labels) {
                                    // when the floored value is the same as the value we have a whole number
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            },
                        },
                    ],
                },
                legend: {
                    onHover: function (e) {
                        e.target.style.cursor = 'pointer';
                    },
                    onLeave: function (e) {
                        e.target.style.cursor = 'default';
                    },
                },
                tooltips: {
                    mode: 'label',
                },
                responsive: true,
                maintainAspectRatio: false,
                annotation: annotations,
            },
            secondaryOptions: {
                scales: {
                    yAxes: [
                        {
                            id: 'simple-y-axis',
                            ticks: {
                                beginAtZero: true,
                                userCallback: function (label, index, labels) {
                                    // when the floored value is the same as the value we have a whole number
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                                max: 100,
                            },
                        },
                    ],
                },
                tooltips: {
                    mode: 'label',
                },
                responsive: true,
                maintainAspectRatio: false,
            },
            memories: false,
            disableMem: true,
        };
    },
    components: {
        AccountHistoryTable,
        LineChart,
    },
    props: ['token', 'funeralHomeId', 'funeralHomeName'],
    watch: {
        memories() {
            if (!this.disableMem) {
                this.setTreasuredMem();
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        toggleDisabledMem() {
            this.disableMem = !this.disableMem;
        },
        setTreasuredMem() {
            this.disableMem = true;
            this.axiosInstance
                .put(`/funeralhomes/treasured-memories/${this.funeralHomeId}?treasuredMemories=${this.memories}`)
                .then(response => {
                    this.showSnackbar({ message: 'Updated treasured memories connection' });
                    this.disableMem = false;
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating treasured memories connection' });
                    this.disableMem = false;
                });
        },
        getTreasuredMem() {
            this.axiosInstance
                .get(`/funeralhomes/treasured-memories/${this.funeralHomeId}`)
                .then(response => {
                    this.memories = response.data.treasuredMemories;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        openGoalModal() {
            this.tempGoal = this.goalStreams;
            this.goalModal = true;
        },
        updateGoal() {
            this.goalModal = false;
            if (this.tempGoal != this.goalStreams) {
                this.chartMounted = false;
                this.axiosInstance
                    .put(`/account-managment/goal-streams/${this.funeralHomeId}?GoalStreams=${this.tempGoal}`)
                    .then(response => {
                        this.goalStreams = this.tempGoal;
                        this.options.annotation.annotations[0].value = this.goalStreams;
                        this.tempGoal = null;
                        this.getChartData();
                        this.showSnackbar({ message: 'Goal streams updated' });
                    })
                    .catch(error => {
                        console.log(error);
                        this.showSnackbar({ message: 'Error updating goal streams' });
                    });
            } else {
                this.goalModal = false;
                return;
            }
        },
        getChartData() {
            this.datacollection.labels = [];
            this.datacollection.datasets[0].data = [];
            this.chartMounted = false;
            this.axiosInstance
                .get(`/account-managment/get-account-usage-chart/${this.funeralHomeId}`)
                .then(response => {
                    let eventCounts = [];
                    response.data.forEach(item => {
                        eventCounts.push(item.eventCounts);
                    });

                    this.datacollection.datasets[0].data = eventCounts;

                    response.data.forEach(item => {
                        this.datacollection.labels.push(item.month);
                    });
                    this.datacollection.labels.reverse();
                    this.datacollection.datasets[0].data.reverse();
                })
                .finally(response => {
                    this.chartMounted = true;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        editingCalls() {
            this.editCalls = !this.editCalls;
            this.tempValueCalls = this.account.callsPerYear;
        },
        editingManager() {
            this.editManager = !this.editManager;
            this.tempValueManager = this.account.accountManagerId;
        },
        editingRep() {
            this.editSalesRep = !this.editSalesRep;
            this.tempValueRep = this.account.salesRepId;
        },
        updateCalls() {
            this.account.callsPerYear = this.tempValueCalls;
            this.axiosInstance
                .post(
                    `/account-managment/calls-per-year?funeralHomeId=${this.funeralHomeId}&calls=${this.account.callsPerYear}`,
                )
                .then(response => {
                    this.showSnackbar({ message: 'Updated calls per year' });
                    this.editCalls = false;
                    this.getAcctManagementData();
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating calls per year' });
                });
        },
        updateManager() {
            this.account.accountManagerId = this.tempValueManager;
            this.axiosInstance
                .post(
                    `/account-managment/account-manager?funeralHomeId=${this.funeralHomeId}&repId=${this.account.accountManagerId}`,
                )
                .then(response => {
                    this.showSnackbar({ message: 'Updated account manager' });
                    this.editManager = false;
                    this.getAcctManagementData();
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating account manager' });
                });
        },
        updateRep() {
            this.account.salesRepId = this.tempValueRep;
            this.axiosInstance
                .post(
                    `/account-managment/sales-rep?funeralHomeId=${this.funeralHomeId}&repId=${this.account.salesRepId}`,
                )
                .then(response => {
                    this.showSnackbar({ message: 'Updated sales rep' });
                    this.editSalesRep = false;
                    this.getAcctManagementData();
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating sales rep' });
                });
        },
        getAdmins() {
            this.axiosInstance
                .get('dropdown/superadmin')
                .then(response => {
                    this.admins = response.data;
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error loading account managers' });
                });
        },
        updateAccount() {
            this.editingAccount = false;
            this.showSnackbar({ message: 'This feature is not ready yet' });
        },
        editAccount() {
            this.editingAccount = !this.editingAccount;
        },
        getGoalStreams() {
            this.axiosInstance
                .get(`account-managment/goal-streams/${this.funeralHomeId}`)
                .then(response => {
                    this.goalStreams = response.data.goalStreams;
                    this.options.annotation.annotations[0].value = this.goalStreams;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getAcctManagementData() {
            this.axiosInstance
                .get(`account-managment/table/${this.funeralHomeId}`)
                .then(response => {
                    this.account = response.data;
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error loading account data' });
                    console.log(error);
                });
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        updateHubspot() {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(this.hubspotEndpoint, this.hubspot)
                .then(({ data }) => {
                    this.showSnackbar({ message: 'Hubspot Company ID successfully updated!' });
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error updating Hubspot Company ID! Please try again.' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getIntegrations() {
            this.loading = true;
            const instance = this.axios.create({ headers: { Authorization: `Bearer ${this.token}` } });
            // const passareRequest = instance.get(this.passareEndpoint + `/${this.funeralHomeId}`);
            // const facebookRequest = instance.get(this.facebookEndpoint);
            // const zapierRequest = instance.get(this.zapierEndpoint + `/apikey`);
            const hubspotRequest = instance.get(this.hubspotEndpoint);
            const requests = [hubspotRequest];
            if (this.$auth.role.includes('SuperAdmin')) {
                // const hubspotRequest = instance.get(this.hubspotEndpoint);
                // requests.push(hubspotRequest);
            }

            Promise.allSettled(requests).then(([hubspotRes]) => {
                // Handle Passare response
                // if (passareRes.status === 'fulfilled') {
                //     const { passareOrganizationId, passareToken, syncPassare } = passareRes.value.data;
                //     this.passare.passareOrganizationId = passareOrganizationId;
                //     this.passare.passareToken = passareToken;
                //     this.passare.syncPassare = syncPassare;
                // } else {
                //     this.showSnackbar({ message: `There was an error retrieving your Passare settings.` });
                // }

                // Handle Facebook response
                // if (facebookRes.status === 'fulfilled') {
                //     const { facebookRtmps, facebookStreamKey } = facebookRes.value.data;
                //     this.facebook.facebookRtmps = facebookRtmps;
                //     this.facebook.facebookStreamKey = facebookStreamKey;
                // } else {
                //     this.showSnackbar({ message: 'There was an error retrieving your Facebook settings.' });
                // }

                // Handle Zapier response
                // if (zapierRes.status === 'fulfilled') {
                //     this.zapier.api_key = zapierRes.value.data.api_key;
                // } else {
                //     this.showSnackbar({ message: 'There was an error retrieving your Zapier settings.' });
                // }

                // Handle Hubspot response
                if (hubspotRes.status === 'fulfilled') {
                    this.hubspot.hubspotCompanyID = hubspotRes.value.data.hubspotCompanyID;
                } else {
                    this.showSnackbar({ message: 'There was an error retrieving your Hubspot settings.' });
                }

                this.loading = false;
            });
        },
    },
    created() {
        // this.passareEndpoint = process.env.VUE_APP_API + `/Integration/Passare/settings`;
        // this.facebookEndpoint = process.env.VUE_APP_API + `/Integration/Facebook/settings/${this.funeralHomeId}`;
        // this.zapierEndpoint = process.env.VUE_APP_API + `/Integration/Zapier/settings/${this.funeralHomeId}`;
        this.hubspotEndpoint = process.env.VUE_APP_API + `/Integration/Hubspot/settings/${this.funeralHomeId}`;
        this.getIntegrations();
    },
    mounted() {
        this.createAxiosInstance();
        this.getGoalStreams();
        this.getAcctManagementData();
        this.getAdmins();
        this.getChartData();
        this.getTreasuredMem();
        setTimeout(() => {
            this.disableMem = false;
        }, '2000');
    },
};
</script>

<style>
.background-hover:hover {
    cursor: pointer;
    background-color: #eee;
}
.pointer-hover:hover {
    cursor: pointer;
}
.centered-input input {
    text-align: center;
}
.large-input input {
    font-size: 1.5em;
}

.cls-1 {
    fill: none;
}
.cls-2 {
    clip-path: url(#clip-path);
}
.cls-3 {
    fill: #33475b;
}
.cls-4 {
    clip-path: url(#clip-path-3);
}
.cls-5 {
    fill: #ff7a59;
}
.hubspot-logo {
    width: 100px;
    margin-bottom: 1rem;
}

.connection-entry {
    width: 100%;
    position: relative;
    cursor: pointer;
    margin: 1rem 0;
}

.zone {
    background-color: #ecf3fb;
    border: 1px solid #61affe;
    border-radius: 5px;
}
</style>
