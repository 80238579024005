<template>
    <div :class="['manage-slides', { expanded: isExpanded }]" @wheel.passive="handleWheel">
        <div class="action-bar">
            <v-row>
                <v-col cols="12" sm="4">
                    <div class="left-buttons">
                        <CustomTooltip :tooltipProps="{ top: true, maxWidth: '400px' }">
                            <template #activator>
                                <v-chip
                                    @click="showSyncSettingsModal = !showSyncSettingsModal"
                                    :color="playlistDurationPercent >= 100 ? '#D1FAE5' : '#FEF3C7'"
                                    :text-color="playlistDurationPercent >= 100 ? '#065F46' : '#92400E'"
                                >
                                    <font-awesome-icon class="mr-2" icon="fa-solid fa-music"></font-awesome-icon>
                                    <span>
                                        {{ playlistDuration | formatTimeStamp }}
                                    </span>

                                    <font-awesome-icon class="mx-3" icon="fa-regular fa-pipe"></font-awesome-icon>
                                    <font-awesome-icon class="mr-2" icon="fa-solid fa-video"></font-awesome-icon>
                                    <span>
                                        {{ tributeVideoTotalDuration | formatTimeStamp }}
                                    </span>

                                    <font-awesome-icon
                                        class="ml-2"
                                        icon="fa-regular fa-circle-info"
                                    ></font-awesome-icon>
                                </v-chip>
                            </template>

                            <template #title> <span>Playlist Duration</span> </template>
                            <template #content>
                                <span
                                    >Please ensure the playlist duration matches the video length. MemoryShare will
                                    automatically adjust slide durations, but you may need to add or remove songs to fit
                                    the number of slides.
                                </span>
                            </template>
                        </CustomTooltip>

                        <CustomTooltip
                            v-if="playlistDurationPercent >= 100"
                            :tooltipProps="{ top: true, maxWidth: '400px' }"
                        >
                            <template #activator>
                                <v-chip
                                    @click="showSyncSettingsModal = !showSyncSettingsModal"
                                    color="#D1FAE5"
                                    text-color="#065F46"
                                >
                                    <font-awesome-icon icon="fa-regular fa-circle-check"></font-awesome-icon>
                                </v-chip>
                            </template>

                            <template #content>
                                <span
                                    >Playlist matches video duration. If the playlist is longer, audio will fade out at
                                    the end of the video.</span
                                >
                            </template>
                        </CustomTooltip>

                        <CustomTooltip v-else :tooltipProps="{ top: true, maxWidth: '400px' }">
                            <template #activator>
                                <v-chip
                                    @click="showSyncSettingsModal = !showSyncSettingsModal"
                                    color="#FEF3C7"
                                    text-color="#92400E"
                                >
                                    <font-awesome-icon icon="fa-regular fa-triangle-exclamation"></font-awesome-icon>
                                </v-chip>
                            </template>

                            <template #title>
                                <span>Warning</span>
                            </template>

                            <template #content>
                                <span
                                    >Playlist is not long enough to match video length. Some sections will play without
                                    audio.</span
                                >
                            </template>
                        </CustomTooltip>
                    </div>
                </v-col>
                <v-col cols="12" sm="4" v-if="!state.isMobile">
                    <div class="center-buttons">
                        <button class="icon-button rounded wide" @click="toggleHeight">
                            <font-awesome-icon
                                class="mr-2"
                                :style="{
                                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'width transform 0.5s',
                                }"
                                icon="fa-regular fa-chevron-up"
                            ></font-awesome-icon>
                            <span v-if="isExpanded"> {{ state.isMobile ? 'Preview' : 'Back to Preview' }} </span>
                            <span v-else>
                                {{ state.isMobile ? 'Music' : 'Explore Music' }}
                            </span>
                        </button>
                    </div>
                </v-col>
                <v-col cols="12" sm="4">
                    <div class="d-flex justify-end" style="gap: 12px" v-if="publicPlaylist.length > 0">
                        <CustomTooltip :tooltipProps="{ top: true, maxWidth: '400px' }">
                            <template #activator>
                                <v-chip
                                    :dark="selectedPlaylist === playlistTypes.ORIGINAL"
                                    :outlined="selectedPlaylist !== playlistTypes.ORIGINAL"
                                    :color="selectedPlaylist === playlistTypes.ORIGINAL ? '#f97316' : ''"
                                    @click="selectPlaylist(playlistTypes.ORIGINAL)"
                                >
                                    <font-awesome-icon
                                        v-if="selectedPlaylist === playlistTypes.ORIGINAL"
                                        icon="fa-solid fa-circle-check"
                                        class="mr-2"
                                    ></font-awesome-icon>
                                    <span> Original Playlist </span>
                                </v-chip>
                            </template>

                            <template #title>
                                <span>Original Playlist</span>
                            </template>

                            <template #content>
                                <span
                                    >The original playlist may contain copyright music, it will be used for the private
                                    or downloaded version of this Tribute Video.</span
                                >
                            </template>
                        </CustomTooltip>

                        <CustomTooltip :tooltipProps="{ top: true, maxWidth: '400px' }">
                            <template #activator>
                                <v-chip
                                    :dark="selectedPlaylist === playlistTypes.PUBLIC"
                                    :outlined="selectedPlaylist !== playlistTypes.PUBLIC"
                                    :color="selectedPlaylist === playlistTypes.PUBLIC ? '#f97316' : ''"
                                    @click="selectPlaylist(playlistTypes.PUBLIC)"
                                >
                                    <font-awesome-icon
                                        v-if="selectedPlaylist === playlistTypes.PUBLIC"
                                        icon="fa-solid fa-circle-check"
                                        class="mr-2"
                                    ></font-awesome-icon>
                                    <span> Public Playlist </span>
                                </v-chip>
                            </template>

                            <template #title>
                                <span>Public Playlist</span>
                            </template>

                            <template #content>
                                <span
                                    >The public playlist will be used in the public embeded version of this Tribute
                                    Video</span
                                >
                            </template>
                        </CustomTooltip>
                    </div>
                </v-col>
            </v-row>
        </div>
        <draggable
            :key="selectedPlaylist"
            v-model="slides"
            class="slides-container"
            v-if="!loading"
            :options="draggableOptions"
            @start="onDragStart"
            @end="onDragEnd"
        >
            <template v-for="(song, index) in slides">
                <div
                    class="slide"
                    :class="{
                        dragging: isDragging && dragIndex === index,
                    }"
                >
                    <div class="slide-order" style="z-index: 10">
                        {{ index + 1 }}
                    </div>
                    <MusicTrack
                        :song="song"
                        mode="slide"
                        :isSelected="true"
                        :token="token"
                        :key="song.id"
                        :class="{ mini: isExpanded }"
                        :playlistType="selectedPlaylist"
                    />
                </div>
            </template>
            <div
                class="empty"
                v-if="showAddMusicTile"
                @click="
                    () => {
                        if (!isExpanded) {
                            toggleHeight();
                        }
                    }
                "
            >
                <template v-if="isExpanded && !slides.length">
                    <div class="icon">
                        <font-awesome-icon icon="fa-regular fa-music"></font-awesome-icon>
                    </div>
                    <strong>No Music Selected</strong>
                    <p>Add a meaningful track to accompany this tribute and make it truly memorable.</p>
                </template>
                <template v-else>
                    <div class="icon">
                        <font-awesome-icon icon="fa-regular fa-plus"></font-awesome-icon>
                    </div>
                    <strong>Add Music</strong>
                </template>
            </div>
            <template v-if="!slides.length">
                <div class="genre-slide" @click="setGenre()">
                    <img :src="allMusicArtwork" alt="Explore All Music" class="slide-image" />
                    <div class="genre-info">
                        <strong>Explore All Music</strong>
                    </div>
                </div>
                <template v-for="genre in musicGenres" v-if="musicGenres.length">
                    <div class="genre-slide" :key="genre.id" @click="setGenre(genre)">
                        <ImageThumbnail :src="genre.image" :alt="genre.name" :w="180" :h="180" class="slide-image" />
                        <div class="genre-info">
                            <strong>{{ genre.name }}</strong>
                        </div>
                    </div>
                </template>
            </template>
        </draggable>
        <template v-else>
            <div class="slides-container">
                <div class="slide loading-slide" v-for="n in 10" :key="n"></div>
            </div>
        </template>
        <modal :open="showDeleteModal" @close="closeDeleteModal">
            <template v-slot:modal-title>
                <div>Are you sure you want to remove this song?</div>
            </template>

            <template v-slot:modal-content>
                <div>This action cannot be undone, and the song will be removed from your slide collection.</div>
            </template>

            <template v-slot:modal-footer>
                <button class="btn btn-deactivate" @click="confirmDelete">Confirm</button>
                <button class="btn btn-cancel" @click="closeDeleteModal">Cancel</button>
            </template>
        </modal>
        <div v-show="isExpanded" class="playlist-container">
            <MusicExplorer
                ref="musicExplorer"
                :funeralHomeId="tributeVideo.funeralHomeId"
                :playlistType="selectedPlaylist"
                @customSongAdded="focusSlide(slides.length - 1)"
            />
        </div>

        <SyncSettingsModal v-model="showSyncSettingsModal" :playlistType="selectedPlaylist" />
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import modal from './WarningModal.vue';
import moment from 'moment';
import { orderBy, debounce, each, findIndex, map, isEqual } from 'lodash';
import SlideUpload from './SlideUpload.vue';
import ImagePreview from '../Media/ImagePreview.vue';
import { mapActions } from 'vuex';
import TributeSongService from '@/services/tributeSong.service';
import WaveformV2 from '@/components/ui/WaveformV2.vue';
import { PlayIcon, PauseIcon } from 'vue-feather-icons';
import MusicExplorer from './Music/MusicExplorer.vue';
import MusicTrack from './Music/MusicTrack.vue';
import ImageThumbnail from '@/components/Media/ImageThumbnail.vue';
import CustomTooltip from '../ui/CustomTooltip.vue';
import { playlistTypes } from '@/constants';
import SyncSettingsModal from '@/components/Tribute/Modals/SyncSettingsModal.vue';

export default {
    name: 'ManageMusic',
    components: {
        draggable,
        modal,
        SlideUpload,
        ImageThumbnail,
        ImagePreview,
        PlayIcon,
        MusicExplorer,
        WaveformV2,
        MusicTrack,
        CustomTooltip,
        SyncSettingsModal,
        CreativeEditor: () => import('./Editor/CreativeEditor.vue'),
    },
    inject: ['state'],
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        tributeVideo: {
            type: Object,
            required: true,
        },
    },
    filters: {
        formatTimeStamp(seconds) {
            const duration = moment.duration(seconds, 'seconds');
            const minutes = duration.minutes();
            const secs = duration.seconds();

            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(secs).padStart(2, '0');

            return `${formattedMinutes}:${formattedSeconds}`;
        },
    },
    created() {
        this.debouncedPlaylistUpdate = debounce(this.playlistUpdate, 400);
    },
    async mounted() {
        if (this.state.token) {
            this.token = this.state.token;
        } else {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        }
        this.apiService = TributeSongService(this.token);

        if (this.eventId) {
            const promises = [];
            const selectedPromise = this.apiService.getSelected(this.$props.tributeVideo.id).then(songsResp => {
                if (songsResp.data?.length) {
                    this.$store.dispatch('tributeVideo/updateTributeVideoPlaylist', {
                        playlistType: playlistTypes.ORIGINAL,
                        songs: songsResp.data,
                    });
                }
            });
            promises.push(selectedPromise);

            const publicPlaylistPromise = this.apiService
                .getCopyrightSafeSongs(this.$props.tributeVideo.id)
                .then(publicSongsResp => {
                    this.$store.dispatch('tributeVideo/updateTributeVideoPlaylist', {
                        playlistType: playlistTypes.PUBLIC,
                        songs: publicSongsResp.data,
                    });
                });
            promises.push(publicPlaylistPromise);

            const genrePromise = this.apiService.getGenres().then(genreResults => {
                if (genreResults.data.musicGenres?.length) {
                    this.musicGenres = genreResults.data.musicGenres;
                }
            });
            promises.push(genrePromise);
            Promise.allSettled(promises).then(() => {
                this.loading = false;
            });
        } else {
            // Could a user get to this page without an eventId?
            // For now we'll just stop the loading
            this.loading = false;
        }
    },
    beforeDestroy() {},

    data() {
        return {
            apiService: null,
            token: null,
            maxFiles: 400,
            musicGenres: [],
            loading: true,
            fallbackArtwork: require(`@/assets/images/fallback-song-art.png`),
            laurelIcon: require(`@/assets/icons/laurel.svg`),
            allMusicArtwork: require(`@/assets/images/exploreArtwork.png`),
            isDragging: false,
            dragIndex: null,
            selectedSlideIndex: null,
            textSlideBgColor: '#ffffff',
            textSlideContent: '',
            showDeleteModal: false,
            selectedPlaylist: 0,
            tmpSlideOrder: [],
            showSyncSettingsModal: false,
            draggableOptions: {
                animation: 300,
                ghostClass: 'ghost-hidden',
                draggable: '.slide',
                delay: 120, // time in milliseconds to define when the sorting should start
                delayOnTouchOnly: true, // only delay if user is using touch
                touchStartThreshold: 20, // px, how many pixels the point should move before cancelling a delayed drag event
            },
            playlistTypes,
            // Still deciding if we want to display newest to beginning or end of arr
            // displaySlidesReversed: true,
        };
    },
    computed: {
        showAddMusicTile() {
            return this.playlistDurationPercent < 100 || !this.slides.length;
        },
        maxFilesLeft() {
            // computed difference between how many files max and existing items
            // TODO: included pending uploads while they resolve and remove if they error, etc
            return this.maxFiles - this.slides.length;
        },
        slidesContainerHeight() {
            if (this.isExpanded) {
                const slidesPerRow = Math.floor(this.$el.clientWidth / 250);
                const numberOfRows = Math.ceil(this.slides.length / slidesPerRow);
                return `${numberOfRows * 258}px`; // slide height (242px) + gap (8px * 2)
            } else {
                return '242px';
            }
        },
        isAnyImageSelected() {
            return this.selectedSlideIndex !== null;
        },
        isExpanded: {
            get() {
                return this.$store.state.tributeEditor.expanded;
            },
        },
        tributeVideoTotalDuration() {
            return Math.ceil(this.$store.state.tributeVideo.totalDuration);
        },
        playlistDuration() {
            return this.slides.reduce((total, slide) => {
                if (slide.duration) {
                    return total + slide.duration;
                }
                return total;
            }, 0);
        },
        playlistDurationPercent() {
            return Math.round((this.playlistDuration / this.tributeVideoTotalDuration) * 100);
        },
        slides: {
            get() {
                return this.selectedPlaylist === this.playlistTypes.PUBLIC
                    ? this.publicPlaylist
                    : this.originalPlaylist;
            },
            set(val) {
                this.$store.dispatch('tributeVideo/updateTributeVideoPlaylist', {
                    playlistType: this.selectedPlaylist,
                    songs: val,
                });
            },
        },
        publicPlaylist: {
            get() {
                return this.$store.state.tributeVideo.publicPlaylist;
            },
        },
        originalPlaylist: {
            get() {
                return this.$store.state.tributeVideo.originalPlaylist;
            },
        },
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),
        playlistUpdate(playlistType, songs) {
            try {
                this.$store.dispatch('tributeVideo/updateTributeVideoPlaylist', {
                    playlistType: playlistType,
                    songs: songs,
                });
            } catch (error) {
                console.log(error, 'error refreshing playlist data');
            }
        },
        selectPlaylist(type) {
            this.selectedPlaylist = type;
        },
        handleResize() {
            const container = this.$el.querySelector('.slides-container');
            if (container) {
                container.scrollLeft = 0;
            }
        },
        handleWheel(event) {
            // How much a user needs to scroll before it triggers the expanding behavior
            const wheelBuffer = 50;
            // Access event.deltaY to get the scroll direction and amount
            const direction = event.deltaY > 0 ? 'down' : event.deltaY < 0 ? 'up' : '';
            const distance = Math.abs(event.deltaY);
            // console.log('Mousewheel:', { direction, distance, y: event.deltaY, event});
            // slides-container
            const slideContainer = document.querySelector('.slides-container');
            // Implement your custom logic here
            if (distance > wheelBuffer) {
                if (direction === 'down' && !this.isExpanded) {
                    this.toggleExpandedState();
                }

                if (direction === 'up' && this.isExpanded && slideContainer.scrollTop < 5) {
                    // ensure slides-container is at the top before collapsing
                    // console.log(slideContainer);
                    this.toggleExpandedState();
                }
            }
        },
        moveSlideForward() {
            if (this.selectedSlideIndex !== null && this.selectedSlideIndex < this.slides.length - 1) {
                const currentIndex = this.selectedSlideIndex;
                const newIndex = currentIndex + 1;
                const slide = this.slides[currentIndex];

                if (!slide) return;

                this.slides.splice(newIndex, 0, this.slides.splice(currentIndex, 1)[0]);
                this.selectedSlideIndex = newIndex;

                const targetOrder = this.getTargetOrder(newIndex);
                this.quickOrder(slide.id, targetOrder);
            }
        },
        moveSlideBackward() {
            if (this.selectedSlideIndex !== null && this.selectedSlideIndex > 0) {
                const currentIndex = this.selectedSlideIndex;
                const newIndex = currentIndex - 1;
                const slide = this.slides[currentIndex];

                if (!slide) return;

                this.slides.splice(newIndex, 0, this.slides.splice(currentIndex, 1)[0]);
                this.selectedSlideIndex = newIndex;

                const targetOrder = this.getTargetOrder(newIndex);
                this.quickOrder(slide.id, targetOrder);
            }
        },
        getSongs() {
            this.apiService.getSongs().then(songResults => {});
        },
        setGenre(genre) {
            if (!this.isExpanded) {
                this.toggleHeight();
            }
            this.$nextTick(() => {
                if (genre) {
                    this.$refs.musicExplorer.setFilter('genre', genre.id);
                } else {
                    this.$refs.musicExplorer.setFilter();
                }
            });
        },
        onDragStart(evt) {
            // store the slide order for referencing later
            this.tmpSlideOrder = map(this.slides, 'id');
            this.isDragging = true;
            this.dragIndex = evt.oldIndex;
        },
        onDragEnd(evt) {
            this.isDragging = false;
            this.dragIndex = null;

            this.handleSingleItemDragEnd(evt).then(() => {
                console.log('clear out the tmpslideorder');
                this.tmpSlideOrder = [];
            });
        },
        getTargetOrder(arrIndex) {
            // return this.displaySlidesReversed ? arrIndex : this.totalSlides - 1 - arrIndex;
            // return this.totalSlides - 1 - arrIndex;
            return arrIndex;
        },
        async handleSingleItemDragEnd(evt) {
            console.log(evt, 'single item drag');
            // if the user placed the slide in the same spot, don't trigger a change
            if (isEqual(this.tmpSlideOrder, map(this.slides, 'id'))) {
                console.log('no order changes made, nothing to save');
                return;
            }

            const slide = this.slides[evt.newIndex];

            if (slide) {
                const targetOrder = this.getTargetOrder(evt.newIndex);
                await this.quickOrder(slide.id, targetOrder);
            }
        },
        quickOrder: debounce(async function (id, order) {
            if (this.isContributePage) {
                this.showSnackbar({ message: 'Unauthorized to edit order' });
                return;
            }
            try {
                const isPublicPlaylist = this.selectedPlaylist === this.playlistTypes.PUBLIC;
                // // grab the order of song Ids and trigger a updateSelectedSongs
                const updatedSongOrder = map(this.slides, 'id');

                const resp = await this.apiService.updateSelectedSongs(this.$props.tributeVideo.id, {
                    selectedSongs: updatedSongOrder,
                    copyrightSafe: isPublicPlaylist,
                });

                const results = isPublicPlaylist ? resp?.data?.copyrightSafeSongs : resp?.data?.songs;

                this.$store.dispatch('tributeVideo/updateTributeVideoPlaylist', {
                    playlistType: this.selectedPlaylist,
                    songs: results,
                });
                this.showSnackbar({ message: 'Song order changes saved.' });
            } catch (error) {
                this.showSnackbar({ message: 'Error updating order' });
            }
        }, 500),

        selectImage(index) {
            if (this.selectedSlideIndex === index) {
                this.selectedSlideIndex = null;
            } else {
                this.selectedSlideIndex = index;
                this.focusSlide(index);
            }
        },
        focusSlide(index) {
            this.$nextTick(() => {
                const slide = this.$el.querySelectorAll('.slide')[index];
                if (slide) {
                    slide.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                }
            });
        },
        openDeleteModal() {
            if (this.isAnyImageSelected) {
                this.showDeleteModal = true;
            }
        },
        closeDeleteModal() {
            this.showDeleteModal = false;
        },
        confirmDelete() {
            if (this.selectedSlideIndex !== null) {
                this.slides.splice(this.selectedSlideIndex, 1);
                this.selectedSlideIndex = null;
            }
            this.closeDeleteModal();
        },
        getCurrentSlideOrder() {
            return this.slides.map(slide => slide.id);
        },
        toggleHeight() {
            // this.isExpanded = !this.isExpanded;
            this.toggleExpandedState();
            // If music explorer is exapanded, don't play in background otherwise do
            this.$store.dispatch('miniPlayer/updatePlayingInBackground', !this.isExpanded);
        },
    },

    sockets: {
        NotifyTributeSongsUpdate(data) {
            if (data.tributeId === this.$props.tributeVideo.id) {
                this.debouncedPlaylistUpdate(data.playlistType, data.songs);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.manage-slides {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: height 0.8s;
    z-index: 1;
    @include mobile() {
        flex-direction: column-reverse;
    }
}

.action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;

    @include mobile() {
        gap: 6px;
        margin-top: 6px;
        @include landscape() {
            margin-top: 0;
        }
    }
}

.primary-button,
.secondary-button {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.shadow-button {
    box-shadow: 0px 1px 2px 0px #0000000d;
}

.left-buttons {
    display: flex;
    align-items: center;
    gap: 12px;
}

.primary-button {
    width: 228px;
    background: $main-orange;
    color: white;
    border: none;
    height: 38px;
    border-radius: 6px;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.secondary-button {
    width: 138px;
    background: white;
    color: #374151;
    border: 1px solid #d1d5db;
    height: 38px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.button-divider {
    width: 1px;
    height: 60%;
    background: #d1d5db;
    margin-right: 8px;
}

.center-buttons {
    display: flex;
    justify-content: center;
    box-shadow: none;

    @include mobile() {
        button {
            line-height: 1;
        }
    }
}

.icon-button {
    width: 38px;
    height: 38px;
    font-size: 14px;
    background: white;
    border: 1px solid #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    &.rounded {
        border-radius: 6px !important;
    }
    &.wide {
        width: initial;
        padding: 0 10px;
        font-weight: 500;
    }
    &.primary-orange {
        background: $main-orange;
        color: #fff;
        border: none;
    }
    &.select {
        border: 1px solid lighten($btn-orange, 20%);
        background: lighten($btn-orange, 40%);
        color: $btn-orange;
    }
    &.remove {
        border: 1px solid lighten($btn-red, 20%);
        background: lighten($btn-red, 35%);
        color: $btn-red;
    }
    &:hover {
        background-color: $light-gray;
    }
}

.highlighted-icon-button {
    width: 38px;
    height: 38px;
    background: #fee2e2;
    border: 1px solid #fca5a5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slides-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    padding: 8px 0;
    overflow-x: auto;
    overflow-y: hidden;
    transition: height 0.8s;
    height: 100%;
    max-height: 242px;
}

.slide,
.genre-slide {
    aspect-ratio: 4 / 3;
    border-radius: 8px;
    position: relative;
    transition: transform 0.2s, border 0.2s;
    flex-shrink: 0;
    display: inline-block;
    overflow: hidden;

    .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        z-index: -1;
    }
}
.slide.loading-slide {
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px, #ddd 400px);
    background-size: 200% 100%;
    background-repeat: repeat-x;
    animation: shimmer 1.6s infinite linear;
}
.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px lighten($medium-gray, 20%) dashed;
    width: 100%;
    max-width: 242px;
    justify-content: center;
    font-size: 0.9rem;
    aspect-ratio: 1 / 1;
    border-radius: 10px;
    cursor: pointer;
    .icon {
        color: $btn-orange;
        background: lighten($btn-orange, 40%);
        padding: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        border-radius: 10em;
        margin-bottom: 1rem;
    }
}
.manage-slides.expanded {
    .slides-container {
        min-height: 170px;
        height: fit-content;
        @include mobile {
            display: none;
        }
    }
    .slide {
        height: fit-content;
        aspect-ratio: auto;
    }
    .genre-slide {
        display: none;
    }
    .empty {
        min-height: 160px;
        max-height: 100%;
        max-width: 100%;
        aspect-ratio: auto;
        flex-grow: 1;
        cursor: auto;
        @include mobile() {
            flex-direction: row;
            flex-wrap: wrap;
            padding: 12px;
            .icon {
                margin-bottom: 0;
            }
        }
    }
}
@keyframes shimmer {
    0% {
        background-position: -32px;
    }
    40%,
    100% {
        background-position: 360px;
    }
}
.genre-slide {
    user-select: none;
    cursor: pointer;
    .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        aspect-ratio: 1;
        z-index: -1;
    }
    .genre-info {
        position: absolute;
        bottom: 0;
        padding: 1rem;
        color: white;
        white-space: nowrap;
        width: 100%;
        // slight gradiant to help the genre text stand out against light backgrounds
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
    }
    .staff-picked-banner {
        background: $btn-orange;
        border: 1px solid $main-orange;
        color: white;
        position: absolute;
        top: 0;
        inset: 0;
        height: 30px;
        padding: 8px;
        border-radius: 54px;
        border-bottom-right-radius: 0;
        width: fit-content;
        font-weight: bold;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 16px;
        font-size: 12px;
        img {
            transform: scale(1.8);
        }
    }
}

.slide.dragging {
    transform: rotate(-3deg);
    border: 4px solid $main-orange;
}

.slide.selected .slide-image {
    border: 4px solid $main-orange;
}

.slide-order {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 42px;
    height: 32px;
    background: white;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px #0000000d;
    color: #6b7280;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2.5%;
}

.selected-indicator {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    background: $main-orange;
    border: 1.5px solid $main-orange;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ghost-hidden {
    display: none !important;
}

.ghost-slide {
    width: 100%;
    max-width: 242px;
    aspect-ratio: 1;
    border: 1px dashed #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    flex-shrink: 0;
}

.ghost-icon-container {
    width: 52px;
    height: 38px;
    background: #fff7ed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.ghost-icon {
    width: 20px;
    height: 20px;
}
.playlist-container {
    overflow-y: auto;
    @include mobile() {
        flex: 1;
    }
}
</style>
