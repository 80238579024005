<template>
    <div :class="['final-step-overlay', miniMode ? 'mini' : '', confetti ? 'confetti' : '']">
        <div class="overlay-content">
            <div class="overlay-message">
                <img src="https://memoryshareprod.blob.core.windows.net/front-end-assets/logo-animated.gif" class="ms-loading-logo" />

                <div class="progress-indicator" v-if="!renderComplete && renderProgress < 100">
                    <div class="progress-slider" :style="{ width: `${renderProgress}%` }"></div>
                </div>
                <p class="text-title">
                    <span v-if="!renderComplete && renderProgress < 100">({{ renderProgress }}%) </span>
                    <span class="render-text">{{ renderStateText }}</span>
                </p>

                <p class="progress-messages">{{ renderProgressMessage }}</p>
                <div class="preview-toggle">
                    <!-- <button class="play-btn" @click.stop="toggleOverlay" v-if="!miniMode && !renderComplete">
                        <font-awesome-icon icon="fa-solid fa-play fa-fw" class="mr-1" />
                        <span class="hidden-while-mini">Watch preview</span>
                    </button> -->
                    <div v-if="!miniMode && renderComplete" class="text-center">
                        <button class="play-btn orange-btn" @click.stop="watchFinalVideo">
                            <font-awesome-icon icon="fa-solid fa-play" class="px-1" /> Watch Final Tribute Video
                        </button>
                    </div>
                    <!-- <button class="play-btn" @click.stop="toggleOverlay" v-else>
                        <font-awesome-icon icon="fa-solid fa-chevron-down" class="px-1 fa-lg" />
                    </button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    name: 'TributeRenderOverlay',
    components: {},
    data() {
        return {
            miniMode: true,
            currentMessageIndex: 0,
            // loaderDefaultOptions: { animationData: loadingAnimation },
            messageInterval: null,
            progressInterval: null,
            autoPlay: true,
            autoPause: true,
            confetti: false,
            confettiFired: false,
        };
    },
    computed: {
        ...mapGetters('tributeVideo', ['currentRenderMessage', 'renderStateText']),
        renderStarted() {
            return this.$store.state.tributeVideo.renderStarted;
        },
        renderProgressMessage() {
            if (this.renderComplete) {
                return '';
            }
            if (this.renderProgress >= 100) {
                return 'Just a few more minutes...';
            }

            return this.currentRenderMessage;
        },
        renderProgress() {
            return this.$store.state.tributeVideo.renderProgress;
        },
        // duration is estimated at 2 X tribute video duration
        renderDuration() {
            return this.$store.state.tributeVideo.totalDuration * 2;
        },
        estRenderFinish() {
            if (!this.renderStarted) {
                return null;
            }
            // return a moment object that is renderStart + renderDuration
            return moment(this.renderStarted).add(this.renderDuration, 'seconds');
        },
        willFinishOn() {
            if (this.estRenderFinish) {
                return this.estRenderFinish.fromNow(true);
            }
            return null;
        },
        renderComplete() {
            return !!this.$store.state.tributeVideo.renderCompleted;
        },
    },
    methods: {
        startConfetti() {
            this.confetti = true;
            this.confettiFired = true;
            setTimeout(() => {
                this.confetti = false;
            }, 2140);
        },
        expandIfMini() {
            if (this.miniMode) {
                this.toggleOverlay();
            }
        },
        toggleOverlay() {
            // Toggle miniMode;
            this.miniMode = !this.miniMode;

            // If overlay is in expanded mode:
            // Auto-play the preview ?
            if (this.miniMode && this.autoPlay) {
                this.$emit('playPreview');
            }
            // else if overlay is in mini mode
            // Auto-pause the preview ?
            if (!this.miniMode && this.autoPause) {
                this.$emit('pausePreview');
            }
        },
        start() {
            // this.confettiFired = false;
            // this.clearIntervals();
            // this.progressInterval = setInterval(() => {
            //     this.calculatePercentage();
            // }, 100);
            // this.messageInterval = setInterval(() => {
            //     this.currentMessageIndex++;
            //     if (this.currentMessageIndex > this.renderMessages.length - 1) {
            //         this.currentMessageIndex = 0;
            //     }
            // }, 2500);
            // Starts the render loading UI
            const duration = this.$store.state.tributeVideo.totalDuration * 2;

            this.$store.dispatch('tributeVideo/updateRenderProgress', {
                duration: duration,
                started: moment().toISOString(),
            });

            this.$store.dispatch('tributeVideo/cycleRenderMessages');
        },
        forceCompleteRender() {
            // stop Intervals
            // this.clearIntervals();
            // this.renderProgress = 100;
            // this.startConfetti();
            this.$store.dispatch('tributeVideo/setRenderCompleted');
        },
        handleAnimation: function (anim) {
            this.anim = anim;
        },
        // calculatePercentage() {
        //     let progress = this.renderProgress;
        //     // if render is finished we can fastpass straight to 100
        //     if (this.renderComplete) {
        //         progress = 100;
        //     } else {
        //         const today = moment();
        //         const secondsFromStart = today.diff(this.renderStarted, 'seconds'); // Seconds since start date
        //         progress = (secondsFromStart / this.renderDuration) * 100; // Progress percentage
        //     }

        //     if (!progress || progress <= 0) {
        //         this.renderProgress = 0;
        //     } else if (progress >= 100) {
        //         this.renderProgress = 100;
        //         // Do we emit an event when our render time has completed?
        //         this.clearIntervals();
        //         this.expandIfMini();
        //         this.$nextTick(() => {
        //             if (this.renderComplete) {
        //                 this.confettiFired = true;
        //                 this.startConfetti();
        //             }
        //         });
        //     } else {
        //         this.renderProgress = Math.round(progress);
        //     }
        // },
        clearIntervals() {
            if (this.messageInterval) {
                this.currentMessageIndex = 0;
                clearInterval(this.messageInterval);
            }
            if (this.progressInterval) {
                clearInterval(this.progressInterval);
            }
        },
        watchFinalVideo() {
            // dismiss the overlay by clearing the render info
            this.$emit('change-step', 'final-video');
            this.$store.dispatch('tributeVideo/dismissRenderOverlay');
        },
    },
    mounted() {},
    beforeDestroy() {
        // remove Intervals
        this.clearIntervals();
    },
    watch: {
        // Watch for changes in renderComplete status
        // For cases where progress reached 100 but we still haven't received the websocket event
        // This ensures that the confetti is still fired since the interval would have already been stopped
        renderComplete(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.$nextTick(() => {
                    if (!this.confettiFired) {
                        this.startConfetti();
                    }
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.final-step-overlay {
    position: absolute;
    display: flex;
    inset: -8px;
    align-items: center;
    z-index: 10;
    color: white;
    transition: all 300ms ease-in-out;

    &::after {
        content: '';
        position: absolute;
        pointer-events: none;
        inset: 0;
        background: rgba(6, 6, 6, 0.697);
        backdrop-filter: blur(4px);
        filter: blur(1px);
        pointer-events: none;
        margin-bottom: 6px;
    }
    .overlay-content {
        color: white;
        position: relative;
        z-index: 2;
        padding: 16%;
        text-align: center;
        font-size: 0.85rem;
        width: 100%;
        container: renderOverlay / inline-size;

        .overlay-message {
            width: min(90%, 440px);
            margin: auto;
        }
    }
    &.mini {
        height: 40px;
        transform: scale(0.85);
        cursor: pointer;
        // &:hover {
        //     transform: scale(0.9);
        // }
        .hidden-while-mini {
            display: none;
        }
        &:after {
            display: none;
        }
        .overlay-content {
            padding: 0 10px;
            width: 70%;
            margin: auto;
            background: rgba(0, 0, 0, 0.7);
            border-radius: 12rem;
            top: 10px;
        }
        .overlay-message {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            gap: 10px;
            height: 45px;
            line-height: 1.1;
            .render-text {
                display: none;
            }
            .ms-loading-logo {
                margin: auto;
                max-height: 60px;
                position: relative;
                top: -18%;
            }
            .progress-indicator {
                margin: auto;
                max-width: 20%;
            }
            p {
                margin: 0;
            }
            .progress-messages {
                flex-grow: 1;
                text-align: left;
            }
        }
    }
    &.confetti {
        &:before {
            content: '';
            background: url('https://memoryshareprod.blob.core.windows.net/front-end-assets/confetti.gif') no-repeat
                bottom center;
            background-size: cover;
            inset: 0;
            margin: auto;
            position: absolute;
            z-index: 9;
        }
    }
}
.text-title {
    font-size: 16px;
    font-weight: bold;
}
.progress-indicator {
    width: 50%;
    display: inline-block;
    height: 5px;
    background: #ffedd5;
    margin: auto;
    border-radius: 10rem;
    margin-bottom: 10px;
}

.progress-slider {
    height: 100%;
    background: #f97316;
    border-radius: 10rem;
    transition: width 200ms ease-in-out;
}
.ms-loading-logo {
    max-width: min(150px, 37%);
    display: block;
    margin: auto;
    margin-bottom: -12px;
}
.play-btn {
    font-weight: bold;
}
.orange-btn {
    @include icon-btn($primary-orange, white, $btn-orange);
    &::v-deep {
        width: 60%;
        margin: auto;
    }
}

@container renderOverlay (max-width: 400px) {
    .progress-messages {
        display: none;
    }
    .progress-indicator {
        max-width: 45% !important;
    }
}
</style>
