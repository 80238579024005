<template>
    <div
        ref="photosContainer"
        tabindex="0"
        v-click-outside="deselectAllSlides"
        @keydown="handleSlideKeydown"
        class="manage-photos-container"
        @wheel.passive="handleWheel"
    >
        <ActionBar
            ref="actionBar"
            :dropTarget="uploadDropTarget"
            :maxFilesLeft="maxFilesLeft"
            :isExpanded="isExpanded"
            :selectedSlides="selectedSlides"
            :disableMoveBack="disableMoveBack"
            :disableMoveForward="disableMoveForward"
            :disableUploads="disableUploads"
            @download-selected="handleDownloadSelected"
            @add-text-slide="addNewTextSlide"
            @temp-file-change="tempFiles => handleTempFilesChange(tempFiles)"
            @files-added="onFileAdded"
            @move-slide-backward="moveSlideBackward"
            @move-slide-forward="moveSlideForward"
            @edit-active-slide="editActiveSlide"
            @rotate-selected-slides="rotateSelectedSlides"
            @open-delete-modal="openDeleteModal"
            @successful-uploads="uploads => handleSuccessfulUploads(uploads)"
            @total-upload-progress="val => handleTotalUploadProgress(val)"
            @toggle-show-upload-users="showUploadUsers = !showUploadUsers"
            @init-filter-by-user="initFilterByUserModal"
        >
            <template #right-buttons>
                <v-fade-transition>
                    <!-- @click="highlightAllDuplicates" -->
                    <v-chip
                        @click="openGroupResolveModal(groupTypes.DUPLICATE_PHASH)"
                        v-show="totalDuplicateSlides > 0"
                        color="#FEF3C7"
                        text-color="#92400E"
                    >
                        <div>
                            <font-awesome-icon
                                class="mr-1"
                                icon="fa-regular fa-triangle-exclamation"
                            ></font-awesome-icon>
                            <span> {{ totalDuplicateSlides }} duplicates</span>
                        </div>
                    </v-chip>
                </v-fade-transition>
                <v-fade-transition>
                    <!-- @click="highlightAllSimilars" -->
                    <v-chip
                        @click="openGroupResolveModal(groupTypes.SIMILAR_LSH_ANY)"
                        v-show="totalSimilarSlides > 0"
                        color="#FEF3C7"
                        text-color="#92400E"
                    >
                        <div>
                            <font-awesome-icon
                                class="mr-1"
                                icon="fa-regular fa-triangle-exclamation"
                            ></font-awesome-icon>
                            <span> {{ totalSimilarSlides }} similar </span>
                        </div>
                    </v-chip>
                </v-fade-transition>

                <template
                    v-if="
                        !state.isMobile &&
                        tributeVideo.deadline &&
                        (state.isContributor || state.isFamily) &&
                        !(showDownloadProgress || showUploadProgress)
                    "
                >
                    <span :class="['deadline-reminder', !deadlineExpired ? 'active' : 'expired']"
                        >The deadline {{ deadlineExpired ? 'was' : 'is' }} {{ deadlineToLocal | moment('LLL') }}</span
                    >
                </template>
                <v-fade-transition>
                    <v-chip v-show="showDownloadProgress" color="#FEF3C7" text-color="#92400E">
                        <div>
                            <font-awesome-icon class="mr-1" icon="fa-regular fa-arrow-down"></font-awesome-icon>
                            Progress
                            {{ downloadProgressPercent }}%
                        </div>
                    </v-chip>
                </v-fade-transition>

                <v-fade-transition>
                    <v-chip v-show="showUploadProgress" color="#FEF3C7" text-color="#92400E">
                        <div>
                            <font-awesome-icon class="mr-1" icon="fa-regular fa-arrow-up"></font-awesome-icon>
                            Progress {{ uploadProgressPercent }}%
                        </div>
                    </v-chip>
                </v-fade-transition>
            </template>

            <template #bar-2-left>
                <div class="d-flex flex-wrap" style="gap: 12px">
                    <v-chip
                        @click="deselectUploadUser(user)"
                        @click:close="deselectUploadUser(user)"
                        close
                        outlined
                        v-for="user in selectedUploadUsers"
                    >
                        {{ user.uploadUserName }}</v-chip
                    >
                </div>
            </template>

            <template #bar-2-center>
                <v-fade-transition>
                    <v-btn
                        depressed
                        v-show="highlightGroup.items"
                        @click="initResolveDuplicatesOrSimilars"
                        dark
                        small
                        color="error"
                    >
                        {{ resolveBtnText }}
                    </v-btn>
                </v-fade-transition>
            </template>

            <template #bar-2-right-prepend>
                <div v-if="!state.isMobile" style="gap: 12px" class="d-flex">
                    <v-chip
                        @click="quickFilterUser(user)"
                        v-for="(user, index) in uploadUsers"
                        :key="index"
                        label
                        :outlined="!userIsSelected(user)"
                        :color="user.color"
                        dark
                        >{{ user.uploadUserName }}</v-chip
                    >
                </div>
            </template>
        </ActionBar>

        <div ref="sortableSlides" :class="['slides-container', { expanded: isExpanded }]">
            <div
                v-if="showLoadMore"
                v-intersect="(entries, observer, isIntersecting) => handleSlidesStartIntersect(isIntersecting)"
                @click="() => slidesNextPage()"
                class="ghost-slide ignore-draggable"
            >
                <div class="d-flex flex-column align-center">
                    <v-progress-circular class="mb-4" size="50" color="orange" indeterminate></v-progress-circular>
                    <span class="text-caption">Loading slides..</span>
                </div>
            </div>
            <div
                v-for="(slide, index) in reversedSlides"
                :key="`${slide.id}-${index}`"
                :id="`slide-${slide.id}`"
                :guid="slide.guid"
                @click.prevent="handleSlideClick($event, slide)"
                @dblclick.prevent="previewSlide($event, index, slide)"
                @contextmenu.prevent="initCustomContext($event, slide)"
                :class="[
                    'slide',
                    {
                        'ignore-draggable': slide.loading || state.isContributor,
                        'show-upload-user': showUploadUsers,
                        'group-highlight': inHighlightGroup(slide.id),
                    },
                ]"
            >
                <div class="slide-top-left">
                    <div
                        class="slide-order"
                        :style="{
                            zIndex: 1000,
                        }"
                    >
                        {{ getSlideDbIndex(index) }}
                        <span v-if="slide.duration" class="duration">
                            <font-awesome-icon icon="fa-regular fa-video" />
                            {{ slide.duration | formatTimeStamp }}
                        </span>
                    </div>

                    <CustomTooltip :tooltipProps="{ top: true }" v-if="isDuplicate(slide.perceptualHash)">
                        <template #activator>
                            <div class="slide-order" @click.stop="highlightDuplicateGroupItems(slide.perceptualHash)">
                                <font-awesome-icon
                                    class="dupe-icon"
                                    icon="fa-solid fa-triangle-exclamation"
                                ></font-awesome-icon>
                            </div>
                        </template>

                        <template #title>
                            <span>Duplicates Found</span>
                        </template>
                        <template #content>
                            <span>Click to highlight duplicates</span>
                        </template>
                    </CustomTooltip>

                    <CustomTooltip :tooltipProps="{ top: true }" v-if="isSimilar(slide)">
                        <template #activator>
                            <div class="slide-order" @click.stop="highlightSimilarGroupItems(slide)">
                                <font-awesome-icon
                                    class="dupe-icon"
                                    icon="fa-solid fa-triangle-exclamation"
                                ></font-awesome-icon>
                            </div>
                        </template>

                        <template #title>
                            <span>Similar Found</span>
                        </template>
                        <template #content>
                            <span>Click to highlight similar</span>
                        </template>
                    </CustomTooltip>
                </div>
                <CustomTooltip
                    :class="['slide-user']"
                    :style="{ color: getUploadUserColor(slide) }"
                    :tooltipProps="{ top: true }"
                >
                    <template #activator>
                        <div class="slide-user-inner">
                            <font-awesome-icon icon="fa-solid fa-user"></font-awesome-icon>
                            <span>
                                {{ slide.uploadUserName | firstName }}
                            </span>
                        </div>
                    </template>
                    <template #content>
                        <strong class="mr-1">{{ slide.uploadUserName }}</strong>
                        <span v-if="slide.uploadUserRelationship !== slide.uploadUserName">{{
                            slide.uploadUserRelationship
                        }}</span>
                    </template>
                </CustomTooltip>

                <div class="slide-bottom-right">
                    <template v-if="!state.isMobile">
                        <CustomTooltip
                            v-if="!inHighlightGroup(slide.id)"
                            class="slide-preview"
                            :tooltipProps="{ top: true }"
                        >
                            <template #activator>
                                <div @click.stop="selectAndDeleteSlide($event, slide)" class="slide-preview-inner">
                                    <button>
                                        <div>
                                            <font-awesome-icon style="color: #ff5252" icon="fa-regular fa-trash-can" />
                                        </div>
                                    </button>
                                </div>
                            </template>

                            <template #content>
                                <span>Delete</span>
                            </template>
                        </CustomTooltip>

                        <CustomTooltip class="slide-preview" :tooltipProps="{ top: true }">
                            <template #activator>
                                <div @click.stop="previewSlide($event, index, slide)" class="slide-preview-inner">
                                    <button>
                                        <div>
                                            <font-awesome-icon icon="fa-regular fa-pencil" />
                                        </div>
                                    </button>
                                </div>
                            </template>

                            <template #content>
                                <span>Preview</span>
                            </template>
                        </CustomTooltip>
                    </template>
                    <template v-else>
                        <div @click.stop="openContextMenuMobile($event, slide)" class="slide-preview-inner">
                            <button>
                                <div>
                                    <font-awesome-icon icon="fa-regular fa-ellipsis-h" />
                                </div>
                            </button>
                        </div>
                    </template>
                </div>

                <div
                    v-if="inHighlightGroup(slide.id)"
                    @click.stop="selectAndDeleteSlide($event, slide)"
                    class="center-delete-btn"
                >
                    <font-awesome-icon
                        style="color: white; font-size: 1.8rem"
                        icon="fa-regular fa-trash-can"
                    ></font-awesome-icon>
                </div>

                <ImageThumbnail
                    smart-crop
                    :key="slide.url"
                    :src="slide.url"
                    :w="400"
                    :h="400"
                    :smartCrop="slide.type !== 1"
                    :type="slide.mediaType"
                    :alt="'Slide ' + getSlideDbIndex(index)"
                    class="slide-image"
                    :style="{
                        transform: 'rotate(' + slide.rotation + 'deg)',
                    }"
                />
                <div
                    v-if="selectedSlides.some(x => x.id === slide.id)"
                    class="selected-indicator"
                    :style="{ position: 'absolute', top: '8px', right: '8px', zIndex: 2 }"
                >
                    <svg
                        width="13.48"
                        height="10.5"
                        viewBox="0 0 13.48 10.5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 4.33333L5.08021 8.41208L12.48 1" stroke="white" stroke-width="2" />
                    </svg>
                </div>

                <v-progress-circular
                    v-if="slide.loading"
                    style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; margin: auto"
                    indeterminate
                    color="#e75a07"
                ></v-progress-circular>
            </div>

            <template v-for="(pendingSlide, index) in pendingUploads" v-if="pendingUploads.length">
                <div class="pending-slide ignore-draggable" :key="pendingSlide.id">
                    <div class="slide-top-left">
                        <div
                            class="slide-order"
                            :style="{
                                zIndex: 1000,
                            }"
                        >
                            {{ totalSlides + (index + 1) }}
                        </div>
                    </div>
                    <ImagePreview
                        class="slide-image"
                        :media="pendingSlide"
                        :alt="'Slide ' + totalSlides + (index + 1)"
                    />
                    <div class="progress-indicator">
                        <div class="progress-slider" :style="{ width: `${pendingSlide.progress.percentage}%` }"></div>
                    </div>
                </div>
            </template>
            <div v-if="totalSlides == 0" class="ghost-slide ignore-draggable" @click="triggerSlideUpload">
                <div class="ghost-icon-container">
                    <svg
                        class="ghost-icon"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289L9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3166 7.09763 12.6834 7.09763 12.2929 6.70711L11 5.41421L11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13L9 5.41421L7.70711 6.70711C7.31658 7.09763 6.68342 7.09763 6.29289 6.70711Z"
                            fill="#F97316"
                        />
                    </svg>
                </div>
            </div>

            <div v-for="i in mounted ? 0 : 10" class="slide ignore-draggable">
                <v-skeleton-loader height="100%" type="image"></v-skeleton-loader>
            </div>
        </div>

        <v-dialog v-model="showDownloadConfirmModal" max-width="500px">
            <v-card class="p-3">
                <v-card-title>
                    <div>
                        <span>
                            Download
                            {{ selectedSlides.length === 1 ? 'selected media' : `${selectedSlides.length} media` }}?
                        </span>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div>
                        Confirm to download
                        {{
                            selectedSlides.length === 1
                                ? 'the selected media'
                                : `these ${selectedSlides.length} slides`
                        }}.
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn outlined @click="showDownloadConfirmModal = false">Cancel</v-btn>
                    <v-btn depressed color="#ff530d" dark @click="confirmDownloadSelected">Download</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showDeleteModal" max-width="500px">
            <v-card class="p-3">
                <v-card-title>
                    <div>
                        <span style="background-color: #fee2e1; border-radius: 10rem; padding: 6px">
                            <font-awesome-icon
                                style="color: #ff5254"
                                icon="fa-regular fa-triangle-exclamation"
                            ></font-awesome-icon>
                        </span>

                        <span>
                            Confirm deletion of
                            {{ selectedSlides.length }} media?
                        </span>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div>
                        Are you sure you want to delete
                        {{ selectedSlides.length === 1 ? 'this slide' : `these ${selectedSlides.length} slides` }}? This
                        action cannot be undone.
                    </div>

                    <div class="delete-modal-thumb-grid">
                        <!-- use cached gallery imgkit img, resize w/ css instead of setting smaller h/w dimensions -->
                        <ImageThumbnail
                            v-for="(slide, index) in selectedSlides"
                            smart-crop
                            :key="slide.url"
                            :src="slide.url"
                            :w="400"
                            :h="400"
                            :smartCrop="slide.type !== 1"
                            :type="slide.mediaType"
                            :alt="'Slide ' + getSlideDbIndex(index)"
                            class="slide-image"
                        />
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn class="no-text-transform" outlined @click="closeDeleteModal">Cancel</v-btn>
                    <v-btn depressed color="error" class="btn btn-deactivate no-text-transform" @click="confirmDelete"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- The Imgly CreativeEditor component wrapped in a modal -->
        <v-dialog v-model="showAddTextSlideModal">
            <v-card v-if="editingPhoto" style="height: 85dvh">
                <div class="text-right pa-2">
                    <v-btn @click="showAddTextSlideModal = false" depressed>Close</v-btn>
                </div>
                <div v-if="editingPhoto.error" class="image-error-div">
                    <div v-if="editingPhoto.error.heicError">
                        <p>Unsupported image type (HEIC) for image editor, image queued for conversion</p>
                    </div>
                    <div class="text-center" v-else>
                        <p>An error occured while loading the image.</p>
                        <v-btn @click="deleteSelectedSlides" depressed color="error">Delete Image</v-btn>
                    </div>
                </div>

                <div v-else class="image-error-div">
                    <v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
                </div>
                <div>
                    <PhotoEditor
                        ref="photoEditor"
                        :photo="editingPhoto.photo"
                        :visible="editingPhoto.visible"
                        :theme="imglyConfig.theme"
                        :layout="imglyConfig.mobile ? 'basic' : 'advanced'"
                        :image-path="editingPhoto.path"
                        :blank-image="editingPhoto.blankImage"
                        :token="token"
                        :eventId="$props.eventId"
                        @close="() => (showAddTextSlideModal = false)"
                        @export-success="onEditorSave"
                        @created-export-success="onEditorSave"
                        @delete="deletePhotoById"
                        @revert-original="revertPhoto(editingPhoto.photo)"
                        @image-error="() => (editingPhoto.error = {})"
                    />
                </div>
            </v-card>
        </v-dialog>
        <!-- Slide preview modal -->
        <v-dialog @keydown="handlePreviewModalKeydown" v-model="showSlidePreviewModal" :overlay-opacity="0.85">
            <div class="preview-modal">
                <v-btn
                    class="carousel-btn modal-close-btn"
                    color="white"
                    depressed
                    @click="() => (showSlidePreviewModal = false)"
                >
                    <font-awesome-icon icon="fa-solid fa-x"></font-awesome-icon>
                </v-btn>
                <div class="slide-toolbar p-2 text-center" v-if="previewingIndex > -1">
                    <div v-if="!isVideoSlide(reversedSlides, previewingIndex) && !state.isMobile" class="edit-button">
                        <v-btn
                            class="carousel-btn"
                            color="white"
                            depressed
                            :disabled="
                                isVideoSlide(reversedSlides, previewingIndex) ||
                                (state.isContributor && !reversedSlides[previewingIndex].guid)
                            "
                            @click="actionFromPreviewImage($event, 'edit')"
                        >
                            <font-awesome-icon icon="fa-regular fa-pencil"></font-awesome-icon>
                        </v-btn>
                    </div>
                    <div v-if="!isVideoSlide(reversedSlides, previewingIndex)" class="rotate-button">
                        <v-btn
                            class="carousel-btn"
                            color="white"
                            depressed
                            :disabled="
                                isVideoSlide(reversedSlides, previewingIndex) ||
                                (state.isContributor && !reversedSlides[previewingIndex].guid)
                            "
                            @click="actionFromPreviewImage($event, 'rotate')"
                        >
                            <font-awesome-icon icon="fa-regular fa-rotate-right"></font-awesome-icon>
                        </v-btn>
                    </div>
                    <div class="delete-button">
                        <v-btn
                            class="carousel-btn"
                            color="white"
                            depressed
                            :disabled="state.isContributor && !reversedSlides[previewingIndex].guid"
                            @click="actionFromPreviewImage($event, 'trash')"
                        >
                            <font-awesome-icon
                                style="color: #ef4444"
                                icon="fa-regular fa-trash-can"
                            ></font-awesome-icon>
                        </v-btn>
                    </div>
                </div>
                <v-carousel v-model="previewingIndex" continuous hide-delimiters ref="previewCarousel">
                    <template v-slot:prev="{ on, attrs }">
                        <v-btn class="carousel-btn" color="white" depressed v-bind="attrs" v-on="on">
                            <font-awesome-icon icon="fa-solid fa-chevron-left"></font-awesome-icon>
                        </v-btn>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                        <v-btn class="carousel-btn" color="white" depressed v-on="on">
                            <font-awesome-icon icon="fa-solid fa-chevron-right"></font-awesome-icon>
                        </v-btn>
                    </template>

                    <v-carousel-item v-for="(slide, index) in reversedSlides" :key="`${slide.id}-${index}`" eager>
                        <v-sheet elevation="0" rounded height="100%">
                            <v-row class="m-0 fill-height" align="center" justify="center">
                                <div class="carousel-content" :ref="`slidePreview_${index}`">
                                    <div class="slide-top-left">
                                        <div class="slide-order">{{ getSlideDbIndex(index) }}</div>
                                    </div>
                                    <CustomTooltip
                                        :class="['slide-user']"
                                        :style="{ color: getUploadUserColor(slide) }"
                                        :tooltipProps="{ top: true }"
                                    >
                                        <template #activator>
                                            <div class="slide-user-inner">
                                                <font-awesome-icon icon="fa-solid fa-user"></font-awesome-icon>
                                                <span>
                                                    {{ slide.uploadUserName | firstName }}
                                                </span>
                                            </div>
                                        </template>
                                        <template #content>
                                            <strong class="mr-1">{{ slide.uploadUserName }}</strong>
                                            <span v-if="slide.uploadUserRelationship !== slide.uploadUserName">{{
                                                slide.uploadUserRelationship
                                            }}</span>
                                        </template>
                                    </CustomTooltip>
                                    <video v-if="slide.mediaType === 1" controls>
                                        <source :src="slide.url" type="video/webm" />
                                    </video>
                                    <ImageThumbnail
                                        v-else
                                        :key="slide.url"
                                        :src="slide.url"
                                        :w="null"
                                        :h="800"
                                        :type="slide.mediaType"
                                        :alt="'Slide ' + getSlideDbIndex(index)"
                                        class="slide-image"
                                    />
                                </div>
                            </v-row>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </div>
        </v-dialog>
        <!-- </div> -->

        <v-dialog v-model="filterByUserModal" max-width="350px">
            <v-card>
                <v-card-title>Filter By Uploader</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="(user, index) in uploadUsers" :key="index">
                            <!-- <v-list-item-avatar>
                            <v-icon :class="file.color" dark v-text="file.icon"></v-icon>
                            test
                        </v-list-item-avatar> -->

                            <v-list-item-content>
                                <v-list-item-title v-text="user.uploadUserName"></v-list-item-title>

                                <v-list-item-subtitle v-text="user.uploadUserRelationship"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-checkbox @change="filterSlidesBySelectedUsers" v-model="user.selected"></v-checkbox>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="closeUploadUserModal" class="no-text-transform" depressed outlined>Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <SlideGroupResolveModal
            v-if="selectedGroupType != null"
            @stage-delete="slides => stageForDelete(slides)"
            v-model="groupResolveModal"
            :groups="selectedGroups"
            :groupType="selectedGroupType"
        />

        <custom-context-menu ref="customContext" id="customContext" :items="contextItems" top></custom-context-menu>
    </div>
</template>

<script>
import sortableHelper from '@/utilities/sortableHelper';
import multiSelectUtils from '../../utilities/multiSelectUtils';
import draggable from 'vuedraggable';
import modal from './WarningModal.vue';
import initApiServices from '@/services/ApiInitializer';
import { debounce, each, map } from 'lodash';
import JSZip from 'jszip';

import ImagePreview from '../Media/ImagePreview.vue';
import ImageThumbnail from '../Media/ImageThumbnail.vue';
import { mapActions, mapGetters } from 'vuex';
import ActionBar from './Slides/ActionBar.vue';

import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import CustomContextMenu from '@/components/ui/CustomContextMenu.vue';
import SlideGroupResolveModal from './Modals/SlideGroupResolveModal.vue';

import { slideGroupTypes } from '../../constants';
import moment from 'moment';

export default {
    name: 'ManagePhotos',
    components: {
        draggable,
        modal,
        ImagePreview,
        ImageThumbnail,
        ActionBar,
        CustomTooltip,
        CustomContextMenu,
        SlideGroupResolveModal,
        PhotoEditor: () => import('@/components/ui/PhotoEditor'),
    },
    inject: ['state'],
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        tributeVideo: {
            type: Object,
            required: true,
        },
    },
    async mounted() {
        this.setContextItems();

        if (this.state.token) {
            this.token = this.state.token;
        } else {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        }
        this.apiService = initApiServices(this.token);

        if (this.eventId) {
            let data = {
                reversed: true,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
            };

            this.replaceCurrentPhotos(data);
        }

        if (this.tributeVideo.serviceId) {
            this.refreshDuplicateAndSimilarSlides(this.tributeVideo.serviceId);
        }

        this.initSortable();

        if (this.tributeVideo.id) {
            this.getUploadUsers(this.tributeVideo.id);
        }
    },
    data() {
        return {
            apiService: null,
            token: null,
            maxFiles: 400,
            pendingUploads: [],
            reversedSlides: [],
            selectedSlides: [],
            totalSlides: 0,
            isDragging: false,
            dragIndex: null,
            sortableInstance: null,
            showAddTextSlideModal: false,
            previewingIndex: -1,
            showSlidePreviewModal: false,
            textSlideBgColor: '#ffffff',
            textSlideContent: '',
            showDeleteModal: false,
            recordedScrollTop: 0,
            recordedScrollLeft: 0,
            pageNumber: 0,
            pageSize: 24,
            mounted: false,
            progress: 0,
            pendingReorder: false,
            pendingDelete: false,
            pendingRotate: false,
            selectRangeStart: null,
            uploadDropTarget: '.manage-photos-container',
            fetchingSlides: false,
            fetchingAllSlides: false,
            uploadUsers: [],
            imglyConfig: {
                theme: 'light',
                mobile: this.state.isMobile,
                // layout: 'advanced',
                // layout: 'basic',
                license: '{"owner":"Imgly Inc.","version":"2.4"}',
            },
            editingPhoto: null,
            downloadProgressMap: {},
            downloadProgressPercent: 0,
            showDownloadProgress: false,
            showDownloadConfirmModal: false,
            showUploadProgress: false,
            uploadProgressPercent: 0,
            showUploadUsers: true,
            filterByUserModal: false,
            contextItems: [],
            contextRefreshKey: 0,
            duplicateSlideGroups: {
                results: [],
                total: 0,
            },
            similarSlideGroups: {
                results: [],
                total: 0,
            },
            totalDuplicateSlides: 0,
            totalSimilarSlides: 0,
            highlightGroup: {
                keys: null,
                groupType: null,
                items: null,
            },
            groupResolveModal: false,
            groupTypes: slideGroupTypes,
            selectedGroups: [],
            selectedGroupType: null,
        };
    },
    computed: {
        maxFilesLeft() {
            // computed difference between how many files max and existing items
            // TODO: included pending uploads while they resolve and remove if they error, etc
            return this.maxFiles - this.reversedSlides.length;
        },
        isExpanded: {
            get() {
                return this.$store.state.tributeEditor.expanded;
            },
        },
        containerHeight: {
            get() {
                return this.$store.state.tributeEditor.workspaceHeight;
            },
        },

        isAnyImageSelected() {
            return this.selectedSlides.length > 0;
        },

        disableMoveBack() {
            if (this.selectedSlides.length !== 1) return true;

            if (this.reversedSlides.length === 0) return true;

            return this.reversedSlides[0].id === this.selectedSlides[0].id;
        },

        disableMoveForward() {
            if (this.selectedSlides.length !== 1) return true;

            if (this.reversedSlides.length === 0) return true;

            return this.reversedSlides[this.reversedSlides.length - 1].id === this.selectedSlides[0].id;
        },
        disableUploads() {
            // Add logic here to disable if needed
            return false;
        },
        showLoadMore() {
            return this.reversedSlides.length < this.totalSlides;
        },
        isFamilyPage() {
            return this.$route.name === 'TributeVideoFamily';
        },
        isContributorPage() {
            return this.$route.name === 'TributeVideoContributor';
        },
        resolveBtnText() {
            let text = 'Resolve';
            if (this.highlightGroup?.groupType === this.groupTypes.SIMILAR_LSH_ANY) {
                text += ' Similar';
            } else if (this.highlightGroup?.groupType === this.groupTypes.DUPLICATE_PHASH) {
                text += ' Duplicates';
            }
            return text;
        },
        selectedUploadUsers() {
            const selectedUsers = this.uploadUsers
                .filter(x => x.selected)
                .map(user => {
                    return { uploadUserName: user.uploadUserName, uploadUserRelationship: user.uploadUserRelationship };
                });

            return selectedUsers;
        },
        ...mapGetters('tributeVideo', ['deadlineExpired', 'deadlineToLocal']),
    },
    filters: {
        formatTimeStamp(seconds) {
            const duration = moment.duration(seconds, 'seconds');
            const minutes = duration.minutes();
            const secs = duration.seconds();

            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(secs).padStart(2, '0');

            return `${formattedMinutes}:${formattedSeconds}`;
        },
        initials(text) {
            const words = text?.split(' ').filter((_word, index, arr) => index === 0 || index === arr.length - 1);
            return map(words, w => w.charAt(0)).join('');
        },
        firstName(text) {
            const words = text?.split(' ');

            if (words.length > 0) return words[0];

            return null;
        },
    },
    watch: {
        isExpanded(newVal) {
            if (newVal) {
                this.$refs.photosContainer.focus();
            }
        },
        selectedSlides(newVal) {
            const selectedIds = newVal.map(slide => slide.id);
            this.syncFromSelected(selectedIds);
            this.setContextItems();
        },
        previewingIndex(newIndex, oldIndex) {
            if (newIndex !== null && oldIndex !== null && newIndex !== oldIndex) {
                const tmpRef = this.$refs[`slidePreview_${oldIndex}`];
                if (tmpRef?.length) {
                    const videoEl = tmpRef[0].querySelector('video');
                    if (videoEl && !videoEl.paused) {
                        console.log('video paused');
                        videoEl.pause();
                    }
                }
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeEditor', ['toggleExpandedState', 'setExpandedState']),
        getUploadUserColor(slide) {
            let uploadUser = this.uploadUsers.find(
                x =>
                    x.uploadUserName === slide.uploadUserName &&
                    x.uploadUserRelationship === slide.uploadUserRelationship,
            );

            if (uploadUser) {
                return uploadUser?.color;
            }
        },
        getUserColor(i) {
            const colors = ['#ff530d', '#3f51b5', '#009688', '#309df4'];

            return colors[i % colors.length];
        },
        userIsSelected(user) {
            var results = this.selectedUploadUsers.filter(
                x =>
                    x.uploadUserName === user.uploadUserName &&
                    x.uploadUserRelationship === user.uploadUserRelationship,
            );

            return results.length > 0;
        },
        quickFilterUser(user) {
            if (this.userIsSelected(user)) {
                this.deselectUploadUser(user);
                return;
            }

            this.uploadUsers = this.uploadUsers.map(u => {
                let selected = false;
                if (
                    u.uploadUserName === user.uploadUserName &&
                    u.uploadUserRelationship === user.uploadUserRelationship
                ) {
                    selected = true;
                }

                return { ...u, selected };
            });

            this.filterSlidesBySelectedUsers();
        },
        stageForDelete(slides) {
            this.selectedSlides = slides;
            const selectedIds = this.selectedSlides.map(slide => slide.id);
            this.syncFromSelected(selectedIds);
            this.openDeleteModal();
        },
        openGroupResolveModal(groupType) {
            // groupTypes.DUPLICATE_PHASH

            // this.highlightAllDuplicates();
            // console.log('opening resolve modal');

            let group = [];
            switch (groupType) {
                case this.groupTypes.DUPLICATE_PHASH:
                    group = this.duplicateSlideGroups?.results;
                    break;
                case this.groupTypes.SIMILAR_LSH_ANY:
                    group = this.similarSlideGroups?.results;
                    break;
                default:
                    console.log('Unsupported group type');
                    return;
            }
            this.selectedGroupType = groupType;
            this.selectedGroups = group || [];

            this.groupResolveModal = true;
        },
        deselectUploadUser(user) {
            const found = this.uploadUsers.find(
                x =>
                    x.uploadUserName === user.uploadUserName && x.uploadUserRelationship == user.uploadUserRelationship,
            );

            if (found) {
                found.selected = false;
            }

            this.filterSlidesBySelectedUsers();
        },
        async filterSlidesBySelectedUsers() {
            this.pageNumber = 0;
            this.pageSize = Math.max(24, this.reversedSlides.length + 1);

            let data = {
                reversed: true,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
            };

            if (this.selectedUploadUsers.length) {
                data.uploadUsers = this.selectedUploadUsers;
            }

            await this.replaceCurrentPhotos(data);
        },
        closeUploadUserModal() {
            this.filterByUserModal = false;
        },
        initFilterByUserModal() {
            this.filterByUserModal = true;

            this.getUploadUsers(this.tributeVideo.id);
        },
        async getUploadUsers(tributeId) {
            try {
                if (!tributeId) throw new Error('Invalid tribute id');

                const resp = await this.apiService.tributePhoto.getUploadUsers(this.tributeVideo.id);

                if (resp.data) {
                    const currentSelections = new Set(
                        this.uploadUsers
                            .filter(x => x.selected)
                            .map(x => `${x.uploadUserName}::${x.uploadUserRelationship}`),
                    );

                    this.uploadUsers = resp.data.map((user, i) => {
                        const key = `${user.uploadUserName}::${user.uploadUserRelationship}`;

                        return { ...user, color: this.getUserColor(i), selected: currentSelections.has(key) };
                    });
                }
            } catch (error) {}
        },
        getUploaderLabelColor(slide) {
            if (slide.uploadUserRelationship === 'Funeral Staff') {
                return 'gray-label';
            }

            return 'orange-label';
        },
        initResolveDuplicatesOrSimilars() {
            if (!this.highlightGroup.keys) return;

            let flaggedItems = [];
            switch (this.highlightGroup.groupType) {
                case this.groupTypes.DUPLICATE_PHASH:
                    flaggedItems = this.duplicateSlideGroups?.results;
                    break;
                case this.groupTypes.SIMILAR_LSH_ANY:
                    flaggedItems = this.similarSlideGroups?.results;
            }

            if (flaggedItems.length === 0) return;

            let slidesToDelete = [];
            for (const index in this.highlightGroup.keys) {
                const key = this.highlightGroup.keys[index];

                const group = this.getGroupByGroupKey(flaggedItems, key);

                if (!group?.photos.length > 1) continue;

                const nonOriginalDuplicates = group.photos.slice(1);
                slidesToDelete = slidesToDelete.concat(nonOriginalDuplicates);
            }

            this.resetHighlightGroup();
            this.selectedSlides = slidesToDelete;
            const selectedIds = this.selectedSlides.map(slide => slide.id);
            this.syncFromSelected(selectedIds);
            this.openDeleteModal();
        },

        selectAndDeleteSlide(event, slide) {
            this.deselectAllSlides();
            this.handleSlideClick(event, slide);
            this.openDeleteModal();
        },
        openContextMenuMobile(event, slide) {
            const customContext = this.$refs.customContext;
            if (!customContext) return;
            // This tells outsideClickHandler to ignore the first click
            // otherwise it auto-closes.
            if (document.querySelector('#context-menu.hidden')) {
                event._skipClickOutsideHandler = true;
            }
            this.deselectAllSlides();
            this.handleSlideClick(event, slide);
            customContext.handleCustomContext(event, slide);
        },
        getGroupByGroupKey(arr, key) {
            if (!key) return null;

            if (!Array.isArray(arr)) return null;

            const found = arr.find(x => x.groupKey === key);

            return found || null;
        },
        inHighlightGroup(id) {
            if (!Array.isArray(this.highlightGroup?.items)) return false;

            const found = this.highlightGroup?.items.find(x => x.id === id);

            return !!found;
        },
        async highlightAllDuplicates() {
            this.deselectAllSlides();
            await this.fetchAllSlides();

            this.setExpandedState(true);
            const arr = this.duplicateSlideGroups?.results;

            if (!arr) return;

            let photoGroup = [];
            let keys = [];

            for (const index in arr) {
                const group = arr[index];
                const photos = group?.photos;
                const groupKey = group?.groupKey;

                if (photos) {
                    photoGroup = photoGroup.concat(photos);

                    keys.push(groupKey);
                }
            }

            this.highlightGroup = {
                keys: keys,
                groupType: this.groupTypes.DUPLICATE_PHASH,
                items: photoGroup,
            };

            this.scrollToFirstInHighlightGroup();
        },
        async highlightAllSimilars() {
            this.deselectAllSlides();
            await this.fetchAllSlides();

            this.setExpandedState(true);
            const arr = this.similarSlideGroups?.results;

            if (!arr) return;

            let groupPhotos = [];
            let keys = [];
            for (const index in arr) {
                const group = arr[index];
                const photos = group?.photos;
                const groupKey = group?.groupKey;

                if (photos) {
                    groupPhotos = groupPhotos.concat(photos);
                    keys.push(groupKey);
                }
            }

            this.highlightGroup = {
                groupType: this.groupTypes.SIMILAR_LSH_ANY,
                items: groupPhotos,
                keys: keys,
            };

            this.scrollToFirstInHighlightGroup();
        },
        scrollToFirstInHighlightGroup() {
            if (!Array.isArray(this.highlightGroup?.items)) return;

            for (let i = 0; i <= this.reversedSlides.length - 1; i++) {
                const slide = this.reversedSlides[i];

                if (this.highlightGroup?.items.some(item => item.id === slide.id)) {
                    this.focusImage(i);
                    break;
                }
            }
        },
        highlightDuplicateGroupItems(groupKey) {
            this.deselectAllSlides();
            this.setExpandedState(true);

            const group = this.getGroupByGroupKey(this.duplicateSlideGroups?.results, groupKey);

            if (group?.photos) {
                this.highlightGroup = {
                    keys: [groupKey],
                    groupType: this.groupTypes.DUPLICATE_PHASH,
                    items: group.photos,
                };

                this.scrollToFirstInHighlightGroup();
            } else {
                this.resetHighlightGroup();
            }
        },
        resetHighlightGroup() {
            this.highlightGroup = {
                keys: null,
                groupType: null,
                items: null,
            };
        },
        highlightSimilarGroupItems(slide) {
            this.deselectAllSlides();
            this.setExpandedState(true);

            const firstBitsGroup = this.getGroupByGroupKey(this.similarSlideGroups?.results, slide.lshFirstBits);
            const middleBitsGroup = this.getGroupByGroupKey(this.similarSlideGroups?.results, slide.lshMiddleBits);
            const randomBitsGroup = this.getGroupByGroupKey(this.similarSlideGroups?.results, slide.lshRandomBits);

            let groupPhotos = [];
            let keys = [];

            if (firstBitsGroup?.photos) {
                groupPhotos = groupPhotos.concat(firstBitsGroup?.photos);
                keys.push(slide.lshFirstBits);
            }

            if (middleBitsGroup?.photos) {
                groupPhotos = groupPhotos.concat(middleBitsGroup?.photos);
                keys.push(slide.lshMiddleBits);
            }

            if (randomBitsGroup?.photos) {
                groupPhotos = groupPhotos.concat(randomBitsGroup?.photos);
                keys.push(slide.lshRandomBits);
            }

            if (groupPhotos.length > 0) {
                this.highlightGroup = {
                    groupType: this.groupTypes.SIMILAR_LSH_ANY,
                    items: groupPhotos,
                    keys: keys,
                };
                this.scrollToFirstInHighlightGroup();
            } else {
                this.resetHighlightGroup();
            }
        },
        totalGroupItems(pHash) {
            const group = this.getGroupByGroupKey(this.duplicateSlideGroups?.results, pHash);

            if (!group) return 0;

            return found?.photos.length || 0;
        },
        isDuplicate(pHash) {
            const group = this.getGroupByGroupKey(this.duplicateSlideGroups?.results, pHash);

            return !!group;
        },
        isSimilar(slide) {
            const { results } = this.similarSlideGroups ? this.similarSlideGroups : {};

            if (!results) return false;

            const keys = [slide.lshFirstBits, slide.lshMiddleBits, slide.lshRandomBits];

            for (const key of keys) {
                const group = this.getGroupByGroupKey(results, key);
                if (group) {
                    return true;
                }
            }
            return false;
        },
        isVideoSlide(arr, index) {
            if (!Array.isArray(arr)) return false;

            if (typeof index !== 'number') return false;

            return arr[index]?.mediaType === 1;
        },
        initCustomContext(e, item) {
            const customContext = this.$refs.customContext;
            if (!customContext) return;

            const selectedIds = this.getSelectedSlideIds();
            if (!selectedIds.includes(item.id)) {
                this.handleSlideClick(e, item, true);
            }

            customContext.handleCustomContext(e, item);
        },

        setContextItems() {
            const selectedCount = this.selectedSlides.length;

            let contextItems = [
                {
                    text: 'Download',
                    icon: 'fa-regular fa-arrow-down-to-bracket',
                    color: '#1877f2',
                    disabled: selectedCount <= 0,
                    value: 0,
                    callback: this.handleDownloadSelected,
                },
                {
                    text: 'Set Profile',
                    icon: 'fa-regular fa-user',
                    color: '',
                    disabled: selectedCount !== 1,
                    value: 6,
                    callback: this.selectProfilePicture,
                },
                {
                    text: 'Edit Photo',
                    icon: 'fa-regular fa-pencil',
                    color: '',
                    disabled: selectedCount !== 1,
                    value: 3,
                    callback: this.editActiveSlide,
                },
                {
                    text: 'Rotate',
                    icon: 'fa-regular fa-rotate-right',
                    color: '',
                    disabled: selectedCount <= 0,
                    value: 4,
                    callback: this.rotateSelectedSlides,
                },
                {
                    text: 'Delete',
                    icon: 'fa-regular fa-trash-can',
                    color: '#ff5252',
                    disabled: selectedCount <= 0,
                    value: 5,
                    callback: this.openDeleteModal,
                },
            ];

            if (this.isContributorPage) {
                const excluded = [6, 0];
                contextItems = contextItems.filter(x => !excluded.includes(x.value));
            }

            this.contextItems = contextItems;

            this.contextRefreshKey++;
        },
        async selectProfilePicture() {
            try {
                if (this.selectedSlides.length !== 1) {
                    throw new Error('Please select 1 photo to continue');
                }

                let slide = this.selectedSlides[0];
                const photoId = this.selectedSlides[0]?.id;

                if (!photoId) {
                    throw new Error('Invalid photo id');
                }

                slide.loading = true;
                await this.apiService.tributePhoto.updateProfilePhoto(this.eventId, photoId);
                slide.loading = false;

                this.showSnackbar({ message: 'Profile picture updated' });
            } catch (error) {
                console.log('error', error);
                this.showSnackbar({ message: error.message, color: 'error' });
            } finally {
            }
        },
        initSortable() {
            const container = this.$refs.sortableSlides;

            if (!container) return;

            this.sortableInstance = sortableHelper.initSortable(container, {
                onStart: this.onDragStart,
                onEnd: this.onDragEnd,
                onSelect: this.onSelect,
                onDeselect: this.onDeselect,
                delay: 120, // time in milliseconds to define when the sorting should start
                delayOnTouchOnly: true, // only delay if user is using touch
                touchStartThreshold: 20, // px, how many pixels the point should move before cancelling a delayed drag event
            });
        },
        handleWheel(event) {
            if (this.fetchingSlides || event.shiftKey) return;

            // How much a user needs to scroll before it triggers the expanding behavior
            const wheelBuffer = 50;
            // Access event.deltaY to get the scroll direction and amount
            const direction = event.deltaY > 0 ? 'down' : event.deltaY < 0 ? 'up' : '';
            const distance = Math.abs(event.deltaY);
            // console.log('Mousewheel:', { direction, distance, y: event.deltaY, event});
            // slides-container
            const slideContainer = document.querySelector('.slides-container');
            // Implement your custom logic here
            if (distance > wheelBuffer) {
                if (direction === 'down' && !this.isExpanded) {
                    this.toggleExpandedState();
                }

                if (direction === 'up' && this.isExpanded && slideContainer.scrollTop < 5) {
                    // ensure slides-container is at the top before collapsing
                    // console.log(slideContainer);
                    this.toggleExpandedState();
                }
            }
        },
        triggerSlideUpload() {
            if (this.$refs.actionBar?.$refs?.uploaderBtn) {
                this.$refs.actionBar.$refs.uploaderBtn.openFileSelection();
            }
        },
        onSelect(evt) {
            const selectedIds = this.selectedSlides.map(slide => slide.id);
            this.syncFromSelected(selectedIds);
        },
        onDeselect(evt) {
            const selectedIds = this.selectedSlides.map(slide => slide.id);
            this.syncFromSelected(selectedIds);
        },
        onDragStart(evt) {
            if (!evt.item) return;

            const id = this.parseSlideId(evt.item);
            if (!id) return;

            if (this.selectedSlides.some(x => x.id === id)) return;

            const found = this.reversedSlides.find(x => x.id === id);
            if (!found) return;

            this.selectedSlides.push(found);
        },

        async onDragEnd(evt) {
            try {
                if (evt.newIndex === evt.oldIndex) return;

                const sortedNewIndicies = evt.newIndicies.slice().sort((a, b) => a.index - b.index);
                const slideIds = sortedNewIndicies
                    .map(item => {
                        let parsedInt = parseInt(item.multiDragElement.id.replace('slide-', ''));
                        return Number.isNaN(parsedInt) ? null : parsedInt;
                    })
                    .filter(Number.isInteger);

                const startingIndex = sortedNewIndicies[0].index;

                await this.reorderSlides(slideIds, startingIndex);
            } catch (error) {
                console.log(error, 'multi update error');
            }
        },
        previewSlide(event, index, slide) {
            this.deselectAllSlides();
            this.handleSlideClick(event, slide);
            this.previewingIndex = index;
            this.showSlidePreviewModal = true;
        },
        actionFromPreviewImage(evt, action) {
            this.selectedSlides = [this.reversedSlides[this.previewingIndex]];

            if (!this.selectedSlides.length) {
                console.log('hmm no slides to perform action on');
                return;
            }
            this.$nextTick(() => {
                setTimeout(() => {
                    if (action === 'edit') {
                        this.editActiveSlide();
                        this.showSlidePreviewModal = false;
                    }
                    if (action === 'rotate') {
                        this.rotateSelectedSlides();
                    }
                    if (action === 'trash') {
                        this.showSlidePreviewModal = false;
                        // timeout to allowe for preview modal to clear.
                        // Future improvement: The delete modal should be able to be opened without having to close the preview modal
                        setTimeout(() => {
                            this.openDeleteModal();
                        }, 400);
                    }
                }, 300);
            });
        },
        async reorderSlides(slideIds, startingArrIndex) {
            try {
                if (this.pendingReorder) {
                    this.showSnackbar({ message: 'Please wait for current edit to finish', color: 'error' });
                    this.deselectAll(this.sortableInstance);
                    return;
                }

                this.pendingReorder = true;
                this.deselectAll(this.sortableInstance);
                this.updateLoadingSlides(slideIds);

                const targetOrder = this.getTargetOrder(startingArrIndex);

                const slideMap = new Map(this.reversedSlides.map(slide => [slide.id, slide]));

                const orderedSlides = slideIds.map(id => slideMap.get(id)).filter(Boolean);

                const remainingSlides = this.reversedSlides.filter(slide => !slideIds.includes(slide.id));

                const updatedSlides = [
                    ...remainingSlides.slice(0, startingArrIndex),
                    ...orderedSlides,
                    ...remainingSlides.slice(startingArrIndex),
                ];

                const dedupedSlides = Array.from(new Map(updatedSlides.map(slide => [slide.id, slide])).values());
                this.reversedSlides = dedupedSlides;

                //May need to rethink error handling here,
                // but the response time feels much better for users
                var res = await this.apiService.tributePhoto.multiUpdateOrder(
                    this.tributeVideo.id,
                    targetOrder,
                    slideIds,
                );
            } catch (error) {
                console.log(error, 'multi update error');
                this.showSnackbar({ message: 'Error', color: 'error' });
            } finally {
                this.$emit('refresh-preview');
                setTimeout(() => {
                    this.updateLoadingSlides([]);
                    this.pendingReorder = false;
                }, 1000);
            }
        },
        updateLoadingSlides(slideIds) {
            this.reversedSlides = this.reversedSlides.map(slide => ({
                ...slide,
                loading: slideIds.includes(slide.id),
            }));
        },
        deselectAll() {
            sortableHelper.deselectAll(this.sortableInstance);
            this.selectedSlides = [];
        },

        syncFromSelected(ids) {
            this.$nextTick(() => {
                sortableHelper.syncSelected(this.sortableInstance, ids, '#slide-');
            });
        },
        parseSlideId(element) {
            let parsedInt = parseInt(element.id.replace('slide-', ''));
            return Number.isNaN(parsedInt) ? null : parsedInt;
        },
        getSelectedSlideGUIDs() {
            const selectedSlides = sortableHelper.getSelectedElements(this.sortableInstance);
            return Array.from(selectedSlides).map(slide => slide.getAttribute('guid'));
        },
        getSelectedSlideIds() {
            const selectedSlides = sortableHelper.getSelectedElements(this.sortableInstance);

            return Array.from(selectedSlides).map(this.parseSlideId).filter(Number.isInteger);
        },
        //TODO: need to wire this up, holding for ui design
        async toggleSlideMute(slide) {
            if (slide.mediaType != 1) {
                this.showSnackbar({ message: 'Invalid slide media type selected', color: 'error' });
                return;
            }

            try {
                slide.mute = !slide.mute;
                await this.apiService.tributePhoto.setVideoSlideMute(slide.id, slide.mute);
            } catch (error) {
                console.log(error, 'error toggling slide mute');
            }
        },
        async handleSlideClick(evt, slide) {
            this.resetHighlightGroup();
            const action = multiSelectUtils.handleClick(evt, slide, {
                selectedItems: this.selectedSlides,
                allItems: this.reversedSlides,
                selectRangeStart: this.selectRangeStart,
            });

            if (Array.isArray(action.selectedItems) && action.type != 'none') {
                this.selectedSlides = action.selectedItems;
                this.selectRangeStart = action.selectRangeStart;
            }
        },
        deselectAllSlides(event) {
            if (
                this.showDeleteModal ||
                this.showSlidePreviewModal ||
                this.showAddTextSlideModal ||
                this.showDownloadConfirmModal
            )
                return;
            if (event?.target.closest('.v-dialog__content--active')) return;
            this.selectedSlides = [];
            this.selectRangeStart = null;

            this.resetHighlightGroup();
        },
        handlePreviewModalKeydown(evt) {
            // If modal is open in a modal let the keypresses be handled normally.
            // TODO: this may need revisting later to maybe be a mode toggle, like ON/OFF
            const activeModal = document.querySelector('.v-dialog__content--active');
            const previewModal = document.querySelector('.preview-modal');
            if (activeModal) {
                if (previewModal) {
                    // Preview modal is open
                    const previewCarousel = this.$refs.previewCarousel;
                    switch (evt.key.toLowerCase()) {
                        case 'arrowleft':
                            // go left
                            previewCarousel?.prev();
                            break;
                        case 'arrowright':
                            // go right
                            previewCarousel.next();
                            break;
                    }
                }
                return;
            }
        },
        async handleSlideKeydown(evt) {
            evt.preventDefault();

            const firstSlide = document.querySelector('.slide');

            const action = await multiSelectUtils.handleKeydown(evt, {
                selectedItems: this.selectedSlides,
                allItems: this.reversedSlides,
                selectRangeStart: this.selectRangeStart,
                containerWidth: this.$refs.sortableSlides.offsetWidth,
                itemWidth: firstSlide ? firstSlide.offsetWidth : 0,
                fetchAllItemsCallback: this.fetchAllSlides,
                deleteItemsCallback: this.openDeleteModal,
            });

            if (action && Array.isArray(action.selectedItems) && action.type != 'none') {
                this.selectedSlides = action.selectedItems;
                this.selectRangeStart = action.selectRangeStart;

                const indexToFocus = this.determineFocusIndex(action);
                if (indexToFocus != null) {
                    this.focusImage(indexToFocus);
                }
            }
        },
        determineFocusIndex(action) {
            if (this.selectedSlides.length == 1) {
                return this.reversedSlides.indexOf(this.selectedSlides[0]);
            }

            if (this.selectedSlides.length > 1) {
                const endSelectedIndex = this.reversedSlides.indexOf(
                    this.selectedSlides[this.selectedSlides.length - 1],
                );
                const startSelectedIndex = this.reversedSlides.indexOf(this.selectedSlides[0]);

                return endSelectedIndex === action.selectRangeStart ? startSelectedIndex : endSelectedIndex;
            }

            return null;
        },
        async fetchAllSlides() {
            try {
                if (this.fetchingAllSlides) return;

                this.fetchingAllSlides = true;

                if (this.reversedSlides.length === this.totalSlides) {
                    return this.reversedSlides;
                }

                let data = {
                    reversed: true,
                    pageSize: this.totalSlides,
                };

                const allSlides = await this.replaceCurrentPhotos(data);

                if (allSlides) {
                    return allSlides;
                }

                return null;
            } finally {
                this.fetchingAllSlides = false;
                this.fetchingSlides = false;
            }
        },
        getSlideDbIndex(index) {
            let hiddenSlides = 0;

            if (this.totalSlides > this.reversedSlides.length) {
                hiddenSlides = this.totalSlides - this.reversedSlides.length;
            }

            return index + 1 + hiddenSlides;
        },
        handleSlidesStartIntersect(isIntersecting) {
            if (this.mounted && isIntersecting) {
                this.slidesNextPage();
            }
        },
        recordScrollPosition() {
            const container = this.$el.querySelector('.slides-container');
            if (container) {
                this.recordedScrollTop = container.scrollHeight - container.scrollTop;
                this.recordedScrollLeft = container.scrollWidth - container.scrollLeft;
            }
        },
        restoreScrollPosition() {
            const container = this.$el.querySelector('.slides-container');

            if (container) {
                container.scrollTop = container.scrollHeight - this.recordedScrollTop;
                container.scrollLeft = container.scrollWidth - this.recordedScrollLeft;
            }
        },
        async slidesNextPage() {
            if (this.pendingUploads.length > 0 || this.reversedSlides.length >= this.totalSlides) return;

            try {
                this.fetchingSlides = true;
                this.recordScrollPosition();

                this.pageNumber++;

                let data = {
                    reversed: true,
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                };

                if (this.selectedUploadUsers.length) {
                    data.uploadUsers = this.selectedUploadUsers;
                }

                var resp = await this.apiService.tributePhoto.getPhotos(this.eventId, data);

                if (resp.data.photos) {
                    let nextPageSlides = resp.data.photos.slice().reverse();

                    this.reversedSlides = [
                        ...nextPageSlides.map(slide => ({ ...slide, rotation: 0, loading: false })),
                        ...this.reversedSlides,
                    ];

                    this.$nextTick(() => {
                        this.restoreScrollPosition();
                    });
                }

                if (resp.data.total) {
                    this.totalSlides = resp.data.total;
                }
            } catch (error) {
                console.log(error, 'error fetching slides');
            } finally {
                this.fetchingSlides = false;
            }
        },

        // This method should not alter current pageNumber or pageSize to
        // avoid interfering with infinite scroll pagination
        //Just reload and replace the current loaded images
        async replaceCurrentPhotos(params) {
            var resp = await this.apiService.tributePhoto.getPhotos(this.eventId, params);

            if (resp.data) {
                this.reversedSlides = resp.data.photos
                    .slice()
                    .reverse()
                    .map(slide => {
                        return { ...slide, rotation: 0, loading: false };
                    });

                this.$nextTick(() => {
                    this.mounted = true;
                    this.scrollGalleryToEnd();
                });
            }

            if (resp.data.total >= 0) {
                this.totalSlides = resp.data.total;
            }

            return this.reversedSlides;
        },
        async deleteSelectedSlides() {
            try {
                this.pendingDelete = true;
                this.block(true);
                let selectedIds = [];
                if (this.state.isContributor) {
                    selectedIds = this.getSelectedSlideGUIDs();
                } else {
                    selectedIds = this.getSelectedSlideIds();
                }

                if (selectedIds.length === 0) throw new Error('No valid slides selected');
                let resp;
                // For anon contributor users hand off the delete call
                if (this.state.isContributor) {
                    resp = await this.apiService.tributePhoto.deletePhotoBatchByGuid(selectedIds);
                } else {
                    resp = await this.apiService.tributePhoto.deletePhotoBatch(selectedIds);
                }

                this.totalSlides -= selectedIds.length;
                this.reversedSlides = this.reversedSlides.filter(x => !selectedIds.includes(x.guid || x.id));
                // update the tributeVideoStore
                this.$store.dispatch(
                    'tributeVideo/updateTributeVideoSelectedPhotos',
                    structuredClone(this.reversedSlides),
                );

                if (this.tributeVideo.serviceId) {
                    this.refreshDuplicateAndSimilarSlides(this.tributeVideo.serviceId);
                }

                this.deselectAllSlides();
                this.$emit('refresh-preview');
            } catch (error) {
                console.log(error, 'error deleting slide');
            } finally {
                this.pendingDelete = false;
                this.block(false);
            }
        },
        async revertPhoto(photo) {
            this.closeAddTextSlideModal();
            if (photo.originalPhoto == null) {
                this.showSnackbar({ message: 'No photo to revert to' });
                return;
            }

            this.apiService.tributePhoto.revertPhotoToOriginal(photo.id).then(resp => {
                this.reversedSlides[this.editingPhoto.slideIndex] = resp.data;
                this.editingPhoto = null;
                this.showSnackbar({ message: 'Original photo restored' });
            });
        },
        deletePhotoById() {
            this.closeAddTextSlideModal();
            this.$nextTick(async () => {
                const selectedId = this.editingPhoto.photo.id;
                const resp = await this.apiService.tributePhoto.deletePhoto(selectedId);
                this.reversedSlides = this.reversedSlides.filter(x => selectedId !== x.id);
                // update the tributeVideoStore
                this.$store.dispatch(
                    'tributeVideo/updateTributeVideoSelectedPhotos',
                    structuredClone(this.reversedSlides),
                );
                this.$emit('refresh-preview');
                this.editingPhoto = null;
                this.showSnackbar({ message: 'Media deleted successfully' });
            });
        },
        scrollGalleryToEnd() {
            const container = this.$el.querySelector('.slides-container');
            if (container) {
                container.scrollTo({
                    top: container.scrollHeight,
                    left: container.scrollWidth,
                    behavior: 'instant',
                });
            }
        },
        moveSlideForward() {
            if (this.selectedSlides.length !== 1) {
                this.showSnackbar({ message: 'Please select 1  slide to move', color: 'error' });
                return;
            }

            const slide = this.selectedSlides[0];
            const index = this.reversedSlides.findIndex(x => x.id === slide.id);

            if (index === -1) {
                this.showSnackbar({ message: 'Slide not found', color: 'error' });
                return;
            }

            if (index + 1 >= this.totalSlides) {
                return;
            }

            this.reorderSlides([slide.id], index + 1);
        },
        moveSlideBackward() {
            if (this.selectedSlides.length !== 1) {
                this.showSnackbar({ message: 'Please select 1  slide to move', color: 'error' });
                return;
            }

            const slide = this.selectedSlides[0];
            const index = this.reversedSlides.findIndex(x => x.id === slide.id);

            if (index === -1) {
                this.showSnackbar({ message: 'Slide not found', color: 'error' });
                return;
            }

            if (index <= 0) {
                return;
            }

            this.reorderSlides([slide.id], index - 1);
        },
        //Converts current "page" slide index to target db order
        getTargetOrder(arrIndex) {
            const invertedIndex = this.reversedSlides.length - arrIndex;
            return this.totalSlides - invertedIndex;
        },
        async editActiveSlide() {
            if (this.selectedSlides.length > 1) {
                this.showSnackbar({ message: 'Please select 1 slide to edit', color: 'error' });
                return;
            }

            if (this.selectedSlides.length === 0) {
                this.showSnackbar({ message: 'No slide selected', color: 'error' });
                return;
            }

            const slide = this.selectedSlides[0];
            const selectedSlideIndex = this.reversedSlides.findIndex(x => x.id === slide.id);
            this.openAddTextSlideModal();
            // TODO: this works for now, but there's a chance that it might need a longer delay once saving the tribute is implemented
            // before attempting to load an existing scene since it sort of assumes the editor is already initialized
            this.$nextTick(async () => {
                this.editingPhoto = {
                    photo: slide,
                    slideIndex: selectedSlideIndex,
                    path: slide.url,
                    blankImage: false,
                    visible: false,
                };
                // Trigger photoEditor's "visible" watcher
                setTimeout(() => {
                    this.editingPhoto.visible = true;
                });
            });
        },
        openDeleteModal() {
            if (this.isAnyImageSelected) {
                this.showDeleteModal = true;
            }
        },
        triggerPhotoUpload() {
            this.$refs.fileInput.click();
        },
        createProgressHandler(file) {
            return progressEvent => {
                const currentBytes = this.uploadedBytes + progressEvent.loaded;
                const percent = Math.ceil((currentBytes / this.totalBytes) * 100);

                this.progress = percent;
            };
        },
        async uploadTextSlide(eventId, file) {
            try {
                var remainingAllowed = await this.apiService.tributePhoto.getRemainingAllowedUploads(eventId);

                if (remainingAllowed.remaining <= 0) {
                    throw new Error(`Cannot exceed remaind allowed uploads: ${remainingAllowed.remaining}`);
                }

                let { data: SAS_INFO } = await await this.apiService.tributePhoto.getUploadUrl(eventId, file);

                // TODO: add cancel token
                await this.apiService.blob.upload(SAS_INFO.sas, file, {
                    onUploadProgress: this.createProgressHandler(file),
                });

                let tributePages = ['TributeUploadPage', 'TributeFamilyPage'];

                let data = [
                    {
                        duration: 0,
                        mediaType: 2,
                        name: file.name,
                        url: SAS_INFO.sas.split('?sv=')[0],
                        uniqueName: SAS_INFO.fileName,
                        uploadSource: tributePages.includes(this.$route.name) ? 2 : 0,
                        uploadUserName: tributePages.includes(this.$route.name) ? 'Unknown' : this.$auth.user.name,
                        uploadUserRelationship: tributePages.includes(this.$route.name) ? 'Unknown' : 'Funeral Staff',
                    },
                ];

                var resp = await this.apiService.tributePhoto.createPhotoBatch(eventId, data, false);
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    // Set lastSyncedChange, in case we need to delay render
                    lastSyncedChange: moment().toISOString(),
                });
                this.scrollGalleryToEnd();
                this.$emit('refresh-preview');

                if (resp.data.length > 0) {
                    return resp.data[0];
                }

                return null;
            } catch (error) {
                console.log('Error uploading text slide', error);
            }
        },
        async fetchSceneData(sceneDataURL) {
            try {
                const resp = await this.axios.get(sceneDataURL);

                if (resp.data.scene) {
                    return resp.data.scene;
                }

                return null;
            } catch (error) {
                console.log('Error fetching scene data', error);
            }
        },
        generateJsonBlob(jsonData) {
            const jsonString = JSON.stringify(jsonData);

            const jsonBlob = new Blob([jsonString], { type: 'application/json' });

            return jsonBlob;
        },
        async updateSlideScene(slide, scene) {
            try {
                const resp = await this.apiService.tributePhoto.getSlideSceneUploadUrl(slide.id);
                const { sas, fileName } = resp.data;

                const jsonData = {
                    slideId: slide.id,
                    lastModified: new Date().toISOString(),
                    scene: scene,
                };

                var sceneBlob = this.generateJsonBlob(jsonData);

                await this.apiService.blob.upload(sas, sceneBlob);
                const updatedSlideResp = await this.apiService.tributePhoto.updateSlideScene(slide.id, fileName);

                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    // Set lastSyncedChange, in case we need to delay render
                    lastSyncedChange: moment().toISOString(),
                });
                return updatedSlideResp.data;
            } catch (error) {
                console.log('error uploading scene json', error);
            }
        },
        async onEditorSave(editorResults) {
            // const { metadata } = editorResults;
            this.closeAddTextSlideModal();
            // Are we editing an existing slide or is this a new one?
            const isEditing = this.editingPhoto?.slideIndex >= 0;
            const index = isEditing ? this.editingPhoto.slideIndex : this.reversedSlides.length - 1;
            const fileName = isEditing ? this.reversedSlides[index].name : 'Title Slide.png';

            if (isEditing) {
                slide = this.reversedSlides[index] = editorResults;
            } else {
                // New Slide being added
                this.reversedSlides.push(editorResults);
                this.focusImage(this.reversedSlides.length - 1);
            }
            this.editingPhoto = null;
        },
        addNewTextSlide() {
            // When adding a new text slide, first clear out any existing selected image
            if (this.selectedSlides.length > 0) {
                this.deselectAll(this.sortableInstance);
            }

            // Then open up the editor modal
            this.openAddTextSlideModal();
            this.editingPhoto = {
                blankImage: true,
                path: '',
                slideIndex: null,
                visible: false,
            };
            setTimeout(() => {
                this.editingPhoto.visible = true;
            });
        },
        async openAddTextSlideModal() {
            this.showAddTextSlideModal = true;
            await this.$nextTick();
        },
        closeAddTextSlideModal() {
            this.showAddTextSlideModal = false;
        },
        handleTempFilesChange(tempFiles) {
            if (tempFiles.length === 0) return;

            this.pendingUploads = tempFiles;
        },
        handleTotalUploadProgress(val) {
            if (val > this.uploadProgressPercent) {
                this.uploadProgressPercent = val;
            }
        },
        handleSuccessfulUploads(newSlides) {
            //Handle batched upload responses
            const newSlideIds = new Set(newSlides.map(slide => slide.uppyId));

            for (let i = this.pendingUploads.length - 1; i >= 0; i--) {
                if (newSlideIds.has(this.pendingUploads[i].id)) {
                    this.pendingUploads.splice(i, 1);
                }
            }

            this.reversedSlides.push(
                ...newSlides.map(slide => ({
                    ...slide.dbData,
                    rotation: 0,
                    loading: false,
                })),
            );

            this.$store.dispatch('tributeVideo/updateTributeVideoSelectedPhotos', structuredClone(this.reversedSlides));

            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                // Set lastSyncedChange, in case we need to delay render
                lastSyncedChange: moment().toISOString(),
            });

            this.totalSlides += newSlides.length;

            this.getUploadUsers(this.tributeVideo.id);
        },
        onFileAdded: debounce(function () {
            this.showUploadProgress = true;
            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                // Set lastSyncedChange, in case we need to delay render
                uploadingPhotos: true,
            });
            this.scrollGalleryToEnd();
            // Listen for files being added then trigger the upload automatically
            this.$refs.actionBar.initUpload(this.$props.eventId).then(newSlides => {
                // all uploads should be finished here, so set percentage to 100
                each(this.pendingUploads, up => {
                    if (up.progress.percentage < 100) {
                        up.progress.percentage = 100;
                    }
                });

                this.pendingUploads = [];
                this.deselectAll(this.sortableInstance);

                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    // Set lastSyncedChange, in case we need to delay render
                    uploadingPhotos: false,
                });
                setTimeout(() => {
                    this.resetUploadState();
                }, 1000);
                //Refresh from webhook after ingest pipeline finishes processing
            });
        }, 300),
        async rotateSelectedSlides() {
            try {
                let selectedIds = this.getSelectedSlideIds();
                let selectedSlides = this.reversedSlides.filter(x => selectedIds.includes(x.id));

                selectedSlides.forEach(slide => {
                    slide.rotation = (slide.rotation + 90) % 360;
                });

                this.debouncedMultiSlideRotate(selectedSlides);
            } catch (error) {
                console.log(error, 'error rotating selected images');
            }
        },
        debouncedMultiSlideRotate: debounce(async function (slides) {
            this.pendingRotate = true;

            this.deselectAll(this.sortableInstance);
            slides.forEach(slide => {
                slide.loading = true;
            });
            const rotatePromises = slides.map(slide => this.generateRotatedImage(slide));
            await Promise.allSettled(rotatePromises);

            setTimeout(() => {
                this.pendingRotate = false;
                this.$emit('refresh-preview');
            }, 1000);
        }, 500),
        generateRotatedImage(slide) {
            return new Promise(async (resolve, reject) => {
                try {
                    const image = new Image();
                    image.crossOrigin = 'anonymous';
                    image.src = slide.url;

                    image.onload = async () => {
                        const rotatedBlob = await this.rotateImageToBlob(image, slide.rotation);

                        const file = this.blob2File(rotatedBlob, slide.name);

                        const updatedSlide = await this.replaceSlideFile(slide, file);

                        const index = this.reversedSlides.findIndex(x => x.id === slide.id);

                        this.reversedSlides.splice(index, 1, {
                            ...slide,
                            // url: 'http://via.placeholder.com/1280x720',
                            url: updatedSlide.url,
                            rotation: 0,
                            loading: false,
                        });
                        resolve();
                    };

                    image.onerror = () => {
                        console.error('image rotate error');
                        reject(error);
                    };
                } catch (error) {
                    reject(error);
                }
            });
        },
        blob2File(blob, fileName) {
            return new File([blob], fileName, {
                lastModified: new Date(),
                type: blob.type,
            });
        },
        async replaceSlideFile(slide, file) {
            try {
                if (!slide) throw new Error('Slide to replace not found');
                if (slide.mediaType === 1) {
                    console.log('TODO: upload video edits...');
                    alert('saving video edits is not wired up yet.');
                    // this.apiService.tributeVideo.getUploadUrl()
                } else {
                    let { data: SAS_INFO } = await this.apiService.tributePhoto.getUploadUrl(slide.eventId, file);

                    // TODO: add cancel token
                    await this.apiService.blob.upload(SAS_INFO.sas, file, {
                        // onUploadProgress: this.createProgressHandler(file),
                    });

                    const replacement = await this.apiService.tributePhoto.replacePhoto(
                        slide.id,
                        SAS_INFO.fileName,
                        true,
                    );

                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        // Set lastSyncedChange, in case we need to delay render
                        lastSyncedChange: moment().toISOString(),
                    });
                    let updatedSlide = { ...slide, url: replacement?.data?.url, rotation: 0, loading: false };

                    return updatedSlide;
                }
            } catch (error) {
                console.log(error, 'error replacing photo');
            }
        },
        /**
         * Rotates an image and returns the rotated copy as a Blob.
         * @param {HTMLImageElement} image - The source image to rotate.
         * @param {number} angle - The angle to rotate the image in degrees (clockwise).
         * @param {string} mimeType - The MIME type of the output image (e.g., 'image/png', 'image/jpeg').
         * @param {number} quality - The quality of the output image (for lossy formats like JPEG, range 0-1).
         * @returns {Promise<Blob>} A Promise that resolves with the rotated image as a Blob.
         */
        rotateImageToBlob(image, angle, mimeType = 'image/png', quality = 1) {
            return new Promise((resolve, reject) => {
                try {
                    // Create a canvas to draw the rotated image
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Calculate the dimensions of the rotated image
                    canvas.width = angle % 180 === 0 ? image.width : image.height;
                    canvas.height = angle % 180 === 0 ? image.height : image.width;

                    //Center the canvas
                    ctx.translate(canvas.width / 2, canvas.height / 2);

                    // Convert the angle to radians
                    const radians = (angle * Math.PI) / 180;

                    // Rotate the canvas
                    ctx.rotate(radians);

                    // Draw the image onto the rotated canvas
                    ctx.drawImage(image, -image.width / 2, -image.height / 2);

                    canvas.toBlob(
                        blob => {
                            if (blob) {
                                resolve(blob);
                            } else {
                                reject(new Error('Canvas toBlob failed'));
                            }
                        },
                        mimeType,
                        quality,
                    );
                } catch (error) {
                    reject(error);
                }
            });
        },
        handleSocketRefresh: debounce(async function () {
            this.pageNumber = 0;
            this.pageSize = Math.max(24, this.reversedSlides.length + 1);

            let data = {
                reversed: true,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
            };

            if (this.selectedUploadUsers.length) {
                data.uploadUsers = this.selectedUploadUsers;
            }

            await this.replaceCurrentPhotos(data);

            if (this.tributeVideo.serviceId) {
                this.refreshDuplicateAndSimilarSlides(this.tributeVideo.serviceId);
            }

            this.getUploadUsers(this.tributeVideo.id);
            // this.$emit('refresh-preview');
        }, 500),

        focusImage(index) {
            this.$nextTick(() => {
                const slide = this.$el.querySelectorAll('.slide')[index];
                if (slide) {
                    slide.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                }
            });
        },

        closeDeleteModal() {
            this.showDeleteModal = false;
        },
        confirmDelete() {
            this.closeDeleteModal();

            if (this.selectedSlides.length === 0) return;

            this.deleteSelectedSlides();
        },
        handleDownloadSelected() {
            const photos = this.selectedSlides;

            if (photos.length === 0) return;

            if (photos.length == 1) {
                this.downloadImages(photos);
            } else {
                this.showDownloadConfirmModal = true;
            }
        },
        confirmDownloadSelected() {
            this.showDownloadConfirmModal = false;
            this.downloadImages(this.selectedSlides);
        },
        async downloadImages(photos) {
            try {
                if (this.showDownloadProgress) return;

                this.showDownloadProgress = true;

                const promises = photos.map(async image => {
                    if (image.url) {
                        const blob = await this.getImageData(image);
                        return { ...image, blob: blob };
                    }
                });

                const res = await Promise.all(promises);

                //Download multiple as zip folder
                if (photos.length > 1) {
                    const zip = new JSZip();

                    res.forEach((image, index) => {
                        zip.file(image.name, image.blob);
                    });

                    const zipFile = await zip.generateAsync({ type: 'blob' });

                    this.downloadBlob(
                        zipFile,
                        `${this.tributeVideo.firstName} ${this.tributeVideo.lastName} Tribute Photos`,
                    );
                    this.showSnackbar({ message: 'Photos downloaded' });
                } else {
                    this.downloadBlob(res[0].blob, res[0].name);
                    this.showSnackbar({ message: 'Photo downloaded' });
                }
                this.deselectAllSlides();
            } catch (err) {
                console.log(err, 'error downloading images');
                this.showSnackbar({ message: 'Error downloading images', color: 'error' });
            } finally {
                setTimeout(() => {
                    this.resetDownloadState();
                }, 1000);
            }
        },
        async getImageData(photo) {
            if (!photo.url || !photo.id) return null;

            try {
                const response = await this.axios({
                    method: 'get',
                    url: photo.url,
                    responseType: 'blob',
                    onDownloadProgress: evt => this.updateProgress(photo.id, evt.loaded, evt.total),
                });

                return response.data;
            } catch (error) {
                console.log(error, 'error');
                this.showSnackbar({ message: 'Error downloading image', color: 'error' });
            }
        },
        updateProgress(photoId, loaded, total) {
            this.downloadProgressMap[photoId] = Math.round((loaded / total) * 100);

            this.calculateOverallProgress();
        },
        calculateOverallProgress() {
            const totalProgress = Object.values(this.downloadProgressMap).reduce((acc, progress) => acc + progress, 0);
            const numItems = Object.keys(this.downloadProgressMap).length;
            const percent = Math.round(totalProgress / numItems);

            if (percent > this.downloadProgressPercent) {
                this.downloadProgressPercent = percent;
            }
        },
        downloadBlob(file, name) {
            const blob = window.URL.createObjectURL(file);
            const anchor = document.createElement('a');

            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = name;
            document.body.appendChild(anchor);
            anchor.click();
            anchor.remove();
            window.URL.revokeObjectURL(blob);
        },
        resetDownloadState() {
            this.showDownloadProgress = false;
            this.downloadProgressMap = {};
            this.downloadProgressPercent = 0;
        },
        resetUploadState() {
            this.showUploadProgress = false;
            this.uploadProgressPercent = 0;
        },
        async getSimilarSlides(serviceId) {
            try {
                if (!serviceId) throw new Error('Invalid serviceId');
                const response = await this.apiService.tributePhoto.getCollectionSimilars(serviceId);

                if (response.data) {
                    return response.data;
                }
            } catch (error) {
                console.log(error, 'error fetching similar photos');
            }
        },
        async getDuplicateSlides(serviceId) {
            try {
                if (!serviceId) throw new Error('Invalid serviceId');
                const response = await this.apiService.tributePhoto.getCollectionDuplicates(serviceId);

                if (response.data) {
                    return response.data;
                }
            } catch (error) {
                console.log(error, 'error fetching duplicate photos');
            }
        },
        async refreshDuplicateAndSimilarSlides(serviceId) {
            const dupes = await this.getDuplicateSlides(serviceId);
            const similars = await this.getSimilarSlides(serviceId);

            this.duplicateSlideGroups = {
                results: dupes?.results || [],
                total: dupes?.total || 0,
            };

            this.similarSlideGroups = {
                results: similars?.results || [],
                total: similars?.total || 0,
            };

            if (dupes?.results) {
                this.totalDuplicateSlides = dupes.results.reduce((acc, current) => acc + current.count, 0);
                this.totalSimilarSlides = similars.results.reduce((acc, current) => acc + current.count, 0);
            }

            const allGroups = [...dupes?.results, ...similars?.results];

            const validGroupKeys = allGroups.map(g => {
                return g.groupKey;
            });

            const validPhotoIds = new Set();
            allGroups.forEach(group => {
                group.photos.forEach(photo => {
                    validPhotoIds.add(photo.id);
                });
            });
            this.selectedGroups = this.selectedGroups
                .filter(group => validGroupKeys.includes(group.groupKey))
                .map(group => {
                    return {
                        ...group,
                        photos: group.photos.filter(photo => validPhotoIds.has(photo.id)),
                    };
                });
        },
    },
    sockets: {
        async NotifyUpload(data) {
            if (data.id != this.eventId) return;

            console.log('upload heard', data);

            if (this.pendingUploads.length > 0) return;

            if (this.pendingReorder || this.pendingDelete || this.pendingRotate) return;

            this.handleSocketRefresh();
        },
        async NotifyTributeJsonRefreshed(data) {
            if (this.tributeVideo.uploadingPhotos) return;

            if (this.tributeVideo?.id && data.id == this.tributeVideo.id) {
                this.handleSocketRefresh();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.manage-photos-container:focus {
    outline: none;
}

.sortable-selected {
    .slide-image {
        border: 4px solid #ea580c;
    }
}

.sortable-drag {
    .slide-image {
        border-radius: 6px;
        transform: rotate(-3deg) !important;
        border: 4px solid #ea580c;
    }
}

.sortable-ghost {
    .slide-image {
        border: 2px solid #ea580c;
    }
}

.manage-photos-container {
    display: flex;
    flex-direction: column;
    @include mobile() {
        flex-direction: column-reverse;
    }
}

.slides-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    overflow-x: auto;
    overflow-y: hidden;
    transition: height 0.8s;
    height: 100%;
}

.slides-container.expanded {
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
    align-content: flex-start;

    @include mobile {
        margin-bottom: 20px;

        .slide,
        .pending-slide {
            width: min(242px, 48%);
            // pointer-events: none;
            height: auto;
            aspect-ratio: 1;
            max-height: 100%;
        }
    }
}

.slide,
.pending-slide {
    height: 100%;
    max-height: 242px;
    min-height: 100px;
    aspect-ratio: 1;
    border-radius: 8px;
    position: relative;
    // transition: transform 0.2s, border 0.2s;
    flex-shrink: 0;
    display: inline-block;
    overflow: hidden;

    .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        z-index: -1;
        background-color: #f1f4f7;
    }
}
.pending-slide {
    img {
        opacity: 0.4;
    }

    .progress-indicator {
        width: 93%;
        display: inline-block;
        height: 5px;
        bottom: 10px;
        position: absolute;
        left: 0;
        right: 0;
        background: #ffedd5;
        margin: auto;
        border-radius: 10rem;
    }

    .progress-slider {
        height: 100%;
        background: #f97316;
        border-radius: 10rem;
        transition: width 200ms ease-in-out;
    }
}

// .slide.dragging {
//     transform: rotate(-3deg) !important;
//     border: 4px solid #ea580c;
// }

// .slide.selected .slide-image {
//     border: 4px solid #ea580c;
// }

.slide-bottom-right {
    position: absolute;
    z-index: 10;
    bottom: 8px;
    right: 8px;
    display: flex;
    gap: 4px;
}

.slide-preview-inner,
.slide-order {
    min-width: 42px;
    height: 32px;
    background: white;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px #0000000d;
    color: #6b7280;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2.5%;
    padding: 0 12px;
}

.slide-preview,
.slide-user {
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    // transition-delay: 200ms;
    opacity: 0;
    transform: translateY(80px);
    @include mobile() {
        opacity: 1 !important;
        transform: translateY(0px) !important;
    }
}
.slide-preview {
    transition-duration: 450ms;
}

.slide:hover,
.slide.sortable-selected {
    .slide-preview,
    .slide-user {
        transform: translateY(0px);
        opacity: 1;
    }
}

.slide.show-upload-user {
    .slide-user {
        transform: translateY(0px);
        opacity: 1;
    }
}

.orange-label {
    color: $primary-orange;
    border-color: $primary-orange;
}

.deep-gray-label {
    color: $deep-gray;
    border-color: $deep-gray;
}

.slide-user {
    position: absolute;
    background: $light-gray;
    bottom: 10px;
    left: 10px;
    // width: 32px;
    overflow: hidden;
    min-width: 50px;
    max-width: 110px;
    border-radius: 20px;
    height: 32px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    text-transform: capitalize;
    border: 2px solid;
    padding: 0 12px;

    font-size: 0.8rem;
    @include mobile() {
        max-width: min(110px, 57%);
    }

    .slide-user-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        gap: 4px;

        span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0;
            margin-top: 2px;
        }
    }
}

.dupe-icon {
    font-size: 1rem;
    color: orange;
}

.slide-top-left {
    z-index: 10;
    top: 8px;
    left: 8px;
    position: absolute;
    display: flex;
    // flex-direction: column;
    flex-direction: row;
    gap: 4px; // align-items: flex-start;

    .duration {
        margin-left: 18px;
        text-wrap: nowrap;
        &:before {
            content: '';
            background: lighten($medium-gray, 16%);
            width: 2px;
            height: 10px;
            bottom: 0;
            position: absolute;
            top: 0;
            margin: auto;
            border-radius: 4px;
            transform: translateX(-10px);
        }
    }
}

.group-highlight {
    .slide-image {
        border: 8px solid orange !important;
    }
}

.selected-indicator {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    background: #ea580c;
    border: 1.5px solid #ea580c;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ghost-hidden {
    display: none !important;
}

.ghost-slide {
    // width: 242px;
    // height: 242px;
    height: 100%;
    max-height: 242px;
    min-height: 100px;
    aspect-ratio: 1;
    border: 1px dashed #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    flex-shrink: 0;
}

.ghost-icon-container {
    width: 52px;
    height: 38px;
    background: #fff7ed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.ghost-icon {
    width: 20px;
    height: 20px;
}

.center-delete-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: $medium-red;
    color: white;
    aspect-ratio: 1;
    border-radius: 50%;
    max-width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    opacity: 0;
    transition: 0.3s;
}

.slide:hover .center-delete-btn {
    opacity: 1;
}

.delete-modal-thumb-grid {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    max-height: 300px;
    overflow: auto;

    .slide-image {
        border-radius: 8px;
        height: 70px;
        width: 70px;
    }
}

::v-deep {
    .v-carousel {
        width: fit-content;
    }
    .v-dialog.v-dialog--active:has(.preview-modal) {
        width: auto;
        box-shadow: none;
    }
    .v-window__next,
    .v-window__prev {
        background: none;
    }
    .carousel-btn {
        .v-btn__content {
            color: $primary-grey;
            font-size: 1.4rem;
            font-weight: bold;
        }
        &.v-btn.v-size--default {
            border: 2px solid $primary-grey;
            padding: 0;
            min-width: 36px;
            width: 48px;
        }
    }

    .v-skeleton-loader__image {
        height: 100%;
    }
}

.preview-modal {
    .slide-toolbar {
        display: flex;
        gap: 10px;
        justify-content: end;
        background: $light-gray;

        .v-btn.v-size--default {
            border: 2px solid lighten($primary-grey, 10%) !important;
            padding: 0;
            min-width: 36px;
            width: 48px;
        }

        .edit-button,
        .rotate-button,
        .delete-button {
            &:has(button[disabled='disabled']) {
                cursor: not-allowed;
            }
        }
    }
}
.carousel-content {
    display: flex;
    justify-content: center;
    height: 100%;

    &::v-deep {
        .v-responsive__content {
            width: fit-content;
        }
    }
    img {
        object-fit: contain;
    }
}
.modal-close-btn {
    position: fixed;
    top: 10px;
    right: 10px;
    margin: auto;
    width: auto;
    &.v-btn:not(.v-btn--round).v-size--default {
        padding: 0;
        min-width: 36px;
        width: 48px;
    }
    @include mobile() {
        bottom: 60px;
        left: 0;
        right: 0;
        top: auto;
    }
}
.v-carousel .edit-button {
    position: absolute;
    left: auto;
    right: 10px;
    top: 10px;
    margin: auto;
    z-index: 99;
    width: fit-content;

    .v-btn:not(.v-btn--round).v-size--default {
        padding: 0;
        min-width: 36px;
        width: 48px;
    }
}
.slide-delete-modal {
    &::v-deep {
        .modal-header {
            justify-content: start;
            margin-bottom: 0;
            .icon {
                height: 32px;
                margin-right: 4px;
            }
        }
        .modal-header,
        .modal-content,
        .modal-footer {
            border: none;
        }
    }
}
.image-error-div {
    min-height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.deadline-reminder {
    background: #f3f4f6;
    border-radius: 20px;
    padding: 5px 16px;
    display: flex;
    align-items: center;
    gap: 8px;

    &::before {
        content: '';
        display: inline-block;
        background: $primary-grey;
        width: 6px;
        height: 6px;
        border-radius: 100%;
    }
    &.active {
        &::before {
            background: $medium-green;
        }
    }
    &.expired {
        &::before {
            background: $medium-red;
        }
    }
}
</style>
