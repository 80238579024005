import axios from 'axios';

const blobAxios = axios.create();

const CHUNK_SIZE = 4 * 1024 * 1024; // 4MB

const BlobService = {
    upload(endpoint, file, config = {}) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            let fileData = file?.data ? file.data : file;
            reader.readAsArrayBuffer(fileData);
            reader.addEventListener('error', error => reject(error));
            reader.addEventListener('load', async () => {
                try {
                    const response = await blobAxios.put(endpoint, reader.result, {
                        headers: {
                            'Content-Type': file?.type ? file.type : '',
                            'x-ms-blob-type': 'BlockBlob',
                        },
                        ...config,
                    });
                    resolve(response.data);
                } catch (error) {
                    reject(error);
                }
            });
        });
    },
    async uploadInBlocks(endpoint, file, config = {}) {
        const blockIds = [];
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        const onUploadProgress = config.onUploadProgress;
        const cancelToken = config.cancelToken;

        let totalUploaded = 0;

        for (let i = 0; i < totalChunks; i++) {
            const start = i * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);
            const chunk = file.slice(start, end);
            const blockId = this.generateBlockId(i);

            const chunkUrl = `${endpoint}&comp=block&blockid=${blockId}`;

            await blobAxios.put(chunkUrl, chunk, {
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': file.type || 'application/octet-stream',
                },
                // onUploadProgress,
                cancelToken,
            });

            blockIds.push(blockId);
            totalUploaded += chunk.size;

            const percent = (totalUploaded / file.size) * 100;

            //overall progress
            if (typeof onUploadProgress === 'function') {
                onUploadProgress({
                    loaded: totalUploaded,
                    total: file.size,
                    percent: percent,
                });
            }
        }

        await this.commitBlockList(endpoint, blockIds, file, cancelToken);
    },
    generateBlockId(index) {
        return btoa(String(index).padStart(6, '0'));
    },
    async commitBlockList(endpoint, blockIds, file, cancelToken) {
        const commitUrl = `${endpoint}&comp=blocklist`;
        const blockListXml = this.buildBlockListXml(blockIds);

        await blobAxios.put(commitUrl, blockListXml, {
            headers: {
                'x-ms-blob-content-type': file.type || 'application/octet-stream',
                'Content-Type': 'application/xml',
            },
            cancelToken,
        });
    },
    buildBlockListXml(blockIds) {
        return `<?xml version="1.0" encoding="utf-8"?>
<BlockList>
${blockIds.map(id => `<Latest>${id}</Latest>`).join('\n')}
</BlockList>`;
    },
};

export default BlobService;
