<template>
    <div :class="['tribute-view', { confetti: confetti }]">
        <template v-if="!pinError">
            <TributeStepper
                :key="stepperRefreshKey"
                :disabled="!tributeVideo.id || conditionallyDisableForContributors"
                :currentStep="currentStep"
                :stepData="steps"
                @change-step="val => handleChangeStep(val)"
                @init-rerender="initRerender"
            />

            <div class="main-content" :class="[`section-${currentStep}`, { expanded: tributeEditorExpanded }]">
                <!-- STEP CONTENT: START -->
                <div class="step-content">
                    <ChangelogPanel
                        v-if="token && tributeVideo.id"
                        v-show="currentStep !== 'setup'"
                        class="my-3"
                        :tributeVideoId="tributeVideo.id"
                        @init-rerender="initRerender"
                    >
                        <template #activator="{ onClick }">
                            <v-fade-transition>
                                <div v-show="showOutOfSyncWarning">
                                    <div
                                        @click="onClick"
                                        class="rounded-pill d-flex align-center out-of-sync-warning-outer"
                                    >
                                        <div class="rounded-pill changes-detected-pill">
                                            <span>Changes Detected</span>
                                        </div>
                                        <span>{{ outOfSyncChanges.length }} changes since last render </span>
                                        <font-awesome-icon
                                            class="mr-2"
                                            style="font-size: 1rem; color: #f97316"
                                            icon="fa-regular fa-chevron-right"
                                        ></font-awesome-icon>
                                    </div>
                                </div>
                            </v-fade-transition>
                        </template>
                    </ChangelogPanel>

                    <template v-if="token && $route.params.slug">
                        <TributeSetupForContributors
                            v-if="isFamilyPage || isContributorPage"
                            v-show="currentStep === 'setup'"
                            :serviceSlug="$route.params.slug"
                            :tributeVideo="tributeVideo"
                            @next-step="handleChangeStep(nextStep.hash)"
                            @setAnonUserDetails="handleSetAnonUserDetails"
                        />
                        <TributeSetup
                            v-else
                            v-show="currentStep === 'setup'"
                            :isActiveTab="currentStep === 'setup'"
                            :serviceSlug="$route.params.slug"
                            :tributeVideo="tributeVideo"
                            @tribute-video-created="data => handleTributeCreated(data)"
                            @next-step="handleChangeStep(nextStep.hash)"
                            @setAnonUserDetails="handleSetAnonUserDetails"
                        />
                    </template>

                    <ManageSummary
                        ref="manageSummary"
                        v-show="currentStep === 'create'"
                        v-if="token && tributeVideo.id"
                        :tributeVideo="tributeVideo"
                        :slideChanges="slideChanges"
                        :mainPhotoChanges="mainPhotoChanges"
                        :themeChanges="themeChanges"
                        :musicChanges="musicChanges"
                        @render-started="onRenderStarted"
                    >
                        <template v-slot:previewPlayer>
                            <div class="center-column">
                                <div class="preview-player">
                                    <div class="preview-player-inner">
                                        <CreatomatePreview
                                            class="flex-grow-1"
                                            ref="creatomatePreview"
                                            :source="previewSource"
                                            :modifications="previewMods"
                                        />
                                    </div>
                                </div>
                            </div>
                        </template>
                    </ManageSummary>

                    <div v-show="currentStep === 'final-video'" class="w-100 final-video-container">
                        <div class="d-flex flex-column align-center">
                            <ManageFinalVideo
                                v-if="tributeVideo.id"
                                :tributeVideo="tributeVideo"
                                :isActiveTab="currentStep === 'final-video'"
                            />
                        </div>
                    </div>

                    <div
                        v-show="!['setup', 'final-video', 'create'].includes(currentStep)"
                        :class="['middle-content', { expanded: tributeEditorExpanded }]"
                    >
                        <div class="action-column">
                            <v-btn @click="handleChangeStep(prevStep.hash)" :disabled="!prevStep">
                                <v-icon left> mdi-chevron-left </v-icon>
                                Prev
                            </v-btn>
                        </div>

                        <div v-show="!tributeEditorExpanded" class="center-column">
                            <div class="preview-player" v-if="!isMobile">
                                <div class="preview-player-inner">
                                    <TributeRenderOverlay
                                        v-if="showRenderOverlay"
                                        @playPreview="playPreview"
                                        @pausePreview="pausePreview"
                                        @change-step="val => handleChangeStep(val)"
                                    ></TributeRenderOverlay>

                                    <CreatomatePreview
                                        class="flex-grow-1"
                                        ref="creatomatePreview"
                                        v-show="currentStep !== 'final-video'"
                                        :source="previewSource"
                                        :modifications="previewMods"
                                    />
                                </div>
                            </div>
                        </div>

                        <v-btn
                            v-if="tributeEditorExpanded && !isMobile"
                            @click="togglePreview"
                            color="white"
                            class="text-normal"
                            style="max-width: 500px; width: 30%"
                        >
                            <font-awesome-icon icon="fa-regular fa-play-circle" class="fa-lg mr-2 play-icon" />
                            Preview Video
                        </v-btn>
                        <div class="action-column">
                            <v-btn
                                class="next-btn"
                                @click="handleChangeStep(nextStep.hash)"
                                v-show="nextStep"
                                :disabled="currentStep === 'setup'"
                            >
                                Next
                                <v-icon right> mdi-chevron-right </v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- STEP CONTENT: START -->

                <!-- LOWER WORKSPACE: START -->
                <div class="workspace">
                    <v-tabs-items
                        v-show="currentStep !== 'setup'"
                        v-model="currentStep"
                        touchless
                        :touch="tabTouchEvents"
                    >
                        <v-tab-item transition="none" value="slides">
                            <ResizableWorkspace v-if="tributeVideo.eventId">
                                <ManagePhotos
                                    :eventId="tributeVideo.eventId"
                                    :tributeVideo="tributeVideo"
                                    @pause-preview="pausePreview"
                                    @refresh-preview="debouncedGeneratePreviewJson(tributeVideo.id)"
                                />
                            </ResizableWorkspace>
                        </v-tab-item>
                        <v-tab-item transition="none" :value="'theme'">
                            <ResizableWorkspace v-if="tributeVideo.eventId && tributeVideo.id">
                                <ManageThemes
                                    :eventId="tributeVideo.eventId"
                                    :tributeVideo="tributeVideo"
                                    @pause-preview="pausePreview"
                                    @refresh-preview="debouncedGeneratePreviewJson(tributeVideo.id)"
                                />
                            </ResizableWorkspace>
                        </v-tab-item>
                        <v-tab-item transition="none" :value="'music'">
                            <ResizableWorkspace v-if="tributeVideo.id">
                                <ManageMusic
                                    :eventId="tributeVideo.eventId"
                                    @refresh-preview="debouncedGeneratePreviewJson(tributeVideo.id)"
                                    :tributeVideo="tributeVideo"
                                />
                            </ResizableWorkspace>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
                <!-- LOWER WORKSPACE: END -->

                <v-overlay opacity="0.7" v-model="showEditModeOverlay" absolute style="z-index: 11">
                    <div class="d-flex flex-column align-items-center">
                        <v-card color="white" class="p-2 m-3" max-width="500">
                            <v-card-title style="color: rgba(0, 0, 0, 0.87)"> Enable Edit Mode? </v-card-title>
                            <v-card-text style="color: rgba(0, 0, 0, 0.6)"
                                >This tribute video has been finalized. Do you want to make changes?
                            </v-card-text>

                            <v-card-actions class="d-flex justify-center">
                                <v-btn
                                    class="primary-btn"
                                    @click="confirmEditModeDismissModal = true"
                                    depressed
                                    color="#ff530d"
                                    >Make Changes</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </div>
                </v-overlay>
            </div>

            <MiniMusicPlayer :token="token" v-if="token" />
        </template>
        <template v-else>
            <div class="tribute-error-display">
                <img src="@/assets/tribute-logo.png" alt="Logo" />
                <div>{{ pinError ? 'Incorrect pin' : '????' }}</div>
            </div>
        </template>

        <v-dialog v-model="confirmEditModeDismissModal" max-width="500">
            <v-card>
                <v-card-title> Edit Finalized Tribute Video? </v-card-title>

                <v-card-text>
                    This tribute video has already been finalized. Any changes made will require a rerender to be seen
                    in the final video, and may incure an additional render fee. Please confirm to continue.
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn class="secondary-btn" @click="confirmEditModeDismissModal = false" depressed>Cancel</v-btn>
                    <v-btn class="error-btn" @click="dismissEditModeWarning" depressed color="error">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import initApiServices from '@/services/ApiInitializer';
import TributeVideoService from '@/services/tributeVideo.service';
import { mapActions, mapGetters } from 'vuex';

import TributeStepper from '@/components/Tribute/TributeStepper.vue';
import TributeSetup from '@/components/Tribute/TributeSetup.vue';
import TributeSetupForContributors from '@/components/Tribute/TributeSetupForContributors.vue';
import ManagePhotos from '@/components/Tribute/ManagePhotos.vue';
import ManageThemes from '@/components/Tribute/ManageThemes.vue';
import MiniMusicPlayer from '@/components/Tribute/Music/MiniMusicPlayer.vue';
import CreatomatePreview from '@/components/Tribute/Themes/CreatomatePreview.vue';
import ManageMusic from '@/components/Tribute/ManageMusic.vue';
import StepCreate from '@/components/Tribute/StepCreate.vue';
import ManageFinalVideo from '@/components/Tribute/ManageFinalVideo.vue';
import ResizableWorkspace from '@/components/Tribute/Layout/ResizableWorkspace.vue';
import TributeRenderOverlay from '@/components/Tribute/RenderOverlay/RenderOverlay.vue';
import ChangelogPanel from '@/components/Tribute/Layout/ChangelogPanel.vue';
import ManageSummary from '@/components/Tribute/ManageSummary.vue';
import { tributeChangelogActionEnums, creatomateRenderStatusEnum } from '../../constants';
import { debounce, find, findIndex, isEmpty } from 'lodash';

const renderStatusDict = ['Planned', 'Rendering', 'Succeeded', 'Failed', 'Deleted'];

export default {
    name: 'TributeView',
    metaInfo: {
        title: 'Tribute Video',
    },
    components: {
        TributeStepper,
        TributeSetup,
        TributeSetupForContributors,
        ManagePhotos,
        CreatomatePreview,
        ManageThemes,
        ManageMusic,
        MiniMusicPlayer,
        StepCreate,
        ResizableWorkspace,
        TributeRenderOverlay,
        ChangelogPanel,
        ManageFinalVideo,
        ManageSummary,
    },
    provide() {
        const tributeRender = {};
        const state = {};

        Object.defineProperty(tributeRender, 'standardRender', {
            enumerable: true,
            get: () => this.standardRender,
        });
        Object.defineProperty(tributeRender, 'publicRender', {
            enumerable: true,
            get: () => this.publicRender,
        });

        Object.defineProperty(state, 'isFamily', {
            enumerable: true,
            get: () => this.isFamilyPage,
        });
        Object.defineProperty(state, 'isContributor', {
            enumerable: true,
            get: () => this.isContributorPage,
        });
        Object.defineProperty(state, 'token', {
            enumerable: true,
            get: () => this.token,
        });
        Object.defineProperty(state, 'anonUserDetails', {
            enumerable: true,
            get: () => this.anonUserDetails,
        });
        Object.defineProperty(state, 'steps', {
            enumerable: true,
            get: () => this.steps,
        });
        Object.defineProperty(state, 'isMobile', {
            enumerable: true,
            get: () => this.isMobile,
        });
        Object.defineProperty(state, 'preview', {
            enumerable: true,
            get: () => ({
                mods: this.previewMods,
                source: this.previewSource,
            }),
        });
        return {
            tributeRender,
            state,
        };
    },
    computed: {
        showOutOfSyncWarning() {
            return this.outOfSyncChanges.length > 0 && !this.tributeEditorExpanded;
        },
        prevStep() {
            const curStepIndex = findIndex(this.steps, { hash: this.currentStep });
            if (this.steps?.[curStepIndex - 1]) {
                return this.steps?.[curStepIndex - 1];
            }
            return null;
        },
        nextStep() {
            const curStepIndex = findIndex(this.steps, { hash: this.currentStep });
            if (this.steps[curStepIndex + 1]) {
                return this.steps[curStepIndex + 1];
            }
            return null;
        },
        lastStep() {
            return this.steps[this.steps?.length - 1];
        },
        currentStepItem() {
            const currentStep = find(this.steps, { hash: this.currentStep });
            return currentStep;
        },
        currentStepIndex() {
            return findIndex(this.steps, { hash: this.currentStep });
        },
        steps() {
            if (this.isFamilyPage) {
                return this.stepData.filter(step => step.family && !step.hidden);
            }
            if (this.isContributorPage) {
                return this.stepData.filter(step => step.contributor && !step.hidden);
            }
            return this.stepData.filter(step => step.default && !step.hidden);
        },
        isFamilyPage() {
            return this.$route.name === 'TributeVideoFamily';
        },
        isContributorPage() {
            return this.$route.name === 'TributeVideoContributor';
        },
        showRenderOverlay() {
            // Show overlay if the following is true
            // we're on step 5
            // we've started and/or completed a render
            //
            if (this.currentStepIndex > 0) {
                if (this.$store.state.tributeVideo.overlayDismissed) {
                    return false;
                }
                if (this.tributeIsRendering) {
                    return true;
                }
                if (this.$store.state.tributeVideo.renderStarted && this.$store.state.tributeVideo.renderCompleted) {
                    return true;
                }
            }
            return false;
        },
        tributeEditorExpanded() {
            return this.$store.state.tributeEditor.expanded;
        },
        tributeVideo: {
            get() {
                return this.$store.state.tributeVideo;
            },
            set(val) {
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    ...val,
                });
            },
        },
        ...mapGetters('tributeVideo', {
            minimumRequirementsMet: 'minimumCreateRequirementsMet',
            tributeIsRendering: 'tributeIsRendering',
        }),
        conditionallyDisableForContributors() {
            // return true to disable
            if (this.isContributorPage || this.isFamilyPage) {
                if (!this.anonUserDetails?.name?.length) {
                    return true;
                }
                if (!this.anonUserDetails?.relationship.length) {
                    return true;
                }
            }
            return false;
        },
        isMobile() {
            this.windowResizeCounter;
            const tmpIsMobile = window.matchMedia(`only screen and (((max-width: 768px) and (orientation: portrait))
                or ((max-height: 768px) and (orientation: landscape)))`).matches;
            console.log({ tmpIsMobile });
            return tmpIsMobile;
        },
        showEditModeOverlay() {
            if (!this.standardRender?.id) {
                return false;
            }

            if (['final-video'].includes(this.currentStep)) {
                return false;
            }

            if (this.showRenderOverlay && this.tributeIsRendering) {
                return false;
            }

            return !this.editModeWarningDismissed;
        },
    },
    data() {
        return {
            creatomateRenderStatusEnum,
            currentStep: this.$route.params.section || 'setup',
            // tributeVideo: null,
            standardRender: null,
            publicRender: null,
            apiService: null,
            token: null,
            previewSource: null,
            previewMods: null,
            editMode: false,
            loading: true,
            outOfSyncChanges: [],
            slideChanges: [],
            mainPhotoChanges: [],
            themeChanges: [],
            musicChanges: [],
            anonUserDetails: null,
            pinError: false,
            stepperRefreshKey: 0,
            confetti: false,
            confettiFired: false,
            confirmEditModeDismissModal: false,
            editModeWarningDismissed: false,
            stepData: [
                {
                    name: "Person's Detail",
                    hash: 'setup',
                    default: true,
                    family: true,
                    contributor: true,
                    hidden: false,
                },
                {
                    name: 'Manage Slides',
                    hash: 'slides',
                    default: true,
                    family: true,
                    contributor: true,
                    hidden: false,
                },
                {
                    name: 'Set Theme',
                    hash: 'theme',
                    default: true,
                    family: true,
                    contributor: false,
                    hidden: false,
                },
                {
                    name: 'Add Music',
                    hash: 'music',
                    default: true,
                    family: true,
                    contributor: false,
                    hidden: false,
                },
                {
                    name: 'Summary',
                    hash: 'create',
                    default: true,
                    family: false,
                    contributor: false,
                    hidden: false,
                },
                {
                    name: 'Summary',
                    hash: 'create',
                    default: false,
                    family: true,
                    contributor: true,
                    hidden: false,
                },
                {
                    name: 'Final Video',
                    hash: 'final-video',
                    default: true,
                    family: true,
                    contributor: true,
                    hidden: true,
                },
            ],
            tabTouchEvents: {
                left(evt) {
                    console.log(evt);
                },
                right(evt) {
                    console.log(evt);
                },
            },
            windowResizeCounter: 0,
        };
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeEditor', ['toggleExpandedState', 'setExpandedState']),

        dismissEditModeWarning() {
            this.confirmEditModeDismissModal = false;
            this.editModeWarningDismissed = true;
        },
        startConfetti() {
            this.confetti = true;
            this.confettiFired = true;
            setTimeout(() => {
                this.confetti = false;
            }, 2140);
        },
        initRerender() {
            this.handleChangeStep('create');

            setTimeout(() => {
                const ref = this.$refs.manageSummary;
                if (ref && typeof ref.confirmRenderIfNeeded === 'function') {
                    ref.confirmRenderIfNeeded();
                }
            }, 200);
        },
        handleChangeStep(val) {
            // if (val < 0) return;
            // if (val > 5) return;
            if (!this.tributeVideo.id) return;

            this.currentStep = val;
            // Pause preview player if currently rendering and going back into the 5th section
            if (this.currentStep === this.lastStep.hash) {
                if (this.tributeIsRendering) {
                    this.pausePreview();
                }
            }
        },
        handleSetAnonUserDetails(userDetails) {
            this.anonUserDetails = userDetails;
            localStorage.setItem('tributeContributor', JSON.stringify(userDetails));
        },
        togglePreview() {
            this.toggleExpandedState();
        },
        onRenderStarted() {
            // Do stuff when render starts
            this.pausePreview();
        },
        playPreview() {
            const previewInstance = this.$refs.creatomatePreview;
            if (previewInstance && previewInstance.playVideo) {
                previewInstance.playVideo();
            }
        },
        pausePreview() {
            const previewInstance = this.$refs.creatomatePreview;
            if (previewInstance && previewInstance.pauseVideo) {
                previewInstance.pauseVideo();
            }
        },
        createTributeVideo() {
            // If not on the last step take them there first
            // then after delay try and trigger the render
            if (this.currentStep !== this.lastStep.hash) {
                this.currentStep = this.lastStep.hash;
            }
            this.$nextTick(() => {
                this.$refs.createStep.submitTributeRender();
            });
        },
        handleTributeCreated(data) {
            this.tributeVideo = data;

            if (this.tributeVideo.eventId) {
                this.handleChangeStep(this.nextStep.hash);
            }

            if (this.tributeVideo.id) {
                this.debouncedGeneratePreviewJson(this.tributeVideo.id);
            }

            this.tryFetchTributeVideo(this.$route.params.slug);
        },
        async setAuthToken() {
            if (this.isContributorPage || this.isFamilyPage) {
                const tmpApi = TributeVideoService(null);
                try {
                    const { data } = await tmpApi.getTributeToken(this.$route.params.slug, this.$route.params.pin);

                    if (data && data.token) {
                        switch (data.role) {
                            case 'Tribute':
                                if (!this.isContributorPage) {
                                    // Don't allow them to continue show error page
                                    this.pinError = true;
                                    return false;
                                }
                                break;
                            case 'TributeAdmin':
                                if (!this.isFamilyPage) {
                                    // Don't allow them to continue show error page
                                    this.pinError = true;
                                    return false;
                                }
                                break;
                        }
                        this.token = data.token;
                        this.role = data.role;
                    }
                } catch (error) {
                    console.error(error);
                    this.pinError = true;
                }
            } else {
                const response = await this.$auth.getIdTokenClaims();
                if (response && response.__raw) {
                    this.token = response.__raw;
                }
            }
        },
        async tryFetchTributeVideo(slug) {
            try {
                this.block(true);
                const resp = await this.apiService.tributeVideo.getTributeVideoByServiceSlug(slug);
                if (resp.data) {
                    this.tributeVideo = resp.data.tributeVideo;
                    this.standardRender = resp.data.standardRender;

                    if (this.standardRender?.id) {
                        const finalVidStep = find(this.stepData, { hash: 'final-video' });
                        if (finalVidStep) {
                            finalVidStep.hidden = false;
                            this.stepperRefreshKey++;
                        }
                    }

                    this.publicRender = resp.data.copyrightSafeRender;

                    if (this.isContributorPage || this.isFamilyPage) {
                        if (isEmpty(this.anonUserDetails)) {
                            // send to setup
                            this.currentStep = 'setup';
                        }
                    } else {
                        if (this.standardRender) {
                            // console.log('RENDER READY', this.standardRender);
                            if (this.$route.params.section) {
                                this.currentStep = this.$route.params.section;
                            } else if (this.currentStep !== this.lastStep.hash) {
                                this.currentStep = this.lastStep.hash;
                            }
                        } else {
                            this.currentStep = this.$route.params.section || 'setup';
                        }
                    }

                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        ...this.tributeVideo,
                    });
                } else {
                    const serviceResp = await this.apiService.services.getBySlug(slug);
                    if (serviceResp.data) {
                        this.$store.dispatch('tributeVideo/updateTributeVideo', {
                            firstName: serviceResp.data.firstName,
                            lastName: serviceResp.data.lastName,
                        });
                    }
                }
            } catch (error) {
                console.error('Error fetching tribute video', error);
            } finally {
                this.block(false);
            }
        },
        async getSelectedPhotos() {
            let payload = {
                reversed: false,
                pageNumber: 0,
                pageSize: 25,
            };

            if (!this.tributeVideo?.eventId) {
                return;
            }
            const { data } = await this.apiService.tributePhoto.getPhotos(this.tributeVideo.eventId, payload);
            if (data?.total > 0) {
                this.$store.dispatch('tributeVideo/updateTributeVideoSelectedPhotos', data.photos);
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    ...this.tributeVideo,
                    totalSlidesCount: data.total,
                });
            }
        },
        async getSelectedThemes() {
            if (!this.tributeVideo?.id) {
                return;
            }
            const { data } = await this.apiService.tributeTemplate.getSelected(this.tributeVideo.id);
            if (data.templates?.length) {
                this.$store.dispatch('tributeVideo/updateTributeVideoSelectedTemplates', data.templates);
            }
        },
        async getSelectedSongs() {
            if (!this.tributeVideo?.id) {
                return;
            }
            const { data: songs } = await this.apiService.tributeSong.getSelected(this.tributeVideo.id);
            if (songs?.length) {
                this.$store.dispatch('tributeVideo/updateTributeVideoSelectedSongs', songs);
            }
        },
        async checkOutOfSync(tributeRenderId) {
            const { data: res } = await this.apiService.tributeVideo.checkOutOfSync(tributeRenderId);

            if (res.outOfSyncChanges) {
                this.outOfSyncChanges = res.outOfSyncChanges;
            }

            this.mainPhotoChanges = this.outOfSyncChanges.filter(
                x => x.action === tributeChangelogActionEnums.MAIN_PHOTO_UPDATE,
            );
            this.slideChanges = this.outOfSyncChanges.filter(x => x.tableName === 'TributeVideoPhoto');
            this.themeChanges = this.outOfSyncChanges.filter(x => x.tableName === 'SelectedTemplates');
            this.musicChanges = this.outOfSyncChanges.filter(x => x.tableName === 'SelectedSongs');
        },
        async tryGeneratePreviewJson(tributeId) {
            try {
                if (this.tributeVideo.uploadingPhotos) return;

                if (!tributeId) {
                    throw new Error('Invalid tribute video id');
                }

                var resp = await this.apiService.tributeVideo.generatePreviewJson(tributeId);

                if (resp.data.duration) {
                    // store the duration on the tribute video store
                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        totalDuration: resp.data.duration,
                        slideDuration: resp.data.slideDuration,
                        introDuration: resp.data.intoDuration,
                        endingDuration: resp.data.endingDuration,
                        selectedAnimation: resp.data.selectedAnimation,
                        selectedTransition: resp.data.selectedTransition,
                        autoSlideDuration: resp.data.autoSlideDuration,
                    });
                }
                if (resp.data.creatomateJson) {
                    const creatomateJson = JSON.parse(resp.data.creatomateJson);

                    if (!creatomateJson.source) throw new Error('Error parsing preview source');

                    this.previewSource = creatomateJson.source;
                    console.log(this.previewSource, 'previewSource');

                    if (creatomateJson.modifications) {
                        this.previewMods = creatomateJson.modifications;
                    }
                }
            } catch (error) {
                console.log(error, 'error');
            }
        },
        updateProfileImageInStore(id, url) {
            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                mainPhotoUrl: url,
                mainPhotoId: id,
            });
        },
        async getSettings(tributeId) {
            try {
                const resp = await this.apiService.tributeVideo.getSettings(tributeId);
            } catch (error) {
                console.log(error, 'error getting tribute settings');
            }
        },
        validateRequestedSection() {
            const requestedStep = this.steps.find(x => x.hash === this.$route.params.section);

            if (!requestedStep) {
                this.currentStep = 'setup';
            }
        },
        handleChangelogNotification(data, eventType) {
            if (!this.standardRender?.id || !this.tributeVideo?.id || !this.tributeVideo?.eventId) return;

            switch (eventType) {
                case 'tributeThemeUpdate':
                case 'tributeSongsUpdate':
                case 'tributeRenderSettings':
                    if (!data.tributeId || data.tributeId !== this.tributeVideo.id) return;
                    this.debouncedOutOfSyncRefresh(this.standardRender.id);
                    break;
                case 'mainPhotoReplace':
                    if (!data.id || data.id !== this.tributeVideo.id) return;
                    this.debouncedOutOfSyncRefresh(this.standardRender.id);
                    break;
                case 'notifyUpload':
                    if (!data.id || data.id !== this.tributeVideo.eventId) return;
                    this.debouncedOutOfSyncRefresh(this.standardRender.id);
                    break;
                case 'tributeRender':
                    if (!data.tributeVideoId || data.tributeVideoId !== this.tributeVideo.id);
                    this.debouncedOutOfSyncRefresh(this.standardRender.id);
                    break;
                default:
                    console.log(eventType, 'unhandled changlelog notification type');
                    break;
            }
        },
        resumeRenderProgress() {
            console.log('resuming rendering');
            this.$store.dispatch('tributeVideo/cycleRenderMessages');

            if (this.tributeVideo.totalDuration > 0 && this.standardRender?.updateDate) {
                this.$store.dispatch('tributeVideo/updateRenderProgress', {
                    started: this.standardRender?.updateDate,
                    duration: this.tributeVideo.totalDuration * 2,
                });
            }
        },
        resizeHandler: debounce(function (event) {
            this.windowResizeCounter++;
        }, 500),
    },
    created() {
        this.debouncedGeneratePreviewJson = debounce(this.tryGeneratePreviewJson, 400);
        this.debouncedOutOfSyncRefresh = debounce(this.checkOutOfSync, 1000);
        this.debouncedUpdateProfileInStore = debounce(this.updateProfileImageInStore, 400);
        this.debouncedGetSelectedPhotos = debounce(this.getSelectedPhotos, 400);
    },
    async mounted() {
        this.block(true);
        await this.setAuthToken();
        this.apiService = initApiServices(this.token);

        this.validateRequestedSection();

        if (this.isMobile) {
            // Force expanded state on mobile
            this.setExpandedState(true);
        }
        window.addEventListener('resize', this.resizeHandler);
        if (this.isFamilyPage || this.isContributorPage) {
            // Check localstorage for stored user details
            const tributeUserDetails = localStorage.getItem('tributeContributor');
            if (typeof tributeUserDetails === 'string') {
                this.anonUserDetails = JSON.parse(tributeUserDetails);
            }
        }
        await this.tryFetchTributeVideo(this.$route.params.slug);
        if (this.tributeVideo?.id) {
            await this.tryGeneratePreviewJson(this.tributeVideo.id);
            // Fetch selected Photos, Music, & Themes
            // Photos
            this.getSelectedPhotos();
            // Themes
            this.getSelectedThemes();
            // Music
            this.getSelectedSongs();
        }

        if (this.standardRender) {
            this.checkOutOfSync(this.standardRender.id);

            if (
                this.standardRender.status === creatomateRenderStatusEnum.RENDERING ||
                this.standardRender.status === creatomateRenderStatusEnum.PLANNED
            ) {
                this.resumeRenderProgress();
            }
        }

        await this.$nextTick();
        this.loading = false;
        this.block(false);
    },
    beforeDestroy() {
        this.$store.dispatch('tributeVideo/clearIntervals');
        window.removeEventListener('resize', this.resizeHandler);
    },
    sockets: {
        async NotifyTributeRender(data) {
            if (this.tributeVideo.id == data.tributeVideoId) {
                switch (renderStatusDict[data.status]) {
                    // TODO: See if Planned & Rendering should set the renderOverlay...
                    // Usecase: starting a render in a different tab or by another user?
                    case 'Planned':
                        break;
                    case 'Rendering':
                        break;
                    case 'Succeeded':
                        // this.$nextTick(async () => {
                        // update the tributestore
                        await this.tryFetchTributeVideo(this.$route.params.slug);
                        // We've recieved the socket event, and we've fetched the latest json
                        // So if we have a standard render and url... can we safely assume that it has finished?

                        if (this.standardRender?.url) {
                            this.startConfetti();
                            this.showSnackbar({ message: 'Tribute Video render finished!' });

                            this.$store.dispatch('tributeVideo/setRenderCompleted');
                            this.handleChangeStep('final-video');
                        }
                        // });
                        break;
                    case 'Failed':
                    case 'Delete':
                        // Render failed or was deleted, we should notify user and remove render overlay
                        this.showSnackbar({
                            message: 'Rendering the video has failed. Please wait and try again.',
                            color: 'error',
                        });
                        this.$store.dispatch('tributeVideo/updateTributeVideo', {
                            renderStarted: null,
                            renderCompleted: null,
                            overlayDismissed: true,
                        });

                        break;
                }
            }
            this.handleChangelogNotification(data, 'tributeRender');
        },
        async NotifyTributeJsonRefreshed(data) {
            //Hold preview refresh untill batched upload is finished
            if (this.tributeVideo.uploadingPhotos) return;

            if (this.tributeVideo?.id && data.id == this.tributeVideo.id) {
                this.debouncedGeneratePreviewJson(this.tributeVideo.id);
            }
        },
        NotifyUpload(data) {
            this.handleChangelogNotification(data, 'notifyUpload');

            if (this.tributeVideo.eventId && data.id === this.tributeVideo.eventId) {
                this.debouncedGetSelectedPhotos();
            }
        },
        NotifyMainPhotoReplace(data) {
            this.handleChangelogNotification(data, 'mainPhotoReplace');

            if (this.tributeVideo.id && data.id === this.tributeVideo.id) {
                this.debouncedUpdateProfileInStore(data.mainPhotoId, data.mainPhotoUrl);
            }
        },
        NotifyTributeThemeUpdate(data) {
            this.handleChangelogNotification(data, 'tributeThemeUpdate');
        },
        NotifyTributeSongsUpdate(data) {
            this.handleChangelogNotification(data, 'tributeSongsUpdate');
        },
        NotifyTributeRenderSettings(data) {
            this.handleChangelogNotification(data, 'tributeRenderSettings');
        },
    },
    watch: {
        // Update route with current step as it changes
        currentStep(newVal, oldVal) {
            if (newVal !== oldVal && this.$route.params.section !== newVal) {
                const currentParams = this.$route.params;
                currentParams.section = newVal;
                this.$router.push({ name: this.$route.name, params: currentParams });
            }

            // If user is going to final step, and the workarea is expanded
            // let's collapse the resizableWorkare for them so that the preview is visible
            if (newVal === 'create' && oldVal !== 'create' && this.tributeEditorExpanded && !this.isMobile) {
                this.toggleExpandedState();
            }
            if (oldVal === 'final-video' && newVal !== 'final-video') {
                const playerRef = this.$refs.finalVideoPlayer;

                if (playerRef && playerRef.pause) {
                    playerRef.pause();
                }
            }

            if (oldVal !== 'final-video' && newVal === 'final-video') {
                const previewRef = this.$refs.creatomatePreview;

                if (previewRef && previewRef.pauseVideo) {
                    previewRef.pauseVideo();
                }
            }
        },
        isMobile(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                if (!this.tributeEditorExpanded) {
                    this.setExpandedState(true);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .v-card__title {
        @include tribute-heading;
    }

    button,
    .v-btn,
    .v-btn__content {
        @include no-text-transform;
    }
}

.primary-btn {
    @include tribute-btn($btn-orange, $textColor: white);
}
.secondary-btn {
    @include tribute-btn($light-gray, $borderColor: transparent);
}
.error-btn {
    @include tribute-btn($btn-red, $textColor: white);
}
.tribute-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100dvh;
    box-sizing: border-box;
    background-color: #f3f4f6;
    overflow-y: auto;
    overflow-x: hidden;
    @include mobile() {
        overscroll-behavior: none;
    }
}

.main-content {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile() {
        &.expanded {
            height: auto;
        }
    }
    &.section-create {
        height: auto;
        flex: 1;
    }
    .v-overlay.v-overlay--active {
        @include mobile() {
            // height is full height minus top & bottom navs
            height: calc(100dvh - (72px + 60px));
            position: fixed;
            top: 72px;
        }
    }
}

.step-content {
    width: 100%;
    // border: 2px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-height: 0;
    flex: 1;
    // margin: 12px;
    overflow: auto;
    position: relative;

    &:empty {
        max-height: 8dvh;
    }
    @include mobile() {
        //padding-top: 80px;
    }
}

.final-video-container {
    max-height: 100%;
    margin-top: 15px;
}

.center-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    min-height: 0;
    height: 100%;
    max-height: 100%;
}

.preview-player {
    flex: 0 1 auto;
    min-height: 200px;
    max-height: 100%;
    min-width: fit-content;
    width: 100%;
    aspect-ratio: 16 / 9;
    max-width: min(800px, 100%);
    position: relative;
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 8px;
}

.preview-player-inner {
    overflow: hidden;
    margin: 12px;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 16 / 9;
}

.middle-content {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-height: 100%;
    overflow: hidden;

    .action-column {
        min-width: 120px;
        text-align: center;
        align-content: center;
        flex-shrink: 0;
    }

    &.expanded {
        .preview-player {
            height: 0;
            overflow: hidden;
            display: none;
        }
    }
    @include mobile() {
        &:not(.expanded) {
            flex-direction: row;
            flex-wrap: wrap;

            // .preview-player {
            //     order: 0;
            //     flex: 1 1 100%;
            // }
            .action-column {
                order: 1;
                flex: 1 0 50%;
            }
        }
        &.expanded {
            justify-content: center;
        }
        .action-column {
            display: none;
        }
    }
}
.play-icon {
    // color: $primary-grey;
}
.next-btn {
    background-color: $primary-orange !important;
    color: white !important;
}

.changelog-panel-activator {
    position: fixed;
    bottom: 12px;
    right: 12px;
}

.workspace {
    position: sticky;
    bottom: 0;
    width: 100%;
}

.out-of-sync-warning-outer {
    border: 1px solid #fcd34d;
    background-color: #fffbeb;
    color: #92400e;
    gap: 8px;
    padding: 4px;
    cursor: pointer;
    font-size: 12px;

    .changes-detected-pill {
        background-color: #fef3c7;
        padding: 2px 10px;
    }
}
.loading-overlay {
    display: flex;
    height: 100dvh;
    align-items: center;
}
::v-deep {
    .styled-videojs {
        .vjs-error-display,
        .vjs-modal-dialog-content {
            z-index: 0;
        }
    }
}
.tribute-error-display {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    inset: 0;
    align-items: center;
    justify-content: center;
}

.confetti {
    &:before {
        content: '';
        background: url('https://memoryshareprod.blob.core.windows.net/front-end-assets/confetti.gif') no-repeat bottom
            center;
        background-size: cover;
        inset: 0;
        margin: auto;
        position: absolute;
        z-index: 9;
    }
}
</style>
