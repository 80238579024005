<template>
    <v-dialog 
        v-model="previewActive" 
        v-if="state.preview" 
        transition="dialog-top-transition" 
        @click:outside="closePreview"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn outlined class="preview-btn" v-bind="attrs" v-on="on">
                Preview
                <font-awesome-icon class="ml-1" icon="fa-regular fa-play-circle"></font-awesome-icon>
            </v-btn>
        </template>

        <v-card>
            <CreatomatePreview
                ref="creatomatePreview"
                :source="state.preview.source"
                :modifications="state.preview.mods"
            />

            <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closePreview">Close</v-btn>
            </v-card-actions> -->
        </v-card>
    </v-dialog>
</template>

<script>
import CreatomatePreview from '@/components/Tribute/Themes/CreatomatePreview.vue';

export default {
    name: 'MobilePreview',
    components: { CreatomatePreview },
    inject: ['state'],
    data() {
        return {
            previewActive: false
        }
    },
    methods: {
        closePreview() {
            // pause preview on close
            this.previewActive = false;
            const previewInstance = this.$refs.creatomatePreview;
            if (previewInstance && previewInstance.pauseVideo) {
                previewInstance.pauseVideo();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.preview-btn {
    &::v-deep {
        border-color: $medium-gray;
        padding: 0 8px !important;
        .v-btn__content {
            letter-spacing: normal;
            text-transform: capitalize;
            color: $deep-gray;
        }
    }
}
// .text-normal {
// }
</style>
