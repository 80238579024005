const collapsedHeight = '34dvh';
const expandedHeight = 'calc(100dvh - 150px)';
export default {
    namespaced: true,
    state: {
        expanded: false,
        workspaceHeight: collapsedHeight,
    },
    mutations: {
        toggleExpandedState(state) {
            state.expanded = !state.expanded;
            state.workspaceHeight = state.expanded ? expandedHeight : collapsedHeight;
        },
        setExpandedState(state, isExpanded) {
            state.expanded = isExpanded;
            state.workspaceHeight = state.expanded ? expandedHeight : collapsedHeight;
        },
        setWorkspaceHeight(state, height) {
            state.workspaceHeight = height;
        },
    },
    actions: {
        toggleExpandedState({ commit }) {
            commit('toggleExpandedState');
        },
        setExpandedState({ commit }, isExpanded) {
            commit('setExpandedState', isExpanded);
        },
    },
};
