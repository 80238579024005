<template>
    <v-dialog transition="slide-x-reverse-transition" content-class="changelog-modal " v-model="show">
        <v-card flat class="flex-card mx-auto">
            <div class="title">
                <v-card-title>
                    <slot name="title"> </slot>
                </v-card-title>
                <v-divider class="mb-0"></v-divider>
            </div>
            <div class="content">
                <slot name="content"></slot>
            </div>
            <div class="actions">
                <v-divider class="mt-0"></v-divider>
                <v-card-actions>
                    <slot name="actions"> </slot>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .changelog-modal {
    position: fixed;
    right: 0;
    width: 850px;
    max-width: calc(100% - 8px);
    min-height: calc(100vh - 8px);
    padding: 0;
    display: flex;
    flex-direction: column;
}

::v-deep .v-dialog {
    margin: 4px !important;
}

.flex-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
}

.title {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 3;
    background-color: white;
}

.content {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.actions {
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 3;
    background-color: white;
}
</style>
