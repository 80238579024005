<template>
    <div>
        <v-data-table
            :options.sync="options"
            :headers="headers"
            :items="items"
            :items-per-page="options.itemsPerPage"
            :loading="loading"
            :server-items-length="total"
        >
            <template v-for="header in headers" v-slot:[`item.${header.value}`]="slotProps">
                <template v-if="$scopedSlots[`item.${header.value}`]">
                    <slot :name="`item.${header.value}`" v-bind="slotProps"></slot>
                </template>
                <template v-else> {{ slotProps.item[header.value] }} </template>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        headers: {
            type: Array,
            required: true,
        },
        fetchFunction: {
            type: Function,
            required: true,
        },
        fetchArgs: {
            type: [Object, Function],
            default: () => ({}),
        },
    },

    data() {
        return {
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
            },
            items: [],
            loading: false,
            total: 0,
        };
    },
    watch: {
        options: {
            deep: true,
            handler() {
                this.fetchData();
            },
        },
    },
    methods: {
        async fetchData() {
            this.loading = true;

            try {
                const { page, itemsPerPage, sortBy, sortDesc } = this.options;

                const paginationParams = {
                    pageNumber: page > 0 ? page - 1 : 0,
                    pageSize: itemsPerPage,
                    sortField: sortBy.length > 0 ? sortBy[0] : 'id',
                    sortAsc: sortDesc.length > 0 ? !sortDesc[0] : false,
                };

                const extraArgs = typeof this.fetchArgs === 'function' ? this.fetchArgs() : this.fetchArgs;
                const result = await this.fetchFunction(Object.assign({}, paginationParams, extraArgs));

                this.items = result.responses || result.items || [];
                this.total = typeof result.total === 'number' ? result.total : 0;
            } catch (err) {
                console.error('[BasePaginatedTable] Fetch error:', err);
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.fetchData();
    },
};
</script>
