<template>
    <!-- START: EXISTING EVENT -->
    <div class="fill-height" v-if="type === 'eventInfo'">
        <!-- START: Tribute Event -->
        <div v-if="event.eventType == 0">
            <tribute-event
                ref="tributeEvent"
                @updateEvent="event => $emit('updateEvent', event)"
                @hide-event="event => $emit('hide-event', event)"
                @unhide-event="event => $emit('unhide-event', event)"
                @deleteEvent="event => $emit('deleteEvent', event)"
                @refresh-service="event => $emit('refresh', event)"
                @refresh-photos="event => $emit('refresh-photos', event)"
                @refresh-tribute="event => $emit('refresh-tribute', event)"
                :refreshKey="tributeVideoRefreshKey"
                :onboarding="tributeOnboarding"
                :event="event"
                :type="event.eventType"
                :service="service"
                :service-events="serviceEvents"
            />
        </div>

        <div class="fill-height" v-else-if="event.eventType == 5">
            <story-event
                class="fill-height"
                ref="storyEvent"
                :event="event"
                :service="service"
                @stories-count="val => $emit('stories-count', val)"
                @event-private-update="val => this.$emit('event-private-update', val)"
                @story-render="val => this.$emit('story-render', val)"
            />
        </div>
        <!-- END: Tribute Event -->

        <div v-else>
            <div>
                <div class="event-title-container">
                    <h2 v-if="type === 'eventInfo'" class="">{{ event.title }}</h2>
                    <h2 v-else>Add Event</h2>

                    <div class="chip-container" style="display: flex; gap: 0.5rem; align-items: center">
                        <span v-if="event.hidden" :style="{ '--background-color': '#fb8c00' }" class="chip">
                            <strong>Hidden</strong>
                        </span>
                        <span
                            v-else-if="event.eventStatus !== 0"
                            :style="{ '--background-color': eventStates[event.eventStatus].color }"
                            class="chip"
                        >
                            {{ eventStates[event.eventStatus].label }}
                        </span>

                        <span v-if="facebookSimulcast" style="background: #41a7cc" class="chip"
                            ><font-awesome-icon icon="fa-brands fa-facebook" /> Simulcast Enabled
                        </span>
                    </div>
                </div>

                <div v-if="!event.hidden && premiumSupport" class="mb-8">
                    <!-- START: User Phone DropDown -->
                    <phone-drop-down
                        v-if="tempUser && event.live"
                        ref="phoneDropDown"
                        :users="users"
                        :event="event"
                        :tempUser="tempUser"
                        :loading="loadingUsers"
                        @getUser="getUser"
                        @addUser="addUser"
                        @updateStreamOwner="user => updateStreamOwner(user)"
                        @update-phone-type="data => updateUserPhoneType(data)"
                    />

                    <!-- START: LandLine Alert Dialog -->
                    <land-line-alert @updateNumber="number => updateUser(number)" ref="landlineAlert" />
                    <!-- END: LandLine Alert Dialog -->

                    <!-- New User Dialog -->
                    <v-dialog max-width="600px" v-model="newUserDialog">
                        <v-card class="p-3">
                            <v-card-title>Add User</v-card-title>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        :rules="[v => !!v || 'This field is required']"
                                        v-model="userForm.firstName"
                                        label="First Name"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        :rules="[v => !!v || 'This field is required']"
                                        v-model="userForm.lastName"
                                        label="Last Name"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        :rules="[v => v.includes('@') || 'Email must be valid']"
                                        v-model="userForm.email"
                                        label="Email"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-mask="'###.###.####'"
                                        v-model="userForm.phoneNo"
                                        label="Phone"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <div class="text-right">
                                <v-btn @click="createUser" color="primary">Add User</v-btn>
                            </div>
                        </v-card>
                    </v-dialog>
                    <!-- End New User Dialog -->

                    <!-- User Dialog -->
                    <v-dialog max-width="400px" v-model="userDialog">
                        <v-card class="p-3">
                            <v-card-title>Update Phone Number</v-card-title>
                            <div>
                                <v-text-field
                                    @keydown.enter="updateUser(userForm.phoneNo)"
                                    v-mask="'###.###.####'"
                                    v-model="userForm.phoneNo"
                                    label="Phone Number"
                                    autofocus
                                ></v-text-field>

                                <div class="text-right">
                                    <v-btn color="primary" @click="updateUser(userForm.phoneNo)">Update</v-btn>
                                </div>
                            </div>
                            <!-- <div class="text-center" v-else>
                        Getting user
                        <v-progress-circular indeterminate></v-progress-circular>
                    </div> -->
                        </v-card>
                    </v-dialog>
                    <!-- End User Dialog -->
                </div>

                <!-- START: Form Area -->
                <form @submit.prevent="handleEventFormSubmission" v-if="renderComponent">
                    <v-text-field
                        :disabled="event.hidden"
                        v-model="eventFormData.title"
                        label="Event Title"
                    ></v-text-field>

                    <calendar datetime v-model="eventFormData.time" :disabled="event.hidden" label="Event Time" />

                    <div class="event-checkbox-container">
                        <!-- <v-checkbox v-model="eventFormData.private" label="Private" /> -->
                        <v-checkbox
                            v-model="eventFormData.live"
                            label="Live"
                            :disabled="event.eventStatus !== 0 ? true : false"
                        />

                        <!-- <v-badge overlap>
                    <div class="introBadge" slot="badge" @click="test">?</div> -->
                        <div style="display: flex">
                            <v-checkbox
                                v-model="eventFormData.introVideo"
                                :disabled="introDisabled || event.hidden"
                                label="Intro Video"
                            ></v-checkbox>
                            <v-btn @click="videoHelp" color="primary" x-small fab>
                                <!-- <font-awesome-icon icon="fa-regular fa-circle-question" /> -->
                                <span style="font-size: 15px">?</span>
                            </v-btn>
                        </div>
                        <!-- </v-badge> -->
                    </div>
                    <v-btn class="my-4" v-if="!inActive" block color="#0C3C60" dark large type="submit"
                        >Update event</v-btn
                    >
                    <v-btn class="my-4" v-else block color="#0C3C60" dark large type="submit">Save</v-btn>
                </form>

                <!-- Intro Video Help Modal -->
                <v-dialog v-model="helpModal" max-width="600px">
                    <v-card>
                        <v-card-title>Intro Video</v-card-title>
                        <v-card-text
                            >Selecting this option will add an introduction video to the beginning of the recording.
                            <br /><br />
                            To enable this: <br />
                            1. Select <strong>Intro Video</strong><br />
                            2. Click <strong>Save</strong> or <strong>Update</strong>

                            <div class="mt-4">
                                <branding-player-preview :source="sampleSource"></branding-player-preview>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <!-- START: SUPER ADMIN BUTTON CONTAINER -->
                <div class="super-admin-button-container" v-if="superAdmin">
                    <h3>Super Admin Edit</h3>

                    <div>
                        <v-row>
                            <v-col cols="12" xl="4">
                                <v-btn
                                    block
                                    @click.stop="() => handleSuperAdminClick('event')"
                                    color="#f0553a"
                                    dark
                                    class="mt-4"
                                    >Event</v-btn
                                >
                            </v-col>
                            <v-col v-if="hasVideo" cols="12" xl="4">
                                <v-btn
                                    block
                                    @click.stop="() => handleSuperAdminClick('video')"
                                    color="#f0553a"
                                    dark
                                    class="mt-4"
                                    >Video</v-btn
                                >
                            </v-col>
                            <v-col v-if="event.live" cols="12" xl="4">
                                <v-btn
                                    block
                                    @click.stop="() => handleSuperAdminClick('streams')"
                                    color="#f0553a"
                                    dark
                                    class="mt-4"
                                    >Live Stream</v-btn
                                >
                            </v-col>
                            <v-col v-if="event.liveStreamId && event.eventStatus > 0" cols="12" xl="4">
                                <v-btn
                                    block
                                    @click.stop="() => handleSuperAdminClick('usage')"
                                    color="#f0553a"
                                    dark
                                    class="mt-4"
                                    >Usage Records</v-btn
                                >
                            </v-col>
                            <v-col v-if="event.convertedVideo || event.eventStatus == 7" cols="12" xl="4">
                                <v-btn
                                    block
                                    @click.stop="() => handleSuperAdminClick('render-history')"
                                    color="#f0553a"
                                    dark
                                    class="mt-4"
                                    >Render History</v-btn
                                >
                            </v-col>
                            <v-col v-if="event.convertedVideo || event.eventStatus == 7" cols="12" xl="4">
                                <v-btn
                                    block
                                    @click.stop="() => handleSuperAdminClick('video-data')"
                                    color="#f0553a"
                                    dark
                                    class="mt-4"
                                    >Video Data</v-btn
                                >
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <!-- END: SUPER ADMIN BUTTON CONTAINER -->

                <!-- <stream-auto-shutoff-countdown v-if="displayStreamAutoStopTimer" :event="event" /> -->

                <!-- START: Action Area -->
                <section v-if="renderComponent">
                    <div v-if="type === 'eventInfo' && !inActive">
                        <!-- Events that aren't set to live -->
                        <div v-if="!event.live">
                            <video-processing-action v-if="event.eventStatus === 4" :event="event" :service="service" />
                            <completed-action
                                v-else-if="event.eventStatus === 7"
                                :event="event"
                                :service="service"
                                @refresh="$emit('refresh', event)"
                            />
                            <default-action
                                v-else
                                :event="event"
                                :service="service"
                                @refresh="$emit('refresh', event)"
                            />
                        </div>

                        <!-- Events that are set to live -->
                        <div v-else>
                            <live-stream-action
                                v-if="event.eventStatus === 2"
                                :event="event"
                                :service="service"
                                @refresh="$emit('refresh', event)"
                            />
                            <finished-stream-action
                                v-else-if="event.eventStatus === 3"
                                :event="event"
                                :service="service"
                                @refresh="$emit('refresh', event)"
                            />

                            <video-processing-action
                                v-else-if="event.eventStatus === 4"
                                :event="event"
                                :service="service"
                            />
                            <paused-stream-action
                                v-else-if="event.eventStatus === 6"
                                :event="event"
                                :service="service"
                                @refresh="$emit('refresh', event)"
                            />
                            <completed-action
                                v-else-if="event.eventStatus === 7"
                                :event="event"
                                :service="service"
                                @refresh="$emit('refresh', event)"
                            />
                            <live-action
                                v-else-if="event.eventStatus != 2"
                                :event="event"
                                :service="service"
                                @refresh="$emit('refresh', event)"
                            />
                        </div>
                    </div>
                </section>
                <!-- END: Action Area -->

                <div class="text-center mt-4 d-flex justify-center">
                    <v-btn v-if="event.hidden" small class="p-3" color="warning" @click="handleUnHideClick(event)"
                        >Un Hide</v-btn
                    >

                    <!-- <v-tooltip bottom v-if="disabledDelete && !event.hidden">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-on="on">
                                <v-btn
                                    v-if="!event.hidden"
                                    small
                                    class="p-3"
                                    color="error"
                                    v-bind="attrs"
                                    @click="handleClick(event)"
                                    :disabled="true"
                                    >Delete</v-btn
                                >
                            </div>
                        </template>
                        <span>Unable to delete events that are currently live</span>
                    </v-tooltip> -->

                    <custom-tooltip v-if="disabledDelete && !event.hidden" :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <v-btn
                                v-if="!event.hidden"
                                small
                                class="p-3"
                                color="error"
                                @click="handleClick(event)"
                                :disabled="true"
                                >Delete</v-btn
                            >
                        </template>

                        <template v-slot:content>
                            <span>Unable to delete events that are currently live</span>
                        </template>
                    </custom-tooltip>

                    <v-btn
                        v-if="!event.hidden && !disabledDelete"
                        small
                        class="p-3 mx-2"
                        color="error"
                        @click="handleClick(event)"
                        >Delete</v-btn
                    >

                    <v-btn v-if="!event.hidden" small class="p-3 mx-2" color="warning" @click="handleHideclick(event)"
                        >Hide</v-btn
                    >
                </div>

                <!-- START: SUPER ADMIN DIALOG -->
                <v-dialog v-model="dialog" :width="getDialogWidth()">
                    <event-super
                        v-if="dialog && dialogType == 'event'"
                        :event="this.event"
                        @close="handleDialogClose('event')"
                    />
                    <video-super
                        v-if="dialog && dialogType == 'video'"
                        :event="this.event"
                        @close="handleDialogClose('event')"
                    />
                    <stream-super
                        v-if="dialog && dialogType == 'streams'"
                        :event="this.event"
                        @close="handleDialogClose('event')"
                    />
                    <usage-super
                        v-if="dialog && dialogType == 'usage'"
                        :event="this.event"
                        @close="handleDialogClose('event')"
                    ></usage-super>
                    <render-history-super
                        v-if="dialog && dialogType == 'render-history'"
                        :event="this.event"
                        @close="handleDialogClose('event')"
                    ></render-history-super>
                    <video-data-super
                        v-if="dialog && dialogType == 'video-data'"
                        :event="this.event"
                        @close="handleDialogClose('event')"
                    ></video-data-super>
                </v-dialog>
                <!-- END: SUPER ADMIN DIALOG -->
            </div>
        </div>
    </div>
    <!-- END: EXISTING EVENT -->

    <!-- START: NEW EVENT -->
    <div v-else-if="type === 'newEvent'">
        <div class="event-title-container mb-8">
            <h2>Add New Event</h2>
        </div>

        <v-form @submit.prevent="handleEventFormSubmission" v-if="renderComponent">
            <v-text-field
                v-model="eventFormData.title"
                label="Event Title"
                :error="titleNewEventFormError"
                :rules="[value => !!value || 'Required.']"
            ></v-text-field>

            <calendar datetime v-model="eventFormData.time" label="Date" />

            <div class="event-checkbox-container">
                <!-- <v-checkbox v-model="eventFormData.private" label="Private" /> -->
                <v-checkbox v-model="eventFormData.live" label="Live" />
                <v-checkbox
                    v-model="eventFormData.introVideo"
                    :disabled="introDisabled"
                    label="Intro Video"
                ></v-checkbox>
            </div>

            <div style="gap: 10px" class="d-flex">
                <v-btn
                    @click="handleTributeCreate"
                    v-if="!hasTributeEvent && tributeAccess"
                    class="flex-grow-1"
                    color="#0C3C60"
                    dark
                    large
                    >Create Tribute Video</v-btn
                >
                <!-- <v-btn
                    @click="handleStoryCreate"
                    v-if="!hasStoryEvent && tributeAccess"
                    class="flex-grow-1"
                    color="#0C3C60"
                    dark
                    large
                    >Create Tribute Story</v-btn
                > -->
                <v-btn class="flex-grow-1" color="#0C3C60" dark large type="submit">Create Event</v-btn>
            </div>
        </v-form>
    </div>
    <!-- END: NEW EVENT -->

    <!-- START: SERVICE EDITOR -->
    <div v-else-if="type === 'serviceInfo'" class="edit-service-panel">
        <div class="event-title-container mb-8">
            <h2>
                Edit {{ serviceEventFormData.firstName }}'s Service
                <!-- <span v-if="$auth.role.includes('SuperAdmin')">({{ service.serviceState }}) </span> -->
            </h2>

            <div class="chip-container" v-if="$auth.role.includes('SuperAdmin')">
                <span :style="{ '--background-color': serviceStates[service.serviceState].color }" class="chip">
                    {{ serviceStates[service.serviceState].label }}
                </span>
            </div>
        </div>
        <form @submit.prevent="handleServiceFormSubmission" class="form">
            <div class="field-container">
                <v-text-field v-model="serviceEventFormData.firstName" label="First Name"></v-text-field>
                <v-text-field v-model="serviceEventFormData.lastName" label="Last Name"></v-text-field>
                <calendar label="Birth Date" v-model="serviceEventFormData.birthDate" :output-local="true" />
                <calendar label="Death Date" v-model="serviceEventFormData.deathDate" :output-local="true" />
            </div>

            <v-checkbox
                v-model="serviceEventFormData.private"
                label="Private Service"
                style="width: 140px"
            ></v-checkbox>

            <v-btn block color="#0C3C60" dark large type="submit">Update service </v-btn>
        </form>
        <v-btn @click.stop="dialog = true" v-if="superAdmin" block color="#f0553a" dark large type="submit" class="mt-4"
            >Super Admin Edit</v-btn
        >
        <!--END: Service Editor -->
        <v-dialog v-model="dialog" width="800">
            <service-super v-if="dialog" :service="this.service" @close="handleDialogClose('service')" />
        </v-dialog>
        <!-- END: SUPER ADMIN DIALOG -->
    </div>
    <!-- END: SERVICE EDITOR -->
</template>

<script>
import Calendar from '@/components/ui/Calendar.vue';
import CompletedAction from './actions/CompletedAction.vue';
import DefaultAction from './actions/DefaultAction.vue';
import LiveAction from './actions/LiveAction.vue';
import LiveStreamAction from './actions/LiveStreamAction.vue';
import VideoProcessingAction from './actions/VideoProcessingAction.vue';
import FinishedStreamAction from './actions/FinishedStreamAction.vue';
import PausedStreamAction from './actions/PausedStreamAction.vue';
import ServiceSuper from './super-admin/ServiceSuper.vue';
import EventSuper from './super-admin/EventSuper.vue';
import VideoSuper from './super-admin/VideoSuper.vue';
import StreamSuper from './super-admin/StreamSuper.vue';
import UsageSuper from './super-admin/UsageSuper.vue';
import RenderHistorySuper from './super-admin/RenderHistorySuper.vue';
import VideoDataSuper from './super-admin/VideoDataSuper.vue';
import { serviceStates } from '@/constants';
import { eventStates } from '@/constants';
import moment from 'moment';
import BrandingPlayerPreview from '@/components/videojs/BrandingPlayerPreview.vue';
import { mapActions } from 'vuex';
import DatePicker from '../ui/DatePicker.vue';
import LandLineAlert from '@/components/Misc/LandLineAlert.vue';
import PhoneDropDown from '@/components/ManageService/buttons/PhoneDropDown.vue';
import TributeEvent from '@/components/ManageService/Tribute/TributeEvent.vue';
import StoryEvent from '@/components/ManageService/Tribute/StoryEvent.vue';
import emojiRegex from 'emoji-regex';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import StreamAutoShutoffCountdown from '@/components/Streams/StreamAutoShutoffCountdown.vue';

export default {
    name: 'EditPanel',
    props: {
        type: {
            type: String,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
        serviceEvents: {
            type: Array,
        },
        currentTab: {
            type: Number,
            required: true,
        },
        users: {
            type: Array,
            required: false,
        },
        loadingUsers: {
            type: Boolean,
            required: false,
        },
        funeralHomeSettings: {
            type: Object,
            default: () => ({}),
        },
        tributeOnboarding: {
            type: Boolean,
            default: true,
        },
        tributeVideoRefreshKey: {
            type: Number,
            default: 0,
        },
    },
    components: {
        PhoneDropDown,
        LiveAction,
        DefaultAction,
        LiveStreamAction,
        CompletedAction,
        VideoProcessingAction,
        ServiceSuper,
        EventSuper,
        VideoSuper,
        StreamSuper,
        UsageSuper,
        RenderHistorySuper,
        VideoDataSuper,
        FinishedStreamAction,
        PausedStreamAction,
        DatePicker,
        Calendar,
        BrandingPlayerPreview,
        LandLineAlert,
        TributeEvent,
        CustomTooltip,
        StoryEvent,
        StreamAutoShutoffCountdown,
    },
    data() {
        return {
            inActive: false,
            userForm: {
                email: '',
                phoneNo: '',
                firstName: '',
                lastName: '',
            },
            eventFormData: {
                title: '',
                time: new Date(),
                live: false,
                private: false,
                introVideo: true,
                userId: 0,
            },
            serviceEventFormData: {
                firstName: null,
                lastName: null,
                birthDate: null,
                deathDate: null,
                private: false,
                test: false,
            },
            deleteDialog: false,
            renderComponent: true,
            superAdmin: false,
            introDisabled: false,
            dialog: false,
            dialogType: '',
            hasVideo: false,
            disabledDelete: false,
            serviceStates: [...serviceStates],
            eventStates: [...eventStates],
            dateNewEventFormError: false,
            titleNewEventFormError: false,
            helpModal: false,
            sampleSource: 'https://memoryshareprod.blob.core.windows.net/front-end-assets/SampleIntro.mp4',
            facebookSimulcast: false,
            tempUser: {
                id: 0,
                funeralHomeId: null,
                firstName: '',
                lastName: '',
                PhoneNo: '',
                landline: false,
                email: '',
                phoneType: 0,
            },
            userDialog: false,
            newUserDialog: false,
            // userReady: false,
            phoneRules: [
                v => v.length === 12 || 'Please follow this format 123.456.7890',
                v => (v[3] === '.' && v[7] === '.') || 'Please follow this format 123.456.7890',
            ],
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        updateUserPhoneType(data) {
            let id = data.id;
            let phoneType = data.phoneType;

            if (!id) {
                this.showSnackbar({ message: 'Invalid user id', color: 'error' });
                return;
            }

            if (phoneType === null) {
                this.showSnackbar({ message: 'Invalid phone type', color: 'error' });
                return;
            }

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/users/update-phone-type/${id}/${phoneType}`)
                .then(response => {
                    this.tempUser.phoneType = response.data.phoneType;

                    if (this.$refs.phoneDropDown) {
                        this.$refs.phoneDropDown.phoneTypeModal = false;
                    }

                    this.showSnackbar({ message: 'User updated' });

                    this.$emit('refreshUserList');
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error updating user' });
                });
        },
        handleTributeCreate() {
            this.eventFormData.live = false;
            this.eventFormData.introVideo = false;
            this.eventFormData.title = `${this.service.firstName} ${this.service.lastName}'s Tribute Video`;
            this.eventFormData.time = new Date().toISOString();

            this.handleEventFormSubmission(undefined, 'tribute');
        },
        handleStoryCreate() {
            this.eventFormData.live = false;
            this.eventFormData.introVideo = false;
            this.eventFormData.title = `${this.service.firstName} ${this.service.lastName}'s Tribute Story`;
            this.eventFormData.time = new Date().toISOString();

            this.handleEventFormSubmission(undefined, 'story');
        },
        containsEmoji(string) {
            const regex = emojiRegex();
            return regex.test(string);
        },
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },

        addUser() {
            this.userForm.phoneNo = '';
            this.newUserDialog = true;
        },
        createUser() {
            this.newUserDialog = false;
            let data = {
                funeralHomeId: this.event.funeralHomeId,
                email: this.userForm.email,
                firstName: this.userForm.firstName,
                lastName: this.userForm.lastName,
                phoneNo: this.userForm.phoneNo,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + '/users/invite', data)
                .then(response => {
                    this.showSnackbar({ message: 'User added' });
                    if (response.data.landLine == true) this.$refs.landlineAlert.dialog = true;
                    this.eventFormData.userId = response.data.id;
                    // this.handleEventFormSubmission();
                    this.$emit('refreshUserList');
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error creating user' });
                });

            // this.$emit('updateService', this.service);
        },

        // checkInput(e) {
        //     var isNumber = /^[0-9]$/i.test(e.key);

        //     if (this.userForm.phoneNo.length === 3 || this.userForm.phoneNo.length === 7) {
        //         if (e.key !== '.' && e.code !== 'Backspace') {
        //             e.preventDefault();
        //         }
        //         return;
        //     } else {
        //        return false
        //     }
        // },
        updateUser(number) {
            this.tempUser.phoneNo = number;

            this.userDialog = false;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(
                    process.env.VUE_APP_API +
                        `/user/phoneNumber?id=${this.tempUser.id}&phoneNumber=${this.tempUser.phoneNo}`,
                )
                .then(response => {
                    this.showSnackbar({ message: 'Updated user' });
                    if (response.data.landLine == true) this.$refs.landlineAlert.dialog = true;
                    this.tempUser.landLine = response.data.landLine;
                    this.$emit('refreshUser', this.tempUser);

                    this.userDialog = false;
                })
                .catch(error => {
                    console.log(error);
                    this.showSnackbar({ message: 'Error updating user' });
                });
        },
        getUser() {
            if (this.tempUser.id > 0) {
                if (this.tempUser.phoneNo) {
                    this.userForm.phoneNo = this.tempUser.phoneNo;
                } else {
                    this.userForm.phoneNo = '';
                }
                this.userDialog = true;
            } else {
                this.showSnackbar({ message: 'Invalid user', color: 'error' });
            }
        },
        updateStreamOwner(user) {
            this.tempUser = user;
            this.eventFormData.userId = user.id;
            this.handleEventFormSubmission();
        },
        getSettings(id) {
            this.$auth.getIdTokenClaims().then(async result => {
                // Get Api Token
                this.token = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/funeralhomes/settings/${id}`)
                    .then(resp => {
                        this.funeralHomeSettings = resp.data;
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    });
            });
        },
        videoHelp() {
            this.helpModal = true;
        },
        getDialogWidth() {
            if (this.dialogType == 'render-history') {
                return 1300;
            } else {
                return 900;
            }
        },
        handleDialogClose: function (type) {
            this.dialog = false;

            if (type === 'event') {
                this.$emit('refresh', this.event);
            } else {
                this.$emit('refresh', { id: null });
            }
        },
        handleInput: function (value) {},
        handleTributeFormSubmission(data) {
            this.$emit('updateEvent', { data: data, id: data.id });
        },
        handleEventFormSubmission: function ($event, specialEventType = '') {
            if (this.containsEmoji(this.service.funeralHomeName)) {
                this.showSnackbar({ message: 'Invalid emoji token in funeral home name', color: 'error' });
                return;
            }

            const isAdmin = this.$auth.impersonating || this.$auth.role.includes('SuperAdmin');
            const isUnassigned = this.eventFormData.userId === 0 || this.eventFormData.userId === null;

            if (!isAdmin && isUnassigned) {
                this.eventFormData.userId = this.$auth.user['https://memoryshare.com/user_id'];
            }

            let error = false;

            if (this.eventFormData.title?.length == 0) {
                this.$store.dispatch('showSnackbar', { message: 'Title must be entered', color: 'error' });
                this.titleNewEventFormError = true;
                error = true;
            }

            if (!this.eventFormData.time) {
                this.$store.dispatch('showSnackbar', { message: 'Date must be entered', color: 'error' });
                this.dateNewEventFormError = true;
                error = true;
            }

            if (error) return;

            let eventType = 4;

            if (this.tributeAccess) {
                if (specialEventType == 'tribute') {
                    eventType = 0;
                } else if (specialEventType == 'story') {
                    eventType = 5;
                }
            }

            const data = {
                ...this.eventFormData,
                serviceId: this.service.id,
                eventType: eventType,
                userId: this.eventFormData.userId > 0 ? this.eventFormData.userId : 0,
            };

            if (this.type === 'eventInfo') {
                this.$emit('updateEvent', { data: data, id: this.event.id });
            }

            if (this.type === 'newEvent') {
                this.$emit('createEvent', { data: data });
            }
        },
        handleServiceFormSubmission: function () {
            const data = {
                ...this.serviceEventFormData,
                firstName: this.serviceEventFormData.firstName,
                lastName: this.serviceEventFormData.lastName,
                funeralHomeId: this.$auth.funeralHomeId,
                pictureURL: 'string',
                test: this.serviceEventFormData.test,
            };
            this.$emit('updateService', data);
        },
        handleClick: function (event) {
            this.$emit('deleteEvent', event);
        },
        handleUnHideClick: function (event) {
            this.$emit('unhide-event', event);
        },
        handleHideclick: function (event) {
            this.$emit('hide-event', event);
        },
        forceRerender: function () {
            this.introDisabled = false;
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },
        handleSuperAdminClick: function (type) {
            this.dialog = true;
            this.dialogType = type;
        },
        fetchConnections: function () {
            if (this.event.liveStreamId != null) {
                this.$auth.getIdTokenClaims().then(async result => {
                    // Get Api Token
                    this.token = result.__raw;

                    // Make Request
                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .get(process.env.VUE_APP_API + `/live/${this.event.liveStreamId}`)
                        .then(response => {
                            this.facebookSimulcast = response.data.facebookSimulcast;
                        });
                });
            }
        },
    },
    watch: {
        // users() {
        //     // console.log(this.users, 'changed');
        //     // this.tempUser = this.users.find(u => u.id == this.event.userId);
        // },
        event: {
            handler() {
                if (this.currentTab === -1) {
                    return;
                }
                // Force re-renders for new entry data
                this.forceRerender();

                // If a New event is being created
                if (this.type !== 'eventInfo') {
                    this.eventFormData = {
                        title: '',
                        time: null,
                        live: false,
                        private: false,
                        introVideo: true,
                        userId: this.$auth.user['https://memoryshare.com/user_id']
                            ? this.$auth.user['https://memoryshare.com/user_id']
                            : 0,
                    };
                }

                if (this.event.user) {
                    this.tempUser = this.event.user;
                }

                // Reset States
                this.eventFormData.time = null;
                this.inActive = false;
                this.hasVideo = false;
                this.disabledDelete = this.event.eventStatus === 2;
                this.dateNewEventFormError = false;
                this.titleNewEventFormError = false;
                this.facebookSimulcast = false;

                // If Event is status 3 || 4 we need to disabled the intro video check
                if (this.event.eventStatus === 4) this.introDisabled = true;

                let startTime = new Date(this.event.time);
                let offset = startTime.getTimezoneOffset() / 60;
                startTime.setHours(startTime.getHours() - offset);

                // This needs to be removed eventually
                // ===========================================================
                let decidedEventTime = new Date();
                let eventTime = this.event?.time;

                // If there is an event time passed in, just check if it's older than 30 days.
                if (this.event.time === '0001-01-01T00:00:00') {
                    startTime = null;
                    this.inActive = true;
                }

                this.eventFormData = {
                    title: this.event.title,
                    time: startTime ? moment(startTime)?.toISOString() : null,
                    live: this.event?.live || false,
                    private: this.event?.private || false,
                    introVideo: this.type === 'newEvent' ? true : this.event?.introVideo,
                    userId: this.event?.userId,
                };

                // Check if event has video
                if (this.$auth.role.includes('SuperAdmin')) {
                    if (this.event.eventStatus === 7 || this.event.eventStatus === 4) {
                        this.hasVideo = true;
                    }
                }

                this.fetchConnections();
            },
            deep: true,
            immediate: true,
        },
        service: function () {
            this.serviceEventFormData = {
                firstName: this.service.firstName,
                lastName: this.service.lastName,
                birthDate: this.service.birthDate,
                deathDate: this.service.deathDate,
                private: this.service.private,
                test: this.service.test,
            };
        },
        // tributeEvent() {
        //     this.eventFormData.live = false;
        //     this.eventFormData.introVideo = false;

        //     this.eventFormData.title = this.tributeEvent
        //         ? `${this.service.firstName} ${this.service.lastName}'s Tribute Video`
        //         : '';
        // },
    },
    computed: {
        // selectedUser() {
        //     var selected = this.users.find(user => user.id == this.event.userId);
        //     return selected;
        // },
        displayStreamAutoStopTimer() {
            if (!this.event) return false;

            if (!this.event.liveStreamId) return false;

            if (this.event.eventStatus !== 2) return false;

            return true;
        },
        hasTributeEvent() {
            const tributeEventCount = this.serviceEvents.filter(x => x.eventType == 0).length;

            return tributeEventCount > 0;
        },
        hasStoryEvent() {
            const storyEventCount = this.serviceEvents.filter(x => x.eventType == 5).length;

            return storyEventCount > 0;
        },
        initials() {
            if (this.tempUser.firstName && this.tempUser.lanstName) {
                return (this.tempUser.firstName[0] + this.tempUser.lastName[0]).toUpperCase();
            }
        },
        premiumSupport() {
            if (this.funeralHomeSettings?.doNotWatch == false) {
                return true;
            }
            return false;
        },
        tributeAccess() {
            if (this.funeralHomeSettings?.tributeVideos) {
                return true;
            }
            return false;
        },
    },
    mounted() {
        if (this.$auth.role.includes('SuperAdmin') && this.$auth.impersonating === false) {
            this.superAdmin = true;
        }

        let birth = new Date(this.service.birthDate);
        let birthOffset = birth.getTimezoneOffset() / 60;
        birth.setHours(birth.getHours() - birthOffset);

        let death = new Date(this.service.deathDate);
        let deathOffset = death.getTimezoneOffset() / 60;
        death.setHours(death.getHours() - deathOffset);

        this.serviceEventFormData = {
            firstName: this.service.firstName,
            lastName: this.service.lastName,
            birthDate: birth,
            deathDate: death,
            // birthDate: birth.toISOString(),
            // deathDate: death.toISOString(),
            private: this.service.private,
            test: this.service.test,
        };
    },
};
</script>

<style lang="scss" scoped>
// .btn-group {
//     border: 2px solid #eee;
//     border-radius: 5px;
//     transition: 0.3s;
// }
// .btn-group:hover {
//     border: 2px solid white;
//     transition: 0.3s;
// }
// .btn-group:hover #btn-divider {
//     opacity: 0;
// }
// .pointer {
//     cursor: pointer;
//     border: 2px solid rgba(255, 255, 255, 0);
//     border-radius: 5px;
//     transition: 0.3s;
// }
// .pointer:hover {
//     border: 2px solid #309cf493;
//     transition: 0.3s;
// }

.introBadge {
    z-index: 1;
    cursor: pointer;
    border: 2px solid red;
}

.event-checkbox-container {
    background: #f8f8f8;
    border-radius: 4px;
    display: flex;
    padding: 1rem 3rem;
    margin: 1rem 0;
    gap: 2rem;
}

.event-action-container {
    background: #f8f8f8;
    border-radius: 4px;
    // margin-top: 2rem;
    padding: 1rem;
}
.hidden-event-action-container {
    background: #fff0dd !important;
}
.edit-service-panel {
    .field-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
        row-gap: 1rem;
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 1200px) {
    .event-checkbox-container {
        flex-direction: column;
        gap: 0;
    }
}

.super-admin-button-container {
    background: #f8f8f8;
    border-radius: 4px;
    padding: 1rem 3rem;
    margin: 1rem 0;
    gap: 2rem;
    text-align: center;
}

.event-title-container {
    display: flex;
    gap: 1rem;
    align-items: center;

    h2 {
        margin-bottom: 0;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .field-container {
        display: grid;
        grid-template-columns: 1fr !important;
        column-gap: 2rem;
        row-gap: 1rem;
        margin-bottom: 2rem;
    }
}
</style>
