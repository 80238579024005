<template>
    <div class="countdown">
        <div class="time-box">
            <span class="countdown-value">
                {{ countdownTime.days }}
            </span>
            <span>Days</span>
        </div>
        <div class="time-box">
            <span class="countdown-value">
                {{ countdownTime.hours }}
            </span>
            <span>Hours</span>
        </div>
        <div class="time-box">
            <span class="countdown-value">
                {{ countdownTime.minutes }}
            </span>
            <span>Minutes</span>
        </div>
        <div v-if="showSeconds" class="time-box">
            <span class="countdown-value">
                {{ countdownTime.seconds }}
            </span>
            <span>Seconds</span>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            countdownInterval: null,
            countdownExpired: false,
            countdownTime: {
                days: '-',
                hours: '-',
                minutes: '-',
                seconds: '-',
            },
        };
    },
    props: {
        deadline: {
            type: String,
            default: null,
        },
        showSeconds: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        stopCountdown() {
            clearInterval(this.countdownInterval);
            this.countdownInterval = null;
        },
        handleCountdownExpired() {
            this.stopCountdown();

            this.countdownTime = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };

            this.countdownExpired = true;
            this.$emit('countdown-expired');
        },
        updateCountdown() {
            const deadline = this.$moment.utc(this.deadline);
            const currentTime = this.$moment.utc(new Date());

            const diff = deadline - currentTime;

            const duration = this.$moment.duration(diff);

            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            if (duration.asSeconds() <= 0) {
                this.handleCountdownExpired();
                return;
            }

            this.countdownTime = {
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds,
            };
        },
    },
    mounted() {
        if (this.deadline) {
            const intDuration = this.showSeconds ? 1000 : 10000;
            this.countdownInterval = setInterval(this.updateCountdown, intDuration);
        }
    },
};
</script>
<style lang="scss" scoped>
.countdown {
    display: flex;
    gap: 10px;
}

.time-box {
    background: #fff;
    border-radius: 6px;
    padding: 8px;

    width: 100px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.time-box {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.countdown-value {
    font-size: 24px;
    font-weight: bold;
}

@media screen and (max-width: 700px) {
    .countdown {
        flex-direction: column;
        width: 100%;
    }
    .time-box {
        min-width: 100%;
    }
}
@include mobile() {
    .time-box {
        min-width: auto;
    }
}
</style>
