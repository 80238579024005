<template>
    <div>
        <v-combobox
            :disabled="!link"
            outlined
            dense
            :hide-details="false"
            @input="handleInput"
            style="width: 100%"
            v-model="inviteList"
            hide-selected
            @blur="manualBlur"
            ref="emailCombo"
            class="email-input has-addon__right"
            multiple
            small-chips
            :deletable-chips="true"
            :counter="inviteList.length > 0"
        >
            <!-- color="#f8f8f8" -->
            <template v-slot:selection="data">
                <v-chip
                    label
                    class="ma-1"
                    :color="invalidInvites.includes(data.item) ? 'error' : '#f8f8f8'"
                    small
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                >
                    {{ data.item }}
                    <font-awesome-icon
                        @click.stop="removeInviteFromList(data)"
                        class="ml-2 del-btn"
                        icon="fa-regular fa-circle-x"
                    />
                </v-chip>
            </template>
            <template v-slot:no-data>
                <div style="border: 2px transparent">
                    <v-list-item>
                        <v-list-item-content>
                            <div>Press <span class="entr-key">Enter</span> to add email or phone</div>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </template>
            <template v-slot:counter="{ props }">
                <div v-if="props.value" class="email-counter">{{ props.value }} Recipients</div>
            </template>

            <template v-slot:append-outer>
                <slot name="append-outer" :submit="handleSubmit" :listLength="inviteList.length"></slot>
            </template>
        </v-combobox>
        <span class="text-caption error--text" v-if="invalidInvites.length > 0"
            >Some invites are invalid. Please correct them.</span
        >
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            message: 'hi',
            inviteList: [],
            invalidInvites: [],
        };
    },
    props: {
        link: {
            type: String,
            default: null,
        },
    },
    watch: {
        inviteList(newVal) {
            this.$emit('change', newVal);
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        reset() {
            this.inviteList = [];
            this.invalidInvites = [];
        },
        handleSubmit() {
            const filteredContacts = this.filterContacts(this.inviteList);

            if (filteredContacts.invalid.length > 0) {
                this.invalidInvites = filteredContacts.invalid;
                this.showSnackbar({
                    message: `${filteredContacts.invalid.length} contacts are not a valid email or phone number.`,
                    color: 'error',
                });
                return;
            }

            let data = {
                emailList: filteredContacts.validEmails,
                phoneList: filteredContacts.validPhoneNumbers,
                url: this.link,
            };

            this.$emit('submit', data);
        },

        handleInput() {
            if (this.inviteList.length > 10) {
                this.showSnackbar({ message: 'Please limit invites to a maximum of 10 at a time.', color: 'error' });
                this.inviteList = this.inviteList.slice(0, 10);
                return;
            }

            this.inviteList = this.inviteList.map(entry => {
                return entry.includes('@') ? entry : this.normalizePhoneNumber(entry);
            });

            const filteredContacts = this.filterContacts(this.inviteList);

            if (filteredContacts.invalid.length > 0) {
                this.invalidInvites = filteredContacts.invalid;
            }
        },
        normalizePhoneNumber(num) {
            const digits = num.replace(/\D/g, ''); // Remove non-digit characters

            if (digits.length === 10) {
                return `${digits.slice(0, 3)}.${digits.slice(3, 6)}.${digits.slice(6, 10)}`;
            }

            return num;
        },
        manualBlur(event) {
            this.$refs.emailCombo.blur();
        },
        isValidEmail(string) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(string);
        },
        isValidPhone(string) {
            const digits = string.replace(/\D/g, '');

            if (digits.length !== 10) {
                return false;
            }

            const phonePattern = /^\d{3}\.\d{3}\.\d{4}$/;
            return phonePattern.test(string);
        },
        filterContacts(contacts) {
            const validEmails = [];
            const validPhoneNumbers = [];
            const invalid = [];

            contacts.forEach(contact => {
                if (this.isValidEmail(contact)) {
                    validEmails.push(contact);
                } else if (this.isValidPhone(contact)) {
                    validPhoneNumbers.push(contact);
                } else {
                    invalid.push(contact);
                }
            });

            return {
                validEmails,
                validPhoneNumbers,
                invalid,
            };
        },
        removeInviteFromList(data) {
            this.inviteList.splice(data.index, 1);

            let found = this.invalidInvites.find(x => x == data.item);
            if (found) {
                let index = this.invalidInvites.indexOf(found);
                this.invalidInvites.splice(index, 1);
            }
        },
        formatPhoneNumber(phoneNumber) {
            return phoneNumber.slice(0, 3) + '.' + phoneNumber.slice(3, 6) + '.' + phoneNumber.slice(6);
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep {
    .v-input.has-addon__right {
        .v-input__slot {
            margin: 0;
        }

        .v-input__control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            fieldset {
                border-color: #ccc;
                border-right: transparent;
            }
        }

        .v-input__append-outer {
            margin: 0 !important;

            button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border: 1px solid #ccc;
                height: 40px;
            }
        }
    }
}
</style>
