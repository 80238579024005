<template>
    <div>
        <v-text-field
            :disabled="disabled"
            :readonly="readonly"
            :value="internalValue"
            outlined
            dense
            class="has-addon__right"
        >
            <template #prepend>
                <slot name="prepend"></slot>
            </template>
            <template #prepend-inner>
                <slot name="prepend-inner"></slot>
            </template>
            <slot name="action" slot="append-outer">
                <v-btn depressed @click="$emit('btn-click')">
                    <font-awesome-icon class="mr-2" icon="fa-regular fa-clone"></font-awesome-icon>

                    Copy</v-btn
                >
            </slot>
        </v-text-field>
    </div>
</template>
<script>
import { readonly } from 'vue';

export default {
    props: {
        value: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        internalValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep {
    .v-input.has-addon__right {
        .v-input__slot {
            margin: 0;
        }

        .v-input__control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            fieldset {
                border-color: #ccc;
                border-right: transparent;
            }
        }

        .v-input__append-outer {
            margin: 0 !important;

            button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border: 1px solid #ccc;
                height: 40px;
            }
        }
    }
}
</style>
