<template>
    <v-dialog v-model="visible" max-width="900px" persistent>
        <v-card>
            <v-card-title class="headline">Compare Duplicate Photos</v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="6" v-for="(slide, index) in slides" :key="index">
                        <v-card outlined class="pa-2">
                            <!-- Tags -->

                            <!-- Image -->
                            <!-- <v-img :src="photo.url" aspect-ratio="1.5" class="my-3 rounded-lg" contain></v-img> -->

                            <div class="image-container">
                                <v-chip color="#e2f5ef" text-color="#207361" class="ma-1 uploader-label" label small>
                                    <span
                                        >Uploaded by
                                        <span class="font-weight-bold">
                                            {{ slide.uploadUserName }}
                                        </span>
                                    </span>
                                </v-chip>
                                <ImageThumbnail
                                    smart-crop
                                    :key="slide.url"
                                    :src="slide.url"
                                    :w="800"
                                    :h="800"
                                    :smartCrop="slide.type !== 1"
                                    :type="slide.mediaType"
                                    :alt="'Slide'"
                                    class="slide-image"
                                />
                            </div>
                            <!-- Info -->
                            <v-row class="my-2">
                                <v-col cols="6">
                                    <div class="text--secondary">Date</div>

                                    <span class="font-weight-bold">
                                        {{ slide.createDate | formatShortDate }}
                                    </span>
                                </v-col>
                                <v-col cols="6">
                                    <div class="text--secondary">Resolution</div>
                                    <span v-if="slide.width && slide.height" class="font-weight-bold">
                                        {{ slide.width }} x {{ slide.height }}
                                    </span>
                                </v-col>
                            </v-row>

                            <!-- <v-row class="my-2">
                                <v-col cols="6">
                                    <div class="text--secondary">Size</div>
                                    <span v-if="mounted && imageSizes[slide.url]" class="font-weight-bold">
                                        {{ formatSize(imageSizes[slide.url]) }}
                                    </span>
                                </v-col>
                                <v-col cols="6">
                                    <div class="text--secondary">Location</div>

                                    <span class="font-weight-bold">
                                        {{ photo.location }}
                                    </span>
                                </v-col>
                            </v-row> -->

                            <!-- Action Buttons -->
                            <div class="d-flex justify-end mt-3">
                                <!-- <v-btn
                                    color="success"
                                    class="mr-2 no-text-transform"
                                    depressed
                                    small
                                    @click="keepPhoto(index)"
                                >
                                    <font-awesome-icon class="mr-2" icon="fa-solid fa-check"></font-awesome-icon>
                                    <span> Keep This </span>
                                </v-btn> -->

                                <v-btn
                                    color="error"
                                    class="no-text-transform"
                                    depressed
                                    small
                                    @click="stageDeleteSlide(slide)"
                                >
                                    <font-awesome-icon class="mr-2" icon="fa-solid fa-trash"></font-awesome-icon>
                                    <span> Delete This </span>
                                </v-btn>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- Recommendation Box -->
                <!-- <v-alert type="info" dense outlined class="mt-4">
                    <div class="font-weight-bold mb-1">Recommendation</div>
                    Based on resolution and file size, we recommend keeping the photo on the left.
                    <br />
                    This photo was uploaded by {{ photos[0].uploader }}.
                </v-alert> -->
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn outlined class="no-text-transform" @click="visible = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ImageThumbnail from '../../Media/ImageThumbnail.vue';
import moment from 'moment';
export default {
    name: 'DuplicatePhotoCompare',
    props: {
        value: {
            type: Boolean,
        },
        slides: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        ImageThumbnail,
    },

    data() {
        return {
            visible: this.value,
            imageSizes: {},
            mounted: false,
        };
    },
    watch: {
        value(val) {
            this.visible = val;
        },
        visible(val) {
            this.$emit('input', val);
        },
    },
    filters: {
        formatShortDate(val) {
            return moment(val).utc().format('l');
        },
    },
    methods: {
        stageDeleteSlide(slide) {
            this.$emit('stage-delete', slide);
            this.visible = false;
        },
        async getImageSizeFromUrl(url) {
            const response = await fetch(url);
            const blob = await response.blob();
            return blob.size; // in bytes
        },
        formatSize(bytes) {
            if (!bytes) return '...';
            const kb = bytes / 1024;
            const mb = kb / 1024;
            return mb > 1 ? `${mb.toFixed(2)} MB` : `${kb.toFixed(0)} KB`;
        },
    },
    async mounted() {
        // for (let i = 0; i < this.slides.length; i++) {
        //     const slide = this.slides[i];
        //     const size = await this.getImageSizeFromUrl(slide.url);
        //     if (slide.url) {
        //         this.imageSizes[slide.url] = size;
        //     }
        // }
        // this.mounted = true;
        // console.log(this.imageSizes, 'image  sized');
    },
};
</script>

<style scoped>
.rounded-lg {
    border-radius: 12px;
}

.image-container {
    position: relative;
    height: 390px;
    width: 390px;
    max-width: 100%;
}

.uploader-label {
    position: absolute;
    top: 0;
    left: 0;
}
</style>
