<template>
    <div :class="['mini-player', { active: isActive, 'background-mode': playInBackground  }]" v-if="currentTrack">
        <div class="close">
            <v-btn fab x-small class="stop-btn p-0" @click="stop" :elevation="0">
                <font-awesome-icon icon="fa-solid fa-times" class="fa-lg" />
            </v-btn>
        </div>
        <MusicTrack mode="miniplayer" :song="currentTrack" :token="$props.token" :playing="isPlaying"
            :isSelected="isSelected">
            <template v-slot:playButton="">
                <button class="play-btn" @click="$refs.miniplayer.togglePlaying()">
                    <span class="fa-stack">
                        <font-awesome-icon :icon="['fa-regular', isPlaying ? 'fa-circle-notch' : 'fa-circle']"
                            :class="['fa-lg fa-stack-1x', isPlaying ? 'fa-spin': '']" />
                        <font-awesome-icon :icon="['fa-solid', isPlaying ? 'fa-pause' : 'fa-play']"
                            class="fa-lg fa-stack-1x small-icon" style="transform: scale(.5);" />
                    </span>

                </button>
            </template>
        </MusicTrack>
        <WaveformV2 class="waveform" ref="miniplayer" :key="currentTrack.id"
            :containerId="`miniplayer-song-${currentTrack.id}`" :sourceUrl="currentTrack.playableLink"
            @is-playing="onPlayPause" @ready="playWhenReady" @progress="onProgress" @error="onError" />
        <template v-if="hasError">
            <div class="error-text">There was an error while trying to load this track.</div>
        </template>
    </div>
</template>

<script>
import MusicTrack from './MusicTrack.vue';
import WaveformV2 from '../../ui/WaveformV2.vue';
import { debounce, map } from 'lodash';
import { mapActions } from 'vuex';

export default {
    components: {
        MusicTrack,
        WaveformV2
    },
    props: {
        token: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            currentTrack: null,
            isActive: false,
            hasError: true
        }
    },
    computed: {
        isSelected: {
            get() {
                if (this.currentTrack?.id) {
                    return map(this.$store.state.tributeVideo.selectedSongs, 'id').includes(this.currentTrack.id);
                }
                return false;
            },
        },
        isPlaying() {
            return this.$store.state.miniPlayer.isPlaying;
        },
        playInBackground() {
            return this.$store.state.miniPlayer.playInBackground;
        }
    },
    methods: {
        ...mapActions(['showSnackbar']),
        stop() {
            this.$refs.miniplayer.pauseAudio();
            this.$root.$emit('musicStop');
            this.$store.dispatch('miniPlayer/clearCurrentTrack');
            this.isActive = false;
            setTimeout(() => {
                this.currentTrack = null;
            }, 600);
        },
        onPlayPause: debounce(function(isPlaying) {
            this.$root.$emit('musicStatus', this.currentTrack, isPlaying);
            this.$store.dispatch('miniPlayer/updatePlayingStatus', isPlaying);
        }, 100),
        playWhenReady() {
            if (!this.$refs.miniplayer.wavesurfer.isPlaying()) {
                this.$refs.miniplayer.togglePlaying();
            }
        },
        onError(err) {
            if (this.playInBackground) {
                this.showSnackbar({ message: 'There was an error while trying to play music track.', color: 'error' });
            }
            this.hasError = true;
            this.$nextTick(() => {
                this.$store.dispatch('miniPlayer/setErrorState', true);
                this.$store.dispatch('miniPlayer/updatePlayingStatus', false);
            })
        },
        onProgress(time) {
            // console.log('onProgress', time);
            this.$store.dispatch('miniPlayer/updateProgress', time);
        }
    },
    mounted() {
        // Mini Player emits status events to update the rest of the app
        this.$root.$on('musicPause', (song) => {
            if (this.currentTrack.id === song.id && this.$refs.miniplayer) {
                this.$refs.miniplayer.pauseAudio();
            }
        });
        this.$root.$on('musicPlay', (song) => {
            if (this.currentTrack?.id !== song.id) {
                this.hasError = false;
                this.currentTrack = song;
                this.$nextTick(() => {
                    this.isActive = true;
                });
            } else if (this.isActive && this.currentTrack?.id === song.id) {
                if (this.$refs.miniplayer) {
                    this.$refs.miniplayer.togglePlaying();
                }
            }
        });
    }
}
</script>

<style lang="scss" scoped>
.mini-player {
    width: 60vw;
    max-width: 460px;
    background: $burnt-orange;
    color: white;
    position: absolute;
    bottom: -400px;
    z-index: 999;
    border-radius: 10px;
    transition: bottom 800ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
    &.active {
        bottom: 20px;
    }
    &.background-mode {
        bottom: -400px;
        position: fixed;
        z-index: -1;
    }
    ::v-deep {
        .track {
            background: transparent;
        }
    }
    .close {
        position: absolute;
        right: 2px;
        top: 0px;
        .stop-btn {
            &::v-deep {
                background: transparent !important;
                color: white !important;
                height: 24px;
                width: 24px;
            }
            &:hover {
                background: lighten($btn-orange, 10%) !important;
            }
        }
    }
    @include mobile() {
        width: 92vw;
        bottom: 69px;
        max-width: 320px;
    }
}

.waveform {
    margin: 10px;
}
.error-text {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 10px;
    background: transparent;
    font-weight: 500;
}
</style>