<template>
    <AppLayout :has-background="true" :contained="true">
        <div class="bg-white p-4 rounded elevation-6">
            <div style="display: flex; justify-content: space-between; flex-wrap: wrap" class="header mb-0">
                <h4><font-awesome-icon class="mr-2" icon="fa-regular fa-gear" />Admin Settings</h4>

                <div
                    v-if="
                        funeralHomeData.funeralHome.name &&
                        ($auth.role.includes('SuperAdmin') ||
                            $auth.role.includes('Owner') ||
                            $auth.role.includes('RegionalManager'))
                    "
                    class="d-flex flex-column align-end"
                >
                    <v-chip
                        class="mb-1 elevation-2"
                        label
                        dark
                        :color="fhStorageType === 3 ? '#FFA000' : '#0d3d60'"
                        @click="$router.push(`/services/homes/${$route.params.id}`)"
                    >
                        <span v-if="$auth.role.includes('SuperAdmin')">
                            {{ funeralHomeData.funeralHome.id }}
                        </span>
                        <v-divider v-if="$auth.role.includes('SuperAdmin')" class="ml-3 mr-3" dark vertical></v-divider>
                        <span>
                            {{ funeralHomeData.funeralHome.name }}
                        </span>
                    </v-chip>

                    <span
                        class="mt-1"
                        v-if="$auth.role.includes('SuperAdmin') && fhStorageType === 3"
                        style="color: #ffa000"
                        >Archived</span
                    >
                </div>
            </div>
            <v-btn class="mt-2 mb-2" @click="$router.go(-1)">back</v-btn>

            <!-- <div style="border: 2px solid blue" class="ml-10 mb-8">
                <p v-if="funeralHomeData.funeralHome.name && $auth.role.includes('SuperAdmin')">
                    {{ funeralHomeData.funeralHome.name }} : ({{ funeralHomeData.funeralHome.id }})
                </p>
                <p
                    v-if="
                        funeralHomeData.funeralHome.name &&
                        ($auth.role.includes('Owner') || $auth.role.includes('RegionalManager'))
                    "
                >
                    {{ funeralHomeData.funeralHome.name }}
                </p>
            </div> -->

            <v-progress-circular v-if="!token" indeterminate color="primary"></v-progress-circular>
            <div v-else>
                <v-tabs show-arrows v-model="tab" color="#0d5259">
                    <v-tab href="#details">Details</v-tab>
                    <v-tab href="#branding">Branding</v-tab>
                    <v-tab href="#marketing">Marketing</v-tab>
                    <v-tab href="#settings">Settings</v-tab>
                    <v-tab :disabled="isDevHome" href="#billing" v-if="$auth.role.includes('SuperAdmin')"
                        >Billing</v-tab
                    >
                    <v-tab href="#subscription">Subscription</v-tab>
                    <v-tab v-if="this.detailsForm.keywordReportLink" href="#keywordReport">Keyword Report</v-tab>

                    <v-tab href="#acctManagement" v-if="$auth.role.includes('SuperAdmin')">Account Management</v-tab>
                    <v-tab
                        href="#acctManagementOwner"
                        v-if="$auth.role.includes('Owner') || $auth.role.includes('RegionalManager')"
                        >Account Management</v-tab
                    >
                    <v-tab href="#integrations">Integrations</v-tab>
                    <v-tab href="#musicLicense">Music License</v-tab>
                    <v-tab href="#products" v-if="$auth.role.includes('SuperAdmin')">Products</v-tab>
                    <v-tab href="#logs" v-if="$auth.role.includes('SuperAdmin')">Logs</v-tab>
                    <!-- <v-tab href="#products" v-if="$auth.role.includes('SuperAdmin')">Products</v-tab> -->
                </v-tabs>

                <v-tabs-items :value="tab">
                    <v-tab-item value="details" v-if="tab === 'details'">
                        <div class="tab-padding">
                            <div class="form-container">
                                <v-form ref="form" lazy-validation>
                                    <h5>Funeral Home Settings</h5>
                                    <v-text-field
                                        v-model="detailsForm.name"
                                        :rules="[
                                            v => !!v || 'Name is required',
                                            v => !this.containsEmoji(v) || 'Name should not contain emojis',
                                        ]"
                                        label="Funeral Home Name"
                                        required
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="detailsForm.address1"
                                        label="Address 1"
                                        required
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="detailsForm.address2"
                                        label="Address 2"
                                        required
                                    ></v-text-field>

                                    <v-row class="mt-0">
                                        <v-col>
                                            <v-text-field
                                                v-model="detailsForm.city"
                                                label="City"
                                                required
                                            ></v-text-field>
                                        </v-col>

                                        <v-col>
                                            <v-select
                                                v-if="selectedCountry == 'US'"
                                                attach=""
                                                v-model="detailsForm.state"
                                                :items="usStates"
                                                item-text="label"
                                                item-value="value"
                                                :rules="[v => !!v || 'State is required']"
                                                label="State"
                                            ></v-select>
                                            <v-select
                                                v-else-if="selectedCountry == 'CA'"
                                                attach=""
                                                v-model="detailsForm.state"
                                                :items="candaianProvinces"
                                                item-text="name"
                                                item-value="abbreviation"
                                                :rules="[v => !!v || 'State is required']"
                                                label="Province"
                                            ></v-select>

                                            <v-text-field
                                                v-else
                                                v-model="detailsForm.state"
                                                label="State"
                                                :rules="[v => !!v || 'State is required']"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col>
                                            <v-text-field
                                                v-model="detailsForm.zipCode"
                                                label="Zip"
                                                :rules="[v => !!v || 'This field is required']"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-autocomplete
                                        attach=""
                                        label="Country"
                                        :items="countries"
                                        item-text="name"
                                        item-value="abbreviation"
                                        v-model="selectedCountry"
                                        :rules="[v => !!v || 'Country is required']"
                                    ></v-autocomplete>

                                    <v-text-field
                                        v-model="detailsForm.email"
                                        :rules="[v => !!v || 'Email is required']"
                                        label="Billing Email"
                                        hint="The billing email is where MemoryShare will send all invoices too. This person does not need to have a login to MemoryShare."
                                        :persistent-hint="true"
                                        type="email"
                                        required
                                        class="mb-5"
                                    ></v-text-field>

                                    <v-row>
                                        <v-col v-if="!$auth.role.includes('Owner')">
                                            <v-select
                                                attach=""
                                                v-model="detailsForm.ownerId"
                                                :items="OwnerIdList"
                                                :disabled="!$auth.role.includes('SuperAdmin')"
                                                item-text="text"
                                                item-value="val"
                                                label="Owner Id"
                                                :rules="[
                                                    v => !!v || 'Owner ID is required',
                                                    v => v > 0 || 'Owner ID cannot be 0',
                                                ]"
                                            ></v-select>
                                        </v-col>
                                        <v-col v-if="!$auth.role.includes('FuneralHome')">
                                            <v-select
                                                attach=""
                                                v-model="detailsForm.rmUserId"
                                                :items="rmList"
                                                item-text="text"
                                                item-value="val"
                                                label="Regional Manager Id"
                                            ></v-select>
                                        </v-col>
                                        <v-col>
                                            <v-select
                                                attach=""
                                                v-model="detailsForm.defaultUser"
                                                :items="users"
                                                :item-text="getFullName"
                                                item-value="id"
                                                label="Default User"
                                                hint="This will be the default contact for live stream support"
                                                persistent-hint
                                                no-data-text="No users available"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <v-text-field
                                        v-model="detailsForm.primaryContact"
                                        :rules="[v => !!v || 'Primary Contact is required']"
                                        label="Primary Contact Name"
                                        required
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="detailsForm.primaryContactPhoneNumber"
                                        v-mask="'###.###.####'"
                                        label="Primary Contact Phone"
                                        type="phone"
                                        required
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="detailsForm.primaryContactEmail"
                                        :rules="[v => !!v || 'Email is required']"
                                        label="Primary Contact Email"
                                        type="email"
                                        required
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="detailsForm.funeralHomeNumber"
                                        v-mask="'###.###.####'"
                                        :rules="[v => !!v || 'Funeral Home Number is required']"
                                        type="phone"
                                        label="Funeral Home Number"
                                        required
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="detailsForm.websiteLink"
                                        label="Website <a href='https://google.com'>Click me</a>"
                                        :rules="[v => !!v || 'Valid link is required']"
                                    >
                                        <template v-slot:label>
                                            <div style="display: flex">
                                                Website Link

                                                <div>
                                                    <custom-tooltip :tooltipProps="{ top: true }">
                                                        <template v-slot:activator>
                                                            <font-awesome-icon
                                                                class="ml-2 link-btn"
                                                                @click.stop="handleLink(detailsForm.websiteLink)"
                                                                icon="fa-regular fa-arrow-up-right-from-square"
                                                            ></font-awesome-icon>
                                                        </template>

                                                        <template v-slot:content>
                                                            <span>Open Website</span>
                                                        </template>
                                                    </custom-tooltip>
                                                </div>
                                            </div>
                                        </template>
                                    </v-text-field>

                                    <v-text-field
                                        v-model="detailsForm.landingPage"
                                        readolnly
                                        label="Landing Page"
                                    ></v-text-field>
                                    <a :href="detailsForm.landingPage">Visit Landing Page</a>

                                    <v-autocomplete
                                        v-model="detailsForm.timezone"
                                        :items="timezoneOptions"
                                        item-value="id"
                                        item-text="displayName"
                                        :menu-props="{ closeOnClick: true }"
                                        label="Timezone"
                                    ></v-autocomplete>

                                    <v-text-field
                                        v-model="detailsForm.keywordReportLink"
                                        readolnly
                                        label="Keyword Report Link"
                                    ></v-text-field>

                                    <v-checkbox
                                        v-model="detailsForm.detailsFormdevHome"
                                        readonly
                                        disabled
                                        label="*Dev Home (Will be ignored from analytics, and billing)"
                                        style="width: 450px"
                                        v-if="!$auth.role.includes('Owner')"
                                    ></v-checkbox>

                                    <v-btn @click="openShopTab()" v-if="$auth.role.includes('SuperAdmin')"
                                        >Shop Page</v-btn
                                    >

                                    <div class="text-right mt-10 mb-6">
                                        <v-btn
                                            @click.prevent="saveHome()"
                                            :loading="busy"
                                            :disabled="busy"
                                            color="primary"
                                            >Save Home</v-btn
                                        >
                                    </div>
                                </v-form>
                            </div>
                        </div>
                    </v-tab-item>

                    <v-tab-item value="branding">
                        <div class="tab-padding">
                            <branding :token="token" :id="$route.params.id" :isActive="tab === 'branding'" />
                        </div>
                    </v-tab-item>

                    <v-tab-item value="marketing">
                        <div class="tab-padding">
                            <marketing-panel :token="token" :id="$route.params.id" />
                        </div>
                    </v-tab-item>

                    <v-tab-item value="settings" v-if="tab === 'settings'">
                        <div class="tab-padding">
                            <general-settings
                                :modal="false"
                                :id="$route.params.id"
                                :token="token"
                                ref="homeSettings"
                                @message="setSnackBar"
                            />
                        </div>
                    </v-tab-item>

                    <v-tab-item value="musicLicense" v-if="tab === 'musicLicense'">
                        <div class="tab-padding">
                            <music-license
                                :adminFuneralHomeId="funeralHomeData.funeralHome.id"
                                :token="token"
                                :modal="false"
                                :id="parseInt($route.params.id)"
                                ref="homeSettings"
                            />
                        </div>
                    </v-tab-item>

                    <v-tab-item value="billing" v-if="tab === 'billing'">
                        <BillingPanel :token="token" />
                    </v-tab-item>

                    <v-tab-item value="subscription" v-if="tab === 'subscription'">
                        <SubscriptionPanel :token="token" :funeralHomeId="Number(this.$route.params.id)" />
                    </v-tab-item>

                    <v-tab-item value="integrations" v-if="tab === 'integrations'">
                        <IntegrationSettings
                            :token="token"
                            :toggleLoading="toggleLoading"
                            :funeralHomeId="Number(this.$route.params.id)"
                            ref="integrationSettings"
                        />
                    </v-tab-item>

                    <v-tab-item value="acctManagement" v-if="tab === 'acctManagement'">
                        <div class="tab-padding">
                            <account-management-panel
                                :funeralHomeName="funeralHomeData.funeralHome.name"
                                :token="token"
                                :funeralHomeId="Number(this.$route.params.id)"
                            />
                        </div>
                    </v-tab-item>

                    <v-tab-item value="keywordReport" v-if="tab === 'keywordReport'">
                        <div class="tab-padding">
                            <keyword-report-panel
                                v-if="funeralHomeData.funeralHome.keywordReportLink"
                                :funeralHomeId="Number(this.$route.params.id)"
                            />
                        </div>
                    </v-tab-item>

                    <v-tab-item value="acctManagementOwner" v-if="tab === 'acctManagementOwner'">
                        <div class="tab-padding">
                            <account-management-owner
                                :funeralHomeName="funeralHomeData.funeralHome.name"
                                :token="token"
                                :funeralHomeId="Number(this.$route.params.id)"
                            />
                        </div>
                    </v-tab-item>

                    <v-tab-item value="logs" v-if="tab === 'logs'">
                        <div class="tab-padding">
                            <LogPanel
                                :fhName="funeralHomeData.funeralHome.name"
                                :fhId="funeralHomeData.funeralHome.id"
                                :token="token"
                                @refresh="getHome"
                            />
                        </div>
                    </v-tab-item>

                    <v-tab-item value="products" v-if="tab === 'products'">
                        <div v-if="$auth.role.includes('SuperAdmin')">
                            <ProductList :funeralHomeId="Number(this.$route.params.id)" />
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>

        <v-snackbar v-model="snackbar" :timeout="3000">
            {{ message }}
        </v-snackbar>
    </AppLayout>
</template>

<script>
import GeneralSettings from '@/components/Settings/GeneralSettings';
import LineChart from '@/components/charts/LineChart';
import IntegrationSettings from '@/components/Settings/IntegrationSettings';
import SubscriptionPanel from '@/components/Panels/SubscriptionPanel';
import SuperAdminDetails from '@/components/Misc/SuperAdminDetails.vue';
import BillingPanel from '@/components/Panels/BillingPanel.vue';
import { log } from '@/utilities/debug-helpers.js';
import { mapActions } from 'vuex';
import musicLicense from '@/components/Misc/MusicLicense';
import LogPanel from '@/components/Panels/LogPanel.vue';
import Branding from '@/components/Settings/Branding.vue';
import AccountManagementPanel from '@/components/Panels/AccountManagementPanel.vue';
import AccountManagementOwner from '@/components/Panels/AccountManagementOwner.vue';
import ProductList from '@/components/Shop/ProductList.vue';
import MarketingPanel from '@/components/Panels/MarketingPanel.vue';
import { usStates, candaianProvinces, countries } from '@/constants';
import KeywordReportPanel from '@/components/Panels/KeywordReportPanel.vue';
import emojiRegex from 'emoji-regex';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    metaInfo: {
        title: 'Admin Settings',
    },
    data() {
        return {
            usStates,
            candaianProvinces,
            countries,
            selectedCountry: '',
            users: [],
            timezoneOptions: [],
            funeralHomeData: {
                funeralHome: {
                    name: '',
                },
            },
            fhStorageType: 0,
            busy: false,
            loading: true,
            undoMigrationDialog: false,
            undoMigrationStatus: 0, // 0 = not started, 1 = in progress, 2 = completed
            token: '',
            snackbar: false,
            message: '',
            stripeId: '',
            isDevHome: false,
            detailsForm: {
                name: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zipCode: '',
                email: '',
                ownerId: null,
                primaryContact: '',
                primaryContactPhoneNumber: '',
                primaryContactEmail: '',
                funeralHomeNumber: '',
                devHome: true,
                websiteLink: null,
                timezone: null,
                defaultUser: null,
                keywordReportLink: '',
            },
            billingForm: {
                streamsPerMonth: null,
                costPerAdditionalStream: null,
                maxStreamLength: null,
                costPerFifteenMinute: null,
                maxViewers: null,
                costPer100Viewers: null,
                billedAnnually: false,
                subscriptionCost: null,
                subscriptionStartDate: null,
                stripePaymentToken: null,
                subscriptionEndDate: null,
                subscriptionStatus: null,
                hasDefaultPaymentMethod: null,
            },
            currencyOptions: {
                locale: 'en-us',
                prefix: '$',
                suffix: '',
                length: 8,
                precision: 2,
            },
            refreshToken: 0,
            hasSubStartDate: false,
            date: new Date(),
            menu: false,
            modal: false,
            menu2: false,
            cancelDialog: false,
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            bandwidthData: {
                label: 'Bandwidth Data',
                borderColor: '#659fc3',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [32, 45, 12, 45, 65, 43, 55],
            },
            streamingExpensesData: {
                label: 'Streaming Expenses',
                borderColor: '#65c3b8',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [99, 120, 44, 54, 65, 23, 43],
            },
            totalExpenseseData: {
                label: 'Total Expenses',
                borderColor: '#c38965',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [23, 45, 32, 87, 67, 66, 56],
            },
            profitData: {
                label: 'Profit',
                borderColor: '#6570c3',
                backgroundColor: 'rgba(0,0,0,0)',
                data: [76, 87, 67, 77, 88, 55, 66],
            },
            datacollection: null,
            chartReady: false,
            confirmCancel: false,
            subscriptionStatus: ['active', 'canceled'],
            OwnerIdList: [
                { text: 'Shane', val: 0 },
                { text: 'Vertin', val: 1 },
                { text: 'Dev Home', val: 2 },
                { text: 'Dev Owner', val: 3 },
            ],
            requiredRules: [v => !!v || 'This field is required'],
            rmList: [],
        };
    },
    watch: {
        tab: {
            handler() {
                if (this.tab === 'settings') {
                    setTimeout(() => {
                        this.$refs.homeSettings.getSettings();
                    });
                }

                if (this.tab === 'profitability') {
                    this.setChart();
                }
            },
            selectedCountry() {
                if (!this.loading) {
                    this.detailsForm.state = '';
                }
            },
        },
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(result => {
            this.token = result.__raw;

            this.getTimezoneOptions()
                .then(() => {
                    this.getOwners().then(() => {
                        this.getHome();
                    });
                })
                .catch(() => {
                    this.showSnackbar({ message: 'Error Loading Timezones' });
                });

            this.getUsers(this.$route.params.id);
        });
    },
    methods: {
        ...mapActions(['showSnackbar']),
        containsEmoji(string) {
            const regex = emojiRegex();
            return regex.test(string);
        },
        handleLink(url) {
            if (!url.startsWith('https://')) {
                url = 'https://' + url;
            }

            window.open(url, '_blank');
        },
        openShopTab() {
            let url =
                process.env.VUE_APP_ECOM +
                `/shop/${encodeURI(this.detailsForm.name)}/${encodeURI(this.detailsForm.name)}`;
            window.open(url, '_blank');
        },
        getUsers(id) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Users?funeralHomeId=${id}&quick=true`)
                .then(res => {
                    if (res.data) this.users = res.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        getFullName(item) {
            return item.firstName + ' ' + item.lastName;
        },
        setSnackBar(message) {
            this.message = message;
            this.snackbar = true;
        },
        toggleLoading(newState) {
            this.loading = newState;
        },
        getHome() {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/FuneralHomes/${this.$route.params.id}`)
                .then(response => {
                    this.loading = false;
                    let result = response.data;
                    this.funeralHomeData = response.data;

                    this.detailsForm.timezone = result.funeralHome.timeZone || null;
                    this.detailsForm.name = result.funeralHome.name;
                    this.detailsForm.address1 = result.funeralHome.address1;
                    this.detailsForm.address2 = result.funeralHome.address2;
                    this.detailsForm.city = result.funeralHome.city;
                    this.detailsForm.state = result.funeralHome.state;
                    this.detailsForm.zipCode = result.funeralHome.zipCode;
                    this.detailsForm.email = result.funeralHome.email;
                    this.detailsForm.ownerId = result.funeralHome.ownerId;
                    this.detailsForm.primaryContact = result.funeralHome.primaryContact;
                    this.detailsForm.primaryContactEmail = result.funeralHome.primaryContactEmail;
                    this.detailsForm.primaryContactPhoneNumber = result.funeralHome.primaryContactPhoneNumber;
                    this.detailsForm.funeralHomeNumber = result.funeralHome.funeralHomeNumber;
                    this.detailsForm.websiteLink = result.funeralHome.website;
                    this.detailsForm.landingPage =
                        window.location.origin + '/landing-page/' + encodeURI(result.funeralHome.name);

                    this.detailsForm.devHome = result.funeralHome.devHome;
                    this.detailsForm.rmUserId = result.funeralHome.rmUserId;

                    this.callsPerYear = result.settings.callsPerYear;
                    this.detailsForm.defaultUser = result.funeralHome.defaultUser;
                    this.detailsForm.keywordReportLink = result.funeralHome.keywordReportLink;

                    if (this.detailsForm.ownerId) {
                        this.getRMs(this.detailsForm.ownerId);
                    }
                    if (result.funeralHome.devHome) this.isDevHome = true;

                    this.selectedCountry = response.data.funeralHome.country;

                    this.fhStorageType = result.settings.storageType;

                    this.refreshToken++;
                })
                .catch(error => {
                    this.message = 'Error! Please try again';
                    this.snackbar = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getRMs(id) {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/users/get-regional-manager/${id}`)
                    .then(response => {
                        this.rmList = response.data.map(rm => {
                            return {
                                text: rm.firstName + ' ' + rm.lastName,
                                val: rm.id,
                            };
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
        getOwners() {
            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;
                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .get(process.env.VUE_APP_API + `/dropdown/owner`)
                        .then(response => {
                            this.OwnerIdList = response.data.map(owner => {
                                return {
                                    text: owner.name,
                                    val: owner.id,
                                };
                            });

                            this.loading = false;
                            resolve();
                        })
                        .catch(error => {
                            this.loading = false;
                            this.showSnackbar({ message: 'Error! Please try again' });
                            reject();
                        });
                });
            });
        },
        saveHome() {
            // this.busy = true;
            let valid = this.$refs.form.validate();

            if (valid) {
                let data = {
                    name: this.detailsForm.name,
                    address1: this.detailsForm.address1,
                    address2: this.detailsForm.address2,
                    city: this.detailsForm.city,
                    state: this.detailsForm.state,
                    zipCode: this.detailsForm.zipCode,
                    country: this.selectedCountry,
                    email: this.detailsForm.email,
                    ownerId: this.detailsForm.ownerId,
                    rmUserId: this.detailsForm.rmUserId ? this.detailsForm.rmUserId : null,
                    primaryContact: this.detailsForm.primaryContact,
                    primaryContactEmail: this.detailsForm.primaryContactEmail,
                    primaryContactPhoneNumber: this.detailsForm.primaryContactPhoneNumber,
                    funeralHomeNumber: this.detailsForm.funeralHomeNumber,
                    website: this.detailsForm.websiteLink,
                    timeZone: this.detailsForm.timezone,
                    defaultUser: this.detailsForm.defaultUser,
                    keywordReportLink: this.detailsForm.keywordReportLink,
                };

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .put(process.env.VUE_APP_API + `/FuneralHomes/${this.$route.params.id}`, data)
                    .then(response => {
                        this.message = `Home successfully updated`;
                    })
                    .catch(error => {
                        this.message = 'Error! Please try again';
                    })
                    .then(() => {
                        this.snackbar = true;
                        this.busy = false;
                        try {
                            this.$refs.integrationSettings.getUserSettings();
                        } catch {}
                    });
            }
        },
        getTimezoneOptions() {
            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(result => {
                    const _TOKEN = result.__raw;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                        .get(process.env.VUE_APP_API + `/dropdown/timezones`)
                        .then(response => {
                            let result = response.data;
                            this.timezoneOptions = this.sortTimezones(result);
                            resolve(result);
                        })
                        .catch(error => {
                            reject();
                        });
                });
            });
        },
        sortTimezones(data) {
            const first = data.filter(e => e.displayName.includes('US'));
            const next = data.filter(
                e =>
                    e.displayName.includes('Arizona') ||
                    e.displayName.includes('Hawaii') ||
                    e.displayName.includes('Alaska'),
            );
            const rest = data.filter(
                e =>
                    !e.displayName.includes('US') &&
                    !e.displayName.includes('Arizona') &&
                    !e.displayName.includes('Hawaii') &&
                    !e.displayName.includes('Alaska'),
            );
            return first.concat(next, rest);
        },
        setChart() {
            let labels = [];
            for (let i = 0; i < 7; i++) {
                labels.push(this.$moment(this.startOfWeek).add(i, 'days').format('MM/DD/YYYY'));
            }
            this.datacollection = {
                labels: labels,
                datasets: [this.bandwidthData, this.streamingExpensesData, this.totalExpenseseData, this.profitData],
            };
        },
        undoMigration() {
            if (this.$auth.role.includes('SuperAdmin')) {
                this.undoMigrationStatus = 1; // in progress
                this.undoMigrationDialog = false;
                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .post(process.env.VUE_APP_API + `/Migration/undo/${this.funeralHomeData.funeralHome.id}`)
                    .then(response => {
                        this.undoMigrationStatus = 2; // completed
                        this.showSnackbar({
                            message: 'Migration successfully reverted',
                        });
                        setTimeout(() => {
                            this.$router.push({ name: 'Homes' });
                        }, 2000);
                    })
                    .catch(error => {
                        console.error(error);
                        this.showSnackbar({
                            message: 'There was a problem undoing this migration. Please contact an administrator.',
                        });
                        this.undoMigrationStatus = 0; // reset
                    });
            } else {
                this.undoMigrationDialog = false;
                this.showSnackbar({ message: 'This privilege is reserved for SuperAdmins only' });
            }
        },
    },
    computed: {
        tab: {
            set(tab) {
                this.$router.replace({ query: { ...this.$route.query, tab } });
            },
            get() {
                return this.$route.query.tab;
            },
        },
    },
    components: {
        GeneralSettings,
        LineChart,
        AccountManagementOwner,
        ProductList,
        IntegrationSettings,
        SubscriptionPanel,
        SuperAdminDetails,
        BillingPanel,
        musicLicense,
        LogPanel,
        Branding,
        AccountManagementPanel,
        MarketingPanel,
        KeywordReportPanel,
        CustomTooltip,
    },
};
</script>

<style lang, SubscriptionPanel="scss" scoped>
.dash-container {
    background: url('https://d1pnnwteuly8z3.cloudfront.net/images/1fde3b9d-4dc8-422c-8e23-bbe443fd0870/f9ae4de7-3055-49e2-885e-92405c900525.svg')
        no-repeat 0 3rem;
}

.link-btn {
    cursor: pointer;
}
</style>
