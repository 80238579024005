<template>
    <div class="stepper-navigation">
        <v-btn text @click="$router.push(`/services/${$route.params.slug}`)" class="logo pointer">
            <img src="@/assets/tribute-logo.png" alt="Logo" />
        </v-btn>

        <div class="steps-container" v-if="hasUserId || currentStepIndex > 0">
            <div :class="['steps', state.isContributor ? 'is-contributor' : '']">
                <div
                    @click="changeStep(step.hash)"
                    v-for="(step, index) in steps"
                    :key="step.hash"
                    class="step"
                    :class="disabled ? 'not-allowed' : 'pointer'"
                >
                    <!-- :class="disabled ? 'disabled' : 'pointer'" -->
                    <div class="step-bar" :class="{ active: step.hash === currentStep }"></div>
                    <div class="step-number" :class="{ active: step.hash === currentStep }">
                        {{ index + 1 }}
                    </div>
                    <div class="step-title" :class="{ active: step.hash === currentStep }">
                        {{ step.name }}
                    </div>
                </div>
            </div>
            <div class="mobile-stepper">
                <v-btn @click="prev" :disabled="currentStepIndex === 0" class="prev-nav" outlined
                    ><font-awesome-icon icon="fa-solid fa-chevron-left"></font-awesome-icon
                ></v-btn>
                <v-select
                    outlined
                    dense
                    :value="currentStep"
                    :items="steps || []"
                    item-text="name"
                    item-value="hash"
                    @change="changeStep"
                    :disabled="disabled"
                    class="m-0"
                ></v-select>
                <v-btn @click="next" :disabled="currentStepIndex === steps.length - 1" class="next-nav" :elevation="0">
                    Next
                    <font-awesome-icon icon="fa-solid fa-chevron-right" class="ml-2"></font-awesome-icon>
                </v-btn>
            </div>
        </div>
        <div class="actions" v-if="state.token">
            <ShareMenu v-if="!state.isMobile" :disabled="!tributeVideoId"></ShareMenu>
            <MobilePreview v-else-if="currentStepIndex > 0"></MobilePreview>
            <ProfileMenu @change-step="val => changeStep(val)" :disabled="currentStepIndex < 1"></ProfileMenu>
        </div>
        <div v-if="$auth.role.includes('SuperAdmin')" class="admin-tools-container">
            <AdminToolsMenu v-if="!tributeEditorExpanded" @init-rerender="$emit('init-rerender')"> </AdminToolsMenu>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ProfileMenu from '@/components/Tribute/Nav/ProfileMenu.vue';
import ShareMenu from '@/components/Tribute/Nav/ShareMenu.vue';
import MobilePreview from './Layout/MobilePreview.vue';
import AdminToolsMenu from '@/components/Tribute/Nav/AdminToolsMenu.vue';
import { findIndex } from 'lodash';

export default {
    name: 'TributeStepper',
    components: { ProfileMenu, ShareMenu, AdminToolsMenu, MobilePreview },
    props: {
        currentStep: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        stepData: {
            type: Array,
            required: true,
        },
    },
    inject: ['state'],
    computed: {
        currentStepIndex() {
            return findIndex(this.steps, { hash: this.currentStep });
        },
        tributeEditorExpanded() {
            return this.$store.state.tributeEditor.expanded;
        },
        hasUserId() {
            return this.$auth?.user && this.$auth?.user['https://memoryshare.com/user_id'] > 0;
        },
        tributeVideoId() {
            return this.$store.state.tributeVideo.id;
        },
    },
    data() {
        return {
            steps: this.$props.stepData || [],
            sharingMenuOpen: false,
        };
    },
    methods: {
        ...mapActions(['showSnackbar']),
        changeStep(val) {
            if (this.disabled) return;

            this.$emit('change-step', val);
        },
        prev() {
            if (this.currentStepIndex > 0) {
                this.changeStep(this.steps[this.currentStepIndex - 1].hash);
            }
        },
        next() {
            if (this.currentStepIndex < this.steps.length - 1) {
                this.changeStep(this.steps[this.currentStepIndex + 1].hash);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.stepper-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    padding: 10px 20px;
    box-shadow: 0px 1px 2px 0px #0000000f, 0px 1px 3px 0px #0000001a;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: #fff;
    //Using sticky instead of fixed here allows us to flex the other layout items w/o werid padding / overflow issues
    position: sticky;
    top: 0;
    z-index: 3;

    &:empty {
        background: transparent;
    }
}
.mobile-stepper {
    display: none;
}
.logo img {
    width: 144.62px;
    height: 24px;
}
.steps-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.steps-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.steps {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    max-width: 1200px;
    &.is-contributor {
        justify-content: center;
        gap: 60px;
    }
}

.step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    border-radius: 4px;
    padding: 8px;
}
.step:hover {
    background-color: #f8fafb;
}

.step.not-allowed {
    cursor: not-allowed !important;
}

.step-bar {
    width: 100px;
    height: 4px;
    background-color: #d1d5db;
    margin-bottom: 8px;
    border-radius: 6px;
}

.step-bar.active {
    background-color: #ea580c;
}

.step-number {
    font-size: 0.8rem;
    font-weight: bold;
    color: #d1d5db;
}

.step-number.active {
    color: #374151;
}

.step-title {
    font-size: 0.9rem;
    text-align: left;
    color: #d1d5db;
}

.step-title.active {
    color: #374151;
}

.actions {
    display: flex;
    gap: 10px;
    align-items: center;
}

// .share-button {
//     padding: 0.5rem 1rem;
//     font-weight: 500;
//     background-color: $primary-orange;
//     color: white;
//     border-radius: 0.375rem;
//     display: flex;
//     align-items: center;
//     gap: 8px;

//     &:disabled {
//         cursor: not-allowed;
//         opacity: 0.6;
//     }
// }

// .sharing-menu {
//     padding: 1.5rem 0.5rem;
//     display: flex;
//     flex-direction: column;

//     .v-list-item {
//         display: inline-block;
//         padding: 0.5rem;
//     }

//     .v-list-item__title {
//         font-weight: 500;
//     }

//     .v-list-item__title,
//     .v-list-item__subtitle {
//         margin: 4px 0;
//         line-height: 1.3;
//     }

//     ::v-deep {
//         .v-input.has-addon__right {
//             .v-input__slot {
//                 margin: 0;
//             }

//             .v-input__control {
//                 border-top-right-radius: 0;
//                 border-bottom-right-radius: 0;
//                 fieldset {
//                     border-color: #ccc;
//                     border-right: transparent;
//                 }
//             }

//             .v-input__append-outer {
//                 margin: 0 !important;

//                 button {
//                     border-top-left-radius: 0;
//                     border-bottom-left-radius: 0;
//                     border: 1px solid #ccc;
//                     height: 40px;
//                 }
//             }
//         }
//     }
// }

// .share-button .divider {
//     width: 1px;
//     height: 20px;
//     background-color: white;
//     margin-left: 8px;
// }

.cancel-button {
    padding: 0.4rem 1rem;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    cursor: pointer;

    &:hover {
        background-color: $light-gray;
    }
}

.chevron {
    font-size: 1rem;
    color: white;
}

.admin-tools-container {
    position: absolute;
    bottom: -40px;
    left: 10px;
}
@include mobile() {
    .steps-container {
        position: fixed;
        bottom: 0;
        width: 100vw;
        background: white;
        flex-direction: column;
        left: 0;
        right: 0;
        padding: 10px 0;
    }
    .steps {
        display: none;
    }
    .mobile-stepper {
        display: flex;
        flex-direction: row;
        gap: 16px;
        margin: 0 13px;
        align-items: center;

        .prev-nav {
            &::v-deep {
                min-width: 40px !important;
                width: 40px;
                border-color: $medium-gray;
                color: $deep-gray;
            }
        }
        .v-input {
            &::v-deep {
                .v-input__slot {
                    margin-bottom: 0;
                }
            }
        }
        .next-nav {
            &::v-deep {
                text-transform: capitalize;
                letter-spacing: normal;
                color: $burnt-orange;
                background-color: lighten($yellow-2, 30%) !important;
            }
        }
    }
}
</style>
