<template>
    <div
        @click="$emit('click')"
        :class="[
            'flex-grow-1',
            'slide photos',
            {
                'out-of-sync': outOfSync,
                incomplete: incomplete,
            },
        ]"
    >
        <div class="slide-top">
            <div class="slide-icon" v-if="incomplete">
                <slot name="fallback-icon"></slot>
            </div>
            <div :class="['thumb-stack', { singular: singleStack }]" v-else>
                <slot name="stack"></slot>
            </div>
        </div>
        <div class="slide-bottom">
            <div class="left">
                <font-awesome-icon icon="fa-regular fa-clock" v-if="outOfSync" />
                <font-awesome-icon icon="fa-regular fa-info-circle" v-else-if="incomplete" />
                <font-awesome-icon icon="fa-solid fa-check" v-else />
            </div>
            <div class="right">
                <slot name="title"> </slot>
                <p class="p-0">
                    <slot v-if="outOfSync" name="out-of-sync-message"> </slot>
                    <slot v-else name="standard-message"> </slot>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: {
        outOfSync: {
            type: Boolean,
            default: false,
        },
        incomplete: {
            type: Boolean,
            default: false,
        },
        showStack: {
            type: Boolean,
            default: false,
        },
        singleStack: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss" scoped>
.slide {
    height: 100%;
    max-height: 242px;
    min-height: 100px;
    aspect-ratio: 1;
    border-radius: 8px;
    position: relative;
    background: $light-gray;
    // transition: transform 0.2s, border 0.2s;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .slide-bottom {
        display: flex;
        width: 100%;
        align-self: flex-end;
        .left {
            flex: 0 1;
            min-width: min(20%, 82px);
            text-align: center;
            svg {
                height: 1.4em;
            }
            color: $medium-green;
        }
        .right {
            flex: 1 0;
            strong {
                margin-bottom: 10px;
            }
            p {
                font-size: 0.9rem;
                color: $primary-grey;
            }
        }
    }
    .slide-icon {
        border-radius: 100%;
        width: min(50px, 25%);
        aspect-ratio: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        inset: 10px;
        background: lighten($medium-green, 50%);
        color: $medium-green;
        img {
            width: 40%;
            filter: brightness(1.1) hue-rotate(292deg) contrast(1) saturate(15);
            user-select: none;
        }
    }
    &.incomplete {
        .left {
            color: $medium-red;
        }
        .slide-icon {
            background: lighten($medium-gray, 25%);
            color: $medium-gray;

            img {
                width: 40%;
                filter: brightness(1.5) hue-rotate(238deg) contrast(0.1) saturate(0);
            }
        }
    }
    &.out-of-sync {
        .left {
            color: $yellow-2;
        }
    }
}

.slide:hover {
    .thumb-stack:not(.singular) .stack-image {
        &:nth-child(3) {
            transform: translate(53px, 18px) rotate(2deg);
            z-index: 3;
        }

        &:nth-child(1) {
            transform: translate(15px, 35px);
            z-index: 2;
        }

        &:nth-child(2) {
            transform: translate(60px, 55px) rotate(-2deg);
            z-index: 1;
        }
    }
}

.thumb-stack {
    .stack-image {
        position: absolute;
        border-radius: 10px;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5));
        height: 60px;
        aspect-ratio: 1;
        width: 60px;
    }
    &:not(.singular) {
        .stack-image {
            transition: all 160ms ease-in-out;

            &:nth-child(3) {
                transform: translate(39px, 18px) rotate(17deg);
                z-index: 3;
            }
            &:nth-child(1) {
                transform: translate(22px, 35px);
                z-index: 2;
            }
            &:nth-child(2) {
                transform: translate(56px, 38px) rotate(-15deg);
                z-index: 1;
            }
        }
    }
    &.singular {
        .stack-image {
            transform: translate(22px, 35px);
        }
    }
}
</style>
