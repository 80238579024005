import moment from 'moment';
import { playlistTypes } from '../../constants';
const RENDER_DELAY_BUFFER = 10000;
const renderMessages = [
    'Gathering your memories.',
    'Matching music to the moments.',
    'Adding a touch of magic.',
    'Turning photos into stories.',
    'Creating a timeless tribute.',
    'Perfecting the transitions.',
    'Synchronizing music and slides.',
    'Almost done crafting your masterpiece.',
    'Bringing your cherished moments to life.',
    'Making it picture-perfect.',
];

export default {
    namespaced: true,
    state: {
        id: null,
        eventId: null,
        storyEventId: null,
        storyEvent: null,
        loading: false,
        uploadingPhotos: false,
        photos: [],
        photosOptions: {
            pageNum: 0,
            pageSize: 24,
        },
        selectedPhotos: [],
        selectedTemplates: [],
        selectedSongs: [],
        originalPlaylist: [],
        publicPlaylist: [],
        age: null,
        deeplink: null,
        familyLink: null,
        deadline: null,
        mainPhotoId: null,
        mainPhotoUrl: null,
        faceUuid: null,
        render: null,
        firstName: null,
        lastName: null,
        birthDate: null,
        deathDate: null,
        totalPhotos: 0,
        autoSlideDuration: true,
        slideDuration: 0,
        introDuration: 0,
        selectedAnimation: 0,
        selectedTransition: 0,
        minPhotos: 1,
        incomingUploadCount: 0,
        endingType: 1,
        endingDuration: 0,
        videoSlidesDuration: 0,
        videoSlidesCount: 0,
        uploadsOnly: false,
        maxItems: 0,
        renderStarted: null,
        totalDuration: 0,
        totalSlidesCount: 0,
        renderCompleted: null,
        overlayDismissed: false,
        lastSyncedChange: null,
        contributorDetailsSet: false,
        profileUploadProgress: 0,
        showProfileUploadProgress: false,
        pendingProfileUpload: null,
        renderProgress: 0,
        renderStarted: null,
        renderCompleted: false,
        currentMessageIndex: 0,
        renderMessageInterval: null,
        renderProgressInterval: null,
    },
    mutations: {
        setRenderProgress(state, payload) {
            state.renderProgress = payload;
        },
        setRenderStarted(state, payload) {
            state.renderStarted = payload;
        },
        clearRenderProgressInterval(state) {
            if (state.renderProgressInterval) {
                clearInterval(state.renderProgressInterval);
            }

            state.renderProgressInterval = null;
        },
        clearRenderMessageInterval(state) {
            if (state.renderMessageInterval) {
                clearInterval(state.renderMessageInterval);
            }

            state.renderMessageInterval = null;
        },
        setRenderCompleted(state) {
            state.renderCompleted = new Date().toISOString();
            state.renderProgress = 100;
            state.overlayDismissed = true;
        },
        setCurrentMessageIndex(state, payload) {
            state.currentMessageIndex = payload;
        },
        setMessageInterval(state, payload) {
            state.renderMessageInterval = payload;
        },
        setProgressInterval(state, payload) {
            state.renderProgressInterval = payload;
        },
        updatePendingProfileUpload(state, payload) {
            state.pendingProfileUpload = payload;
        },
        updateProfileUploadProgress(state, payload) {
            state.profileUploadProgress = payload;
        },
        updateShowProfileUploadProgress(state, payload) {
            state.showProfileUploadProgress = payload;
        },
        updateUploadingPhotos(state, payload) {
            state.uploadingPhotos = payload;
        },
        updateGalleryItem(state, payload) {
            // Find the gallery item in the state by ID
            const foundItem = state.photos.find(item => item.id === payload.id);

            if (foundItem) {
                // Update the properties of the found item
                for (const key in payload.data) {
                    if (Object.hasOwnProperty.call(payload.data, key)) {
                        foundItem[key] = payload.data[key];
                        foundItem.refreshKey++;
                    }
                }
            }
        },
        updateTributeVideoPhotosOptions(state, payload) {
            // Checking if the payload values for pageNum and pageSize are numbers and are 0 or greater
            if (typeof payload.pageNum === 'number' && payload.pageNum >= 0) {
                state.photosOptions.pageNum = payload.pageNum;
            }

            if (typeof payload.pageSize === 'number' && payload.pageSize >= 0) {
                state.photosOptions.pageSize = payload.pageSize;
            }
        },
        updateTributeVideoSelectedSongs(state, payload) {
            state.selectedSongs = payload;
        },
        updateTributeVideoPlaylist(state, payload) {
            if (payload.playlistType === playlistTypes.ORIGINAL) {
                state.originalPlaylist = payload.songs;
                return;
            }

            if (payload.playlistType === playlistTypes.PUBLIC) {
                state.publicPlaylist = payload.songs;
                return;
            }
        },
        updateTributeVideoSelectedTemplates(state, payload) {
            state.selectedTemplates = payload;
        },
        updateTributeVideoSelectedPhotos(state, payload) {
            state.selectedPhotos = payload;
        },
        updateTributeVideoPhotos(state, payload) {
            state.photos = payload;
        },
        updateTributeVideo(state, payload) {
            Object.assign(state, payload);
        },
        setTributeVideoRenderState(state, payload) {
            state.renderStarted = payload.startTime;
        },
        resetTributeVideoState(state) {
            const originalState = {
                id: null,
                eventId: null,
                storyEventId: null,
                storyEvent: null,
                loading: false,
                photos: [],
                photosOptions: {
                    pageNum: 0,
                    pageSize: 24,
                },
                selectedPhotos: [],
                selectedTemplates: [],
                selectedSongs: [],
                originalPlaylist: [],
                publicPlaylist: [],
                age: null,
                familyLink: null,
                deeplink: null,
                mainPhotoId: null,
                mainPhotoUrl: null,
                faceUuid: null,
                render: null,
                firstName: null,
                lastName: null,
                birthDate: null,
                deathDate: null,
                totalPhotos: 0,
                autoSlideDuration: true,
                introDuration: 0,
                selectedAnimation: 0,
                selectedTransition: 0,
                slideDuration: 0,
                minPhotos: 1,
                incomingUploadCount: 0,
                endingType: 1,
                endingDuration: 0,
                videoSlidesDuration: 0,
                videoSlidesCount: 0,
                deadline: null,
                onlyUploads: false,
                maxItems: 0,
                renderStarted: null,
                totalDuration: 0,
                totalSlidesCount: 0,
                renderCompleted: null,
                overlayDismissed: false,
                lastSyncedChange: null,
                contributorDetailsSet: false,
                profileUploadProgress: 0,
                showProfileUploadProgress: false,
                pendingProfileUpload: null,
                renderProgress: 0,
                renderStarted: null,
                renderCompleted: false,
                currentMessageIndex: 0,
                renderMessageInterval: null,
                renderProgressInterval: null,
            };

            if (state.renderProgressInterval) {
                clearInterval(state.renderProgressInterval);
            }

            if (state.renderMessageInterval) {
                clearInterval(state.renderMessageInterval);
            }

            Object.assign(state, originalState);
        },
    },
    actions: {
        updateRenderProgress({ commit, state }, payload) {
            const { started, duration } = payload;

            if (!duration) return;

            commit('setRenderStarted', started);
            state.renderProgressInterval = setInterval(() => {
                const now = moment().utc();
                const start = moment.utc(state.renderStarted);
                const elapsed = now.diff(start, 'seconds');

                let progress = (elapsed / duration) * 100;

                progress = progress > 100 ? 100 : Math.floor(progress);

                if (progress < 0) {
                    clearInterval(state.renderProgressInterval);
                    commit('setRenderProgress', 0);
                    return;
                }

                commit('setRenderProgress', progress);
                if (progress >= 100) {
                    clearInterval(state.renderProgressInterval);
                }
            }, 400);
        },
        cycleRenderMessages({ commit, state }) {
            const messageInterval = setInterval(() => {
                let newIndex = state.currentMessageIndex + 1;

                if (newIndex > renderMessages.length - 1) {
                    newIndex = 0;
                }

                commit('setCurrentMessageIndex', newIndex);
            }, 4000);

            commit('setMessageInterval', messageInterval);
        },
        clearRenderMessageInterval({ commit }) {
            commit('clearMessageInterval');
        },
        clearRenderProgressInterval({ commit }) {
            commit('clearProgressInterval');
        },
        clearIntervals({ commit }) {
            commit('clearRenderMessageInterval');
            commit('clearRenderProgressInterval');
        },
        setRenderCompleted({ commit }) {
            commit('setRenderCompleted');
            commit('clearRenderMessageInterval');
            commit('clearRenderProgressInterval');
        },
        updatePendingProfileUpload({ commit }, payload) {
            commit('updatePendingProfileUpload', payload);
        },
        updateProfileUploadProgress({ commit }, payload) {
            commit('updateProfileUploadProgress', payload);
        },
        updateShowProfileUploadProgress({ commit }, payload) {
            commit('updateShowProfileUploadProgress', payload);
        },
        updateUploadingPhotos({ commit }, payload) {
            commit('updateUploadingPhotos', payload);
        },
        updateGalleryItem({ commit }, payload) {
            commit('updateGalleryItem', payload);
        },
        updateTributeVideoPhotosOptions({ commit }, payload) {
            commit('updateTributeVideoPhotosOptions', payload);
        },
        updateTributeVideoSelectedSongs({ commit }, payload) {
            commit('updateTributeVideoSelectedSongs', payload);
        },
        updateTributeVideoPlaylist({ commit }, payload) {
            commit('updateTributeVideoPlaylist', payload);
        },
        updateTributeVideoSelectedTemplates({ commit }, payload) {
            commit('updateTributeVideoSelectedTemplates', payload);
        },
        updateTributeVideoSelectedPhotos({ commit }, payload) {
            commit('updateTributeVideoSelectedPhotos', payload);
        },
        updateTributeVideoPhotos({ commit }, payload) {
            commit('updateTributeVideoPhotos', payload);
        },
        updateTributeVideo({ commit }, payload) {
            commit('updateTributeVideo', payload);
        },
        resetTributeVideoState({ commit }) {
            commit('resetTributeVideoState');
        },
        dismissRenderOverlay({ commit }) {
            commit('updateTributeVideo', { overlayDismissed: true });
        },
    },
    getters: {
        minimumCreateRequirementsMet(state) {
            if (state?.selectedPhotos?.length < 1) {
                return false;
            }
            if (state?.selectedTemplates?.length < 1) {
                return false;
            }

            if (!state?.mainPhotoId > 0) {
                return false;
            }

            return true;
        },
        topThreeSelectedPhotos(state) {
            return state.selectedPhotos.filter(x => x.mediaType !== 1).slice(0, 3);
        },
        topThreeSelectedSongs(state) {
            return state.selectedSongs.slice(0, 3);
        },
        selectedPhotoCount(state) {
            return state.selectedPhotos.length;
        },
        selectedSongCount(state) {
            return state.selectedSongs.length;
        },
        selectedTemplateCount(state) {
            return state.selectedTemplates.length;
        },
        // alias of Templates
        selectedThemeCount(state) {
            return state.selectedTemplates.length;
        },
        selectedTheme(state) {
            // assumes only one theme/template can beselected at once
            return state.selectedTemplates[0];
        },
        tributeIsRendering(state) {
            if (state.overlayDismissed) {
                return false;
            }
            if (state.renderStarted && !state.renderCompleted) {
                return moment().isBefore(moment(state.renderStarted).add(state.totalDuration, 'seconds'));
            }
            return false;
        },
        delayRenderCallByMilliseconds(state) {
            // Workaround a known issue where image changes aren't ready by the time you start a render, leading to a failed render
            // We're front-loading a 10 second buffer. So the user will see the loading UI, but render call won't happen until after that initial delay
            // to make this robust we'll need to make sure that the call is executed before a user tries to leave/refresh/etc.
            if (!state.lastSyncedChange) {
                return 0;
            }
            const now = moment(),
                then = moment(state.lastSyncedChange).add(RENDER_DELAY_BUFFER, 'milliseconds');
            // Take the lastSyncedChange timestamp and add the buffer to it, return the amount of delay or 0
            if (now.isBefore(then)) {
                return Math.ceil(then.diff(now, 'milliseconds', true));
            }
            return 0;
        },
        deadlineToLocal(state) {
            if (state?.deadline?.date || state?.deadline) {
                return moment.utc(state?.deadline?.date || state?.deadline).local();
            }
            return null;
        },
        deadlineExpired(state) {
            const deadline = moment.utc(state?.deadline?.date || state?.deadline);
            if (deadline.isValid()) {
                return moment().isAfter(deadline.local());
            }
            return false;
        },
        currentRenderMessage(state) {
            return renderMessages[state.currentMessageIndex];
        },
        renderStateText(state) {
            if (state.renderCompleted) {
                return 'Render finished!';
            }
            if (state.renderProgress >= 100) {
                return 'Just a few more minutes...';
            }
            return 'Rendering your tribute video...';
        },
        formattedTributeDuration(state) {
            const seconds = Math.ceil(state.totalDuration);
            const duration = moment.duration(seconds, 'seconds');
            const minutes = duration.minutes();
            const secs = duration.seconds();

            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(secs).padStart(2, '0');

            return `${formattedMinutes}:${formattedSeconds}`;
        },
        estimatedRenderDuration(state) {
            const seconds = Math.round((state.totalDuration * 2) / 60) * 60;
            const duration = moment.duration(seconds, 'seconds');

            const minutes = duration.minutes();
            const secs = duration.seconds();
            if (minutes && secs) {
                return `${minutes} minutes and ${secs} seconds`;
            }

            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
        },
    },
};
