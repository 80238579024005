<template>
    <div v-if="standardRender && standardRender.url" class="manage-final-video-container">
        <transition name="slide-in">
            <div class="d-flex flex-column" v-show="tributeRender.standardRender && isActiveTab">
                <div v-if="publicRender" class="d-flex justify-center my-2" style="gap: 12px">
                    <v-chip
                        :dark="selectedRenderType === tributeRenderTypeEnums.ORIGINAL"
                        :outlined="selectedRenderType !== tributeRenderTypeEnums.ORIGINAL"
                        :color="selectedRenderType === tributeRenderTypeEnums.ORIGINAL ? '#f97316' : ''"
                        @click="selectRender(tributeRenderTypeEnums.ORIGINAL)"
                        >Original</v-chip
                    >
                    <v-chip
                        :dark="selectedRenderType === tributeRenderTypeEnums.PUBLIC"
                        :outlined="selectedRenderType !== tributeRenderTypeEnums.PUBLIC"
                        :color="selectedRenderType === tributeRenderTypeEnums.PUBLIC ? '#f97316' : ''"
                        @click="selectRender(tributeRenderTypeEnums.PUBLIC)"
                        >Public</v-chip
                    >
                </div>
                <div class="preview-player">
                    <div class="player-wrapper">
                        <!-- @playPreview="playPreview"
                            @pausePreview="pausePreview"
                            @change-step="val => handleChangeStep(val)" -->
                        <TributeRenderOverlay v-if="showTributeOverlay"></TributeRenderOverlay>

                        <StyledVideojsPlayer ref="finalVideoPlayer" :src="renderURL" />
                    </div>
                </div>
            </div>
        </transition>

        <div class="d-flex justify-center mt-3">
            <div class="d-flex flex-column align-center justify-center flex-grow-1 btn-flex-container">
                <transition name="slide-in">
                    <v-btn
                        v-show="tributeRender.standardRender && isActiveTab"
                        @click="downloadTributeVideo"
                        :loading="downloading"
                        :disabled="downloading || showTributeOverlay"
                        :dark="!downloading && !showTributeOverlay"
                        color="primary"
                        class="delay-order-1"
                        depressed
                        block
                    >
                        <font-awesome-icon class="mr-2" icon="fa-solid fa-arrow-down-to-bracket"></font-awesome-icon>
                        <span> Download Video </span>
                    </v-btn>
                </transition>

                <transition name="slide-in">
                    <v-btn
                        v-show="tributeRender.standardRender && isActiveTab"
                        @click="showEmbedModal = true"
                        outlined
                        class="outlined-secondary-btn delay-order-2"
                        depressed
                        block
                        :disabled="disableEmbedCodeBtn"
                    >
                        <font-awesome-icon icon="fa-solid fa-code-simple" class="mr-2" />
                        <span> Embed Code </span>
                    </v-btn>
                </transition>

                <transition name="slide-in">
                    <v-btn
                        v-show="tributeRender.standardRender && isActiveTab"
                        @click="shareToFacebook"
                        class="outlined-secondary-btn delay-order-3"
                        depressed
                        block
                        outlined
                        :disabled="downloading || showTributeOverlay"
                    >
                        <font-awesome-icon
                            icon="fa-brands fa-facebook-square"
                            style="font-size: 1.2rem; color: #0764ff"
                            class="mr-2"
                        />
                        <span> Share to Facebook </span>
                    </v-btn>
                </transition>
            </div>
        </div>

        <v-dialog v-model="showEmbedModal" :overlay-opacity="0.85" max-width="500">
            <v-card>
                <v-card-title class="tribute-heading"> Embed Code </v-card-title>
                <v-card-text>
                    <code class="embed-code"> {{ tributeEmbedCode }} </code>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn outlined class="outlined-secondary-btn" @click="() => (showEmbedModal = false)">Close</v-btn>
                    <v-btn depressed color="primary" @click="copyToClipboard(tributeEmbedCode)">
                        <font-awesome-icon class="mr-2" icon="fa-regular fa-clone"></font-awesome-icon>
                        Copy</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog max-width="500" v-model="downloading">
            <v-card>
                <v-card-title class="tribute-heading"> Downloading... </v-card-title>
                <v-card-text>
                    <span>Progress: {{ downloadProgress }}%</span>
                    <v-progress-linear
                        :color="downloadProgress === 100 ? '#ff530d' : '#fb8c00'"
                        rounded
                        v-model="downloadProgress"
                    ></v-progress-linear>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn class="no-text-transform" @click="cancelDownload" color="error" depressed>Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import StyledVideojsPlayer from '../../components/videojs/StyledVideojsPlayer.vue';
import ServicesService from '@/services/services.service';
import TributeRenderOverlay from '@/components/Tribute/RenderOverlay/RenderOverlay.vue';
import { tributeRenderTypeEnums } from '@/constants';
import TributeVideoService from '../../services/tributeVideo.service';

export default {
    data() {
        return {
            tributeRenderTypeEnums,
            selectedRenderType: 0,
            showEmbedModal: false,
            downloading: false,
            tributeEmbedCode: null,
            token: null,
            serviceApi: null,
            tributeApi: null,
            downloadProgress: 0,
            downloadCancelToken: null,
            showBtns: true,
            showSecondBtn: false,
        };
    },
    components: { StyledVideojsPlayer, TributeRenderOverlay },
    inject: ['tributeRender', 'state'],
    props: {
        tributeVideo: {
            type: Object,
            default: () => {},
        },
        isActiveTab: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('tributeVideo', {
            tributeIsRendering: 'tributeIsRendering',
        }),
        standardRender() {
            if (this.tributeRender.standardRender) return this.tributeRender.standardRender;

            return null;
        },
        publicRender() {
            if (this.tributeRender.publicRender) return this.tributeRender.publicRender;

            return null;
        },
        showTributeOverlay() {
            if (this.$store.state.tributeVideo.overlayDismissed) {
                return false;
            }
            if (this.tributeIsRendering) {
                return true;
            }
            if (this.$store.state.tributeVideo.renderStarted && this.$store.state.tributeVideo.renderCompleted) {
                return true;
            }

            return false;
        },
        renderURL() {
            if (this.selectedRenderType === this.tributeRenderTypeEnums.PUBLIC && this.publicRender?.url) {
                return this.publicRender?.url;
            }

            return this.standardRender?.url;
        },
        disableEmbedCodeBtn() {
            if (this.publicRender?.url && this.selectedRenderType === this.tributeRenderTypeEnums.ORIGINAL) {
                return true;
            }

            return false;
        },
        tributeVideoId() {
            return this.$store.state.tributeVideo.id;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        async shareToFacebook() {
            if (!this.tributeVideoId) {
                console.error('Invalid id');
                return;
            }

            const url = `${process.env.VUE_APP_API}/TributeVideo/share/tribute/player/${this.tributeVideoId}`;

            const facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);

            window.open(facebookUrl, 'Share to Facebook');
        },
        selectRender(type) {
            this.selectedRenderType = type;
        },
        copyToClipboard(val) {
            navigator.clipboard.writeText(val);
            this.showSnackbar({ message: 'Embed code copied to clipboard' });
        },
        async fetchEmbedCode() {
            try {
                if (!this.tributeVideo?.serviceId) return;

                const resp = await this.serviceApi.getEmbedCode(this.tributeVideo?.serviceId);
                if (!resp.data.htmlWrapper) return;

                var tributeEmbedURL = `${process.env.VUE_APP_VIDEO}/video/${this.$route.params.slug}/tribute-video`;
                var htmlWrapper = resp.data.htmlWrapper;

                this.tributeEmbedCode = this.replaceEmbedCodePlaceholders(htmlWrapper, tributeEmbedURL);
            } catch (error) {
                console.log('error fetching embed code', error);
            }
        },
        replaceEmbedCodePlaceholders(embedCode, srcURL) {
            const links = {
                '{{src}}': srcURL,
                '{{tributeContributeLink}}': this.tributeVideo.deeplink || '',
                '{{storyContributeLink}}': this.tributeVideo.storyContributeLink || '',
                '{{storyViewLink}}': `${process.env.VUE_APP_BASE_URI}/view-story/${this.$route.params.slug}`,
                '{{tributeViewLink}}': srcURL.endsWith('/tribute-video') ? srcURL : `${srcURL}/tribute-video`,
            };

            for (const placeholder in links) {
                embedCode = this.replacePlaceholder(embedCode, links[placeholder], placeholder);
            }

            return embedCode;
        },
        replacePlaceholder(wrapper, url, placeHolder) {
            if (!wrapper) return url;
            return wrapper.replace(placeHolder, url);
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        cancelDownload() {
            this.downloadCancelToken.cancel('Download canceled by user.');
        },
        resetDownloadState() {
            this.downloading = false;
            this.downloadCancelToken = null;
            this.downloadProgress = 0;
        },
        async downloadTributeVideo() {
            let videoUrl = this.standardRender?.url;
            let name = `${this.tributeVideo.firstName} ${this.tributeVideo.lastName}'s' Tribute`;

            if (this.selectedRenderType === this.tributeRenderTypeEnums.PUBLIC && this.publicRender?.url) {
                videoUrl = this.publicRender?.url;
                name = `${this.tributeVideo.firstName} ${this.tributeVideo.lastName}'s' Tribute (Public)`;
            }
            if (!videoUrl) {
                return;
            }
            try {
                this.showSnackbar({ message: 'Downloading' });
                this.downloadCancelToken = this.axios.CancelToken.source();

                this.downloading = true;

                const response = await this.axios.get(videoUrl, {
                    responseType: 'blob',
                    onDownloadProgress: progressEvent => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        this.downloadProgress = progress;
                    },
                    cancelToken: this.downloadCancelToken.token,
                });

                const blob = new Blob([response.data], { type: 'video/mp4' });
                const blobUrl = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.style.display = 'none';
                anchor.href = blobUrl;
                anchor.download = name;
                document.body.appendChild(anchor);
                anchor.click();
                window.URL.revokeObjectURL(blobUrl);
                this.showSnackbar({ message: 'Download complete' });
            } catch (error) {
                console.log(error);
                if (this.axios.isCancel(error)) {
                    this.showSnackbar({ message: 'Download Canceled', color: 'error' });
                } else {
                    this.showSnackbar({ message: 'Error downloading video', color: 'error' });
                }
            } finally {
                this.resetDownloadState();
            }
        },
    },
    async mounted() {
        if (this.state.token) {
            this.token = this.state.token;
        } else {
            await this.setAuthToken();
        }

        this.serviceApi = ServicesService(this.token);
        this.tributeApi = TributeVideoService(this.token);
        this.fetchEmbedCode();
    },
};
</script>
<style lang="scss" scoped>
.manage-final-video-container {
    width: 100%;
    max-width: 800px;
    height: fit-content;
}

.player-wrapper {
    padding: 12px;
    background-color: white;
    border-radius: 8px;
    position: relative;
}

.btn-flex-container {
    gap: 12px;
    max-width: min(350px, 100%);
    margin: 4px;
}
code.embed-code {
    display: block;
    background-color: #1f2227 !important;
    color: #deb887 !important;
    padding: 18px;
}

.v-btn.primary {
    &::v-deep {
        background: $primary-orange !important;
        text-transform: none;
    }
}

.v-btn.outlined-secondary-btn {
    &::v-deep {
        background-color: white;
        border-color: #ccc;
        text-transform: none;
    }
}

.delay-order-1 {
    transition-delay: 0.1s !important;
}
.delay-order-2 {
    transition-delay: 0.2s !important;
}
.delay-order-3 {
    transition-delay: 0.3s !important;
}

.slide-in-enter-active {
    transition: all 0.3s ease;
}

.slide-in-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-in-enter,
.slide-in-leave-to {
    transform: translateY(20px);
    opacity: 0;
}
.slide-in-leave,
.slide-in-enter-to {
    transform: translateY(0);
    opacity: 1;
}
</style>
