<template>
    <AppLayout :has-background="true" :contained="true">
        <div
            class="d-flex justify-end mb-4"
            v-if="
                $auth.role.includes('SuperAdmin') ||
                $auth.role.includes('Owner') ||
                $auth.role.includes('RegionalManager')
            "
        >
            <v-btn
                class="create-button"
                :to="{ name: 'CreateHome' }"
                style="display: flex; gap: 1rem; align-items: center"
            >
                <font-awesome-icon icon="fa-solid fa-plus" style="margin-right: 0.5rem" />
                <span>Create New Home</span>
            </v-btn>
        </div>

        <v-card class="p-4">
            <v-card-title style="align-items: center">
                <span style="display: flex">
                    <font-awesome-icon icon="fa-regular fa-house" style="margin-right: 0.5rem" />
                    <h4 class="m-0">Homes</h4>
                </span>

                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>

            <v-data-table
                :headers="headers"
                :items="homes"
                :options.sync="options"
                :server-items-length="totalHomes"
                :loading="tableLoading"
                :footer-props="{
                    itemsPerPageOptions: [5, 25, 50],
                }"
                loading-text="Getting homes..."
                :search="search"
                @click:row="handleRowClick"
                :must-sort="true"
            >
                <template v-slot:header.ownerName="{ header }">
                    <v-menu ref="menu" :close-on-content-click="false" :offset-y="true">
                        <template v-slot:activator="{ on: menu, attrs }">
                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <span v-bind="attrs" v-on="{ ...menu }">
                                        {{ header.text }}

                                        <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Select Filters</span>
                                </template>
                            </custom-tooltip>
                        </template>
                        <v-list class="p-3" style="width: 300px">
                            <!-- when more than one owner id is accepted at endpoint -->
                            <!-- <v-list-item v-for="(item, index) in ownersList" :key="index" style="height: 20px">
                                    <v-checkbox
                                        v-model="selectedOwners"
                                        :label="item.name"
                                        :value="item.id"
                                    ></v-checkbox>
                                </v-list-item> -->

                            <v-select :items="ownersList" v-model="selectedOwner">
                                <template v-slot:selection="{ item }">
                                    <span class="d-flex justify-center" style="width: 100%">
                                        {{ item.text }}
                                    </span>
                                </template>
                            </v-select>
                            <v-btn small @click="clearSelectedOwner" v-if="selectedOwner">Clear</v-btn>
                        </v-list>
                    </v-menu>
                </template>

                <template v-slot:header.regionalManagerName="{ header }">
                    <v-menu ref="rmMenu" :close-on-content-click="false" :offset-y="true">
                        <template v-slot:activator="{ on: menu, attrs }">
                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <span v-bind="attrs" v-on="{ ...menu }">
                                        {{ header.text }} <span v-if="selectedRM">(1) </span>

                                        <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Select Filters</span>
                                </template>
                            </custom-tooltip>
                        </template>
                        <v-list class="p-3" style="width: 300px">
                            <!-- when more than one owner id is accepted at endpoint -->
                            <!-- <v-list-item v-for="(item, index) in ownersList" :key="index" style="height: 20px">
                                    <v-checkbox
                                        v-model="selectedOwners"
                                        :label="item.name"
                                        :value="item.id"
                                    ></v-checkbox>
                                </v-list-item> -->

                            <v-select :items="rmList" item-text="text" item-value="val" v-model="selectedRM">
                            </v-select>
                            <v-btn small @click="clearSelectedRM" v-if="selectedRM">Clear</v-btn>
                        </v-list>
                    </v-menu>
                </template>

                <template v-slot:header.subscriptionStatus="{ header }">
                    <v-menu
                        :close-on-content-click="false"
                        :offset-y="true"
                        :disabled="tableLoading"
                        @input="handleMenuToggle"
                        max-height="400"
                    >
                        <template v-slot:activator="{ on: menu, attrs }">
                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <span v-bind="attrs" v-on="{ ...menu }">
                                        {{ header.text }} ({{ selectedStatuses.length }})

                                        <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Select Filters</span>
                                </template>
                            </custom-tooltip>
                        </template>

                        <v-list style="width: 200px; overflow: hidden">
                            <v-list-item>
                                <v-btn @click="toggleAllStatatuses" block small
                                    >{{ allStatusesSelected ? 'Deselect' : 'Select' }} All</v-btn
                                >
                            </v-list-item>
                            <v-list-item
                                v-for="(item, index) in subscriptionStatuses"
                                :key="index"
                                style="height: 20px"
                            >
                                <v-checkbox v-model="selectedStatuses" :label="item.label" :value="index"></v-checkbox>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>

                <template v-slot:header.lastEventTime="{ header }">
                    <v-menu
                        @input="handleMenuToggle"
                        :close-on-content-click="false"
                        :allow-overflow="false"
                        :disabled="tableLoading"
                        nudge-right="90"
                        transition="slide-x-transition"
                        :content-class="
                            selectedLastServiceSymbol === 1 || selectedLastServiceSymbol === 2 ? 'high' : 'low'
                        "
                    >
                        <template v-slot:activator="{ on: menu, attrs }">
                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <span v-bind="attrs" v-on="{ ...menu }">
                                        {{ header.text }} <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Select Filters</span>
                                </template>
                            </custom-tooltip>
                        </template>
                        <v-list
                            v-bind:class="[{ calendarShowing: calendarVisible }, 'lastServiceMenu']"
                            style="padding: 1rem"
                        >
                            <p class="m-0" style="font-size: 0.8rem; font-weight: bold">
                                Select to filter before or after date
                            </p>
                            <v-select :items="lastServiceSymbols" v-model="selectedLastServiceSymbol"></v-select>
                            <div v-if="selectedLastServiceSymbol === 1 || selectedLastServiceSymbol === 2">
                                <p class="m-0" style="font-size: 0.8rem; font-weight: bold">Select Date</p>
                                <calendar
                                    @focus="data => checkVisibility(data)"
                                    v-model="selectedDate"
                                    :output-local="true"
                                ></calendar>
                            </div>
                        </v-list>
                    </v-menu>
                </template>

                <template v-slot:header.totalServices="{ header }">
                    <div>Total Services</div>
                </template>

                <template v-slot:header.totalServicesLastSixtyDays="{ header }">
                    <div>60 Days</div>
                </template>

                <template v-slot:item.name="{ item }">
                    <div style="min-width: 100px" class="position-relative">
                        <a @click.stop v-if="item.website" :href="handleWebsiteLink(item.website)" target="_blank">{{
                            item.name
                        }}</a>
                        <span v-if="!item.website">
                            {{ item.name }}
                        </span>

                        <div v-if="$auth.role.includes('SuperAdmin')">
                            <div style="line-height: 0.9rem" class="d-flex flex-column">
                                <small v-if="isArchivedHome(item)" style="color: #ff5252"> Archived</small>
                                <small v-if="isRevertedHome(item)" style="color: #ffc400">
                                    Reverted {{ item.oldFuneralHomeId }}
                                </small>
                                <small v-if="isMigratedHome(item)" style="color: #750085">
                                    Migrated {{ item.oldFuneralHomeId }}
                                </small>
                                <small v-if="isDevHome(item)" style="color: #fb8c00">Dev</small>
                            </div>

                            <div>
                                <div v-if="isArchivedHome(item)" class="archived-home-border"></div>
                                <div v-else-if="isRevertedHome(item)" class="reverted-home-border"></div>
                                <div v-else-if="isMigratedHome(item)" class="migrated-home-border"></div>
                                <div v-else-if="isDevHome(item)" class="dev-home-border"></div>
                            </div>
                        </div>
                    </div>
                </template>
                <!--
                <template v-slot:item.contact="{ item }">
                    <a :href="`mailto:${item.email}`">{{ item.primaryContact }}</a>
                </template> -->

                <template v-slot:item.regionalManagerName="{ item }">
                    {{ item.regionalManagerName }}
                </template>

                <template v-slot:item.location="{ item }">
                    <span v-if="item.city && item.state">{{ item.city + ', ' + item.state }}</span>
                    <span v-else>{{ item.city + '' + item.state }}</span>
                </template>

                <template v-slot:item.lastEventTime="{ item }">
                    <span v-if="item.lastEventTime" class="d-block">{{ item.lastEventTime | moment('M/D/YY') }}</span>
                    <!-- <span v-if="item.lastEventTime" class="d-block">{{
                            item.lastEventTime | moment('h:mm a')
                        }}</span> -->
                </template>

                <template v-slot:item.totalServices="{ item }">
                    <div>
                        <span>{{ item.totalServices }}</span>
                    </div>
                </template>

                <template v-slot:item.totalServicesLastSixtyDays="{ item }">
                    <div>{{ item.totalServicesLastSixtyDays }}</div>
                </template>

                <template v-slot:item.integrations="{ item }">
                    <div style="display: flex; justify-content: center; align-items: center; gap: 10px">
                        <div v-if="item.passareIntegration" style="width: 30px">
                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <v-img :src="passareLogo"></v-img>
                                </template>

                                <template v-slot:content>
                                    <span>Passare</span>
                                </template>
                            </custom-tooltip>
                        </div>
                        <div v-if="item.apiIntegration" class="p-1" style="width: 35px">
                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <v-img :src="msLogo" :lazy-src="msLogo"></v-img>
                                </template>

                                <template v-slot:content>
                                    <span>Api</span>
                                </template>
                            </custom-tooltip>
                        </div>
                        <div v-if="item.facebookIntegration" class="p-1" style="width: 30px">
                            <custom-tooltip :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <v-img :src="fbLogo"></v-img>
                                </template>

                                <template v-slot:content>
                                    <span>Facebook</span>
                                </template>
                            </custom-tooltip>
                        </div>
                    </div>
                </template>

                <template v-slot:item.subscriptionStatus="{ item }">
                    <v-chip dark :color="subscriptionStatuses[item.subscriptionStatus].color">
                        {{ subscriptionStatuses[item.subscriptionStatus].label }}
                    </v-chip>
                </template>

                <template v-slot:item.actions="{ item }">
                    <div style="display: flex; gap: 12px; align-items: center; justify-content: center">
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span @click.stop="goToSettings(item.id)">
                                    <font-awesome-icon icon="fa-regular fa-gear" style="font-size: 1.1rem" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Settings</span>
                            </template>
                        </custom-tooltip>

                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span>
                                    <font-awesome-icon
                                        @click.stop="getUsers(item)"
                                        icon="fa-regular fa-user-group"
                                        style="font-size: 1.1rem"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Users</span>
                            </template>
                        </custom-tooltip>

                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span>
                                    <font-awesome-icon
                                        @click.stop="$router.push(`/analytics?fh=${item.id}`)"
                                        icon="fa-regular fa-chart-simple"
                                        style="font-size: 1.1rem"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Analytics</span>
                            </template>
                        </custom-tooltip>

                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span @click.stop="impersonate(item.id)">
                                    <font-awesome-icon
                                        icon="fa-regular fa-arrow-up-right-from-square"
                                        style="font-size: 1.1rem"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Impersonate</span>
                            </template>
                        </custom-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>

        <users-table ref="usersTable" :itemId="item.id" />

        <v-snackbar v-model="errorLoading">
            Error getting data

            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="getHomes()"> Retry </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ message }}
        </v-snackbar>
    </AppLayout>
</template>

<script>
import { subscriptionStatuses } from '@/constants';
import { mapActions } from 'vuex';
import UsersTable from '@/components/Tables/UsersTable';
import { setLocalSettings, getLocalSettings } from '@/utilities/general';
import MigrateHomeButton from '@/components/Actions/Homes/MigrateHomeButton.vue';
import Calendar from '@/components/ui/Calendar.vue';
import { debounceV2 } from '@/utilities/debounce.js';
import fbLogo from '@/assets/images/facebook.png';
import passareLogo from '@/assets/images/passare-2-icon-cropped.png';
import msLogo from '@/assets/images/logo.png';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    metaInfo: {
        title: 'Homes',
    },
    data() {
        return {
            filterMenuOpen: false,
            calendarVisible: false,
            selectedDate: '',
            selectedLastServiceSymbol: 0,
            lastServiceSymbols: [
                { text: '--', value: 0 },
                { text: 'after', value: 1 },
                { text: 'before', value: 2 },
            ],
            subscriptionStatuses,
            statusParamString: '',
            selectedStatuses: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            tableLoading: true,
            interval: {},
            value: 0,
            formValidMsg: '',
            mainFormVal: false,
            loading: true,
            loadder: false,
            snackbar: false,
            message: '',
            subMessage: '',
            subMesgBool: true,
            item: [],
            dataFunral: false,
            showButtonFlag: false,
            deleteModal: false,
            errorLoading: false,
            migrationError: false,
            loadingMessage: null,
            deleteMessage: '',
            selectedIndex: 0,
            selectedId: null,
            totalHomes: 0,
            sortAsc: false,
            sortBy: 'CreateDate',
            createOrEditModal: false,
            headers: [],
            form: {
                funeralHomeId: '',
                stripeSubscriptionId: '',
            },
            search: '',
            homes: [],
            token: '',
            options: {
                itemsPerPage: 25,
                page: 1,
            },
            selectedItem: {},
            migrationStep: 0,
            migrationPercentage: 0,
            ownersList: [],
            selectedOwner: '',
            ownerParamString: '',
            selectedRM: {},
            rmList: [],
            rmParamString: '',
            fbLogo,
            passareLogo,
            msLogo,
            allStatusesSelected: true,
        };
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },

    components: {
        Calendar,
        UsersTable,
        MigrateHomeButton,
        CustomTooltip,
    },

    watch: {
        options: {
            handler() {
                if (!this.tableLoading) {
                    this.getHomes();

                    setLocalSettings('funeralHomesTable', {
                        search: this.search,
                        page: this.options.page,
                        pageSize: this.options.itemsPerPage,
                        sortBy: this.options.sortBy,
                        sortDesc: this.options.sortDesc,
                        statuses: this.selectedStatuses,
                        owner: this.selectedOwner,
                    });
                }
            },
        },
        search: debounceV2(function () {
            if (!this.tableLoading) {
                setLocalSettings('funeralHomesTable', {
                    search: this.search,
                    page: 1,
                    pageSize: this.options.itemsPerPage,
                    sortBy: this.options.sortBy,
                    sortDesc: this.options.sortDesc,
                });

                this.options = {
                    ...this.options,
                    page: 1,
                };
            }
        }, 500),
        selectedStatuses(newVal) {
            setLocalSettings('funeralHomesTable', {
                search: this.search,
                page: this.options.page,
                pageSize: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                statuses: this.selectedStatuses,
                owner: this.selectedOwner,
            });

            this.allStatusesSelected = newVal.length === this.subscriptionStatuses.length;
        },
        selectedOwner() {
            this.ownerParamString = `&ownerId=${this.selectedOwner}`;
            this.$refs.menu.save();

            setLocalSettings('funeralHomesTable', {
                search: this.search,
                page: this.options.page,
                pageSize: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                statuses: this.selectedStatuses,
                owner: this.selectedOwner,
            });

            this.getHomes();
        },
        selectedRM() {
            if (this.$refs.rmMenu) {
                this.$refs.rmMenu.save();
            }
            this.rmParamString = `&rmUserId=${this.selectedRM}`;
            this.getHomes();
        },
        // selectedDate() {
        //     this.getHomes();
        // },
        selectedLastServiceSymbol() {
            if (!this.selectedLastServiceSymbol) {
                this.selectedDate = '';
                this.calendarVisible = false;
            }
            // this.getHomes();
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        toggleAllStatatuses() {
            if (this.allStatusesSelected) {
                this.selectedStatuses = [];
            } else {
                this.selectedStatuses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            }
        },
        isArchivedHome(item) {
            return item.storageType === 3;
        },
        isDevHome(item) {
            return item.devHome;
        },
        isMigratedHome(item) {
            return item.oldFuneralHomeId !== null && item.oldFuneralHomeId !== 0;
        },
        isRevertedHome(item) {
            return item.oldFuneralHomeId === -1;
        },
        handleWebsiteLink(website) {
            // Check if the website starts with 'http://' or 'https://'
            if (website.startsWith('http://') || website.startsWith('https://')) {
                return website; // Website already includes the protocol, return as is.
            } else {
                // Website doesn't include the protocol, assume it's a relative URL and add 'https://'
                return `https://${website}`;
            }
        },
        handleMenuToggle() {
            if (this.filterMenuOpen === false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen === true) {
                this.getHomes();
                this.filterMenuOpen = false;
            }
        },
        checkVisibility(data) {
            if (data) {
                this.showCalendar();
            } else {
                this.hideCalendar();
            }
        },
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        clearSelectedOwner() {
            this.selectedOwner = '';
            this.$refs.menu.save();
        },
        clearSelectedRM() {
            this.selectedRM = '';
        },
        getRMs() {
            this.$auth.getIdTokenClaims().then(result => {
                const _TOKEN = result.__raw;
                let id = this.$auth.user['https://memoryshare.com/owner_id'];
                this.axios
                    .create({ headers: { Authorization: `Bearer ${_TOKEN}` } })
                    .get(process.env.VUE_APP_API + `/users/get-regional-manager/${id}`)
                    .then(response => {
                        this.rmList = response.data.map(rm => {
                            return {
                                text: rm.firstName + rm.lastName,
                                val: rm.id,
                            };
                        });
                        this.rmList.push({ text: 'All Homes', val: '' });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        },
        getOwners() {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + '/dropdown/owner')
                .then(response => {
                    this.ownersList = response.data.map(owner => {
                        return {
                            text: owner.name,
                            value: owner.id,
                        };
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        showCalendar() {
            this.calendarVisible = true;
        },
        hideCalendar() {
            this.calendarVisible = false;
        },
        showProgress(item) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Migration/status/${item.oldFuneralHomeId}?preview=false`)
                .then(response => {
                    if (response.data.percentage) {
                        this.migrationPercentage = response.data.percentage;
                    } else {
                        this.migrationPercentage = 100;
                    }

                    const text = `
                    <h4 style="font-weight: bold; font-size: 20px;">${this.migrationPercentage + '%'}</h4>
                    <span style="margin: 1rem 0; font-size: 12px; color: #444">*there is a known issue of the migration appearing to be stuck between 70-80%. This is expected, during this time we are moving the analytics, and this can take anywhere from 20-30 minutes. If it takes longer than an hour, please contact Shane</span>
                    `;

                    this.$swal.fire({
                        type: 'success',
                        title: 'Migration Status',
                        html: text,
                    });
                })
                .catch(error => {
                    this.$swal.fire({
                        type: 'error',
                        title: 'Migration Status',
                        html: error.response.data.Message,
                    });
                });
        },
        filterService(item) {
            this.$router.push({ path: `/services/homes/${item.id}` });
        },
        handleRowClick(item) {
            this.filterService(item);
        },
        getSortBy() {
            const { sortBy } = this.options;
            let sortString = sortBy && sortBy[0] ? sortBy[0] : null || null;

            if (sortString) {
                switch (sortString) {
                    case 'name':
                        return 'Name';
                    case 'location':
                        return 'City';
                    case 'contact':
                        return 'PrimaryContact';
                    case 'ownerName':
                        return 'OwnerName';
                    case 'lastEventTime':
                        return 'LastEventTime';
                    case 'subscriptionStatus':
                        return 'SubscriptionStatus';
                    case 'regionalManagerName':
                        return 'RegionalManagerName';
                    case 'totalServices':
                        return 'TotalServices';
                    case 'totalServicesLastSixtyDays':
                        return 'TotalServicesLastSixtyDays';
                    case 'totalServicesLastThirtyDays':
                        return 'TotalServicesLastThirtyDays';
                    default:
                        return 'Id';
                }
            } else {
                return 'Id';
            }
        },
        getHomes() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            if (!this.selectedStatuses.length) {
                this.homes = [];
                return;
            }
            this.tableLoading = true;
            // this.loading = true;
            this.errorLoading = false;
            //history.replaceState(undefined, undefined, `?page=${ page }`)

            var string = '';
            this.statusParamString = this.selectedStatuses.forEach(
                status => (string += `&subscriptionStatus=${status}`),
            );
            this.statusParamString = string;

            const sortByField = this.getSortBy();

            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .get(
                            process.env.VUE_APP_API +
                                `/FuneralHomes?pageSize=${itemsPerPage ? itemsPerPage : 25}&pageNumber=${
                                    page ? page - 1 : 1
                                }&sortAsc=${
                                    sortBy && sortBy[0] ? !sortDesc[0] : false || false
                                }&sortBy=${sortByField}&search=${this.search.toString().toLowerCase()}${
                                    this.statusParamString
                                }&dateSymbol=${this.selectedLastServiceSymbol}&lastServiceDate=${this.selectedDate}${
                                    this.ownerParamString
                                }${this.rmParamString}`,
                        )
                        .then(response => {
                            this.homes = response.data.homes;
                            this.totalHomes = response.data.total;
                            this.tableLoading = false;

                            resolve(response.data);
                        })
                        .catch(error => {
                            // this.loading = false;
                            this.errorLoading = true;
                        });
                });
            });

            // temp
        },
        getUsers(item) {
            this.$refs.usersTable.id = item.id;
            this.$refs.usersTable.openDialog = true;
        },
        editHome(id) {
            this.$router.push({ path: `/manage-homes/${id}` });
        },
        impersonate(id) {
            // this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/superadmin/impersonate?funeralHomeId=${id}&impersonate=true`)
                .then(response => {
                    this.$router.push({ name: 'Services' });
                    this.$router.go(0);
                });
        },
        updateBilling(item) {
            this.$refs.billingModal.home = item;
            this.$refs.billingModal.openDialog();
        },
        updateSettings(item) {
            this.$refs.homeSettingsModal.home = item;
            this.$refs.homeSettingsModal.openDialog();
        },
        goToSettings(id) {
            this.$router.push({ path: `/homes/settings/${id}` });
        },
        async applyLocalSettings() {
            const settings = await getLocalSettings();

            this.selectedOwner = settings.funeralHomesTable.owner || '';

            if (settings.funeralHomesTable.statuses && settings.funeralHomesTable.statuses.length > 0) {
                this.selectedStatuses = settings.funeralHomesTable.statuses;
                this.statusParamString = settings.funeralHomesTable.statuses
                    .map(status => `&subscriptionStatus=${status}`)
                    .join('');
            } else {
                this.selectedStatuses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
                this.statusParamString = this.selectedStatuses.map(status => `&subscriptionStatus=${status}`).join('');
            }

            this.search = settings.funeralHomesTable.search || '';
            this.options = {
                itemsPerPage: settings.funeralHomesTable.pageSize || 25,
                page: settings.funeralHomesTable.page || 1,
                sortBy: settings.funeralHomesTable.sortBy || [],
                sortDesc: settings.funeralHomesTable.sortDesc || [false],
            };
        },
    },
    mounted: async function () {
        if (this.$auth.role.includes('SuperAdmin')) {
            this.headers = [
                { text: 'Id', value: 'id', sortable: true },
                { text: 'Home', value: 'name' },
                { text: 'Location', value: 'location' },
                // { text: 'Contact', value: 'contact' },
                // { text: 'RM', value: 'rmUserId' },
                { text: 'Owner', value: 'ownerName' },
                { text: 'Last Service', value: 'lastEventTime' },
                { text: 'Total Services', value: 'totalServices' },
                { text: '60 Days', value: 'totalServicesLastSixtyDays' },
                { text: '30 Days', value: 'totalServicesLastThirtyDays' },
                { text: 'Integrations', value: 'integrations' },
                { text: 'Subscription Status', value: 'subscriptionStatus' },
                { text: 'Actions', value: 'actions', width: '20%', align: 'center', sortable: false },
            ];
        }

        if (this.$auth.role.includes('Owner') || this.$auth.role.includes('RegionalManager')) {
            this.headers = [
                { text: 'Home', value: 'name' },
                { text: 'Location', value: 'location' },
                { text: 'Manager', value: 'regionalManagerName', sortable: false, width: '20%' },
                { text: 'Last Service', value: 'lastEventTime' },
                { text: 'Total Services', value: 'totalServices' },
                { text: '30 Days', value: 'totalServicesLastThirtyDays' },
                { text: '60 Days', value: 'totalServicesLastSixtyDays' },
                { text: 'Integrations', value: 'integrations' },
                { text: 'Subscription Status', value: 'subscriptionStatus' },
                { text: 'Actions', value: 'actions', width: '20%', align: 'center', sortable: false },
            ];
            this.getRMs();
            if (this.$auth.role.includes('RegionalManager')) {
                this.selectedRM = this.$auth.user['https://memoryshare.com/user_id'];
            }
        }

        try {
            await this.applyLocalSettings();
        } catch {
            let string = '';
            this.selectedStatuses.forEach(status => (string += `&subscriptionStatus=${status}`));
            this.statusParamString = string;
        }

        await this.getHomes();

        this.tableLoading = false;

        this.interval = setInterval(() => {
            if (this.value === 100) {
                return (this.value = 0);
            }
            this.value += 10;
        }, 1000);
        if (this.$auth.role.includes('SuperAdmin')) {
            this.getOwners();
        }
    },
};
</script>

<style lang="scss" scoped>
.home-state-border-container {
    position: absolute;
    border: 2px solid red;
}
.dev-home-border {
    // background: #ff530d;
    background: #fb8c00;
    position: absolute;
    left: -0.75rem;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    width: 0.4rem;
    border-radius: 5px;
}
.archived-home-border {
    background: #ff5252;
    position: absolute;
    left: -0.75rem;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    width: 0.4rem;
    border-radius: 5px;
}

.migrated-home-border {
    background: #750085;
    position: absolute;
    left: -0.75rem;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    width: 0.4rem;
    border-radius: 5px;
}

.reverted-home-border {
    background: #ffc400;
    position: absolute;
    left: -0.75rem;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    width: 0.4rem;
    border-radius: 5px;
}

.v-application p {
    margin-bottom: 0px;
    margin-right: 9px;
}

.respCall {
    width: 100%;
    padding: 10px 4px 16px 14px;
}

.textF {
    font-weight: 200;
}
.errorSub {
    color: red;
    font-size: 11px;
}

.textBenifit {
    font-size: 15px;
    margin: 8px 4px 4px 10px;
}

.create-button {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}
.lastServiceMenu {
    padding: 10px;
    width: 352px;
}
.calendarShowing {
    height: 640px;
}
.low {
    transition: 0.3s;
    margin-top: -100px;
}
.high {
    margin-top: -180px;
    transition: 0.3s;
}
</style>
