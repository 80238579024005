<template>
    <!-- <v-btn @click="test">test</v-btn> -->
    <div class="player-container">
        <div :class="[fullscreen ? 'branding-player-only-layout' : 'branding-player-layout']">
            <div v-if="showTitle" class="customSidebar">
                <div v-if="showDropDown" class="customSidebar-label">{{ title }}</div>
            </div>
            <div style="border-radius: 5px; aspect-ratio: 16/9; max-width: 100%" class="elevation-2">
                <video
                    style="border-radius: 5px"
                    id="customBrandingPlayer"
                    @play="toggleDropDown"
                    @pause="toggleDropDown"
                    controls
                    ref="videoPlayer"
                    class="video-js vjs-big-play-centered vjs-show-big-play-button-on-pause player"
                    :poster="poster"
                ></video>
            </div>
        </div>
    </div>
</template>

<script>
import videojs from 'video.js';

export default {
    name: 'BrandingPlayerPreview',
    data() {
        return {
            player: null,
            showDropDown: true,
            fullscreen: false,
            options: {
                controlBar: {
                    children: [
                        'playToggle',
                        'currentTimeDisplay',
                        'progressControl',
                        'volumePanel',
                        'fullscreenToggle',
                    ],
                },
            },
            token: '',
            loop: false,
        };
    },
    components: {
        videojs,
    },
    props: {
        videoPlayerPrimaryColor: {
            type: String,
            default: '#ab0658',
        },
        videoPlayerSecondaryColor: {
            type: String,
            default: '#ffffff',
        },
        logo: {
            type: String,
            default: '',
            required: false,
        },
        logoSize: {
            type: Number,
            default: 0,
            required: false,
        },
        source: {
            type: String,
            required: true,
        },
        logoReady: {
            type: Boolean,
            default: false,
            required: false,
        },
        title: {
            type: String,
            default: 'Test Service',
            required: false,
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
        poster: {
            type: String,
            required: false,
        },
    },
    watch: {
        videoPlayerPrimaryColor() {
            this.cssChangePrimary();
        },
        videoPlayerSecondaryColor() {
            this.cssChangeSecondary();
        },
        logoSize() {
            this.cssChangeLogoSize();
        },
        logo() {
            let x = document.getElementsByClassName('customLogo');
            if (x[0]) {
                x[0].remove();
            }
            if (this.logo && this.logoReady) {
                this.createLogoComponent(this.logo);
                //     document.getElementsByClassName('customLogo')[0].style.opacity = '1';
                // }
            }
        },
        poster() {
            if (this.player) {
                this.player.poster(this.poster);
            }
        },
    },
    methods: {
        toggleLoopState() {
            this.loop = !this.loop;

            var loopBtn = document.getElementById('repeat-control');

            if (this.loop) {
                loopBtn.classList.add('active-svg');
            } else {
                loopBtn.classList.remove('active-svg');
            }

            this.player.loop(this.loop);
        },
        play() {
            if (this.player) {
                this.player.play();
            }
        },
        pause() {
            if (this.player) {
                this.player.pause();
            }
        },
        toggleDropDown() {
            this.showDropDown = !this.showDropDown;
        },
        cssChangeLogoSize() {
            document.documentElement.style.setProperty('--custom-logo-width', this.logoSize + '%');
        },
        cssChangePrimary() {
            document.documentElement.style.setProperty('--custom-color1', this.videoPlayerPrimaryColor);
        },
        cssChangeSecondary() {
            document.documentElement.style.setProperty('--custom-color2', this.videoPlayerSecondaryColor);
        },
        initVideoJs() {
            this.player = videojs(this.$refs.videoPlayer, this.options, this.onPlayerReady);

            this.injectLoopBtn(this.player);

            this.player.src({
                type: 'video/mp4',
                src: this.source,
            });
        },
        async onPlayerReady() {
            // concat plugin -- only here for testing purposes

            // videos that don't currently work w/ concat plugin
            // error: Did not find a supported playlist for each manifest

            // https://cdn3.wowza.com/2/Qy94ZTVQcXdKM1R4/LzlVVTNW/hls/93lv8z2x/playlist.m3u8
            // https://cdn3.wowza.com/2/Qy94ZTVQcXdKM1R4/LzlVVTNW/hls/9jsbpbnw/playlist.m3u8

            // this.player.concat({
            //     manifests: [
            //         {
            //             url: 'https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8',
            //             mimeType: 'application/x-mpegURL',
            //         },
            //         {
            //             url: 'https://moctobpltc-i.akamaihd.net/hls/live/571329/eight/playlist.m3u8',
            //             mimeType: 'application/x-mpegURL',
            //         },
            //     ],

            //     targetVerticalResolution: 720,
            //     callback: (err, result) => {
            //         if (err) {
            //             console.error(err);
            //             return;
            //         }
            //         console.log(result);

            //         this.player.src({
            //             src: `data:application/vnd.videojs.vhs+json,${JSON.stringify(result.manifestObject)}`,
            //             type: 'application/vnd.videojs.vhs+json',
            //         });
            //     },
            // });
            // --- end concat plugin -----

            this.player.volume(0);
            this.player.on('fullscreenchange', () => {
                this.fullscreen = !this.fullscreen;
            });
            this.player.on('play', () => {
                document.documentElement.style.setProperty('--variable-opacity', '0');
                document.documentElement.style.setProperty('--logo-position-height', '65px');
            });
        },
        createLogoComponent(src) {
            // reference: https://jsbin.com/vobacas/edit?html,css,js,output

            const Component = videojs.getComponent('Component');

            const Logo = videojs.extend(Component, {
                constructor: function LogoComponent(player, options) {
                    Component.call(this, player, arguments);
                },
                createEl: function () {
                    const el = document.createElement('img');
                    el.className = 'customLogo';
                    el.src = src;
                    return el;
                },
            });

            videojs.registerComponent('Logo', Logo);

            this.player.addChild('Logo');
        },
        injectLoopBtn(player) {
            // add btn to control bar
            var loopBtn = player.controlBar.addChild('button', {}, 4);

            // add btn to control bar
            var loopBtnDom = loopBtn.el();

            // loopBtnDom.innerHTML = `<span id=ms-loop-btn class="vjs-icon-replay vjs-icon-placeholder"></span>`;
            loopBtnDom.innerHTML = `<span id=ms-loop-btn class="vjs-icon-placeholder"><svg class="svg-icon" id=repeat-control  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M764.16 213.333333h-512l55.466667-55.04a42.666667 42.666667 0 0 0-60.586667-60.586666l-128 128a42.666667 42.666667 0 0 0 0 60.586666l128 128a42.666667 42.666667 0 0 0 60.586667 0 42.666667 42.666667 0 0 0 0-60.586666L252.16 298.666667h512a66.56 66.56 0 0 1 67.84 65.28V469.333333a42.666667 42.666667 0 0 0 85.333333 0V363.946667A151.893333 151.893333 0 0 0 764.16 213.333333zM776.96 609.706667a42.666667 42.666667 0 0 0-60.586667 60.586666l55.466667 55.04h-512a66.56 66.56 0 0 1-67.84-65.28V554.666667a42.666667 42.666667 0 0 0-85.333333 0v105.386666A151.893333 151.893333 0 0 0 259.84 810.666667h512l-55.466667 55.04a42.666667 42.666667 0 0 0 0 60.586666 42.666667 42.666667 0 0 0 60.586667 0l128-128a42.666667 42.666667 0 0 0 0-60.586666z"  /></svg></span>`;

            loopBtn.setAttribute('id', 'ms-loop-btn');

            //add style classes
            loopBtn.addClass('vjs-control');
            loopBtn.addClass('vjs-button');
            loopBtn.addClass('pointer');

            //add tooltip
            loopBtn.controlText('Loop');

            loopBtn.on('click', () => {
                this.toggleLoopState();
            });
        },
    },
    mounted() {
        this.initVideoJs();
        let x = document.getElementsByClassName('customLogo');
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    },
};
</script>

<style lang="scss">
:root {
    --custom-color1: #ab0658;
    --custom-color2: #ffffffff;
    --variable-opacity: 1;
    --logo-position-height: 5px;
}
$control-bar-height: 60px;

#customBrandingPlayer {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    //height: 270px;
    position: relative;

    .pointer {
        cursor: pointer;
    }

    .svg-icon {
        width: 2em;
        height: 2em;
        vertical-align: middle;
        fill: rgba(255, 255, 255, 0.476);
        overflow: hidden;
    }

    .active-svg {
        fill: var(--custom-color1) !important;
    }

    /* Adjust Size of Video JS Play Icon inside button */
    .vjs-icon-placeholder:before {
        font-size: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px !important;
    }

    .vjs-big-play-button {
        background: var(--custom-color1) !important;
        border-color: var(--custom-color1) !important;
        border-radius: 5px !important;
        color: var(--custom-color2) !important;
        opacity: 0.9 !important;
        transition: 0.2s all !important;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 25%;
        height: 25%;
        max-width: 160px;
        max-height: 88px;
    }
    &:hover .vjs-big-play-button {
        background: var(--custom-color1) !important;
        border-color: var(--custom-color1) !important;
        opacity: 1 !important;
    }
    .vjs-play-progress {
        background: var(--custom-color1) !important;
    }
}

.branding-player-layout {
    position: relative;
    .customSidebar {
        position: absolute;
        z-index: 2;
        padding: 1rem;

        &-label {
            background: var(--custom-color1);
            border-radius: 5px;
            font-weight: 600;
            padding: 0.25rem;
            text-align: center;
            color: var(--custom-color2);
            transition: 0.2s all;
            padding: 1em;
            font-size: 13px !important;
            opacity: 1;
        }
    }
    .customLogo {
        width: var(--custom-logo-width);
        // max-width: 30%;
        max-width: 400px;
        position: absolute;
        bottom: var(--logo-position-height);
        right: 5px;
        z-index: 2;
        opacity: var(--variable-opacity);
        transition: opacity 0.5s linear;
    }
}
.branding-player-only-layout {
    position: relative;
    .customSidebar {
        position: absolute;
        z-index: 2;
        padding: 1rem;

        &-label {
            background: var(--custom-color1);
            border-radius: 5px;
            font-weight: 600;
            padding: 0.25rem;
            text-align: center;
            color: var(--custom-color2);
            transition: 0.2s all;
            padding: 1em;
            font-size: 13px !important;
            opacity: 1;
        }
    }
    .customLogo {
        width: var(--custom-logo-width);
        // max-width:30%;
        max-width: 400px;
        position: absolute;
        bottom: $control-bar-height + 15px;
        right: 15px;
        z-index: 2;
        opacity: var(--variable-opacity);
        transition: opacity 0.5s linear;
    }
}

//logo toggles with coontrol bar
.video-js.vjs-paused.vjs-has-started .customLogo,
.video-js.vjs-user-active.vjs-has-started .customLogo {
    opacity: 1;
}

.customBrandingPlayer-dimensions {
    width: 100%;
    height: 100%;
}
</style>
