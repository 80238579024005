import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { Auth0Plugin } from './auth';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import './assets/styles/custom.scss';
import VueLayers from 'vuelayers';
import 'vuelayers/lib/style.css';
import VueSignalR from '@latelier/vue-signalr';
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import { VueMaskDirective } from 'v-mask';
import VuetifyMoney from 'vuetify-money';
import VueMeta from 'vue-meta';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueIntercom from 'vue-intercom';

import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

import events from 'events';
events.EventEmitter.defaultMaxListeners = 30;

const domain = process.env.VUE_APP_AUTH0_CONFIG_DOMAIN,
    clientId = process.env.VUE_APP_AUTH0_CONFIG_CLIENTID;

// =============================================================================
// APP LAYOUT
// =============================================================================
import AppLayout from './components/layout/AppLayout';

// =============================================================================
// FONT AWESOME CORE
// =============================================================================
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// =============================================================================
// FONT AWESOME ICONS
// =============================================================================
import {
    faClockRotateLeft,
    faScissors,
    faGear as faSolidGear,
    faPhoneRotary,
    faCameraWeb,
    faMicrophone,
    faVideo as faVideoSolid,
    faStop,
    faCircleUser as faCircleUserSolid,
    faBellExclamation,
    faRotateExclamation,
    faEllipsisVertical,
    faMobile as faMobileSolid,
    faFolder,
    faHeart as faHeartSolid,
    faChartLineUp,
    faPenToSquare as faPenToSquareSolid,
    faShuffle,
    faTrashUndo,
    faCircleQuestion as faCirclequestionSolid,
    faShare as faShareSolid,
    faX as faXSolid,
    faUsbDrive,
    faArrowLeftLongToLine,
    faArrowRightLongToLine,
    faFamily,
    faHouse as faHouseSolid,
    faCircle as faCircleSolid,
    faArrowDownToBracket as faSolidArrowDownToBracket,
    faRotate,
    faTrash,
    faImage,
    faCheck,
    faCircleCheck,
    faShareFromSquare,
    faRotateLeft,
    faRotateRight,
    faListMusic,
    faImages as faImagesSolid,
    faBolt as faBoltSolid,
    faTriangleExclamation as faTriangleExclamationSolid,
    faThumbsUp as faThumbsUpSolid,
    faMagnifyingGlass as faMagnifyingGlassSolid,
    faBadgeDollar as faBadgeDollarSolid,
    faCircleXmark as faCircleXmarkSolid,
    faSignalStream,
    faScreenUsers,
    faCloudMusic,
    faStore,
    faToolbox,
    faCameraMovie,
    faRepeat,
    faCalendarDay,
    faHandPointer,
    faBinoculars,
    faBadgeCheck,
    faStar,
    faCalendars,
    faInfinity,
    faUser,
    faArrowRight,
    faArrowDown as faArrowDownSolid,
    faArrowUp as faArrowUpSolid,
    faBars,
    faRightFromBracket,
    faArrowLeft,
    faPlay,
    faCloudArrowUp,
    faChartSimple,
    faPlus,
    faMinus,
    faCodeSimple,
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faChevronsRight,
    faChevronLeft,
    faChevronsLeft,
    faPause,
    faPhone,
    faPhonePlus,
    faEllipsis,
    faShop,
    faLink,
    faPaperPlaneTop,
    faCompactDisc,
    faComputerClassic,
    faLocationDot,
    faPerson,
    faCalendarRange,
    faCalendarCircleUser,
    faLoader,
    faProjector,
    faChild,
    faPersonCane,
    faThoughtBubble,
    faTv,
    faFileMusic as faFileMusicSolid,
    faFileVideo as faFileVideoSolid,
    faImageSlash,
    faTimes,
    faDownload,
    faMusic as faMusicSolid,
    faCaretDown,
    faCaretUp,
    faClock as faClockSolid,
    faWandMagicSparkles as faWandMagicSparklesSolid,
    faCircleInfo as faCircleInfoSolid,
    faSlidersH,
    faPaintBrush,
    faMagicWandSparkles,
    faCircleExclamation as faCircleExclamationSolid,
    faMessage as faMessageSolid,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faBarsStaggered,
    faUsers,
    faPipe,
    faTriangleExclamation as faTriangleExclamationReg,
    faArrowRotateLeft as faArrowRotateLeftReg,
    faRotate as faRotateReg,
    faTableLayout,
    faImageUser,
    faDatabase,
    faUser as faUserReg,
    faEllipsis as faEllipsisRegular,
    faCircleArrowRight,
    faEnvelopesBulk,
    faCircleInfo,
    faMagnifyingGlassPlus,
    faRabbitRunning,
    faFileVideo,
    faBullseyeArrow,
    faScaleBalanced,
    faListCheck,
    faChartSimple as faChartSimpleReg,
    faArrowDown as faArrowDownReg,
    faGaugeMax,
    faKey,
    faBackspace,
    faArrowUpWideShort,
    faCloudExclamation,
    faBroomWide,
    faCalendarCircleExclamation,
    faArrowDownShortWide,
    faShuffle as faShuffleReg,
    faShareFromSquare as faShareFromSquareReg,
    faPlus as faPlusReg,
    faCloudPlus,
    faCircleMinus,
    faCirclePlus,
    faImage as faImageReg,
    faRotateRight as faRotateRightReg,
    faRotateLeft as faRotateLeftReg,
    faHeart as faHeartReg,
    faCalendarClock,
    faCalendarDay as faCalendarDayReg,
    faFileArrowUp,
    faArrowLeft as faArrowLeftReg,
    faArrowRight as faArrowRightReg,
    faClapperboardPlay,
    faShare,
    faBackwardFast,
    faForwardFast,
    faPlayCircle,
    faCircle as faCircleReg,
    faGrid,
    faMobileNotch,
    faPrint,
    faCloudArrowUp as faCloudArrowUpReg,
    faPhotoFilmMusic,
    faMusic,
    faCirclePlay,
    faCirclePause,
    faCircleStop,
    faX,
    faArrowUp,
    faListDropdown,
    faTags,
    faCashRegister,
    faVolume,
    faVolumeXmark,
    faBoxOpen,
    faCartShopping,
    faCircleUser,
    faCircleQuestion,
    faCircleX,
    faThumbsUp,
    faHouse,
    faVideo,
    faServer,
    faBoxArchive,
    faSuitcase,
    faDollarSign,
    faDisplay,
    faGear,
    faArrowUpRightFromSquare,
    faArrowDownToBracket,
    faUserPlus,
    faUserGroup,
    faTrashCan,
    faClock,
    faFileLines,
    faMapLocation,
    faClone,
    faPencil,
    faArrowUpFromBracket,
    faArrowRightFromBracket,
    faTriangleExclamation,
    faEnvelope,
    faLock,
    faList,
    faBolt,
    faMagnifyingGlass,
    faBan,
    faEyeSlash,
    faEye,
    faPenToSquare,
    faCode,
    faCheck as faCheckReg,
    faCheckCircle,
    faWifiExclamation,
    faGraduationCap,
    faMobile,
    faCloud,
    faShield,
    faExpand,
    faBadgeDollar,
    faImages,
    faTruckFast,
    faPalletBox,
    faMapLocationDot,
    faFileArrowDown,
    faUtilityPoleDouble,
    faFilm,
    faChevronRight as faChevronRightReg,
    faChevronLeft as faChevronLeftReg,
    faCircleNotch,
    faWrench,
    faChevronUp as faChevronUpReg,
    faPhotoVideo,
} from '@fortawesome/pro-regular-svg-icons';
import { faCcStripe, faStripe, faFacebook, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

// =============================================================================
// FONT AWESOME REGISTER
// =============================================================================
library.add(
    faMessageSolid,
    faFacebookSquare,
    faCircleExclamationSolid,
    faCircleInfoSolid,
    faWandMagicSparklesSolid,
    faClockSolid,
    faBarsStaggered,
    faCaretDown,
    faCaretUp,
    faChevronUpReg,
    faUsers,
    faMusicSolid,
    faPipe,
    faTriangleExclamationReg,
    faWrench,
    faArrowRotateLeftReg,
    faRotateReg,
    faTableLayout,
    faCheckReg,
    faImageUser,
    faDatabase,
    faUserReg,
    faImageSlash,
    faEllipsisRegular,
    faFileMusicSolid,
    faFileVideoSolid,
    faCircleArrowRight,
    faEnvelopesBulk,
    faCircleInfo,
    faMagnifyingGlassPlus,
    faRabbitRunning,
    faFileVideo,
    faClockRotateLeft,
    faScissors,
    faTv,
    faSolidGear,
    faPhoneRotary,
    faThoughtBubble,
    faBullseyeArrow,
    faScaleBalanced,
    faListCheck,
    faCameraWeb,
    faChevronLeftReg,
    faChevronRightReg,
    faChartSimpleReg,
    faArrowDownReg,
    faGaugeMax,
    faMicrophone,
    faVideoSolid,
    faStop,
    faCircleUserSolid,
    faKey,
    faBackspace,
    faBellExclamation,
    faRotateExclamation,
    faArrowUpWideShort,
    faCloudExclamation,
    faBroomWide,
    faCalendarCircleExclamation,
    faArrowDownShortWide,
    faShuffleReg,
    faShareFromSquareReg,
    faPlusReg,
    faEllipsisVertical,
    faCalendarClock,
    faCloudPlus,
    faMobileSolid,
    faCircleMinus,
    faCirclePlus,
    faImageReg,
    faRotateRightReg,
    faRotateLeftReg,
    faFolder,
    faHeartReg,
    faHeartSolid,
    faFilm,
    faCalendarDayReg,
    faChartLineUp,
    faPenToSquareSolid,
    faShuffle,
    faFileArrowUp,
    faTrashUndo,
    faCirclequestionSolid,
    faArrowRightReg,
    faArrowLeftReg,
    faShareSolid,
    faCircleCheck,
    faXSolid,
    faUsbDrive,
    faArrowLeftLongToLine,
    faArrowRightLongToLine,
    faFamily,
    faHouseSolid,
    faClapperboardPlay,
    faShare,
    faForwardFast,
    faBackwardFast,
    faPlayCircle,
    faCircleSolid,
    faCircleReg,
    faGrid,
    faMobileNotch,
    faSolidArrowDownToBracket,
    faRotate,
    faTrash,
    faPrint,
    faCloudArrowUpReg,
    faImage,
    faCheck,
    faShareFromSquare,
    faRotateLeft,
    faRotateRight,
    faListMusic,
    faImagesSolid,
    faCircleXmarkSolid,
    faTriangleExclamationSolid,
    faMagnifyingGlassSolid,
    faThumbsUpSolid,
    faBadgeDollarSolid,
    faBoltSolid,
    faHandPointer,
    faPhotoFilmMusic,
    faMusic,
    faCirclePlay,
    faCirclePause,
    faCircleStop,
    faCloudMusic,
    faRepeat,
    faSignalStream,
    faCameraMovie,
    faCalendarDay,
    faScreenUsers,
    faToolbox,
    faStore,
    faX,
    faBinoculars,
    faBadgeCheck,
    faStar,
    faPersonCane,
    faChild,
    faCalendars,
    faInfinity,
    faProjector,
    faLoader,
    faArrowUp,
    faCalendarCircleUser,
    faCalendarRange,
    faPerson,
    faLocationDot,
    faArrowDownSolid,
    faArrowUpSolid,
    faComputerClassic,
    faCompactDisc,
    faStripe,
    faPaperPlaneTop,
    faListDropdown,
    faUtilityPoleDouble,
    faLink,
    faFileArrowDown,
    faMapLocationDot,
    faPalletBox,
    faTruckFast,
    faImages,
    faBadgeDollar,
    faTags,
    faCashRegister,
    faExpand,
    faVolume,
    faVolumeXmark,
    faShop,
    faBoxOpen,
    faCheckCircle,
    faWifiExclamation,
    faGraduationCap,
    faShield,
    faCloud,
    faMobile,
    faEllipsis,
    faPhonePlus,
    faPhone,
    faMinus,
    faCartShopping,
    faCode,
    faPenToSquare,
    faEyeSlash,
    faEye,
    faPause,
    faChevronRight,
    faChevronsRight,
    faChevronLeft,
    faChevronsLeft,
    faBan,
    faMagnifyingGlass,
    faBolt,
    faList,
    faUser,
    faArrowRight,
    faCcStripe,
    faBars,
    faHouse,
    faVideo,
    faServer,
    faBoxArchive,
    faChartSimple,
    faSuitcase,
    faDollarSign,
    faDisplay,
    faCircleUser,
    faGear,
    faRightFromBracket,
    faArrowLeft,
    faPlay,
    faCircleQuestion,
    faArrowUpRightFromSquare,
    faArrowUpFromBracket,
    faArrowRightFromBracket,
    faThumbsUp,
    faCloudArrowUp,
    faPlus,
    faUserPlus,
    faUserGroup,
    faTrashCan,
    faClock,
    faFileLines,
    faMapLocation,
    faClone,
    faCodeSimple,
    faPencil,
    faCircleX,
    faTriangleExclamation,
    faEnvelope,
    faLock,
    faChevronDown,
    faChevronUp,
    faArrowDownToBracket,
    faFacebook,
    faCircleNotch,
    faTimes,
    faDownload,
    faSlidersH,
    faPaintBrush,
    faMagicWandSparkles,
    faPhotoVideo,
);

// =============================================================================
// INTERCOM
// =============================================================================

if (process.env.VUE_APP_INTERCOM_APPID) {
    Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APPID });
}

// =============================================================================
// AUTH 0
// =============================================================================
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    onRedirectCallback: appState => {
        router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
    },
});

// Add global formatMoney filter
const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
});

Vue.filter('formatMoney', function (cents) {
    const dollarAmount = cents / 100;
    return moneyFormatter.format(dollarAmount);
});

// Font Awesome Config
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.component('datetime', Datetime);
Vue.use(VueMeta);
Vue.use(VueSignalR, process.env.VUE_APP_SIGNALR_HUBS);
Vue.use(VueAxios, axios);
Vue.use(VueLayers);
Vue.use(require('vue-moment'));
Vue.use(Donut);
Vue.use(VuetifyMoney);
Vue.use(VueSweetalert2);
Vue.use(VueIntercom, { appId: 'your-app-id' });
Vue.use(PerfectScrollbar);
Vue.directive('mask', VueMaskDirective);
Vue.component('AppLayout', AppLayout);

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');
