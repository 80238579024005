<template>
    <div class="d-flex flex-column">
        <div class="action-bar mb-2">
            <div class="left-buttons"></div>
            <div class="right-buttons">
                <v-btn
                    color="primary"
                    depressed
                    @click="confirmRenderIfNeeded"
                    :disabled="!minimumCreateRequirementsMet || tributeIsRendering"
                    v-if="!state.isFamily && !state.isContributor"
                >
                    Create Video
                </v-btn>
            </div>
        </div>
        <div class="slides-container">
            <RenderRequirementSummaryCard
                v-if="stepMap['setup']"
                @click="$emit('changeStep', 'setup')"
                :outOfSync="mainPhotoChanges.length > 0"
                :incomplete="!hasMainPhoto"
                :showStack="hasMainPhoto"
                single-stack
            >
                <template #fallback-icon>
                    <img :src="photoIcon" />
                </template>

                <template #stack>
                    <ImageThumbnail :src="mainPhotoUrl" :w="60" :h="60" class="stack-image" :type="1" />
                </template>

                <template #title>
                    <strong>Profile Picture</strong>
                </template>

                <template #out-of-sync-message>
                    <span>{{ slideChanges.length }} profile changes found.</span>
                </template>

                <template #standard-message>
                    <span>You {{ hasMainPhoto ? 'have' : 'have not' }} set a profile picture.</span>
                </template>
            </RenderRequirementSummaryCard>

            <RenderRequirementSummaryCard
                v-if="stepMap['slides']"
                @click="$emit('changeStep', 'slides')"
                :outOfSync="slideChanges.length > 0"
                :incomplete="selectedPhotoCount === 0 && slideChanges.length === 0"
                :showStack="selectedPhotoCount > 0"
            >
                <template #fallback-icon>
                    <img :src="photoIcon" />
                </template>

                <template #stack>
                    <template v-for="photo in topThreeSelectedPhotos">
                        <ImageThumbnail
                            :key="photo.url"
                            :src="photo.url"
                            :w="60"
                            :h="60"
                            :type="photo.mediaType"
                            :allow-video-fallback="false"
                            class="stack-image"
                        />
                    </template>
                </template>

                <template #title>
                    <strong>Slides</strong>
                </template>

                <template #out-of-sync-message>
                    <span>{{ slideChanges.length }} slide changes found.</span>
                </template>

                <template #standard-message>
                    <span>You've uploaded {{ selectedPhotoCount }} photos.</span>
                </template>
            </RenderRequirementSummaryCard>

            <RenderRequirementSummaryCard
                v-if="stepMap['theme']"
                @click="$emit('changeStep', 'theme')"
                :outOfSync="themeChanges.length > 0"
                :incomplete="selectedThemeCount === 0 && themeChanges.length === 0"
                :showStack="selectedThemeCount > 0"
                single-stack
            >
                <template #fallback-icon>
                    <img :src="exploreIcon" alt="" />
                </template>

                <template #stack>
                    <ImageThumbnail :src="selectedTheme.url" :w="60" :h="60" class="stack-image" :type="1" />
                </template>

                <template #title>
                    <strong>Set Theme</strong>
                </template>

                <template #out-of-sync-message>
                    <span>{{ themeChanges.length }} theme changes found.</span>
                </template>

                <template #standard-message>
                    <span>{{ selectedThemeCount > 0 ? 'Theme is set.' : 'Theme is not set.' }}</span>
                </template>
            </RenderRequirementSummaryCard>

            <RenderRequirementSummaryCard
                v-if="stepMap['music']"
                @click="$emit('changeStep', 'music')"
                :outOfSync="musicChanges.length > 0"
                :incomplete="selectedSongCount === 0 && musicChanges.length === 0"
                :showStack="selectedSongCount === 0"
                :singleStack="topThreeSelectedSongs.length === 1"
            >
                <template #fallback-icon>
                    <img :src="musicIcon" alt="" />
                </template>

                <template #stack>
                    <template v-for="song in topThreeSelectedSongs">
                        <ImageThumbnail
                            :key="song.id"
                            :src="song.artwork || fallbackArtwork"
                            :w="null"
                            :h="60"
                            class="stack-image"
                        />
                    </template>
                </template>

                <template #title>
                    <strong>Add Music</strong>
                </template>

                <template #out-of-sync-message>
                    <span>{{ musicChanges.length }} music changes found.</span>
                </template>

                <template #standard-message>
                    <span>You've added {{ selectedSongCount }} tracks.</span>
                </template>
            </RenderRequirementSummaryCard>
        </div>

        <v-dialog v-model="showConfirmRenderModal" :overlay-opacity="0.85" max-width="500">
            <template v-slot:default="{ isActive }">
                <v-card>
                    <v-card-text class="p-4">
                        <h4>Regenerate Tribute Video</h4>
                        <p>
                            You've made changes to your tribute video. To reflect these changes, the video needs to be
                            regenerated. A small fee will be charged. This will take a few minutes to complete.
                        </p>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="cancel" @click="() => (showConfirmRenderModal = false)">Cancel</v-btn>
                        <v-btn
                            color="primary"
                            @click="
                                () => {
                                    submitTributeRender();
                                    showConfirmRenderModal = false;
                                }
                            "
                            >Proceed to Regenerate</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import TributeVideoService from '@/services/tributeVideo.service';
import ImageThumbnail from '../Media/ImageThumbnail.vue';
import RenderRequirementSummaryCard from './Layout/RenderRequirementSummaryCard.vue';
import moment from 'moment';
import { keyBy } from 'lodash';
export default {
    props: {
        tributeVideo: {
            type: Object,
            default: () => {},
        },
        slideChanges: {
            type: Array,
            default: () => [],
        },
        mainPhotoChanges: {
            type: Array,
            default: () => [],
        },
        themeChanges: {
            type: Array,
            default: () => [],
        },
        musicChanges: {
            type: Array,
            default: () => [],
        },
    },
    inject: ['tributeRender', 'state'],
    components: {
        ImageThumbnail,
        RenderRequirementSummaryCard,
    },
    computed: {
        isExpanded: {
            get() {
                return this.$store.state.tributeEditor.expanded;
            },
        },
        ...mapGetters('tributeVideo', [
            'minimumCreateRequirementsMet',
            'selectedPhotoCount',
            'topThreeSelectedPhotos',
            'topThreeSelectedSongs',
            'selectedSongCount',
            'selectedThemeCount',
            'selectedTheme',
            'tributeIsRendering',
        ]),
        stepMap() {
            if (this.state?.steps?.length) {
                return keyBy(this.state.steps, 'hash');
            }
            return {};
        },
        mainPhotoId() {
            return this.$store.state.tributeVideo.mainPhotoId;
        },
        mainPhotoUrl() {
            return this.$store.state.tributeVideo.mainPhotoUrl;
        },
        hasMainPhoto() {
            return this.mainPhotoId > 0 && Boolean(this.mainPhotoUrl);
        },
    },
    data() {
        return {
            tributeApi: null,
            axiosInstance: null,
            loading: false,
            renderInProg: false,
            exploreIcon: require(`@/assets/icons/explore.svg`),
            musicIcon: require(`@/assets/icons/music.svg`),
            photoIcon: require(`@/assets/icons/photo.svg`),
            fallbackArtwork: require('@/assets/images/fallback-song-art.png'),
            enableDownloadButton: false,
            enableEmbedButton: false,
            downloadCancelToken: null,
            showConfirmRenderModal: false,
            downloading: false,
            downloadProgress: 0,
            showEmbedModal: false,
            tributeEmbedCode: null,
            pendingRender: null,
            pendingRenderResolver: null,
        };
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),
        copyToClipboard(val) {
            navigator.clipboard.writeText(val);
            this.showSnackbar({ message: 'Copied to clipboard' });
        },
        testCard(val) {
            console.log('TESTING  CARD', val);
        },
        toggleHeight() {
            this.toggleExpandedState();
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        confirmRenderIfNeeded() {
            // if there are no unsynced changes then there's no need to render again..
            // Let the user know
            if (this.tributeRender?.standardRender?.url) {
                this.showConfirmRenderModal = true;
            } else {
                this.submitTributeRender();
            }
        },
        async submitTributeRender() {
            // For testing locally without triggering an actual render
            // this.$store.dispatch('tributeVideo/updateTributeVideo', { renderStarted: moment().toISOString(), renderCompleted: null, overlayDismissed: false });
            // this.$emit('render-started');
            // return;
            try {
                if (!this.tributeVideo.id) {
                    this.showSnackbar({ message: 'Error submitting render', color: 'error' });
                    return;
                }
                // Starts the render loading UI
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    renderStarted: moment().toISOString(),
                    // Reset any previous render stats/state
                    renderCompleted: null,
                    overlayDismissed: false,
                });
                // delayRenderCallByMilliseconds
                // console.info('Pre Render stats...');
                // console.info(':: Tribute Video Duration:', this.$store.state.tributeVideo.totalDuration);
                this.loading = true;
                // this.block(true);
                this.pendingRender = new Promise(resolve => {
                    const renderDelay = this.$store.getters['tributeVideo/delayRenderCallByMilliseconds'];
                    console.log('Delaying render by this much:', renderDelay);
                    let resolveTimeout = setTimeout(() => {
                        console.log('Resolving render promise with timeout');
                        this.pendingRenderResolver = null;
                        resolve();
                    }, renderDelay);
                    this.pendingRenderResolver = function () {
                        // Clear timeout since we're resolving it now instead
                        if (resolveTimeout) {
                            clearTimeout(resolveTimeout);
                        }
                        resolve();
                        this.pendingRenderResolver = null;
                    };
                }).then(() => {
                    // console.log('Render Promise resolved... kicking off delayed render to api');
                    return this.tributeApi.submitRender(this.tributeVideo.id).catch(err => {
                        console.error('Render Error:', err);
                        this.$store.dispatch('tributeVideo/updateTributeVideo', {
                            renderStarted: null,
                            overlayDismissed: true,
                            renderCompleted: null,
                        });
                        this.renderInProg = false;
                        this.showSnackbar({
                            color: 'error',
                            message: 'Rendering the video has failed. Please wait and try again.',
                        });
                    });
                });

                this.showSnackbar({
                    message: 'Tribute Video Uploaded. Please allow time for processing',
                });
                // TODO: Fetch render status on page mount
                this.renderInProg = true;
                this.$emit('render-started');
            } catch (error) {
                const errorMessage = error.response.data.Message;
                // we errored, so get rid of the rendering overlay
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    renderStarted: null,
                    overlayDismissed: true,
                    renderCompleted: null,
                });
                this.renderInProg = false;
                if (errorMessage == 'Multipart render error') {
                    this.showSnackbar({
                        color: 'error',
                        message: 'Multipart render error, please try another template.',
                    });
                } else {
                    this.showSnackbar({ color: 'error', message: 'Error creating tribute video' });
                }
            } finally {
                this.loading = false;
                // this.block(false);
            }
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    async mounted() {
        if (this.state.token) {
            this.token = this.state.token;
        } else {
            await this.setAuthToken();
        }

        this.tributeApi = TributeVideoService(this.token);
        this.createAxiosInstance(this.token);
        // Setup beforeunload
        addEventListener('beforeunload', event => {
            if (this.pendingRenderResolver) {
                // prevent user leaving until we're sure the api call has been kicked off
                event.preventDefault();
                // Included for legacy support, e.g. Chrome/Edge < 119
                event.returnValue = true;
                this.pendingRenderResolver();
                // console.info('Kicking off delayed render!! triggered by [beforeunload]');
            }
        });
    },
    beforeDestroy() {
        if (this.pendingRenderResolver) {
            // console.info('Kicking off delayed render!! triggered by [beforeDestroy]');
            this.pendingRenderResolver();
        }
    },
    beforeRouteLeave(_to, _from, next) {
        // console.log('beforeRouteLeave')
        if (this.pendingRenderResolver) {
            // console.info('Kicking off delayed render, triggered by [beforeRouteLeave]');
            this.pendingRenderResolver();
            return next();
        }
        next();
    },
};
</script>
<style lang="scss" scoped>
.primary-button,
.secondary-button {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.primary-button {
    width: 228px;
    background: #ea580c;
    color: white;
    border: none;
    height: 38px;
    border-radius: 6px;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.secondary-button {
    width: 138px;
    background: white;
    color: #374151;
    border: 1px solid #d1d5db;
    height: 38px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.primary-button:disabled {
    opacity: 0.5;
}

.action-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 38px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.right-buttons {
    display: flex;
    align-items: center;
}

.icon-button {
    margin-right: 8px;
    width: 38px;
    height: 38px;
    background: white;
    border: 1px solid #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}
.slides-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    overflow-x: auto;
    overflow-y: hidden;
    transition: height 0.8s;
    height: 100%;
}

.white-action-btn {
    @include tribute-btn(white);
}
.orange-action-btn {
    @include tribute-btn($btn-orange, white, $btn-orange);
}
.right-buttons {
    gap: 8px;
    width: auto;

    .v-btn {
        &::v-deep {
            box-shadow: none;
            text-transform: none;
            letter-spacing: normal;
        }
    }
}

.v-btn.cancel {
    &::v-deep {
        background: white !important;
        color: $medium-gray;
        outline: 1px solid $medium-gray;
    }
}
.v-btn.primary {
    &::v-deep {
        background: $primary-orange !important;
        color: white;
    }
}
</style>
