<template>
    <div>
        <div v-click-outside="handleOutsideClick" id="context-menu" class="hidden">
            <v-card>
                <v-list style="border-radius: 5px" nav dense>
                    <v-list-item-group>
                        <v-list-item
                            :disabled="item.disabled"
                            @click="handleItemClick(item)"
                            v-for="(item, i) in items"
                            :key="i"
                            :style="item.color && !item.disabled ? { color: item.color } : {}"
                        >
                            <v-list-item-icon class="mr-2">
                                <div class="d-flex align-items-center">
                                    <font-awesome-icon :icon="item.icon"></font-awesome-icon>
                                </div>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        left: {
            type: Boolean,
            default: true,
        },
        top: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleOutsideClick(e) {
            var insideClick = false;
            if (e._skipClickOutsideHandler) {
                return;
            }
            const contextEl = document.getElementById('context-menu');

            if (contextEl) {
                if (contextEl.contains(e.target)) {
                    insideClick = true;
                }
            }

            const isOutside = !insideClick;

            if (isOutside) {
                this.hideMenu();
            }
        },
        calculatePosition(e, menuElement) {
            const menuHeight = menuElement.offsetHeight; // Get the height of the menu
            const menuWidth = menuElement.offsetWidth; // Get the height of the menu

            let adjustedXPosition = e.pageX;
            let adjustedYPosition = e.pageY;

            if (this.top) {
                adjustedYPosition = e.pageY - menuHeight;
            }

            if (this.left) {
                adjustedXPosition = e.pageX - menuWidth;
            }

            // Get the viewport height
            const screenHeight = window.innerHeight;
            const screenWidth = window.innerWidth;
            const buffer = 10;

            // Ensure the menu does not go above the top of the screen
            if (adjustedXPosition < buffer) {
                adjustedXPosition = buffer;
            }

            // Ensure the menu does not go above the top of the screen
            if (adjustedYPosition < buffer) {
                adjustedYPosition = buffer;
            }

            let offsetHeight = menuHeight + adjustedYPosition + buffer;
            if (offsetHeight > screenHeight) {
                adjustedYPosition = screenHeight - menuHeight - buffer;
            }

            let offsetWidth = menuWidth + adjustedXPosition + buffer;
            if (offsetWidth > screenWidth) {
                adjustedXPosition = screenWidth - menuWidth - buffer;
            }

            return { x: adjustedXPosition, y: adjustedYPosition };
        },
        handleCustomContext(e) {
            const contextMenu = document.getElementById('context-menu');
            if (!contextMenu) return;

            const { x, y } = this.calculatePosition(e, contextMenu);

            contextMenu.style.top = y + 'px';
            contextMenu.style.left = x + 'px';

            this.showMenu();
        },
        showMenu() {
            this.attachListeners();

            const contextMenu = document.getElementById('context-menu');
            if (!contextMenu) return;

            contextMenu.classList.remove('hidden');
            this.$emit('open');
        },
        hideMenu() {
            this.removeListeners();

            const contextMenu = document.getElementById('context-menu');
            if (!contextMenu) return;

            contextMenu.classList.add('hidden');

            this.$emit('close');
        },
        handleItemClick(menuItem) {
            if (menuItem.callback && typeof menuItem.callback === 'function') {
                menuItem.callback();
                this.hideMenu();
            }
            this.$emit('item-click', menuItem);
        },
        attachListeners() {
            document.addEventListener('scroll', this.hideMenu, true);
            document.addEventListener('wheel', this.hideMenu, true);
            document.addEventListener('resize', this.hideMenu, true);
        },
        removeListeners() {
            document.removeEventListener('scroll', this.hideMenu, true);
            document.removeEventListener('wheel', this.hideMenu, true);
            document.removeEventListener('resize', this.hideMenu, true);
        },
    },
    mounted() {
        this.attachListeners();

        const contextMenu = document.getElementById('context-menu');
        if (contextMenu) {
            // Remove context-menu from the parent container
            contextMenu.remove();

            // Add context-menu directly to the body
            document.body.appendChild(contextMenu);
        }

        this.hideMenu();
    },
    beforeDestroy() {
        this.removeListeners();

        const contextMenu = document.getElementById('context-menu');

        // Remove context-menu from the parent container
        if (contextMenu) {
            contextMenu.remove();
        }
    },
};
</script>
<style lang="scss" scoped>
#context-menu {
    // border: 2px solid black;
    width: 200px;
    border-radius: 5px;
    position: absolute;
    z-index: 1000;
    // height: 200px;
    top: 79px;
    left: 91px;
    background-color: #fff;
    display: block;

    &.hidden {
        // opacity: 0;
        top: -999px;
        left: -999px;
        z-index: -10;
    }
}
</style>
