<template>
    <div class="branding-section">
        <v-form ref="form" lazy-validation>
            <v-tabs v-model="tab" color="rgb(13, 82, 89)" class="mt-3" :class="{ 'hide-tabs': !tributeVideosEnabled }">
                <v-tab href="#stream">Live Stream</v-tab>
                <v-tab href="#tribute" v-if="tributeVideosEnabled">Tribute</v-tab>
            </v-tabs>

            <v-tabs-items :value="tab">
                <v-tab-item value="stream">
                    <v-row class="mt-5">
                        <v-col>
                            <div class="d-flex justify-center">
                                <div>
                                    <h5 class="text-center">Primary Color</h5>

                                    <v-color-picker
                                        width="250px"
                                        v-model="videoPlayerPrimaryColor"
                                        elevation="2"
                                        mode="hexa"
                                        style="padding-bottom: 26px"
                                    ></v-color-picker>
                                </div>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="d-flex justify-center">
                                <div>
                                    <h5 class="text-center">Secondary Color</h5>
                                    <v-color-picker
                                        width="250px"
                                        v-model="videoPlayerSecondaryColor"
                                        elevation="2"
                                        mode="hexa"
                                        style="padding-bottom: 26px"
                                    ></v-color-picker>
                                </div>
                            </div>
                        </v-col>

                        <v-col class="d-flex justify-center">
                            <div>
                                <h5 class="text-center">Logo</h5>
                                <v-card
                                    class="m-0 pt-3"
                                    style="width: 250px; display: flex; justify-content: center"
                                    width="250px"
                                    elevation="2"
                                >
                                    <logo-upload
                                        class="logoUpload"
                                        :funeralHomeId="id"
                                        @refresh="handleRefresh()"
                                        @logo-not-ready="logoNotReady()"
                                        @logo-is-ready="logoIsReady()"
                                    ></logo-upload>
                                </v-card>
                            </div>
                        </v-col>
                    </v-row>

                    <v-divider class="my-8"></v-divider>

                    <v-row class="justify-center">
                        <v-col style="max-width: 400px">
                            <div v-if="logo">
                                <h5 class="text-center">Logo Size</h5>
                                <v-slider v-model="logoSize" min="0" max="30"></v-slider>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="justify-center pb-5">
                        <v-col>
                            <div
                                style="aspect-ration: 16/9"
                                class="d-flex flex-column align-items-center justify-content-center"
                            >
                                <custom-tooltip :tooltipProps="{ top: true }">
                                    <template v-slot:activator>
                                        <h3>Upcoming</h3>
                                    </template>

                                    <template v-slot:content>
                                        <span>This will be visible when a live stream is scheduled.</span>
                                    </template>
                                </custom-tooltip>

                                <div class="upcoming-preview">
                                    <div style="width: 100%" class="p-2">
                                        <!-- <p style="color: white">test</p> -->
                                        <v-text-field
                                            class="text-center centered-input"
                                            dark
                                            v-model="videoPlayerHeading"
                                        ></v-text-field>
                                    </div>
                                    <div style="color: white">
                                        <p class="mb-0">
                                            {{ $moment(new Date()).add(3, 'days').format('MMMM Do, YYYY') }} - 1:00 pm
                                        </p>

                                        <div class="text-center awaiting-event-entry">
                                            <span> Visitation </span>
                                            <span class="live-chip"> • Live </span>
                                        </div>
                                    </div>

                                    <img v-if="logo" class="upcoming-logo" :src="logo" alt="logo" />
                                </div>
                            </div>
                        </v-col>
                        <v-col>
                            <div
                                style="aspect-ration: 16/9"
                                class="d-flex flex-column align-items-center justify-content-center"
                            >
                                <custom-tooltip :tooltipProps="{ top: true }">
                                    <template v-slot:activator>
                                        <h3>Video Player</h3>
                                    </template>

                                    <template v-slot:content>
                                        <span>This will be visible when a video is available to watch.</span>
                                    </template>
                                </custom-tooltip>

                                <div style="width: 480px">
                                    <branding-player-preview
                                        ref="brandingPlayerPreview"
                                        :videoPlayerPrimaryColor="videoPlayerPrimaryColor"
                                        :videoPlayerSecondaryColor="videoPlayerSecondaryColor"
                                        :logo="logo"
                                        :logoSize="logoSize"
                                        :source="source"
                                        :logoReady="logoReady"
                                        :poster="poster"
                                    ></branding-player-preview>
                                </div>
                            </div>
                            <div class="d-flex justify-center">
                                <v-switch inset label="Display Thumbnail" v-model="displayThumbnail"></v-switch>
                            </div>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item value="tribute">
                    <v-row v-if="tributeVideosEnabled" class="pa-1">
                        <!-- <v-col cols="12">
                            <div class="d-flex justify-center">
                                <v-checkbox label="Display Logo" v-model="tributeEndingShowLogo"></v-checkbox>

                            </div>
                        </v-col> -->

                        <v-col class="mt-5" cols="12" lg="6">
                            <div
                                @click="selectTributeEnding(1)"
                                :class="tributeEndingStyle == 1 ? 'active-ending' : ''"
                                class="ending-selection"
                            >
                                <div class="d-flex justify-center align-center">
                                    <font-awesome-icon
                                        v-if="tributeEndingStyle == 1"
                                        class="mr-2"
                                        style="color: #1976d2"
                                        icon=" fa-solid fa-circle-check"
                                    ></font-awesome-icon>
                                    <span class="text-center">Default Ending</span>
                                </div>
                                <div class="content-wrapper">
                                    <!-- v-if="tributeEndingBackgroundImage" -->
                                    <v-img
                                        cover
                                        style="z-index: 2; opacity: 50%"
                                        class="main-bg-img elevation-2"
                                        :src="tributeEndingBackgroundImage"
                                    ></v-img>
                                    <div class="image-dark-background"></div>
                                    <p class="life-span">1953 - 2023</p>
                                    <div
                                        style="
                                            position: absolute;
                                            top: 30%;
                                            width: 100%;
                                            display: flex;
                                            justify-content: center;
                                        "
                                    >
                                        <v-img
                                            style="
                                                font-size: 2rem;
                                                max-width: 50%;
                                                text-align: center;
                                                color: white;
                                                z-index: 4;
                                            "
                                            :src="sampleLetters"
                                        ></v-img>
                                    </div>

                                    <div v-if="tributeEndingShowLogo && logo" class="logo-overlay">
                                        <font-awesome-icon
                                            @click="tributeEndingShowLogo = false"
                                            class="hide-logo-btn"
                                            icon="fa-regular fa-circle-x"
                                        ></font-awesome-icon>
                                        <img :src="logo" alt="logo" />
                                    </div>
                                    <div
                                        style="display: flex; justify-content: center; align-items: center; width: 10%"
                                        class="logo-overlay"
                                        v-else
                                    >
                                        <font-awesome-icon
                                            @click="tributeEndingShowLogo = true"
                                            class="show-logo-btn"
                                            style="font-size: 1.2rem"
                                            icon="fa-regular fa-cloud-plus"
                                        ></font-awesome-icon>
                                    </div>
                                </div>
                                <media-uploader
                                    ref="imgUpload"
                                    :token="token"
                                    :uploaderType="MEDIA_UPLOADER_TYPES.TRIBUTE_ENDING_BG"
                                    :itemId="
                                        parseInt(
                                            this.$auth.role.includes('FuneralHome')
                                                ? this.$auth.funeralHomeId
                                                : this.id,
                                        )
                                    "
                                    @refresh="getSettings"
                                />
                            </div>
                            <div style="gap: 10px" class="mt-2 d-flex justify-center flex-wrap">
                                <v-btn
                                    v-if="tributeEndingBackgroundImage"
                                    color="error"
                                    @click="deleteEndingBackgroundImage"
                                    >Delete</v-btn
                                >
                                <v-btn @click.stop="imgUploadInit">Upload Image</v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <div
                                @click="selectTributeEnding(2)"
                                :class="tributeEndingStyle == 2 ? 'active-ending' : ''"
                                class="ending-selection"
                            >
                                <div class="d-flex justify-center align-center">
                                    <font-awesome-icon
                                        v-if="tributeEndingStyle == 2"
                                        class="mr-2"
                                        style="color: #1976d2"
                                        icon=" fa-solid fa-circle-check"
                                    ></font-awesome-icon>
                                    <span class="text-center">Custom Ending</span>
                                </div>
                                <div class="content-wrapper">
                                    <video
                                        v-if="tributeEndingVideo"
                                        controls
                                        :key="refreshKey"
                                        class="ending-video elevation-2"
                                        :src="tributeEndingVideo"
                                    ></video>
                                    <div
                                        v-else
                                        style="
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            height: 100%;
                                        "
                                    >
                                        <v-alert outlined text type="info"> Please upload video to continue.</v-alert>
                                    </div>
                                    <v-img
                                        v-if="tributeEndingShowLogo && logo && tributeEndingVideo"
                                        class="logo-overlay"
                                        :src="logo"
                                    ></v-img>
                                </div>
                            </div>
                            <div style="gap: 10px" class="mt-2 d-flex justify-center flex-wrap">
                                <v-btn v-if="tributeEndingVideo" color="error" @click="deleteEndingVideo">Delete</v-btn>
                                <v-btn @click.stop="videoUploadInit">Upload Video</v-btn>
                            </div>
                            <media-uploader
                                ref="videoUpload"
                                :token="token"
                                :uploaderType="MEDIA_UPLOADER_TYPES.TRIBUTE_VIDEO_ENDING_VIDEO"
                                :itemId="
                                    parseInt(
                                        this.$auth.role.includes('FuneralHome') ? this.$auth.funeralHomeId : this.id,
                                    )
                                "
                                :allowedFileTypes="['.mp4', '.mov']"
                                @refresh="getSettings"
                            />
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-form>

        <!-- <v-form ref="form" class="mt-8" lazy-validation> -->

        <v-row>
            <v-col class="text-right">
                <v-btn :loading="loading" :disabled="loading" color="primary" @click="saveSettings"> Save </v-btn>
            </v-col>
        </v-row>
        <!-- </v-form> -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import LogoUpload from '@/components/Misc/LogoUpload.vue';
import BrandingPlayerPreview from '@/components/videojs/BrandingPlayerPreview.vue';
import BrandingPlayerThumbnail from '@/assets/images/branding-preview-thumbnail.jpg';
import SampleLetters from '@/assets/images/alex-brush-letters.png';
import MediaUploader from '@/components/ui/MediaUploader.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { MEDIA_UPLOADER_TYPES } from '@/constants';

export default {
    data() {
        return {
            MEDIA_UPLOADER_TYPES,
            editColors: false,
            options: '',
            refreshKey: 9,
            message: 'branding',
            videoPlayerPrimaryColor: '',
            videoPlayerSecondaryColor: '',
            logo: '',
            logoSize: 0,
            source: 'https://memoryshareprod.blob.core.windows.net/front-end-assets/BrandingSampleVideo.mp4',
            poster: '',
            logoReady: true,
            tributeEndingBackgroundImage: '',
            tributeEndingShowLogo: false,
            sampleLetters: SampleLetters,
            tributeEndingVideo: '',
            tributeEndingStyle: 1,
            tributeVideosEnabled: false,
            loading: true,
            tab: 'stream',
            videoPlayerHeading: '',
            displayThumbnail: false,
            sampleThumbnail: BrandingPlayerThumbnail,
            blankThumbnail: process.env.VUE_APP_AZURE_BASE_DOMAIN + '/front-end-assets/video_poster_placeholder.png',
        };
    },
    components: {
        LogoUpload,
        BrandingPlayerPreview,
        BrandingPlayerThumbnail,
        MediaUploader,
        SampleLetters,
        CustomTooltip,
    },
    props: ['id', 'token', 'homeId', 'isActive'],
    async mounted() {
        await this.getSettings();

        if (this.tributeVideosEnabled && this.$route.query.brandingTab === 'tribute') {
            this.tab = this.$route.query.brandingTab;
        } else {
            this.tab = 'stream';
        }

        this.updateQueryParam('brandingTab', this.tab);
    },
    watch: {
        tributeEndingShowLogo() {
            if (!this.loading) {
                this.updateShowLogo();
            }
        },
        displayThumbnail() {
            if (this.displayThumbnail) {
                this.poster = this.sampleThumbnail;
            } else {
                this.poster = this.blankThumbnail;
            }
        },
        tab(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.updateQueryParam('brandingTab', newVal);
            }
        },
        isActive(newVal, oldVal) {
            if (!newVal) {
                this.updateQueryParam('brandingTab', null);
            } else if (this.tab) {
                this.updateQueryParam('brandingTab', newVal);
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        updateQueryParam(param, value) {
            const currentQuery = { ...this.$route.query };

            if (currentQuery[param] === value) return;

            if (value === null || value === undefined) {
                delete currentQuery[param];
            } else {
                currentQuery[param] = value;
            }

            this.$router.replace({ query: currentQuery }).catch(err => {});
        },
        selectTributeEnding(val) {
            if (val == 2 && !this.tributeEndingVideo) {
                // this.showSnackbar({ message: 'Please upload a video before enabling', color: 'error' });
                return;
            }
            this.tributeEndingStyle = val;
        },
        deleteEndingBackgroundImage() {
            let id = this.$auth.role.includes('FuneralHome') ? this.$auth.funeralHomeId : this.id;

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .delete(process.env.VUE_APP_API + `/funeralhomes/tribute-ending-bg/${id}`)
                .then(response => {
                    (this.tributeEndingBackgroundImage = ''),
                        this.showSnackbar({ message: 'Settings successfuly updated' });
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error! Settings not updated' });
                    console.log(error);
                });
        },
        deleteEndingVideo() {
            let id = this.$auth.role.includes('FuneralHome') ? this.$auth.funeralHomeId : this.id;

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .delete(process.env.VUE_APP_API + `/funeralhomes/tribute-ending-video/${id}`)
                .then(response => {
                    this.tributeEndingVideo = '';
                    this.refreshKey++;
                    this.tributeEndingStyle = 1;
                    this.saveSettings();
                    this.showSnackbar({ message: 'Settings successfuly updated' });
                })
                .catch(error => {
                    this.showSnackbar({ message: 'Error! Settings not updated' });
                    console.log(error);
                });
        },
        imgUploadInit() {
            this.$refs.imgUpload.handleOpen();
        },
        videoUploadInit() {
            this.$refs.videoUpload.handleOpen();
        },
        logoIsReady() {
            this.logoReady = true;
        },
        logoNotReady() {
            this.logoReady = false;
        },
        handleRefresh() {
            this.logo = '';
            this.logoSize = 10;
            this.getSettings();
        },
        toggleEditColors() {
            this.editColors = !this.editColors;
        },
        updateShowLogo() {
            let data = {
                tributeEndingShowLogo: this.tributeEndingShowLogo,
            };

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(
                    process.env.VUE_APP_API + `/funeralhomes/settings/branding/update-show-logo-tribute/${this.id}`,
                    data,
                )
                .then(response => {})
                .catch(error => {
                    // this.showSnackbar({ message: 'Error! Settings not updated' });
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveSettings() {
            let valid = this.$refs.form.validate();

            if (valid) {
                this.loading = true;

                let data = {
                    videoPlayerPrimaryColor: this.videoPlayerPrimaryColor,
                    videoPlayerSecondaryColor: this.videoPlayerSecondaryColor,
                    videoPlayerLogoSize: this.logoSize,
                    logoPath: this.logo,
                    tributeEndingShowLogo: this.tributeEndingShowLogo,
                    tributeEndingStyle: this.tributeEndingStyle,
                    videoPlayerHeading: this.videoPlayerHeading,
                    displayThumbnail: this.displayThumbnail,
                };

                if (!this.$auth.role.includes('FuneralHome')) {
                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .put(process.env.VUE_APP_API + `/funeralhomes/settings/branding/${this.id}`, data)
                        .then(response => {
                            this.showSnackbar({ message: 'Settings successfuly updated' });
                        })
                        .catch(error => {
                            this.showSnackbar({ message: 'Error! Settings not updated' });
                            console.log(error);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else if (this.$auth.role.includes('FuneralHome')) {
                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .put(
                            process.env.VUE_APP_API + `/funeralhomes/settings/branding/${this.$auth.funeralHomeId}`,
                            data,
                        )
                        .then(response => {
                            this.showSnackbar({ message: 'Settings successfuly updated' });
                        })
                        .catch(error => {
                            this.showSnackbar({ message: 'Error! Settings not updated' });
                            console.log(error);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            }
        },
        getSettings() {
            if (!this.$auth.role.includes('FuneralHome')) {
                this.loading = true;
                return this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/funeralhomes/settings/branding/${this.id}`)
                    .then(response => {
                        let data = response.data;

                        if (response.data) {
                            this.logo = data.logoPath;
                            this.logoSize = data.videoPlayerLogoSize ? data.videoPlayerLogoSize : 10;
                            this.videoPlayerPrimaryColor = data.videoPlayerPrimaryColor
                                ? data.videoPlayerPrimaryColor
                                : '#ab0658';
                            this.videoPlayerSecondaryColor = data.videoPlayerSecondaryColor
                                ? data.videoPlayerSecondaryColor
                                : '#ffffff';
                            this.tributeEndingBackgroundImage = data.tributeEndingBackgroundImage;
                            this.tributeEndingVideo = data.tributeEndingVideo;
                            this.tributeEndingShowLogo = data.tributeEndingShowLogo;
                            this.tributeEndingStyle = data.tributeEndingStyle;
                            this.tributeVideosEnabled = data.tributeVideos;
                            this.videoPlayerHeading = data.videoPlayerHeading;
                            this.displayThumbnail = data.displayThumbnail;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$emit('message', error.response.data);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.loading = true;
                return this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .get(process.env.VUE_APP_API + `/funeralhomes/settings/branding/${this.$auth.funeralHomeId}`)
                    .then(response => {
                        let data = response.data;

                        if (response.data) {
                            this.logo = data.logoPath;
                            this.logoSize = data.videoPlayerLogoSize ? data.videoPlayerLogoSize : 10;
                            this.videoPlayerPrimaryColor = data.videoPlayerPrimaryColor
                                ? data.videoPlayerPrimaryColor
                                : '#ab0658';
                            this.videoPlayerSecondaryColor = data.videoPlayerSecondaryColor
                                ? data.videoPlayerSecondaryColor
                                : '#ffffff';

                            this.tributeEndingBackgroundImage = data.tributeEndingBackgroundImage;
                            this.tributeEndingVideo = data.tributeEndingVideo;
                            this.tributeEndingShowLogo = data.tributeEndingShowLogo;
                            this.tributeEndingStyle = data.tributeEndingStyle;
                            this.tributeVideosEnabled = data.tributeVideos;
                            this.videoPlayerHeading = data.videoPlayerHeading;
                        }
                    })
                    .catch(error => {
                        console.log(error);

                        this.showSnackbar({ message: 'Error retreiving settings' });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<style lang="scss">
.branding-section {
    .hide-tabs .v-tabs-bar {
        display: none !important;
    }

    .hide-logo-btn {
        opacity: 0;
        color: white;
        transition: 0.2s;
    }

    .show-logo-btn {
        transition: 0.2s;
        color: rgba(255, 255, 255, 0.65);
    }
    .show-logo-btn:hover {
        color: white;
    }

    .hide-logo-btn:hover {
        color: #ff5252;
    }
    .logo-overlay:hover .hide-logo-btn {
        opacity: 1;
    }

    .awaiting-event-entry {
        font-weight: 300;
        font-size: 1rem;
        margin: 0;
        display: flex;
        gap: 0.75rem;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
    }
    .live-chip {
        background: #df2000;
        border-radius: 5px;
        padding: 0 0.2rem;
        font-size: 0.7rem;
        color: #fff;
        font-weight: bold;
    }
    .centered-input .v-input__slot {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .upcoming-logo {
        width: var(--custom-logo-width);
        position: absolute;
        right: 5px;
        bottom: 5px;
        max-width: 400px;
        z-index: 2;
    }

    .upcoming-preview {
        height: 100%;
        border-radius: 5px;
        background-color: #333;
        width: 480px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        aspect-ratio: 16/9;
        position: relative;
    }

    .logoUpload {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 250px;
        height: 304px;
    }

    .ending-selection {
        /* border: 2px solid #d8d8d8; */
        padding: 10px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        border: 2px solid transparent;
        transition: 0.3s;
    }
    .ending-selection:hover {
        border: 2px solid #d8d8d8;
    }

    .active-ending {
        border: 2px solid #1976d2 !important;
        background-color: hsla(211, 80%, 86%, 0.376);
    }

    .image-main-container {
        position: relative;
        width: 100%;
        max-width: 480px;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        aspect-ratio: 16/9;
    }

    .main-bg-img {
        border-radius: 5px;
        aspect-ratio: 16/9;
    }

    .image-dark-background {
        border-radius: 5px;
        position: absolute;
        top: 0;
        width: 100%;
        background-color: black;
        display: flex;
        flex-grow: 1;
        aspect-ratio: 16/9;
    }

    .life-span {
        font-size: 1rem;
        position: absolute;
        top: 70%;
        width: 100%;
        text-align: center;
        color: white;
        z-index: 4;
    }

    .content-wrapper {
        width: 100%;
        max-width: 480px;
        aspect-ratio: 16/9;
        position: relative;
    }
    .logo-overlay {
        width: var(--custom-logo-width);
        position: absolute;
        right: 5px;
        bottom: 5px;
        max-width: 400px;
        z-index: 5;
    }

    .ending-video {
        width: 100%;
        min-height: 100%;
        max-height: 100%;
        aspect-ratio: 16/9;
        max-width: 100%;
        object-fit: cover;
        border-radius: 5px;
        margin: 0;
        padding: 0;
    }
}
</style>
