<template>
    <div class="slide">
        <div class="timestamp-bar" :style="timestampStyle">
            <!-- <svg
                class="camera-icon"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10 6.66667L13.0352 5.14907C13.4785 4.92744 14 5.24977 14 5.74536V10.2546C14 10.7502 13.4785 11.0726 13.0352 10.8509L10 9.33333M3.33333 12H8.66667C9.40305 12 10 11.403 10 10.6667V5.33333C10 4.59695 9.40305 4 8.66667 4H3.33333C2.59695 4 2 4.59695 2 5.33333V10.6667C2 11.403 2.59695 12 3.33333 12Z"
                    stroke="#6B7280"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg> -->
            <font-awesome-icon icon="fa-regular fa-video" style="font-weight: 700"></font-awesome-icon>
            <span class="ml-2"> {{ formatTimeStamp(duration) }}</span>
        </div>

        <div 
            class="slide-image"
            :class="{ 'loaded': previewVideoLoaded }"
            @mouseenter="() => hasMousedOver = true">
            <img :src="thumbnail" />
            <video 
                v-if="!state.isMobile && hasMousedOver"
                :src="previewVideo"
                preload="none"
                muted
                class="slide-preview"
                ref="videoPreview"
                @loadstart="loadPreview"
                @load="previewLoaded"
                @mouseenter="play" 
                @mouseleave="pause"
                loop
            ></video>
        </div>
         
        <div v-if="staffFavorite" class="staff-picked" :style="staffPickedStyle">
            <CrownIcon />
            <span>STAFF PICKED</span>
        </div>

        <div class="template-overlay">
            <div class="template-title">{{ title }}</div>
            <div class="button-group">
                <CustomTooltip :tooltipProps="tooltipProps">
                    <template #activator>
                        <button
                            v-if="!isSelected"
                            :disabled="loading"
                            @click.stop="$emit('select')"
                            class="template-button white-button"
                        >
                            <font-awesome-icon icon="fa-regular fa-plus"></font-awesome-icon>
                        </button>
                    </template>

                    <template #content>
                        <span>Select</span>
                    </template>
                </CustomTooltip>

                <CustomTooltip :tooltipProps="tooltipProps">
                    <template #activator>
                        <button
                            v-if="!isSelected"
                            @click.stop="action('preview')"
                            class="template-button white-button preview"
                        >
                            <font-awesome-icon icon="fa-regular fa-eye"></font-awesome-icon>
                        </button>
                    </template>

                    <template #content>
                        <span>Preview</span>
                    </template>
                </CustomTooltip>

                <div class="button-group">
                    <button v-if="isSelected" class="template-button orange-button">
                        <font-awesome-icon icon="fa-regular fa-check" style="color: #f97316"></font-awesome-icon>
                    </button>

                    <CustomTooltip :tooltipProps="tooltipProps" v-if="!state.isMobile">
                        <template #activator>
                            <button v-if="isSelected" @click.stop="action('edit')" class="template-button white-button">
                                <font-awesome-icon icon="fa-regular fa-pencil"></font-awesome-icon>
                            </button>
                        </template>
                        <template #content>
                            <span>Edit</span>
                        </template>
                    </CustomTooltip>
                    <CustomTooltip :tooltipProps="tooltipProps" v-else>
                        <template #activator>
                            <button
                                v-if="isSelected"
                                @click.stop="action('preview')"
                                class="template-button white-button preview"
                            >
                                <font-awesome-icon icon="fa-regular fa-eye"></font-awesome-icon>
                            </button>
                        </template>

                        <template #content>
                            <span>Preview</span>
                        </template>
                    </CustomTooltip>
                </div>
            </div>
        </div>
        <v-dialog 
            v-model="mobilePreviewActive"
            transition="dialog-bottom-transition" 
            @click:outside="mobilePreviewActive = false"
        >
            <v-card
                class="mobile-preview-modal">
                <div class="card-header m-0">
                    <div class="card-heading">Preview - "{{ title }}"</div> 
                    <v-btn class="close-btn" outlined @click="mobilePreviewActive = false">
                        <font-awesome-icon icon="fa-solid fa-times" style="font-weight: 700"></font-awesome-icon>
                    </v-btn>
                </div>
                <div class="card-body">
                    <video
                        :src="previewVideo"
                        class="theme-preview"
                        autoplay
                        muted
                        playsinline
                        loop
                        controls
                    ></video>
                </div>
                <v-card-actions class="mb-2">
                    <v-btn class="select-btn" @click="$emit('select')" v-if="!isSelected">
                        <font-awesome-icon icon="fa-regular fa-plus" class="mr-1"></font-awesome-icon>
                        Use this theme
                    </v-btn>
                    <div class="selected-block" v-else>
                        Currently selected theme
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CrownIcon from '@/assets/icons/crownIcon.vue';
import moment from 'moment';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    name: 'ThemeCard',
    components: {
        CrownIcon,
        CustomTooltip,
    },
    props: {
        template: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    inject: ['state'],
    data() {
        return {
            tooltipProps: { top: true },
            previewVideoLoading: false,
            previewVideoLoaded: false,
            hasMousedOver: false,
            mobilePreviewActive: false
        };
    },
    computed: {
        timestampStyle() {
            return {
                position: 'absolute',
                top: '8px',
                left: '8px',
                zIndex: 2,
            };
        },
        staffPickedStyle() {
            return {
                position: 'absolute',
                top: '8px',
                right: '8px',
                zIndex: 2,
            };
        },
        currentTemplate() {
            return this.template;
        },
        title() {
            return this.template.title;
        },
        duration() {
            return this.template.duration;
        },
        thumbnail() {
            const srcPath = this.template.url.split('/tribute-template-renders/')[1];
            const thumbNailMod = '/ik-thumbnail.jpg?tr=so-2';
            return `${process.env.VUE_APP_IMG_KIT_BASE}templates/${srcPath}${thumbNailMod}`;
        },
        previewVideo() {
            const srcPath = this.template.url.split('/tribute-template-renders/')[1];
            const previewMod = '?tr=so-2,q-50,w-400,ar-16-9';
            return `${process.env.VUE_APP_IMG_KIT_BASE}templates/${srcPath}${previewMod}`;
        },
        staffFavorite() {
            return this.template.staffFavorite;
        },
    },
    methods: {
        formatTimeStamp(seconds) {
            const duration = moment.duration(seconds, 'seconds');
            const minutes = duration.minutes();
            const secs = duration.seconds();

            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(secs).padStart(2, '0');

            return `${formattedMinutes}:${formattedSeconds}`;
        },
        loadPreview() {
            this.previewVideoLoading = true;
        },
        previewLoaded() {
            this.previewVideoLoading = false;
            this.previewVideoLoaded = true;
        },
        play() {
            this.$refs.videoPreview.play();
        },
        pause() {
            if (!this.$refs.videoPreview.paused) {
                this.$refs.videoPreview.pause();
            }
        },
        action(actionType) {
            // if not mobile then just emit the action
            if (!this.state.isMobile) {
                return this.$emit(actionType);
            }
            switch (actionType) {
                // TODO: are we allowing user to edit on mobile? For now also do preview?
                case 'edit':
                case 'preview':
                    this.mobilePreviewActive = true;    
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.slide {
    width: 360px;
    height: 100%;
    max-height: 225px;
    border-radius: 12px;
    position: relative;
    transition: transform 0.2s, border 0.2s;
    flex-shrink: 0;
    display: inline-block;
    background: white;
    overflow: hidden;
    border: 1px solid #ccc;
}

.timestamp-bar {
    display: flex;
    align-items: center;
    width: 90px;
    height: 35px;
    background: white;
    border: 1px solid #d1d5db;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    /* color: #6b7280; */
    padding-right: 12px;
    padding-left: 12px;
    letter-spacing: 2.5%;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.staff-picked {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 133.14px;
    height: 35px;
    padding: 9px 8px 9px 4px;
    border-radius: 54px 54px 0px 54px;
    background: #f97316;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: white;
    border: 1px solid #9a3412;
}
// this ensures that hover autoplay isn't interupted when hovering on the overlays
.timestamp-bar, 
.staff-picked {
    pointer-events: none;
}
.template-overlay {
    width: 100%;
    height: 54px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9fafb;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
}

.slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
    }
    .slide-preview {
        position: absolute;
        inset: 0;
        z-index: -1;
        height: auto;
        transition: opacity 250ms ease-in-out;
        opacity: 0;
    }
    &:hover .slide-preview {
        z-index: 1;
        opacity: 1;
        
    }
}

.template-title {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #374151;
}

.button-group {
    display: flex;
    gap: 8px;
}

.template-button {
    width: 38px;
    height: 38px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid #d1d5db;
}

.template-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.white-button {
    background: white;
    border: 1px solid #d1d5db;
}

.orange-button {
    background: #fff7ed;
    border: 1px solid #fdba74;
    cursor: default;
}

.white-button:hover {
    border-color: #f97316;
}

.white-button:hover svg path {
    stroke: #f97316;
    fill: #f97316;
}
.slide-preview {
    position: absolute;
    /* inset: 0; */
    z-index: 0;
    width: 100%;
    height: 100%;
}

.spinner-container {
    position: absolute;
    inset: 0;
}
/* .preview:hover svg path {
    stroke: #f97316;
    fill: none;
} */

/* .preview:hover {
    color: #f97316;
} */

.mobile-preview-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    .theme-preview {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);

        @include landscape() {
            width: auto;
            max-height: min(60vh, 198px);
            margin: auto;
            align-self: center;
            display: flex;
        }
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .card-heading {
            flex-grow: 1;
            font-weight: bold;
            font-size: 0.95rem;
        }
        .close-btn {
            flex-shrink: 1;
            min-width: 40px;
            max-width: 40px;
            border-color: $medium-gray;
            color: $deep-gray;
        }
    }
    .v-card__actions {
        &::v-deep {
            display: flex;
            justify-content: center;
        }
    }
    .select-btn {
        text-transform: none;
        letter-spacing: normal;
        flex: 1;
        &::v-deep {
            background-color: $primary-orange !important;
        }
        color: white;
    }
    .selected-block {
        color: $deep-gray;
    }
}
</style>
