<template>
    <div style="z-index: 11">
        <v-menu
            offset-y
            v-model="menuOpen"
            max-width="512px"
            :min-width="state.isMobile ? '90dvw' : '400px'"
            :close-on-content-click="false"
            :close-on-click="closeOnOutsideClick"
        >
            <template v-slot:activator="{ on, attrs }">
                <button class="share-button" v-bind="attrs" v-on="on" :disabled="disabled" v-if="mode === 'button'">
                    <span
                        >Share
                        <span class="with-family-text" v-if="!state.isFamily && !state.isContributor">with family</span>
                    </span>
                    <span class="divider"></span>
                    <font-awesome-icon icon="fa-solid fa-caret-down"></font-awesome-icon>
                </button>
                <v-list-item v-on="on" class="share-list-item" v-else>
                    <font-awesome-icon class="share-icon mr-2" icon="fa-share"></font-awesome-icon>
                    <span class="share-text"
                        >Share <span class="" v-if="!state.isFamily && !state.isContributor">with family</span></span
                    >
                </v-list-item>
            </template>
            <v-card v-click-outside="handleClickOutside">
                <v-tabs v-model="tabIndex" color="#ee5207" grow dense>
                    <v-tab v-for="(tab, index) in shareTabs" :ripple="false" :key="index">{{
                        state.isMobile && tab.tabTitleMobile ? tab.tabTitleMobile : tab.tabTitle
                    }}</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabIndex">
                    <v-tab-item v-for="(tab, index) in shareTabs" :value="index">
                        <v-list class="sharing-menu">
                            <v-list-item :key="tab.link">
                                <v-list-item-title class="font-weight-bold">{{ tab.linkTitle }}</v-list-item-title>
                                <v-list-item-subtitle style="white-space: normal">
                                    {{ tab.linkSubtitle }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <v-text-field
                                        outlined
                                        dense
                                        class="has-addon__right"
                                        prepend-inner-icon="mdi-account-group-outline"
                                        v-model="tab.link"
                                        readonly
                                    >
                                        <v-btn
                                            :disabled="tab.link === '...'"
                                            depressed
                                            slot="append-outer"
                                            @click="copyToClipboard(tab.linkType, tab.link)"
                                        >
                                            <font-awesome-icon
                                                class="mr-2"
                                                icon="fa-regular fa-clone"
                                            ></font-awesome-icon>

                                            Copy</v-btn
                                        >
                                    </v-text-field>
                                </v-list-item-subtitle>
                            </v-list-item>
                            <hr class="m-2" />

                            <v-list-item>
                                <v-list-item-title class="font-weight-bold">Send Email or SMS</v-list-item-title>
                                <v-list-item-subtitle> Send this link via email or sms </v-list-item-subtitle>
                                <EmailSmsLink
                                    :ref="`share-input-${index}`"
                                    v-if="menuOpen"
                                    @change="val => handleInvitesChange(val)"
                                    @submit="data => tab.shareCallback(data)"
                                    :link="tab.link"
                                >
                                    <template #append-outer="{ submit, listLength }">
                                        <v-btn
                                            style="background-color: rgba(251, 140, 0, 0.15); color: rgb(251, 140, 0)"
                                            @click="submit"
                                            depressed
                                            :disabled="isSendLinkDisabled(listLength, tab.linkType)"
                                            >Send</v-btn
                                        >
                                    </template>
                                </EmailSmsLink>
                            </v-list-item>

                            <hr class="m-2" v-if="tab.linkType === 'Family' || tab.linkType === 'Contributor'" />

                            <v-list-item v-if="tab.linkType === 'Family' || tab.linkType === 'Contributor'">
                                <v-list-item-title class="font-weight-bold"
                                    >Print {{ tab.linkType }} QR Code</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                    Print the {{ tab.linkType.toLowerCase() }} share link as a QR
                                    Code</v-list-item-subtitle
                                >
                                <v-btn
                                    :disabled="loading"
                                    :loading="loading"
                                    @click="downloadPdf(tab.linkType)"
                                    block
                                    depressed
                                >
                                    <font-awesome-icon icon="fa-regular fa-print" class="mr-2"></font-awesome-icon>
                                    Print</v-btn
                                >
                            </v-list-item>
                        </v-list>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-menu>

        <qr-printout
            ref="contributorQrPrint"
            v-if="tributeVideo && service && tributeVideo.deeplink"
            title="Tribute Video"
            :service="service"
            :link="tributeVideo.deeplink"
            @loading="val => (loading = val)"
        />

        <qr-printout
            ref="familyQrPrint"
            v-if="tributeVideo && service && tributeVideo.familyLink"
            title="Tribute Video"
            :service="service"
            :link="tributeVideo.familyLink"
            @loading="val => (loading = val)"
        />
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import EmailSmsLink from '@/components/ui/EmailSmsLink.vue';
import TributeVideoService from '@/services/tributeVideo.service';
import ServicesService from '@/services/services.service';
import QrPrintout from '@/components/ManageService/Tribute/QrPrintout.vue';

export default {
    data() {
        return {
            menuOpen: false,
            tabIndex: 0,
            tributeApi: null,
            serviceApi: null,
            token: null,
            currentInvites: [],
            service: null,
            loading: false,
        };
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'button',
        },
    },
    components: { EmailSmsLink, QrPrintout },
    computed: {
        isSendLinkDisabled() {
            return (listLength, linkType) => {
                return listLength === 0 || (linkType === 'Completed video' && this.completedVideoLink === '...');
            };
        },
        shareTabs() {
            var tabs = [
                {
                    tabTitle: 'Invite Family',
                    tabTitleMobile: 'Family',
                    linkTitle: 'Invite Family to Upload Media',
                    linkSubtitle: 'Allow family members to contribute photos and videos for the tribute.',
                    link: this.familyShareLink,
                    linkType: 'Family',
                    contributorVisible: false,
                    postRender: false,
                    shareCallback: this.handleInviteSubmit,
                },
                {
                    tabTitle: 'Invite Contributors',
                    tabTitleMobile: 'Contributor',
                    linkTitle: 'Contributors',
                    linkSubtitle: 'Only allows photo uploads. Recommended for extended family and friends.',
                    link: this.contributorLink,
                    linkType: 'Contributor',
                    contributorVisible: true,
                    postRender: false,
                    shareCallback: this.handleInviteSubmit,
                },
                {
                    tabTitle: 'TributeVideo',
                    linkTitle: 'Share Completed Tribute Video',
                    linkSubtitle: 'Allow anyone with the link to view the tribute video.',
                    link: this.completedVideoLink,
                    linkType: 'Completed video',
                    contributorVisible: true,
                    postRender: true,
                    shareCallback: this.handleTributeVideoShare,
                },
            ];

            if (this.state && this.state.isContributor) {
                tabs = tabs.filter(tab => tab.contributorVisible);
            }

            if (this.state && this.tributeRender?.standardRender) {
                tabs = tabs.filter(tab => tab.postRender);
            } else {
                tabs = tabs.filter(tab => !tab.postRender);
            }

            return tabs;
        },
        familyShareLink() {
            return this.$store.state.tributeVideo.familyLink || '...';
        },
        contributorLink() {
            return this.$store.state.tributeVideo.deeplink || '...';
        },
        completedVideoLink() {
            // TODO: Figure out where the completed video link lives
            if (this.tributeRender?.standardRender?.url) {
                return `${process.env.VUE_APP_VIDEO}/video/${this.$route.params.slug}/tribute-video`;
            }

            return '...';
        },
        tributeVideoId() {
            return this.$store.state.tributeVideo.id;
        },
        closeOnOutsideClick() {
            return this.currentInvites.length === 0;
        },
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
    },
    inject: ['tributeRender', 'state'],
    watch: {
        tabIndex(newVal, oldVal) {
            this.resetShareInput(oldVal);
        },
        menuOpen(newVal) {
            if (!newVal) {
                this.resetShareInput(this.tabIndex);
            }
        },
        tributeVideoId(newVal) {
            if (newVal && !this.service) {
                if (this.tributeVideo.serviceId) {
                    this.getTributeService(this.tributeVideo.serviceId);
                }
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        downloadPdf(type) {
            let ref;

            type = type.toLowerCase();

            if (type === 'family') {
                ref = this.$refs.familyQrPrint;
            } else if (type === 'contributor') {
                ref = this.$refs.contributorQrPrint;
            }

            if (!ref || typeof ref.generatePdf != 'function') {
                this.showSnackbar({ message: 'An error occurred during pdf generation', color: 'error' });
                return;
            }

            this.menuOpen = false;
            ref.generatePdf();
        },
        resetShareInput(index) {
            this.currentInvites = [];

            const ref = this.$refs[`share-input-${index}`][0];
            if (ref) {
                ref.reset();
            }
        },
        handleInvitesChange(val) {
            this.currentInvites = val;
        },
        handleClickOutside() {
            if (!this.closeOnOutsideClick) {
                this.showSnackbar({ message: 'Please send or clear invites to close menu', color: 'error' });
            }
        },
        copyToClipboard(type, link) {
            // TODO: browser support for this is really good, but need to double check if a fallback is needed
            navigator.clipboard.writeText(link);
            this.showSnackbar({
                message: `${type} link copied.`,
            });
            this.sharingMenuOpen = false;
        },
        handleInvalidEmail(email) {
            this.showSnackbar({ message: `${email} is not a valid email`, color: 'error' });
        },
        async handleInviteSubmit(contacts) {
            let data = {
                emailList: contacts.emailList.join(','),
                phoneList: contacts.phoneList.join(','),
                url: contacts.url,
            };

            try {
                await this.tributeApi.invite(this.tributeVideoId, data);
                this.showSnackbar({ message: 'Invitations sent' });
                this.resetShareInput(this.tabIndex);
                this.menuOpen = false;
            } catch (error) {
                console.log(error, 'error during invite submit');
                if (error.response.status === 401) {
                    this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                } else {
                    this.showSnackbar({ message: 'Error sending invitations', color: 'error' });
                }
            }
        },
        async handleTributeVideoShare(contacts) {
            let data = {
                emailList: contacts.emailList.join(','),
                phoneList: contacts.phoneList.join(','),
                url: contacts.url,
            };

            try {
                await this.tributeApi.shareFinalVideo(this.tributeVideoId, data);
                this.showSnackbar({ message: 'Invitations sent' });
                this.menuOpen = false;
            } catch (error) {
                console.log(error, 'error during invite submit');
                if (error.response.status === 401) {
                    this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                } else {
                    this.showSnackbar({ message: 'Error sending invitations', color: 'error' });
                }
            }
        },
        async getTributeService(serviceId) {
            try {
                const resp = await this.serviceApi.get(serviceId);

                if (resp.data) {
                    this.service = resp.data;
                }
            } catch (error) {
                console.log(error, 'error');
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async mounted() {
        if (this.state.token) {
            this.token = this.state.token;
        } else {
            await this.setAuthToken();
        }
        this.tributeApi = TributeVideoService(this.token);
        this.serviceApi = ServicesService(this.token);

        if (this.tributeVideo.serviceId) {
            this.getTributeService(this.tributeVideo.serviceId);
        }
    },
};
</script>
<style lang="scss" scoped>
.share-button {
    padding: 0.5rem 1rem;
    font-weight: 500;
    background-color: $primary-orange;
    color: white;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    gap: 8px;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}

.sharing-menu {
    padding: 1.5rem 0.5rem;
    display: flex;
    flex-direction: column;

    .v-list-item {
        display: inline-block;
        padding: 0.5rem;
    }

    .v-list-item__title {
        font-weight: 500;
    }

    .v-list-item__title,
    .v-list-item__subtitle {
        margin: 4px 0;
        line-height: 1.3;
    }

    ::v-deep {
        .v-input.has-addon__right {
            .v-input__slot {
                margin: 0;
            }

            .v-input__control {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                fieldset {
                    border-color: #ccc;
                    border-right: transparent;
                }
            }

            .v-input__append-outer {
                margin: 0 !important;

                button {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border: 1px solid #ccc;
                    height: 40px;
                    text-transform: none;
                }
            }
        }
    }
}

.share-button .divider {
    width: 1px;
    height: 20px;
    background-color: white;
    margin-left: 8px;
}

.share-list-item {
    .share-icon {
        color: $medium-gray;
    }
    .share-text {
        color: $deep-gray;
    }
}
@media only screen and (max-width: 1200px) {
    .with-family-text {
        display: none;
    }
}
</style>
