<template>
    <AppLayout :has-background="true" :contained="true">
        <div class="d-flex justify-end mb-4" v-if="$auth.role.includes('SuperAdmin')">
            <v-btn class="create-button" :to="{ name: 'CreateHome', params: { ownerId: this.ownerId } }"
                >+ Create New Home</v-btn
            >
        </div>

        <v-card class="p-4">
            <v-card-title>
                <span style="display: flex">
                    <font-awesome-icon icon="fa-regular fa-house" style="margin-right: 0.5rem" />
                    <h4 class="m-0">Homes</h4>
                </span>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    v-on:keyup.enter="getHomes"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="homes"
                :options.sync="options"
                :server-items-length="totalHomes"
                :loading="loading"
                :footer-props="{
                    itemsPerPageOptions: [5, 25, 50],
                }"
                loading-text="Getting homes..."
                :search="search"
                @click:row="handleRowClick"
                :must-sort="true"
            >
                <template v-slot:header.subscriptionStatus="{ header }">
                    <v-menu
                        :close-on-content-click="false"
                        :offset-y="true"
                        :disabled="loading"
                        @input="handleSubscriptionStatusMenuToggle"
                    >
                        <template v-slot:activator="{ on: menu, attrs }">
                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <span v-bind="attrs" v-on="{ ...menu }">
                                        {{ header.text }} ({{ selectedStatuses.length }})

                                        <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Select Filters</span>
                                </template>
                            </custom-tooltip>
                        </template>
                        <v-list style="width: 200px; overflow: hidden">
                            <v-list-item
                                v-for="(item, index) in subscriptionStatuses"
                                :key="index"
                                style="height: 20px"
                            >
                                <v-checkbox v-model="selectedStatuses" :label="item.label" :value="index"></v-checkbox>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>

                <template v-slot:header.lastEventTime="{ header }">
                    <v-menu :close-on-content-click="false" :offset-y="true">
                        <template v-slot:activator="{ on: menu, attrs }">
                            <custom-tooltip :tooltipProps="{ top: true }">
                                <template v-slot:activator>
                                    <span v-bind="attrs" v-on="{ ...menu }">
                                        {{ header.text }} <font-awesome-icon icon="fa-solid fa-chevron-down" />
                                    </span>
                                </template>

                                <template v-slot:content>
                                    <span>Select Filters</span>
                                </template>
                            </custom-tooltip>
                        </template>
                        <v-list
                            v-bind:class="[{ calendarShowing: calendarVisible }, 'lastServiceMenu']"
                            style="padding: 1rem"
                        >
                            <p class="m-0" style="font-size: 0.8rem; font-weight: bold">
                                Select to filter before or after date
                            </p>
                            <v-select :items="lastServiceSymbols" v-model="selectedLastServiceSymbol"></v-select>
                            <div v-if="selectedLastServiceSymbol === 1 || selectedLastServiceSymbol === 2">
                                <p class="m-0" style="font-size: 0.8rem; font-weight: bold">Select Date</p>
                                <calendar
                                    @input="showCalendar"
                                    @close="hideCalendar"
                                    v-model="selectedDate"
                                    :output-local="true"
                                ></calendar>
                            </div>
                        </v-list>
                    </v-menu>
                </template>

                <template v-slot:item.name="{ item }">
                    <div class="position-relative">
                        {{ item.name }}

                        <div v-if="item.oldFuneralHomeId === -1" style="line-height: 0.9rem">
                            <small style="color: #ffc400"> Reverted Home {{ item.oldFuneralHomeId }} </small>
                            <div class="reverted-home-border"></div>
                        </div>
                        <div
                            v-else-if="item.oldFuneralHomeId !== null && item.oldFuneralHomeId !== 0"
                            style="line-height: 0.9rem"
                        >
                            <small style="color: #750085"> Migrated Home {{ item.oldFuneralHomeId }} </small>
                            <div class="migrated-home-border"></div>
                        </div>
                        <div v-else-if="item.devHome" style="line-height: 0.9rem">
                            <small style="color: #ff530d">Dev Home</small>
                            <div class="dev-home-border"></div>
                        </div>
                    </div>
                </template>

                <template v-slot:item.contact="{ item }">
                    <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                </template>

                <template v-slot:item.location="{ item }">
                    <span v-if="item.city && item.state">{{ item.city + ', ' + item.state }}</span>
                    <span v-else>{{ item.city + '' + item.state }}</span>
                </template>

                <template v-slot:item.lastEventTime="{ item }">
                    <span v-if="item.lastEventTime" class="d-block">{{ item.lastEventTime | moment('M/D/YY') }}</span>
                    <span v-if="item.lastEventTime" class="d-block">{{ item.lastEventTime | moment('h:mm a') }}</span>
                </template>

                <template v-slot:item.integrations="{ item }">
                    <div style="display: flex; justify-content: center; align-items: center; gap: 10px">
                        <div style="width: 30px">
                            <custom-tooltip v-if="item.passareIntegration" :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <v-img :src="passareLogo"></v-img>
                                </template>

                                <template v-slot:content>
                                    <span>Passare</span>
                                </template>
                            </custom-tooltip>
                        </div>
                        <div class="p-1" style="width: 30px">
                            <custom-tooltip v-if="item.apiIntegration" :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <v-img :src="msLogo"></v-img>
                                </template>

                                <template v-slot:content>
                                    <span>Api</span>
                                </template>
                            </custom-tooltip>
                        </div>
                        <div class="p-1" style="width: 30px">
                            <custom-tooltip v-if="item.facebookIntegration" :tooltipProps="{ bottom: true }">
                                <template v-slot:activator>
                                    <v-img :src="fbLogo"></v-img>
                                </template>

                                <template v-slot:content>
                                    <span>Facebook</span>
                                </template>
                            </custom-tooltip>
                        </div>
                    </div>
                </template>

                <template v-slot:item.subscriptionStatus="{ item }">
                    <v-chip dark :color="subscriptionStatuses[item.subscriptionStatus].color">
                        {{ subscriptionStatuses[item.subscriptionStatus].label }}
                    </v-chip>
                </template>

                <template v-slot:item.actions="{ item }">
                    <div class="action-list">
                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span @click.stop="goToSettings(item.id)" v-bind="attrs" v-on="on">
                                    <font-awesome-icon icon="fa-regular fa-gear" style="font-size: 1rem" />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Settings</span>
                            </template>
                        </custom-tooltip>

                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span>
                                    <font-awesome-icon
                                        icon="fa-regular fa-user-group"
                                        style="font-size: 1rem"
                                        @click.stop="getUsers(item)"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Users</span>
                            </template>
                        </custom-tooltip>

                        <custom-tooltip :tooltipProps="{ bottom: true }">
                            <template v-slot:activator>
                                <span @click.stop="impersonate(item.id)">
                                    <font-awesome-icon
                                        icon="fa-regular fa-arrow-up-right-from-square"
                                        style="font-size: 1rem"
                                    />
                                </span>
                            </template>

                            <template v-slot:content>
                                <span>Impersonate</span>
                            </template>
                        </custom-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>

        <users-table ref="usersTable" :itemId="item.id" />

        <v-snackbar v-model="errorLoading">
            Error getting data

            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="getHomes()"> Retry </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ message }}
        </v-snackbar>
    </AppLayout>
</template>

<script>
import { subscriptionStatuses } from '@/constants';
import { mapActions } from 'vuex';
import UsersTable from '@/components/Tables/UsersTable';
import { setLocalSettings, getLocalSettings } from '@/utilities/general';
import MigrateHomeButton from '@/components/Actions/Homes/MigrateHomeButton.vue';
import Calendar from '@/components/ui/Calendar.vue';
import { debounceV2 } from '@/utilities/debounce.js';
import fbLogo from '@/assets/images/facebook.png';
import passareLogo from '@/assets/images/passare-2-icon-cropped.png';
import msLogo from '@/assets/images/logo.png';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    metaInfo: {
        title: 'Homes',
    },
    data() {
        return {
            calendarVisible: false,
            selectedDate: null,
            selectedLastServiceSymbol: 0,
            lastServiceSymbols: [
                { text: '--', value: 0 },
                { text: 'after', value: 1 },
                { text: 'before', value: 2 },
            ],
            filterMenuOpen: false,
            ownerId: null,
            subscriptionStatuses,
            statusParamString: '',
            selectedStatuses: [0, 5, 6, 7, 10],
            tableLoading: true,
            value: 0,
            formValidMsg: '',
            mainFormVal: false,
            loading: true,
            loadder: false,
            snackbar: false,
            message: '',
            subMessage: '',
            subMesgBool: true,
            item: [],
            dataFunral: false,
            showButtonFlag: false,
            errorLoading: false,
            migrationError: false,
            loadingMessage: null,
            selectedIndex: 0,
            selectedId: null,
            totalHomes: 0,
            sortAsc: false,
            sortBy: 'CreateDate',
            createOrEditModal: false,
            headers: [],
            form: {
                funeralHomeId: '',
                stripeSubscriptionId: '',
            },
            search: '',
            homes: [],
            token: '',
            options: {
                itemsPerPage: 25,
            },
            selectedItem: {},
            migrationStep: 0,
            migrationPercentage: 0,
            fbLogo,
            passareLogo,
            msLogo,
        };
    },

    mounted: async function () {
        // catch error where path 'homes/create' evals 'create' as ownerId
        if (this.$route.params.owner_id === 'create') {
            this.$router.push({ name: 'CreateHome' });
            return;
        }

        this.ownerId = this.$route.params.owner_id;

        const settings = await getLocalSettings();
        var string = '';
        try {
            if (settings.ownerFuneralHomesTable.statuses) {
                this.selectedStatuses = settings.ownerFuneralHomesTable.statuses;
                this.statusParamString = settings.ownerFuneralHomesTable.statuses.forEach(
                    status => (string += `&subscriptionStatus=${status}`),
                );
                this.statusParamString = string;
            }
            this.search = settings.ownerFuneralHomesTable.search || '';

            this.options = {
                itemsPerPage: settings.ownerFuneralHomesTable.pageSize || 25,
                page: settings.ownerFuneralHomesTable.page || 1,
                // sortBy: settings.ownerFuneralHomesTable.sortBy || 'Id',
                // sortDesc: settings.ownerFuneralHomesTable.sortDesc || false,
            };
        } catch {
            this.selectedStatuses.forEach(status => (string += `&subscriptionStatus=${status}`));
            this.statusParamString = string;
        }

        if (this.$auth.role.includes('SuperAdmin')) {
            this.headers = [
                { text: 'Id', value: 'id', sortable: true },
                { text: 'Home', value: 'name' },
                { text: 'Location', value: 'location' },
                { text: 'Contact', value: 'contact' },
                { text: 'Funeral Home Owner', value: 'ownerName' },
                { text: 'Last Service', value: 'lastEventTime' },
                { text: 'Total Services', value: 'totalServices' },
                { text: '60 Days', value: 'totalServicesLastSixtyDays' },
                { text: 'Integrations', value: 'integrations' },
                { text: 'Subscription Status', value: 'subscriptionStatus' },
                { text: 'Actions', value: 'actions', width: '20%', align: 'center', sortable: false },
            ];
        }

        if (this.$auth.role.includes('Owner')) {
            this.headers = [
                { text: 'Home', value: 'name' },
                { text: 'Location', value: 'location' },
                { text: 'Contact', value: 'contact' },
                { text: 'Last Service', value: 'lastEventTime' },
                { text: 'Total Services', value: 'totalServices' },
                { text: '60 Days', value: 'totalServicesLastSixtyDays' },
                { text: 'Subscription Status', value: 'subscriptionStatus' },
                { text: 'Actions', value: 'actions', width: '20%', align: 'center', sortable: false },
            ];
        }

        await this.getHomes();
        this.tableLoading = false;
    },
    components: {
        Calendar,
        UsersTable,
        MigrateHomeButton,
        CustomTooltip,
    },
    watch: {
        options: {
            handler() {
                if (!this.loading) {
                    this.getHomes();

                    setLocalSettings('ownerFuneralHomesTable', {
                        search: this.search,
                        page: this.options.page,
                        pageSize: this.options.itemsPerPage,
                        sortBy: this.options.sortBy,
                        sortDesc: this.options.sortDesc,
                        statuses: this.selectedStatuses,
                    });
                }
            },
        },
        search: debounceV2(function () {
            if (!this.loading) {
                setLocalSettings('ownerFuneralHomesTable', {
                    search: this.search,
                    page: 1,
                    pageSize: this.options.itemsPerPage,
                    statuses: this.selectedStatuses,
                    sortBy: this.options.sortBy,
                    sortDesc: this.options.sortDesc,
                });

                this.options = {
                    ...this.options,
                    page: 1,
                };
            }
        }, 500),
        selectedStatuses() {
            setLocalSettings('ownerFuneralHomesTable', {
                search: this.search,
                page: this.options.page,
                pageSize: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                statuses: this.selectedStatuses,
            });
        },
        selectedDate() {
            this.getHomes();
        },
        selectedLastServiceSymbol() {
            if (!this.selectedLastServiceSymbol) {
                this.selectedDate = null;
                this.calendarVisible = false;
            }
            this.getHomes();
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        showCalendar() {
            this.calendarVisible = true;
        },
        hideCalendar() {
            this.calendarVisible = false;
        },
        handleSubscriptionStatusMenuToggle() {
            if (this.filterMenuOpen === false) {
                this.filterMenuOpen = true;
            } else if (this.filterMenuOpen === true) {
                if (this.selectedStatuses.length === 0) {
                    this.homes = [];
                    return (this.statusParamString = '');
                }
                var string = '';
                this.statusParamString = this.selectedStatuses.forEach(
                    status => (string += `&subscriptionStatus=${status}`),
                );
                this.statusParamString = string;

                this.getHomes();
                this.filterMenuOpen = false;
            }
        },
        showProgress(item) {
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/Migration/status/${item.oldFuneralHomeId}?preview=false`)
                .then(response => {
                    if (response.data.percentage) {
                        this.migrationPercentage = response.data.percentage;
                    } else {
                        this.migrationPercentage = 100;
                    }

                    const text = `
                    <h4 style="font-weight: bold; font-size: 20px;">${100 + '%'}</h4>
                    <span style="margin: 1rem 0; font-size: 12px; color: #444">*there is a known issue of the migration appearing to be stuck between 70-80%. This is expected, during this time we are moving the analytics, and this can take anywhere from 20-30 minutes. If it takes longer than an hour, please contact Shane</span>
                    `;

                    this.$swal.fire({
                        type: 'success',
                        title: 'Migration Status',
                        html: text,
                    });
                })
                .catch(error => {
                    this.$swal.fire({
                        type: 'error',
                        title: 'Migration Status',
                        html: error.response.data.Message,
                    });
                });
        },
        filterService(item) {
            this.$router.push({ path: `/services/homes/${item.id}` });
        },
        handleRowClick(item) {
            this.filterService(item);
        },
        getSortBy() {
            const { sortBy } = this.options;
            let sortString = sortBy && sortBy[0] ? sortBy[0] : null || null;

            if (sortString) {
                switch (sortString) {
                    case 'name':
                        return 'Name';
                    case 'location':
                        return 'City';
                    case 'contact':
                        return 'PrimaryContact';
                    case 'ownerName':
                        return 'OwnerName';
                    case 'lastEventTime':
                        return 'LastEventTime';
                    case 'subscriptionStatus':
                        return 'SubscriptionStatus';
                    case 'totalServices':
                        return 'TotalServices';
                    case 'totalServicesLastSixtyDays':
                        return 'TotalServicesLastSixtyDays';
                    default:
                        return 'Id';
                }
            } else {
                return 'Id';
            }
        },
        getHomes() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            this.loading = true;

            this.errorLoading = false;
            //history.replaceState(undefined, undefined, `?page=${ page }`)

            const sortByField = this.getSortBy();
            const ownerId = this.$route.params.owner_id;

            return new Promise((resolve, reject) => {
                this.$auth.getIdTokenClaims().then(result => {
                    this.token = result.__raw;

                    this.axios
                        .create({ headers: { Authorization: `Bearer ${this.token}` } })
                        .get(
                            process.env.VUE_APP_API +
                                `/FuneralHomes?pageSize=${itemsPerPage}&pageNumber=${page ? page - 1 : 0}&sortAsc=${
                                    sortBy && sortBy[0] ? !sortDesc[0] : false || false
                                }&sortBy=${sortByField}&search=${this.search.toString().toLowerCase()}${
                                    this.statusParamString
                                }&dateSymbol=${this.selectedLastServiceSymbol}&ownerId=${ownerId}
                                ${this.selectedDate ? this.selectedDate : ''}`,
                        )
                        .then(response => {
                            this.homes = response.data.homes;
                            this.totalHomes = response.data.total;
                            this.loading = false;

                            resolve(response.data);
                        })
                        .catch(error => {
                            this.loading = false;
                            this.errorLoading = true;
                        });
                });
            });

            // temp
        },
        getUsers(item) {
            this.$refs.usersTable.openDialog = true;
            this.$refs.usersTable.id = item.id;
        },
        // watchSearch() {
        //     if (this.search.length === 0) {
        //         this.getHomes();
        //     } else {
        //         this.getHomes();
        //         this.options = {
        //             ...this.options,
        //             page: 1,
        //         };
        //     }
        // },
        editHome(id) {
            this.$router.push({ path: `/manage-homes/${id}` });
        },
        impersonate(id) {
            this.loading = true;
            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .post(process.env.VUE_APP_API + `/superadmin/impersonate?funeralHomeId=${id}&impersonate=true`)
                .then(response => {
                    this.$router.push({ name: 'Services' });
                    this.$router.go(0);
                });
        },
        updateBilling(item) {
            this.$refs.billingModal.home = item;
            this.$refs.billingModal.openDialog();
        },
        updateSettings(item) {
            this.$refs.homeSettingsModal.home = item;
            this.$refs.homeSettingsModal.openDialog();
        },
        goToSettings(id) {
            this.$router.push({ path: `/homes/settings/${id}` });
        },
    },
};
</script>

<style lang="scss" scoped>
.dev-home-border {
    background: #ff530d;
    position: absolute;
    left: -0.75rem;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    width: 0.4rem;
    border-radius: 5px;
}

.migrated-home-border {
    background: #750085;
    position: absolute;
    left: -0.75rem;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    width: 0.4rem;
    border-radius: 5px;
}

.reverted-home-border {
    background: #ffc400;
    position: absolute;
    left: -0.75rem;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    width: 0.4rem;
    border-radius: 5px;
}

.v-application p {
    margin-bottom: 0px;
    margin-right: 9px;
}

.respCall {
    width: 100%;
    padding: 10px 4px 16px 14px;
}

.textF {
    font-weight: 200;
}
.errorSub {
    color: red;
    font-size: 11px;
}

.textBenifit {
    font-size: 15px;
    margin: 8px 4px 4px 10px;
}

.create-button {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}
.lastServiceMenu {
    padding: 10px;
    width: 340px;
}
.calendarShowing {
    height: 600px;
}
</style>
