<template>
    <v-dialog v-model="showModal">
        <v-card v-if="editingPhoto" style="height: 85dvh">
            <div class="text-right pa-2">
                <v-btn @click="close" depressed>Close</v-btn>
            </div>
            <div v-if="editingPhoto.error" class="image-error-div">
                <div v-if="editingPhoto.error.heicError">
                    <p>Unsupported image type (HEIC) for image editor, image queued for conversion</p>
                </div>
                <div class="text-center" v-else>
                    <p>An error occured while loading the image.</p>
                    <v-btn @click="deleteSelectedSlides" depressed color="error">Delete Image</v-btn>
                </div>
            </div>

            <div v-else class="image-error-div">
                <v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
            </div>
            <div>
                <PhotoEditor
                    ref="photoEditor"
                    :photo="editingPhoto.photo"
                    :visible="editingPhoto.visible"
                    :theme="imglyConfig.theme"
                    :layout="imglyConfig.mobile ? 'basic' : 'advanced'"
                    :image-path="editingPhoto.path"
                    :blank-image="editingPhoto.blankImage"
                    :token="$props.token"
                    :eventId="$props.eventId"
                    @close="() => (showModal = false)"
                    @export-success="onEditorSave"
                    @created-export-success="onEditorSave"
                    @delete="deleteProfilePhoto"
                    @revert-original="revertPhoto(editingPhoto.photo)"
                    @image-error="() => (editingPhoto.error = {})"
                />
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import TributePhotoService from '@/services/tributePhoto.service';
import { mapActions } from 'vuex';
export default {
    name: 'PhotoEditorModal',
    props: {
        mainPhotoId: {
            type: Number,
            required: true
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        eventId: {
            type: Number,
            required: true
        },
        token: {
            type: String,
            required: true
        }
    },
    components: {
        PhotoEditor: () => import('@/components/ui/PhotoEditor'),
    },
    data() {
        return {
            api: null,
            showModal: false,
            editingPhoto: null,
            photo: null
        }
    },
    computed: {
        imglyConfig() {
            return {
                theme: 'light',
                mobile: this.isMobile,
                // layout: this.state.isMobile ? 'basic': 'advanced',
                // layout: 'advanced',
                // layout: 'basic',
                license: '{"owner":"Imgly Inc.","version":"2.4"}',
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        initApiService() {
            if (this.$props.token && !this.api) {
                this.api = TributePhotoService(this.$props.token)
            }
        },
        async open() {
            this.initApiService();
            this.showModal = true;
            if (!this.$props.mainPhotoId) {   
                return;
            }
            const { data } = await this.api.getPhotoById(this.$props.mainPhotoId);
            if (data.id === this.$props.mainPhotoId) {
                this.photo = data;
                this.editingPhoto = {
                    photo: data,
                    path: data.url,
                    visible: false,
                    error: null,
                    blankImage: null
                }
                setTimeout(() => {
                    this.editingPhoto.visible = true;
                }, 200);
            }
        },
        close() {
            this.showModal = false;
        },
        onEditorSave(editorResults) {
            console.log(editorResults);
            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                mainPhotoId: editorResults.id,
                mainPhotoUrl: editorResults.url,
            });
            this.close();
        },
        async deleteProfilePhoto() {
            const photoId = this.$props.mainPhotoId;

            if (!photoId) {
                this.showSnackbar({ message: 'Error deleting profile photo', color: 'error' });
                return;
            }

            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                mainPhotoId: 0,
                mainPhotoUrl: null,
            });

            try {
                await this.api.deletePhoto(photoId);
                this.showSnackbar({ message: 'Profile picture deleted' });
                this.close();
            } catch (error) {
                console.log(error, 'error deleting profile photo');
            }
        },
        revertPhoto(photo) {
            if (photo.originalPhoto == null) {
                this.showSnackbar({ message: 'No photo to revert to' });
                return;
            }

            this.api.revertPhotoToOriginal(photo.id).then(resp => {
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    ...this.tributeVideo,
                    mainPhotoId: resp.data.id,
                    mainPhotoUrl: resp.data.url,
                });
                this.showSnackbar({ message: 'Original photo restored' });
                this.close();
                this.editingPhoto = null;
            });
        }
    },
    mounted() {
        this.initApiService();
    },

}
</script>
<style lang="scss" scoped>
.image-error-div {
    min-height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>