<template>
    <div style="z-index: 11">
        <v-menu min-width="250" offset-y v-model="menuOpen" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <button fab v-bind="attrs" v-on="on" :disabled="disabled">
                    <v-avatar v-bind="attrs" v-on="on" size="42" color="#dbe2e8">
                        <v-img v-if="profileUrl" :src="profileUrl"></v-img>
                        <font-awesome-icon v-else icon="fa-solid fa-user"></font-awesome-icon>
                    </v-avatar>
                </button>
            </template>
            <v-card>
                <v-list dense class="profile-menu">
                    <v-list-item>
                        <v-list-item-title>
                            <h5>
                                {{ profileName }}
                            </h5>
                        </v-list-item-title>
                    </v-list-item>
                    <template v-if="state.isMobile">
                        <v-divider class="my-1"></v-divider>
                        <ShareMenu mode="list-item">
                        </ShareMenu>
                    </template>
                    <template v-if="!state.isContributor">
                        <v-divider class="my-1"></v-divider>

                        <v-list-item @click="initProfileUpdate">
                            <font-awesome-icon
                                style="color: #9ca3af"
                                class="mr-2"
                                icon="fa-solid fa-rotate"
                            ></font-awesome-icon>
                            <span style="color: #374151">Change Image</span>
                        </v-list-item>
                        <v-list-item @click="$refs.editModal.open()">
                            <font-awesome-icon
                                style="color: #9ca3af"
                                class="mr-2"
                                icon="fa-regular fa-pencil"
                            ></font-awesome-icon>
                            <span style="color: #374151">Edit Image</span>
                        </v-list-item>
                        <v-list-item :disabled="!mainPhotoId" class="danger" @click="deleteProfilePhoto">
                            <font-awesome-icon
                                class="mr-2"
                                icon="fa-regular fa-trash-can"
                                style="color: #ef4444"
                            ></font-awesome-icon>
                            <span style="color: #b91c1c">Remove Image</span>
                        </v-list-item>
                    </template>
                    <v-divider class="my-1"></v-divider>

                    <v-list-item @click="$router.push(`/services/${$route.params.slug}`)">
                        <img :src="require(`@/assets/images/icon_dark.png`)" alt="logo" class="ms-icon" />
                        <span style="color: #374151">Back to MemoryShare</span>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>

        <SlideUpload
            ref="menuProfileUploader"
            @filesAdded="file => handleUpdateProfileImage(file)"
            @progress="val => handleProgress(val)"
            :maxFiles="1"
            :videoAllowed="false"
            :disabled="disableProfileUpdate"
            hidden
        />
        <PhotoEditorModal
            v-if="mainPhotoId && state.token && tributeVideo.eventId"
            ref="editModal"
            :mainPhotoId="mainPhotoId"
            :token="state.token"
            :isMobile="state.isMobile"
            :eventId="tributeVideo.eventId"
        />
    </div>
</template>
<script>
import initApiServices from '@/services/ApiInitializer';
import { mapActions } from 'vuex';
import SlideUpload from '@/components/Tribute/SlideUpload.vue';
import ShareMenu from './ShareMenu.vue';
import PhotoEditorModal from '../Modals/PhotoEditorModal.vue';

export default {
    data() {
        return {
            menuOpen: false,
            apiService: null,
        };
    },
    inject: ['state'],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: { SlideUpload, ShareMenu, PhotoEditorModal },
    computed: {
        profileName() {
            return `${this.$store.state.tributeVideo.firstName} ${this.$store.state.tributeVideo.lastName}`;
        },
        profileUrl() {
            return this.$store.state.tributeVideo.mainPhotoUrl;
        },
        mainPhotoId() {
            return this.$store.state.tributeVideo.mainPhotoId;
        },
        contributorDetailsSet() {
            return this.$store.state.tributeVideo.contributorDetailsSet;
        },
        disableProfileUpdate() {
            return this.state.isContributor || (this.state.isFamily && !this.contributorDetailsSet);
        },
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        profileUploadProgress() {
            return this.$store.state.tributeVideo.profileUploadProgress;
        },
        showProfileUploadProgress() {
            return this.$store.state.tributeVideo.showProfileUploadProgress;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', [
            'updateProfileUploadProgress',
            'updateShowProfileUploadProgress',
            'updatePendingProfileUpload',
        ]),
        moveToSetup() {
            this.$emit('change-step', 'setup');
            this.menuOpen = false;
        },
        handleProgress(val) {
            if (val > this.profileUploadProgress) {
                this.updateProfileUploadProgress(val);
            }
        },
        initProfileUpdate() {
            if (this.disableProfileUpdate) {
                if (this.state.isFamily && !this.contributorDetailsSet) {
                    this.showSnackbar({ message: 'Please set set your details to continue', color: 'error' });
                } else {
                    this.showSnackbar({ message: 'This action is currently unavailable', color: 'error' });
                }
            }

            this.moveToSetup();
            const uploader = this.$refs.menuProfileUploader;

            if (!uploader || typeof uploader.openFileSelection !== 'function') {
                throw new Error('Error initiating upload');
            }

            return uploader.openFileSelection();
        },
        async handleUpdateProfileImage(file) {
            try {
                this.updateShowProfileUploadProgress(true);

                this.updatePendingProfileUpload(file);

                const uploadResp = await this.uploadPhoto(this.tributeVideo.eventId);
                this.updatePendingProfileUpload(null);

                if (uploadResp.data.length === 0) {
                    this.showSnackbar({ message: 'Error updating profile', color: 'error' });
                    return;
                }

                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    ...this.tributeVideo,
                    mainPhotoUrl: uploadResp.data[0].url,
                    mainPhotoId: uploadResp.data[0].id,
                });

                this.showSnackbar({ message: 'Profile picture updated' });
            } catch {
                console.log(error, 'error');
            } finally {
                this.updateProfileUploadProgress(0);
                this.updateShowProfileUploadProgress(false);
            }
        },
        uploadPhoto(eventId) {
            if (!eventId || eventId <= 0) {
                throw new Error('Invalid event id');
            }

            const uploader = this.$refs.menuProfileUploader;

            if (!uploader || typeof uploader.initUpload !== 'function') {
                throw new Error('Error initiating upload');
            }

            //For Profile Image, replaceMain must be set to true;
            return uploader.initUpload(eventId, true);
        },
        async deleteProfilePhoto() {
            const photoId = this.mainPhotoId;
            this.menuOpen = false;

            if (!photoId) {
                this.showSnackbar({ message: 'Error deleting profile photo', color: 'error' });
                return;
            }

            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                mainPhotoId: 0,
                mainPhotoUrl: null,
            });

            try {
                await this.apiService.tributePhoto.deletePhoto(photoId);
                this.showSnackbar({ message: 'Profile picture deleted' });
            } catch (error) {
                console.log(error, 'error deleting  profile photo');
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async mounted() {
        if (this.state.token) {
            this.token = this.state.token;
        } else {
            await this.setAuthToken();
        }
        this.apiService = initApiServices(this.token);
    },
};
</script>
<style lang="scss" scoped>
.profile-menu {
    // min-width: 500px;
}

.v-list-item {
    &.v-list-item--disabled {
        opacity: 0.6;
    }
}
.ms-icon {
    width: 18px;
    margin-left: -2px;
    margin-right: 6px;
    filter: contrast(0.1);
}
</style>
