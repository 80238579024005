<template>
    <div class="step-setup">
        <div class="container">
            <div>
                <SlideUpload
                    class="mb-8"
                    ref="uploader"
                    :videoAllowed="false"
                    :maxFiles="1"
                    @filesAdded="file => onFileAdded(file)"
                    @progress="val => handleProgress(val)"
                    :disabled="disableProfileUpdate"
                >
                    <template #activator="{ onClick }">
                        <div class="d-flex flex-column align-center">
                            <div
                                @click="onClick"
                                :class="['profile-wrapper pointer', { disabled: disableProfileUpdate }]"
                            >
                                <v-progress-circular
                                    v-if="showProfileUploadProgress"
                                    :color="profileUploadProgress >= 100 > 0 ? '#ff530d' : 'orange'"
                                    v-model="profileUploadProgress"
                                    class="upload-progress-ring"
                                    size="300"
                                    width="5"
                                ></v-progress-circular>

                                <div class="pending-slide" v-if="pendingProfileUpload">
                                    <ImagePreview :media="pendingProfileUpload" alt="Profile Photo" />
                                </div>

                                <div v-else-if="profileImageUrl" class="image-preview">
                                    <!-- class="slide-image" -->

                                    <ImageThumbnail
                                        class="image-thumbnail"
                                        :src="profileImageUrl"
                                        :type="0"
                                        :h="500"
                                        :w="500"
                                        smart-crop
                                    />

                                    <button
                                        @click.stop="showEditModal"
                                        class="edit-image"
                                        v-if="$props.tributeVideo.mainPhotoId && !state.isContributor"
                                    >
                                        <font-awesome-icon icon="fa-regular fa-pencil" />
                                    </button>
                                    <button @click.stop="removeImage" class="delete-image">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15.833 5.833L15.111 15.952c-.062.872-.788 1.548-1.662 1.548H6.552c-.875 0-1.601-.676-1.663-1.548L4.167 5.833M8.333 9.167v5M11.667 9.167v5M12.5 5.833V3.333c0-.46-.373-.833-.833-.833H8.333c-.46 0-.833.373-.833.833v2.5M3.333 5.833h13.333"
                                                stroke="#EF4444"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>

                                <div v-else class="profile-place-holder">
                                    <font-awesome-icon
                                        icon="fa-solid fa-user"
                                        style="font-size: 2.5rem"
                                    ></font-awesome-icon>
                                </div>
                            </div>
                        </div>
                    </template>
                </SlideUpload>

                <template v-if="!state.isContributor && !state.isFamily">
                    <h5 class="font-weight-bold">Upload {{ firstName }}'s Image</h5>
                    <p>Upload a photo of your loved one to serve as the thumbnail for this tribute.</p>
                    <div class="d-flex" style="gap: 12px">
                        <button @click="openFileExplorer" for="image" class="secondary-button">
                            <font-awesome-icon
                                class="mr-2"
                                icon="fa-regular fa-arrow-up-from-bracket"
                            ></font-awesome-icon>
                            <span> {{ profileImageUrl ? 'Re-Upload' : 'Upload' }}</span>
                        </button>
                        <button
                            @click="showEditModal"
                            class="secondary-button edit"
                            v-if="$props.tributeVideo.mainPhotoId && !state.isContributor"
                        >
                            <font-awesome-icon class="mx-2" icon="fa-regular fa-pencil"></font-awesome-icon>
                            Edit
                        </button>
                        <button v-if="!pendingUpload" @click="removeImage" class="danger-button">
                            <font-awesome-icon
                                style="color: #ef4444"
                                icon="fa-regular fa-trash-can "
                            ></font-awesome-icon>
                        </button>
                    </div>
                </template>
                <template v-else>
                    <h5 class="font-weight-bold text-center">{{ fullName }}'s Tribute Video</h5>
                </template>
            </div>

            <div class="deadline-container mt-4">
                <template v-if="!state.isContributor && !state.isFamily">
                    <h5 class="font-weight-bold">Set a Deadline for Contributions</h5>
                    <p>Set a deadline for contributors to submit photos and videos for the tribute.</p>
                    <div class="select-wrapper">
                        <calendar
                            datetime
                            v-model="deadline.date"
                            top
                            ref="deadlinePicker"
                            label="Choose Date and Time"
                            outlined
                            clearable
                            disable-past-dates
                            :defaultDate="defaultDeadline"
                            @save="this.updateDeadline"
                            @click:clear="this.clearDeadline"
                        />
                    </div>
                </template>
            </div>
            <template v-if="state.isContributor || state.isFamily">
                <template v-if="!contributorDetailsSet">
                    <div class="field">
                        <div class="mb-3">
                            <h5 class="font-weight-bold">Your details</h5>
                            <p class="mb-0">To continue please state your name and relationship to {{ firstName }}.</p>
                        </div>
                        <div class="mb-3 field">
                            <h6 class="font-weight-bold">Name</h6>
                            <v-text-field v-model="contributor.name" outlined dense></v-text-field>
                        </div>
                        <div class="mb-3 field">
                            <h6 class="font-weight-bold">Relationship</h6>
                            <v-select
                                outlined
                                dense
                                v-model="contributor.relationship"
                                :items="['Acquaintance', 'Relative', 'Friend', 'Family']"
                            ></v-select>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="font-weight-medium mt-4 text-center">
                        <h5 class="font-weight-bold">Welcome Back</h5>
                        <p>
                            {{ contributorFirstName }} ({{ contributor.relationship }})
                            <v-btn class="ml-2" outlined small @click="resetContributeDetailsSet">
                                <font-awesome-icon icon="fa-regular fa-pencil"></font-awesome-icon>
                            </v-btn>
                        </p>
                    </div>
                </template>
            </template>
            <template v-if="(state.isFamily || state.isContributor) && tributeVideoExists">
                <v-btn
                    color="#ea580c"
                    dark
                    depressed
                    :disabled="loading || conditionallyRequired"
                    @click="handleClickNext"
                    class="primary-button"
                >
                    <span class="button-text">Next</span>
                </v-btn>
            </template>
            <template v-else>
                <v-btn
                    color="#ea580c"
                    dark
                    depressed
                    v-if="tributeVideoExists"
                    :disabled="loading"
                    @click="handleClickNext"
                    class="primary-button"
                >
                    <span class="button-text">Next</span>
                </v-btn>
                <v-btn
                    color="#ea580c"
                    dark
                    depressed
                    v-else
                    :disabled="loading"
                    @click="createTributeVideo"
                    class="primary-button"
                >
                    <span class="button-text">Create Tribute Video</span>
                </v-btn>
            </template>
        </div>
        <PhotoEditorModal
            v-if="profileImageUrl && state.token && $props.tributeVideo.eventId"
            ref="editModal"
            :mainPhotoId="$props.tributeVideo.mainPhotoId"
            :token="state.token"
            :isMobile="state.isMobile"
            :eventId="$props.tributeVideo.eventId"
        />
    </div>
</template>

<script>
import initApiServices from '@/services/ApiInitializer';
import Calendar from '@/components/ui/Calendar.vue';
import SlideUpload from '@/components/Tribute/SlideUpload.vue';
import { mapActions, mapGetters } from 'vuex';
import ImageThumbnail from '../Media/ImageThumbnail.vue';
import ImagePreview from '../Media/ImagePreview.vue';
import PhotoEditorModal from './Modals/PhotoEditorModal.vue';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
export default {
    name: 'TributeSetup',
    data() {
        return {
            deadline: {
                date: null,
                time: null,
            },
            defaultDeadline: null,
            image: {
                previewURL: null,
                file: null,
            },
            loading: false,
            token: null,
            apiService: null,
            pendingUpload: null,
            tempFiles: [],
            contributor: {
                name: null,
                relationship: null,
            },
        };
    },
    inject: ['state'],
    props: {
        serviceSlug: {
            type: String,
            required: true,
        },
        tributeVideo: {
            type: Object,
            default: () => {},
        },
        isActiveTab: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        calendar: Calendar,
        SlideUpload,
        ImageThumbnail,
        ImagePreview,
        PhotoEditorModal,
    },
    computed: {
        tributeVideoExists() {
            if (!this.tributeVideo || !this.tributeVideo.id) return false;

            return this.tributeVideo.id > 0;
        },
        fullName() {
            if (this.tributeVideo?.firstName && this.tributeVideo?.lastName) {
                return `${this.tributeVideo?.firstName} ${this.tributeVideo?.lastName}`;
            }
            return this.firstName;
        },
        firstName() {
            return this.tributeVideo?.firstName || 'Honoree';
        },
        profileImageUrl() {
            return this.tributeVideo?.mainPhotoUrl;
        },
        ...mapGetters('tributeVideo', ['deadlineExpired']),
        conditionallyRequired() {
            // return true to disable fields
            // disable next until fields are set
            if (this.state.isContributor || this.state.isFamily) {
                if (!this.contributor.name || !this.contributor.name.length) {
                    return true;
                }
                if (!this.contributor.relationship || !this.contributor.relationship.length) {
                    return true;
                }
            }
            return false;
        },
        contributorFirstName() {
            if (this.contributor?.name?.length > 0) {
                const words = this.contributor.name.split(' ');
                return words[0];
            }
            return '';
        },
        disableProfileUpdate() {
            return this.state.isContributor || (this.state.isFamily && !this.contributorDetailsSet);
        },
        contributorDetailsSet() {
            return this.$store.state.tributeVideo.contributorDetailsSet;
        },
        profileUploadProgress() {
            return this.$store.state.tributeVideo.profileUploadProgress;
        },
        showProfileUploadProgress() {
            return this.$store.state.tributeVideo.showProfileUploadProgress;
        },
        pendingProfileUpload() {
            return this.$store.state.tributeVideo.pendingProfileUpload;
        },
    },

    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeVideo', [
            'updateProfileUploadProgress',
            'updateShowProfileUploadProgress',
            'updatePendingProfileUpload',
        ]),

        async getLastScheduledEventTime(slug) {
            try {
                if (!this.apiService?.services) return;

                const resp = await this.apiService.services.getLastScheduledEventTime(slug);

                return resp?.data?.time;
            } catch (error) {
                console.log(error, 'error');
            }
        },
        async setDefaultDeadline() {
            let defaultDeadline;

            try {
                if (!this.serviceSlug) throw new Error('Invalid service slug');

                const lastEventTime = await this.getLastScheduledEventTime(this.serviceSlug);

                if (lastEventTime) {
                    //last event datetime - 1hr

                    var currentDate = this.$moment();
                    var eventDate = this.$moment.utc(lastEventTime).local();

                    if (eventDate > currentDate) {
                        var eventHour = eventDate.get('hour');
                        defaultDeadline = eventDate.set('hour', eventHour - 1).toISOString();
                    }

                    this.defaultDeadline = this.parseDeadline(defaultDeadline);
                } else {
                    //current date + 5 days @ 1pm (if no valid events)
                    let defaultDate = this.$moment();
                    defaultDate.set('date', defaultDate.get('date') + 5);
                    defaultDate.set('hour', 13);
                    defaultDate.set('minute', 0);
                    defaultDate.set('second', 0);

                    defaultDeadline = defaultDate.toISOString();

                    this.defaultDeadline = this.parseDeadline(defaultDeadline);
                }
            } catch (error) {
                console.log(error, 'error setting default deadline');
            }
        },
        resetContributeDetailsSet() {
            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                contributorDetailsSet: false,
            });
        },
        openFileExplorer() {
            if (!this.$refs.uploader || typeof this.$refs.uploader.openFileSelection !== 'function') {
                console.log('error opening file explorer');
                return;
            }
            this.$refs.uploader.openFileSelection();
        },
        handleProgress(val) {
            if (val > this.profileUploadProgress) {
                this.updateProfileUploadProgress(val);
            }
        },
        async onFileAdded(file) {
            if (this.tributeVideoExists && this.tributeVideo.eventId) {
                await this.handleUpdateProfileImage(file);
            } else {
                if (file) {
                    const reader = new FileReader();
                    reader.onload = e => {
                        this.$store.dispatch('tributeVideo/updateTributeVideo', {
                            ...this.tributeVideo,
                            mainPhotoUrl: e.target.result,
                        });
                    };

                    let fileData = file.data ? file.data : file;
                    reader.readAsDataURL(fileData);
                    this.image.file = fileData;
                } else {
                    this.removeImage();
                }
            }
        },
        async handleUpdateProfileImage(file) {
            try {
                this.updateShowProfileUploadProgress(true);
                this.updatePendingProfileUpload(file);

                const uploadResp = await this.uploadPhoto(this.tributeVideo.eventId);
                this.updatePendingProfileUpload(null);

                if (uploadResp.data.length === 0) {
                    this.showSnackbar({ message: 'Error updating profile', color: 'error' });
                    return;
                }

                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    ...this.tributeVideo,
                    mainPhotoUrl: uploadResp.data[0].url,
                    mainPhotoId: uploadResp.data[0].id,
                });

                this.showSnackbar({ message: 'Profile picture updated' });
            } catch (error) {
                console.log(error, 'errror');
            } finally {
                this.updateProfileUploadProgress(0);
                this.updateShowProfileUploadProgress(false);
            }
        },
        uploadPhoto(eventId) {
            if (!eventId || eventId <= 0) {
                throw new Error('Invalid event id');
            }

            const uploader = this.$refs.uploader;

            if (!uploader || typeof uploader.initUpload !== 'function') {
                throw new Error('Error initiating upload');
            }

            //For Profile Image, replaceMain must be set to true;
            return uploader.initUpload(eventId, true);
        },
        async removeImage() {
            const mainPhotoId = this.tributeVideo?.mainPhotoId;

            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                mainPhotoId: 0,
                mainPhotoUrl: null,
            });

            const uploader = this.$refs.uploader;
            if (uploader && typeof uploader.reset === 'function') {
                uploader.reset();
            }

            if (mainPhotoId) {
                await this.apiService.tributePhoto.deletePhoto(mainPhotoId);
                this.showSnackbar({ message: 'Profile picture deleted' });
            }
        },
        clearDeadline() {
            this.deadline.date = null;
            this.updateDeadline();
        },
        async updateDeadline() {
            if (!this.tributeVideo?.id) return;

            const deadlineData = { deadline: this.deadline.date ? this.deadline.date : null };

            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                ...deadlineData,
            });

            try {
                await this.apiService.tributeVideo.updateDeadline(this.tributeVideo.id, deadlineData);
                this.showSnackbar({ message: 'Deadline updated' });
            } catch (error) {
                console.log('error updating deadline', error);
                this.showSnackbar({ message: 'Error updating deadline', color: 'error' });
            }
        },
        datesAreEqual(date1, date2) {
            const dateString1 = this.$moment.utc(date1).toISOString();
            const dateString2 = this.$moment.utc(date2).toISOString();
            return dateString1 === dateString2;
        },
        async handleClickNext() {
            if (this.state.isContributor || this.state.isFamily) {
                // If the fields aren't filled out then prevent going to next state
                if (isEmpty(this.contributor.name) || isEmpty(this.contributor.relationship)) {
                    this.showSnackbar({ message: 'Please fill out the fields.', color: 'error' });
                    return;
                }
                this.$emit('setAnonUserDetails', this.contributor);
            }
            this.$emit('next-step');
        },
        async createTributeVideo() {
            const slug = this.serviceSlug;
            if (!slug) {
                this.showSnackbar({ messaage: 'Invalid service slug', color: 'error' });
                return;
            }

            try {
                let tributeData = {
                    deadline: this.deadline.date,
                };

                this.loading = true;
                this.block(true);
                const tributeResp = await this.apiService.tributeVideo.createTributeVideoByService(slug, tributeData);

                if (!tributeResp.data.eventId) {
                    throw new Error('Error creating  tribute video');
                }

                const eventId = tributeResp.data.eventId;

                if (this.image.file) {
                    const photoResp = await this.uploadPhoto(eventId);
                    console.log(photoResp, 'initial photo resp');
                }

                this.$emit('tribute-video-created', tributeResp.data);
            } catch (error) {
                console.log(error, 'error creating tribute video');
            } finally {
                this.loading = false;
                this.block(false);
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        parseDeadline(deadline) {
            if (!deadline) return;

            const localDeadline = this.$moment.utc(deadline).local();
            return localDeadline.toISOString();
        },
        showEditModal() {
            this.$refs.editModal.open();
        },
    },
    async mounted() {
        if (this.state.token) {
            this.token = this.state.token;
        } else {
            await this.setAuthToken();
        }

        this.apiService = initApiServices(this.token);
        if (this.tributeVideo?.deadline) {
            this.deadline.date = this.parseDeadline(this.tributeVideo?.deadline);

            console.log(this.tributeVideo, 'tribute video on setup mount');
        } else {
            this.setDefaultDeadline();
        }

        if (this.state.isFamily || this.state.isContributor) {
            const tributeUserDetails = localStorage.getItem('tributeContributor');
            if (typeof tributeUserDetails === 'string') {
                this.contributor = JSON.parse(tributeUserDetails);
                // this.contributorDetailsSet = true;
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    ...this.tributeVideo,
                    contributorDetailsSet: true,
                });
            }
        }
    },
    watch: {
        '$props.tributeVideo': {
            handler(newVal) {
                if (newVal.deadline) {
                    this.deadline.date = this.parseDeadline(newVal.deadline);
                } else {
                    this.setDefaultDeadline();
                }
            },
            deep: true,
            immediate: true,
        },
        state: {
            handler(newVal, oldVal) {
                if (newVal?.anonUserDetails) {
                    if (newVal.anonUserDetails?.name) {
                        this.contributor.name = newVal.anonUserDetails.name;
                    }
                    if (newVal.anonUserDetails?.relationship) {
                        this.contributor.relationship = newVal.anonUserDetails.relationship;
                    }
                    // this.contributorDetailsSet = true;
                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        ...this.tributeVideo,
                        contributorDetailsSet: true,
                    });
                }
            },
            deep: true,
            immediate: true,
        },
        isActiveTab(newVal) {
            if (!newVal) return;

            if (this.tributeVideo?.deadline) {
                this.deadline.date = this.parseDeadline(this.tributeVideo?.deadline);
            } else {
                this.deadline.date = null;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.step-setup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    max-height: 100%;
}

.container {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-items: center;
    max-height: 100%;
    @include mobile() {
        width: auto;
    }
}

.deadline-container,
.image-upload {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

// h2 {
//     color: #6b7280;
//     // font-weight: 600;
//     // font-size: 12px;
//     // line-height: 16px;
//     text-transform: uppercase;
// }

// p {
//     color: #111827;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 20px;
// }

.select-wrapper {
    display: flex;
    gap: 8px;
    width: 100%;
}

.select-button {
    padding: 10px;
    width: 100%;
    background-color: #fff;
    color: #374151;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px #0000000d;
    border-radius: 6px;
}

.secondary-button {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    background-color: #fff;
    color: #374151;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000d;

    &:hover {
        background-color: $light-gray;
    }
}

.danger-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border-radius: 4px;
    background: #fee2e2;
    border: 1px solid #fca5a5;
    color: #374151;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000d;

    &:hover {
        background: #ffd3d3;
    }
}

#image {
    display: none;
}

.edit-image {
    position: absolute;
    bottom: 6%;
    right: 21%;
    left: auto;
    background: #f6f6f6;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 50px;
    height: 50px;
    margin: auto;
    cursor: pointer;
    font-size: 1.4rem;
    border-radius: 10rem;
    transition: all ease-in-out 250ms;
}
// .delete-image {
//     // position: absolute;
//     // bottom: 5px;
//     // right: 5px;
//     background: #fee2e2;
//     border: 1px solid #fca5a5;
//     border-radius: 4px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 10px;
//     // width: 24px;
//     // height: 24px;
//     cursor: pointer;
// }
.upload-progress-ring {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 5;
}
.primary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    background-color: #ea580c;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 6px;
    transition: all 0.3s;
}

.primary-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pending-slide {
    height: 100%;
    width: 100%;

    img {
        // opacity: 0.4;
        height: 100%;
        width: 100%;
        max-width: 300px;
        object-fit: cover;
    }

    .progress-indicator {
        width: 93%;
        display: inline-block;
        height: 5px;
        bottom: 10px;
        position: absolute;
        left: 0;
        right: 0;
        background: #ffedd5;
        margin: auto;
        border-radius: 10rem;
    }

    .progress-slider {
        height: 100%;
        background: #f97316;
        border-radius: 10rem;
        transition: width 200ms ease-in-out;
    }
}

.profile-wrapper {
    aspect-ratio: 1;
    border-radius: 10rem;
    width: 100%;
    max-width: 300px;
    overflow: hidden;
    position: relative;
    &.disabled {
        opacity: 1;
        cursor: auto;
    }
    @include mobile() {
        max-width: 210px;
    }
}

.profile-wrapper:not(.disabled):hover {
    opacity: 0.8;
}

.image-preview {
    // position: relative;
    display: inline-block;
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    aspect-ratio: 1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &:hover {
        .edit-image {
            transform: scale(1.1);
        }
    }
}

.image-preview img {
    border-radius: 8px;
}

.profile-place-holder {
    background-color: #dbe2e8;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    h1 {
        // color: white;
        margin: 0;
        padding: 0;
    }
}
.field {
    width: 100%;
}
</style>
