import { render, staticRenderFns } from "./CustomContextMenu.vue?vue&type=template&id=51418a62&scoped=true&"
import script from "./CustomContextMenu.vue?vue&type=script&lang=js&"
export * from "./CustomContextMenu.vue?vue&type=script&lang=js&"
import style0 from "./CustomContextMenu.vue?vue&type=style&index=0&id=51418a62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51418a62",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VList,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
