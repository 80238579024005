<template>
    <div class="action-bar-wrapper">
        <div class="action-bar">
            <v-row>
                <v-col cols="12" sm="4">
                    <div class="left-buttons">
                        <SlideUpload
                            ref="uploaderBtn"
                            :videoAllowed="allowVideoUploads"
                            :maxFiles="maxFilesLeft"
                            :dropTarget="$props.dropTarget"
                            :batchSize="10"
                            @change="tempFiles => $emit('temp-file-change', tempFiles)"
                            @filesAdded="$emit('files-added')"
                            @successful-uploads="uploads => $emit('successful-uploads', uploads)"
                            @progress="val => $emit('total-upload-progress', val)"
                            :disabled="$props.disableUploads"
                        >
                            <template #activator="{ onClick }">
                                <button class="primary-button" @click="onClick" :disabled="$props.disableUploads">
                                    <font-awesome-icon
                                        class="mr-2"
                                        icon="fa-regular fa-image"
                                        style="font-size: 1.2rem"
                                    ></font-awesome-icon>
                                    Add {{ state.isMobile ? 'Memories' : 'Media' }}
                                </button>
                            </template>
                        </SlideUpload>

                        <button
                            class="secondary-button"
                            @click="$emit('add-text-slide')"
                            v-if="!state.isMobile && !state.isContributor"
                        >
                            <font-awesome-icon
                                icon="fa-regular fa-bars-staggered"
                                style="font-size: 1.2rem"
                                class="mr-2"
                            ></font-awesome-icon>
                            Add Text Slide
                        </button>

                        <slot name="left-buttons-append"></slot>
                    </div>
                </v-col>
                <template v-if="!state.isMobile">
                    <v-col cols="6" sm="4">
                        <div class="center-buttons">
                            <button class="icon-button rounded wide" @click="toggleHeight">
                                <font-awesome-icon
                                    class="mr-2"
                                    :style="{
                                        transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'width transform 0.5s',
                                    }"
                                    icon="fa-regular fa-chevron-up"
                                ></font-awesome-icon>
                                {{ !isExpanded ? 'Explore Slides' : 'Back to Preview' }}
                            </button>

                            <!-- <v-btn depressed @click="toggleHeight">
                                <font-awesome-icon
                                    class="mr-2"
                                    :style="{
                                        transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.5s',
                                    }"
                                    icon="fa-regular fa-chevron-up"
                                ></font-awesome-icon>
                                <span> {{ isExpanded ? 'Close' : 'Explore Gallery' }} </span>
                            </v-btn> -->
                        </div>
                    </v-col>

                    <v-col cols="6" sm="4">
                        <div class="right-buttons">
                            <div class="d-flex align-center" style="gap: 12px">
                                <slot name="right-buttons"></slot>
                            </div>
                        </div>
                    </v-col>
                </template>
            </v-row>
        </div>

        <div class="action-bar-2" v-if="isExpanded">
            <v-row>
                <v-col cols="2" md="4" v-if="!state.isMobile">
                    <slot name="bar-2-left"></slot>
                </v-col>
                <v-col cols="2" md="4" class="d-flex justify-center align-end bar-2-wrapper">
                    <slot name="bar-2-center"></slot>
                </v-col>
                <v-col cols="2" md="4" class="d-flex justify-end align-center" style="gap: 12px">
                    <slot name="bar-2-right-prepend"></slot>
                    <div class="button-group">
                        <CustomTooltip :tooltipProps="{ top: true }" v-if="!state.isMobile">
                            <template #activator>
                                <button class="icon-button" @click="toggleShowUploadUsers">
                                    <font-awesome-icon
                                        :style="{ color: '#6B7280' }"
                                        icon="fa-regular fa-users"
                                    ></font-awesome-icon>
                                </button>
                            </template>

                            <template #content>
                                <span>Show Upload Users</span>
                            </template>
                        </CustomTooltip>
                        <CustomTooltip :tooltipProps="{ top: true }" v-if="!state.isMobile">
                            <template #activator>
                                <button class="icon-button" @click="initFilterByUser">
                                    <font-awesome-icon
                                        :style="{ color: '#6B7280' }"
                                        icon="fa-regular fa-user"
                                    ></font-awesome-icon>
                                </button>
                            </template>

                            <template #content>
                                <span>Filter Uploads By User</span>
                            </template>
                        </CustomTooltip>
                        <CustomTooltip :tooltipProps="{ top: true }">
                            <template #activator>
                                <button
                                    class="icon-button"
                                    :disabled="downloadDisabled"
                                    @click="downloadSelectedSlides"
                                >
                                    <font-awesome-icon
                                        :style="{ color: downloadDisabled ? '#d1d5db' : '#6B7280' }"
                                        icon="fa-regular fa-arrow-down-to-bracket"
                                    ></font-awesome-icon>
                                </button>
                            </template>

                            <template #content>
                                <span>Download</span>
                            </template>
                        </CustomTooltip>

                        <CustomTooltip :tooltipProps="{ top: true }" v-if="!state.isMobile">
                            <template #activator>
                                <button
                                    @click="$emit('edit-active-slide')"
                                    :disabled="editDisabled"
                                    class="icon-button"
                                >
                                    <font-awesome-icon
                                        :style="{ color: editDisabled ? '#d1d5db' : '#6B7280' }"
                                        icon="fa-regular fa-pencil"
                                    ></font-awesome-icon>
                                </button>
                            </template>

                            <template #content>
                                <span>Edit</span>
                            </template>
                        </CustomTooltip>

                        <CustomTooltip :tooltipProps="{ top: true }">
                            <template #activator>
                                <button
                                    @click="$emit('rotate-selected-slides')"
                                    class="icon-button"
                                    :disabled="rotateDisabled"
                                >
                                    <font-awesome-icon
                                        :style="{ color: rotateDisabled ? '#d1d5db' : '#6B7280' }"
                                        icon="fa-regular fa-rotate-right"
                                    ></font-awesome-icon>
                                </button>
                            </template>
                            <template #content>
                                <span>Rotate</span>
                            </template>
                        </CustomTooltip>

                        <CustomTooltip :tooltipProps="{ top: true }">
                            <template #activator>
                                <button
                                    @click="$emit('open-delete-modal')"
                                    class="highlighted-icon-button"
                                    :disabled="deleteDisabled"
                                    style="border-top-right-radius: 6px; border-bottom-right-radius: 6px"
                                >
                                    <font-awesome-icon
                                        :style="{ color: deleteDisabled ? '#FCA5A5' : '#EF4444' }"
                                        icon="fa-regular fa-trash-can"
                                    ></font-awesome-icon>
                                </button>
                            </template>

                            <template #content>
                                <span>Delete</span>
                            </template>
                        </CustomTooltip>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import SlideUpload from '@/components/Tribute/SlideUpload.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { partition } from 'lodash';

export default {
    data() {
        return {
            // Don't allow video uploads for contributors
            allowVideoUploads: this.state.isContributor ? false : true,
        };
    },
    components: {
        SlideUpload,
        CustomTooltip,
    },
    inject: ['state'],
    props: {
        maxFilesLeft: {
            type: Number,
            default: 0,
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
        selectedSlides: {
            type: Array,
            default: () => [],
        },
        disableMoveBack: {
            type: Boolean,
            default: true,
        },
        disableMoveForward: {
            type: Boolean,
            default: true,
        },
        disableUploads: {
            type: Boolean,
            default: false,
        },
        dropTarget: {
            type: String,
            required: false,
        },
    },
    computed: {
        isAnyImageSelected() {
            if (this.state.isContributor) {
                // for contributors, this is true only if they only have their own photos selected
                const selectedImageCounts = partition(this.selectedSlides, slide => !!slide.guid);
                return (
                    selectedImageCounts[0].length > 0 && selectedImageCounts[0].length === this.selectedSlides.length
                );
            }
            return this.selectedSlides.length > 0;
        },
        editDisabled() {
            if (this.isAnyImageSelected) {
                return this.selectedSlides.length !== 1 || this.selectedSlides[0]?.mediaType === 1;
            }
            return true;
        },
        downloadDisabled() {
            return !this.isAnyImageSelected;
        },
        rotateDisabled() {
            return !this.isAnyImageSelected;
        },
        deleteDisabled() {
            return !this.isAnyImageSelected;
        },
    },
    methods: {
        ...mapActions('tributeEditor', ['toggleExpandedState']),
        initFilterByUser() {
            this.$emit('init-filter-by-user');
        },
        toggleShowUploadUsers() {
            this.$emit('toggle-show-upload-users');
        },
        toggleHeight() {
            this.toggleExpandedState();
        },
        downloadSelectedSlides() {
            this.$emit('download-selected');
        },

        initUpload(eventId) {
            if (!this.$refs.uploaderBtn) {
                throw new Error('Uploader not found');
            }

            return this.$refs.uploaderBtn.initUpload(eventId);
        },
    },
};
</script>
<style lang="scss" scoped>
.action-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0;

    @include mobile() {
        flex-wrap: wrap;
        flex-grow: 1;
        gap: 7px;
    }
}

.action-bar-2 {
    display: flex;
    justify-content: flex-end;
    // justify-content: space-between;
    align-items: center;
    margin: 8px 0;

    @include mobile() {
        flex-wrap: wrap;
        flex-grow: 1;
        gap: 7px;
    }
}
.center-buttons {
    display: flex;
    justify-content: center;
}

.left-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
}
.right-buttons {
    display: flex;
    justify-content: flex-end;
}

.button-group {
    display: flex;
    //justify-content: space-between;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px #0000000d;

    div:first-of-type > div > .icon-button {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    div:last-of-type > div > .icon-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}

.icon-button {
    width: 38px;
    height: 38px;
    font-size: 14px;
    background: white;
    border: 1px solid #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled {
        cursor: not-allowed;
    }

    &.rounded {
        border-radius: 6px !important;
    }

    &.wide {
        width: initial;
        padding: 0 10px;
        font-weight: 500;
    }

    &:hover {
        background-color: $light-gray;
    }
}

.highlighted-icon-button {
    width: 38px;
    height: 38px;
    background: #fee2e2;
    border: 1px solid #fca5a5;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled {
        cursor: not-allowed;
    }
}

.highlighted-icon-button:hover {
    background-color: #fca5a568;
}

.button-divider {
    width: 1px;
    height: 60%;
    background: #d1d5db;
    margin-right: 8px;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.primary-button,
.secondary-button {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 6px;
    padding: 10px 12px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    &[disabled],
    &:disabled {
        cursor: not-allowed;
    }
}

.primary-button {
    background: #ea580c;
    color: white;
    border: none;
    &:hover {
        background-color: $btn-orange-hover;
    }
}

.secondary-button {
    background: white;
    color: #374151;
    border: 1px solid #d1d5db;
    &:hover {
        background-color: $light-gray;
    }
}

.shadow-button {
    box-shadow: 0px 1px 2px 0px #0000000d;
}
// Mobile styling
@include mobile() {
    .action-bar-wrapper {
        display: flex;

        .action-bar,
        .action-bar-2 {
            flex: 1;
        }

        .action-bar-2 .row {
            justify-content: flex-end;
        }
    }
    .bar-2-wrapper {
        max-width: 100%;
        bottom: 55px;
        z-index: 13;
        width: 100vw;
        position: absolute;
        background: white;
        left: 0;
        right: 0;
    }
}
</style>
