<template>
    <div v-if="isOpen" class="modal-container">
        <div class="modal-overlay" @click="closeModal"></div>
        <div class="modal-content">
            <div class="modal-banner">
                <div class="banner-text">Modify Theme</div>
                <div class="close-button" @click="closeModal">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 15L15 5M5 5L15 15" stroke="#6B7280" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
            </div>

            <div class="modal-body">
                <div class="preview-container">
                    <div class="video-frame">
                        <CreatomateSmallPreview :currentSource="this.$props.previewSource"
                            :FirstName="tempModifications['FirstName'].value"
                            :LastName="tempModifications['LastName'].value"
                            :BirthDate="tempModifications['BirthDate'].value"
                            :DeathDate="tempModifications['DeathDate'].value" :photo="this.$props.mainPhoto"
                            :customFont="tempFont" />
                    </div>
                </div>

                <div class="mods-form">
                    <label>Font</label>
                    <google-fonts-drop-down v-show="isOpen" v-model="tempFont" class="font-drop-down"
                        @font-changed="handleFontChange" ref="fontDropdown"></google-fonts-drop-down>

                    <div class="form-group-row">
                        <div class="form-group">
                            <label>First Name</label>
                            <input v-model="tempModifications['FirstName'].value" maxlength="100" type="text"
                                class="text-input" @input="checkForChanges" />
                        </div>
                        <div class="form-group">
                            <label>Last Name</label>
                            <input v-model="tempModifications['LastName'].value" maxlength="100" type="text"
                                class="text-input" @input="checkForChanges" />
                        </div>
                    </div>

                    <div class="form-group-row">
                        <div class="form-group">
                            <label>Birth Year</label>
                            <input v-model="tempModifications['BirthDate'].value" maxlength="4" type="text"
                                class="text-input" @input="checkForChanges" />
                        </div>
                        <div class="form-group">
                            <label>Death Year</label>
                            <input v-model="tempModifications['DeathDate'].value" maxlength="4" type="text"
                                class="text-input" @input="checkForChanges" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <v-btn class="save-button" :disabled="!hasChanges" :style="{ opacity: hasChanges ? 1 : 0.9 }"
                    @click="saveChanges">
                    Save Changes
                    <v-icon right> mdi-chevron-right </v-icon>
                </v-btn>
            </div>
        </div>

        <WarningModal :open="showWarning" @close="showWarning = false">
            <template #modal-title> Unsaved Changes </template>
            <template #modal-content>
                You have unsaved changes. Are you sure you want to discard changes to your Tribute's Theme?
            </template>
            <template #modal-footer>
                <button class="btn btn-cancel" @click="showWarning = false">Cancel</button>
                <button class="btn btn-deactivate" @click="confirmClose">Discard Changes</button>
            </template>
        </WarningModal>
    </div>
</template>

<script>
import CreatomateSmallPreview from '@/components/Tribute/Themes/EditPreview.vue';
import GoogleFontsDropDown from '@/components/Tribute/Themes/GoogleFontsDropdown.vue';
import { mapActions } from 'vuex';
import WarningModal from '../WarningModal.vue';

export default {
    name: 'ModifyThemeModal',
    components: {
        CreatomateSmallPreview,
        GoogleFontsDropDown,
        WarningModal,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        tributeId: {
            type: Number,
            required: true,
        },
        previewSource: {
            type: Object,
            required: true,
        },
        previewMods: {
            type: Object,
            required: true,
        },
        currentFont: {
            type: Object,
            required: true,
        },
        mainPhoto: {
            type: String,
            required: false,
        },
        currentModifications: {
            type: Array,
            required: true,
        },
    },
    async mounted() {
        if (this.isOpen) {
            this.$nextTick(() => {
                this.ensureFontsLoaded(() => {
                    if (this.$refs.fontDropdown && this.$refs.fontDropdown.setFont) {
                        this.$refs.fontDropdown.setFont(this.tempFont.family);
                    }
                });
            });
        }
    },
    watch: {
        currentModifications: {
            immediate: true,
            handler(newVal) {
                if (newVal.length > 0) {
                    this.initializeTempModifications();
                }
            },
        },
        isOpen(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.ensureFontsLoaded(() => {
                        if (this.$refs.fontDropdown && this.$refs.fontDropdown.setFont) {
                            this.$refs.fontDropdown.setFont(this.currentFont.family);
                        }
                    });
                });
            }
        },
    },

    data() {
        return {
            originalFont: this.currentFont,
            originalModifications: this.currentModifications,
            tempFont: this.currentFont,
            tempModifications: null,
            hasChanges: false,
            showWarning: false,
        };
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeVideo', ['updateTributeVideo', 'updateTributeVideoSelectedTemplates']),
        resetState() {
            this.initializeTempModifications();
            this.tempFont = this.originalFont;
            this.hasChanges = false;
        },
        initializeTempModifications() {
            const expectedNames = ['FirstName', 'LastName', 'BirthDate', 'DeathDate'];
            this.tempModifications = expectedNames.reduce((acc, name) => {
                const mod = this.currentModifications.find(m => m.name === name);
                acc[name] = mod ? { key: mod.key, value: mod.value } : { key: '', value: '' };
                return acc;
            }, {});

            const fullNameMod = this.currentModifications.find(m => m.name === 'FullName');
            if (fullNameMod && fullNameMod.value) {
                const parts = fullNameMod.value.split(' ');
                if (parts.length === 2) {
                    this.tempModifications['FirstName'] = { key: fullNameMod.key, value: parts[0] };
                    this.tempModifications['LastName'] = { key: fullNameMod.key, value: parts[1] };
                } else {
                    console.warn('FullName does not split into exactly two parts:', fullNameMod.value);
                }
            }
        },
        closeModal() {
            if (this.hasChanges) {
                this.showWarning = true;
            } else {
                this.confirmClose();
            }

        },
        confirmClose() {
            this.showWarning = false; 
            this.resetState();
            this.$emit('close'); 
        },

        saveChanges() {
            if (!this.validateForm()) return;
            const updatedModifications = {};
            const allMods = {};

            for (const [name, mod] of Object.entries(this.tempModifications)) {
                const original = this.currentModifications.find(m => m.key === mod.key)?.value;
                if (mod.value !== original) {
                    updatedModifications[mod.key] = mod.value;
                    allMods[mod.key] = mod.value;
                } else {
                    allMods[mod.key] = mod.value;
                }
            }
            let customFont = null;
            if (
                this.tempFont &&
                (this.tempFont.family !== this.$props.currentFont.family ||
                    this.tempFont.style !== this.$props.currentFont.style ||
                    this.tempFont.weight !== this.$props.currentFont.weight)
            ) {
                customFont = {
                    family: this.tempFont.family,
                    source: this.tempFont.files.regular.replace('http:', 'https:'),
                    style: 'normal',
                    weight: 700,
                };
            }
            let mods = customFont === null ? updatedModifications : allMods;

            this.$emit('save', { modifications: mods, font: customFont });
            this.hasChanges = false;
        },
        checkForChanges() {
            this.hasChanges =
                Object.entries(this.tempModifications).some(([key, mod]) => {
                    const original = this.currentModifications.find(m => m.key === mod.key)?.value;
                    return mod.value !== original;
                }) || this.tempFont.family !== this.currentFont.family;
        },
        validateForm() {
            const firstName = this.tempModifications.FirstName.value;
            const lastName = this.tempModifications.LastName.value;
            const birthDate = this.tempModifications.BirthDate.value;
            const deathDate = this.tempModifications.DeathDate.value;

            if (!/^\d{4}$/.test(birthDate)) {
                this.showSnackbar({ message: 'Birth date must be a 4-digit year.' });
                return false;
            }
            if (!/^\d{4}$/.test(deathDate)) {
                this.showSnackbar({ message: 'Death date must be a 4-digit year.' });
                return false;
            }

            const numericBirthYear = parseInt(birthDate, 10);
            const numericDeathYear = parseInt(deathDate, 10);
            if (numericDeathYear <= numericBirthYear) {
                this.showSnackbar({ message: 'Death year must be greater than birth year.' });
                return false;
            }

            const normalise = str => str.replace(/\s+/g, '').toLowerCase();

            const restrictedWords = ['firstname', 'lastname', 'birthyear', 'deathyear'];
            const normalisedFirstName = normalise(firstName);
            const normalisedLastName = normalise(lastName);

            if (restrictedWords.includes(normalisedFirstName)) {
                this.showSnackbar({ message: 'Phrase "First Name" cannot be used as a name' });
                return false;
            }
            if (restrictedWords.includes(normalisedLastName)) {
                this.showSnackbar({ message: 'Phrase "Last Name" cannot be used as a name' });
                return false;
            }

            return true;
        },

        handleFontChange(font) {
            this.tempFont = font;
            this.checkForChanges();
        },
        ensureFontsLoaded(callback) {
            const dropdown = this.$refs.fontDropdown;
            if (dropdown && dropdown.fonts.length > 0) {
                callback();
            } else {
                const interval = setInterval(() => {
                    if (dropdown && dropdown.fonts.length > 0) {
                        clearInterval(interval);
                        callback();
                    }
                }, 50);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-container {
    position: fixed;
    top: 90px;
    bottom: 0;
    right: 0;
    height: 100%;
    max-height: 80vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9998;
    border: 1px solid #d1d5db;
    @include mobile() {
        top: 90px;
        bottom: 90px;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9997;
}

.modal-content {
    position: relative;
    width: auto;
    min-width: 50vw;
    max-width: 98vw;
    height: 99vh;
    background: white;
    z-index: 9997;
    @include mobile() {
        height: auto;
        flex-grow: 1;
        max-height: calc(100vh - 150px);
    }
}

.modal-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    max-height: 5vh;
    background: white;
    border-bottom: 1px solid #d1d5db;
    @include mobile() {
        max-height: 50px;
        max-height: min-content;
        padding: 8px;
    }
}

.preview-container {
    margin-top: -20px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    max-height: 60vh;

    @media screen and (max-width: 768px) {
        margin-top: 0px;
        max-height: 70vh;
    }

    @media screen and (max-width: 480px) {
        padding: 5px;
        max-height: 80vh;
    }
}

.video-frame {
    max-width: 700px;
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: #ffffff;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 7.47px 7.47px -3.74px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 14.95px 18.69px -3.74px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 768px) {
        max-width: 90%;
    }

    @media screen and (max-width: 480px) {
        max-width: 100%;
    }
}

.mods-form {
    margin-top: -20px;
    max-height: 40vh;

    @media screen and (max-width: 1200px) {
        margin-top: 0px;
    }
    @include mobile() {
        max-height: initial;
    }
}

.banner-text {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #374151;
}

.close-button {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000d;
}

.modal-body {
    flex: 1;
    padding: 0px 16px;
    background: #f9fafb;
    overflow-y: auto;
    @include mobile() {
        padding-bottom: 10px;
    }
}

label {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #374151;
}

.form-group-row {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.form-group {
    flex: 1;
}

.text-input {
    width: 100%;
    height: 38px;
    padding: 8px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;

    @media screen and (max-width: 768px) {
        height: 48px;
    }

    @media screen and (max-width: 480px) {
        height: 58px;
    }
}

.modal-footer {
    display: flex;
    align-items: center;
    height: 8vh;
    background-color: #ffffff;
    padding: 0 10px;
    gap: 5px;
    width: 100%;
    @include mobile () {
        flex-wrap: nowrap;
    }
}

.save-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $primary-orange !important;
    width: 206px;
    height: 38px;
    padding: 9px 15px 9px 17px;
    border: none;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    color: white !important;
    text-transform: none;
    letter-spacing: normal;

    @media screen and (max-width: 740px) {
        height: 38px;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        gap: 4px;
        flex: 1;
        min-width: 0;

    }
}

.save-button[disabled] {
    cursor: not-allowed;
}

.save-button svg {
    stroke: white;
}
</style>
